import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserApiService } from '../../api/services/user-api.service';
interface FormType {
    contactUsText: FormControl<string>;
}

@UntilDestroy()
@Component({
    selector: 'app-contact-us-popup',
    templateUrl: './contact-us-popup.component.html',
    styleUrls: ['./contact-us-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUsPopupComponent {
    public form!: FormGroup<FormType>;
    public isUserFeedbackSent: boolean = false;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<ContactUsPopupComponent>,
        private userApiService: UserApiService,
        private readonly cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            contactUsText: this.formBuilder.nonNullable.control('', Validators.required)
        });
    }

    private close(): void {
        this.dialogRef.close();
    }

    public sendFeedbackEmail(): void {
        if (this.form.valid) {
            this.userApiService
                .sendUserFeedback({ body: { feedback: this.form.value.contactUsText! } })
                .pipe(untilDestroyed(this))
                .subscribe({
                    next: () => {
                        this.isUserFeedbackSent = true;
                        setTimeout(() => {
                            this.close();
                        }, 10000);
                        this.cdr.markForCheck();
                    }
                });
        }
    }
}
