import { createReducer, on } from '@ngrx/store';
import {
    adminGetProducerStats,
    adminGetProducerStatsFailure,
    adminGetProducerStatsSuccess,
    adminGetStructurePopularityStatistics,
    adminGetStructurePopularityStatisticsFailure,
    adminGetStructurePopularityStatisticsSuccess,
    adminGetSupplierPopularityStatistics,
    adminGetSupplierPopularityStatisticsFailure,
    adminGetSupplierPopularityStatisticsSuccess,
    adminGetUserRoleStatistics,
    adminGetUserRoleStatisticsFailure,
    adminGetUserRoleStatisticsSuccess
} from './admin-producer-analytics.actions';
import { AdminProducerAnalyticsState } from './admin-producer-analytics.state';

export const initialState: AdminProducerAnalyticsState = {
    producerStats: null,
    producerStatsInProgress: false,
    producerStatsError: null,
    roleUserStat: null,
    roleUserStatInProgress: false,
    roleUserStatError: null,
    SupplierPopularityStatistics: null,
    SupplierPopularityStatisticsInProgress: false,
    SupplierPopularityStatisticsError: null,
    structurePopularityStatistics: null,
    structurePopularityStatisticsInProgress: false,
    structurePopularityStatisticsError: null
};

export const adminProducerAnalyticsReducer = createReducer(
    initialState,
    on(adminGetProducerStats, (state) => ({
        ...state,
        producerStatsInProgress: true,
        producerStatsError: null
    })),
    on(adminGetProducerStatsSuccess, (state, { producerStats }) => ({
        ...state,
        producerStats,
        producerStatsInProgress: false
    })),
    on(adminGetProducerStatsFailure, (state, { error }) => ({
        ...state,
        producerStatsInProgress: false,
        producerStatsError: error
    })),

    on(adminGetUserRoleStatistics, (state) => ({
        ...state,
        roleUserStatInProgress: true,
        roleUserStatError: null
    })),
    on(adminGetUserRoleStatisticsSuccess, (state, { roleUserStat }) => ({
        ...state,
        roleUserStat,
        roleUserStatInProgress: false
    })),
    on(adminGetUserRoleStatisticsFailure, (state, { error }) => ({
        ...state,
        roleUserStatInProgress: false,
        roleUserStatError: error
    })),

    on(adminGetSupplierPopularityStatistics, (state) => ({
        ...state,
        SupplierPopularityStatisticsInProgress: true,
        SupplierPopularityStatisticsError: null
    })),
    on(adminGetSupplierPopularityStatisticsSuccess, (state, { SupplierPopularityStatistics }) => ({
        ...state,
        SupplierPopularityStatistics,
        SupplierPopularityStatisticsInProgress: false
    })),
    on(adminGetSupplierPopularityStatisticsFailure, (state, { error }) => ({
        ...state,
        SupplierPopularityStatisticsInProgress: false,
        SupplierPopularityStatisticsError: error
    })),

    on(adminGetStructurePopularityStatistics, (state) => ({
        ...state,
        structurePopularityStatisticsInProgress: true,
        structurePopularityStatisticsError: null
    })),
    on(
        adminGetStructurePopularityStatisticsSuccess,
        (state, { structurePopularityStatistics }) => ({
            ...state,
            structurePopularityStatistics,
            structurePopularityStatisticsInProgress: false
        })
    ),
    on(adminGetStructurePopularityStatisticsFailure, (state, { error }) => ({
        ...state,
        structurePopularityStatisticsInProgress: false,
        structurePopularityStatisticsError: error
    }))
);
