import { createReducer, on } from '@ngrx/store';
import { AdminTab } from 'app/enums/admin-tab.enum';
import dayjs from 'dayjs';
import { isSetDateTimeValue, setDateTimeValue, setTabName } from './admin-tab-config.actions';
import { TabConfigState } from './admin-tab-config.state';

export const initialState: TabConfigState = {
    tabName: AdminTab.BuildingAndSuperStructureFunctions,
    date: dayjs(new Date()).format(),
    isDateTimeValueChecked: false
};

export const tabConfigReducer = createReducer(
    initialState,
    on(setTabName, (state, { tabName }) => ({
        ...state,
        tabName
    })),
    on(setDateTimeValue, (state, { date }) => ({
        ...state,
        date
    })),
    on(isSetDateTimeValue, (state, { isDateTimeValueChecked }) => ({
        ...state,
        isDateTimeValueChecked
    }))
);
