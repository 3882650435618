import { createAction, props } from '@ngrx/store';
import { GeoRegionArchiveEntity } from 'app/api';

export const getGeoRegions = createAction(
    '[Geo Regions Tab] Get geo regions from backend',
    props<{ date: string | null }>()
);

export const getGeoRegionsSuccess = createAction(
    '[Geo Regions Tab] Get geo regions from backend success',
    props<{ geoRegions: Array<GeoRegionArchiveEntity> }>()
);

export const getGeoRegionsFailure = createAction(
    '[Geo Regions Tab] Get geo regions from backend failure',
    props<{ error: string | null }>()
);

export const geoRegionArchives = createAction(
    '[Geo Regions Tab] Get geo region archives from backend',
    props<{ id: string }>()
);

export const geoRegionArchivesSuccess = createAction(
    '[Geo Regions Tab] Get geo region archives from backend success',
    props<{ id: string; geoRegionArchives: Array<GeoRegionArchiveEntity> }>()
);

export const geoRegionArchivesFailure = createAction(
    '[Geo Regions Tab] Get geo region archives from backend failure',
    props<{ error: string | null }>()
);

export const editGeoRegions = createAction(
    '[Geo Regions Tab] Edit geo regions from backend',
    props<{ id: string; multiplier: number }>()
);

export const editGeoRegionsSuccess = createAction(
    '[Geo Regions Tab] Edit geo regions from backend success',
    props<{ id: string; multiplier: number }>()
);

export const editGeoRegionsFailure = createAction(
    '[Geo Regions Tab] Edit geo regions from backend failure',
    props<{ error: string | null }>()
);

export const logout = createAction('[Header] Logout');
export const clearGeoRegionsData = createAction('[Geo Regions Tab] Clear geo regions data');
