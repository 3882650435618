<ng-container *ngIf="summaryTableService.summaryTableComponent">
    <div class="flex w-full items-center">
        <button
            *ngIf="element.isCategory && !summaryTableService.summaryTableComponent.editedRow?.id"
            mat-icon-button
            (click)="onAddNewRow(element)"
        >
            <mat-icon
                class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
                >add</mat-icon
            >
        </button>
        <ng-container *ngIf="element.isCategory">
            <ng-container
                *ngLet="{
                    entriesForCategory: element.workType | invoke : $any(getEntriesFor),
                    subCategories: element.workType | invoke : $any(getSubcategoriesFor)
                } as ctx"
            >
                <ng-container
                    *ngIf="element.id !== summaryTableService.summaryTableComponent.editedRow?.id"
                >
                    <button
                        *ngIf="
                            $any(ctx?.entriesForCategory)?.length > 0 ||
                            $any(ctx?.subCategories)?.length > 0
                        "
                        mat-icon-button
                        (click)="onEditRow(element)"
                    >
                        <mat-icon
                            class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
                        >
                            edit
                        </mat-icon>
                    </button>
                </ng-container>
                <ng-container
                    *ngIf="element.id === summaryTableService.summaryTableComponent.editedRow?.id"
                >
                    <button mat-icon-button (click)="onSaveCategoryVatRate(element)">
                        <mat-icon>save</mat-icon>
                    </button>

                    <button mat-icon-button (click)="onUndoCategoryVatRateEdit()">
                        <mat-icon>undo</mat-icon>
                    </button>
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!element.isCategory">
            <ng-container
                *ngIf="element.id !== summaryTableService.summaryTableComponent.editedRow?.id"
            >
                <button mat-icon-button (click)="onDeleteNewRow(element)">
                    <mat-icon
                        class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
                    >
                        delete
                    </mat-icon>
                </button>

                <button mat-icon-button (click)="onEditRow(element)">
                    <mat-icon
                        class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
                        >edit
                    </mat-icon>
                </button>
            </ng-container>

            <ng-container
                *ngIf="element.id === summaryTableService.summaryTableComponent.editedRow?.id"
            >
                <button mat-icon-button (click)="onSaveRow(element)">
                    <mat-icon>save</mat-icon>
                </button>

                <button mat-icon-button (click)="onUndoRow()">
                    <mat-icon>undo</mat-icon>
                </button>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
