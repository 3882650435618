import { createReducer, on } from '@ngrx/store';
import {
    clearBaseData,
    getCurrencies,
    getCurrenciesFailure,
    getCurrenciesSuccess,
    getRoles,
    getRolesFailure,
    getRolesSuccess,
    getSubscriptionTypeInfo,
    getSubscriptionTypeInfoFailure,
    getSubscriptionTypeInfoSuccess
} from './base.actions';
import { BaseState } from './base.state';

export const initialState: BaseState = {
    currencies: [],
    currenciesInProgress: false,
    currenciesError: null,
    roles: [],
    subscriptionTypeInfo: []
};

export const baseReducer = createReducer(
    initialState,
    on(getCurrencies, (state) => ({
        ...state,
        currenciesInProgress: true,
        currenciesError: null
    })),
    on(getCurrenciesSuccess, (state, { currencies }) => ({
        ...state,
        currencies,
        currenciesInProgress: false,
        currenciesError: null
    })),
    on(getCurrenciesFailure, (state, { error }) => ({
        ...state,
        currenciesInProgress: false,
        currenciesError: error
    })),
    on(getRoles, (state) => ({
        ...state,
        roles: []
    })),
    on(getRolesSuccess, (state, { roles }) => ({
        ...state,
        roles
    })),
    on(getRolesFailure, (state, { error }) => ({
        ...state,
        error
    })),
    on(getSubscriptionTypeInfo, (state) => ({
        ...state,
        subscriptionTypeInfo: []
    })),
    on(getSubscriptionTypeInfoSuccess, (state, { subscriptionTypeInfo }) => ({
        ...state,
        subscriptionTypeInfo
    })),
    on(getSubscriptionTypeInfoFailure, (state, { error }) => ({
        ...state,
        error
    })),
    on(clearBaseData, (state) => ({
        ...initialState
    }))
);
