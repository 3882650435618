import { DraftPhaseEntity } from 'app/api';
import { WorkEntry } from '../components/summary-table/summary-table.component';

export const SPECIAL_CATEGORY_OMNI_CLASS = '21-02';
export const entryWorkTypeMajorOnlyCostBuilding = [1, 2, 3, 4, 6, 7, 8, 9, 10, 12, 13, 17];

export function getProjectSubTotal(workEntries: WorkEntry[]): number {
    return workEntries
        .filter(Boolean)
        .filter((entry) => !entry.shadowed)
        .filter((entry) => !entry.isCategory)
        .reduce((acc, entry) => acc + (entry.value ?? 0), 0);
}
export function getProjectSubTotalLabourCost(workEntries: WorkEntry[]): number {
    return workEntries
        .filter(Boolean)
        .filter((entry) => !entry.shadowed)
        .filter((entry) => !entry.isCategory)
        .reduce((acc, entry) => acc + (entry.labourCost ?? 0), 0);
}

export function getStructureTotalValue(workEntries: WorkEntry[]): number {
    return workEntries
        .filter(Boolean)
        .filter((entry) => !entry.shadowed)
        .filter((entry) => !entry.isCategory)
        .reduce((acc, val) => {
            if (val.workType?.major != 2 || val.shadowed) return acc;

            return acc + val.value!;
        }, 0);
}

export function getStructureTotalLabourCost(workEntries: WorkEntry[]): number {
    return workEntries
        .filter(Boolean)
        .filter((entry) => !entry.shadowed)
        .filter((entry) => !entry.isCategory)
        .reduce((acc, val) => {
            if (val.workType?.major != 2 || val.shadowed) return acc;

            return acc + val.labourCost!;
        }, 0);
}

export function getProjectFinalTotalValue(
    project: DraftPhaseEntity,
    workEntries: WorkEntry[]
): number {
    return (
        getProjectSubTotal(workEntries) +
        getProjectSubTotalLabourCost(workEntries) +
        getProjectDesignBudgetValueOnlyCostBuilding(project, workEntries) +
        getProjectGeneralPlanningCost(project, workEntries)
    );
}

export function getProjectDesignBudgetValue(
    project: DraftPhaseEntity,
    workEntries: WorkEntry[]
): number {
    return !project?.designBudget || isNaN(project.designBudget)
        ? 0
        : (getProjectSubTotalOnlyCostBuilding(workEntries) +
              getProjectSubTotalLabourCostOnlyCostBuilding(workEntries)) *
              (+project.designBudget / 100);
}

export function getProjectGeneralPlanningCost(
    project: DraftPhaseEntity,
    workEntries: WorkEntry[]
): number {
    return (
        (getProjectSubTotal(workEntries) +
            getProjectSubTotalLabourCost(workEntries) +
            getProjectDesignBudgetValue(project, workEntries)) *
        (project.generalPlanningCost / 100)
    );
}

export const TAX_RATE = 1.27;

export function getProjectFinalTotalValueWithTax(
    project: DraftPhaseEntity,
    workEntries: WorkEntry[]
): number {
    return (
        getProjectSubTotalValueWithTax(workEntries) +
        getProjectSubTotalLabourCostWithTax(workEntries) +
        (getProjectDesignBudgetValueOnlyCostBuilding(project, workEntries) +
            getProjectGeneralPlanningCost(project, workEntries)) *
            TAX_RATE
    );
}

export function getProjectSubTotalValueWithTax(workEntries: WorkEntry[]): number {
    return workEntries
        .filter(Boolean)
        .filter((entry) => !entry.shadowed)
        .filter((entry) => !entry.isCategory)
        .reduce((acc, entry) => acc + (entry.value ?? 0) * (1 + (entry.vatRate ?? 0) / 100), 0);
}
export function getProjectSubTotalLabourCostWithTax(workEntries: WorkEntry[]): number {
    return workEntries
        .filter(Boolean)
        .filter((entry) => !entry.shadowed)
        .filter((entry) => !entry.isCategory)
        .reduce(
            (acc, entry) => acc + (entry.labourCost ?? 0) * (1 + (entry.vatRate ?? 0) / 100),
            0
        );
}

/* OnlyCostBuilding */
export function getProjectSubTotalOnlyCostBuilding(workEntries: WorkEntry[]): number {
    return workEntries
        .filter(Boolean)
        .filter((entry) => !entry.shadowed)
        .filter((entry) => !entry.isCategory)
        .filter((entry) =>
            entry.workType?.major
                ? entryWorkTypeMajorOnlyCostBuilding.includes(entry.workType?.major)
                : false
        )
        .reduce((acc, entry) => acc + (entry.value ?? 0), 0);
}

export function getProjectSubTotalLabourCostOnlyCostBuilding(workEntries: WorkEntry[]): number {
    return workEntries
        .filter(Boolean)
        .filter((entry) => !entry.shadowed)
        .filter((entry) => !entry.isCategory)
        .filter((entry) =>
            entry.workType?.major
                ? entryWorkTypeMajorOnlyCostBuilding.includes(entry.workType?.major)
                : false
        )
        .reduce((acc, entry) => acc + (entry.labourCost ?? 0), 0);
}

export function getProjectFinalTotalValueOnlyCostBuilding(
    project: DraftPhaseEntity,
    workEntries: WorkEntry[]
): number {
    return (
        getProjectSubTotalOnlyCostBuilding(workEntries) +
        getProjectSubTotalLabourCostOnlyCostBuilding(workEntries) +
        getProjectDesignBudgetValueOnlyCostBuilding(project, workEntries) /* +
        getProjectGeneralPlanningCost(project, workEntries) */
    );
}

export function getProjectDesignBudgetValueOnlyCostBuilding(
    project: DraftPhaseEntity,
    workEntries: WorkEntry[]
): number {
    return !project?.designBudget || isNaN(project.designBudget)
        ? 0
        : (getStructureTotalValueOnlyCostBuilding(workEntries) +
              getStructureTotalLabourCostOnlyCostBuilding(workEntries)) *
              (+project.designBudget / 100);
}
export function getStructureTotalValueOnlyCostBuilding(workEntries: WorkEntry[]): number {
    return workEntries
        .filter(Boolean)
        .filter((entry) => !entry.shadowed)
        .filter((entry) => !entry.isCategory)
        .filter((entry) =>
            entry.workType?.major
                ? entryWorkTypeMajorOnlyCostBuilding.includes(entry.workType?.major)
                : false
        )
        .reduce((acc, val) => acc + (val.value ?? 0), 0);
}

export function getStructureTotalLabourCostOnlyCostBuilding(workEntries: WorkEntry[]): number {
    return workEntries
        .filter(Boolean)
        .filter((entry) => !entry.shadowed)
        .filter((entry) => !entry.isCategory)
        .filter((entry) =>
            entry.workType?.major
                ? entryWorkTypeMajorOnlyCostBuilding.includes(entry.workType?.major)
                : false
        )
        .reduce((acc, val) => acc + (val.labourCost ?? 0), 0);
}
