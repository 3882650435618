import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    AdminApiService,
    LandImprovementTypeArchiveEntity,
    LandImprovementTypeEntity
} from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as LandImprovementTypesActions from './land-improvement-types.actions';

@Injectable()
export class LandImprovementTypesEffects {
    constructor(
        private actions$: Actions,
        public adminApiService: AdminApiService,
        private readonly router: Router
    ) {}

    AdminGetLandImprovementTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LandImprovementTypesActions.getLandImprovementTypes),
            switchMap(({ date }) => {
                return this.adminApiService
                    .filterLandImprovementTypesByDate({ body: { queryDate: date } })
                    .pipe(
                        map((typeData: LandImprovementTypeArchiveEntity[]) => {
                            return LandImprovementTypesActions.getLandImprovementTypesSuccess({
                                landImprovementTypes: typeData
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                LandImprovementTypesActions.getLandImprovementTypesFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    AdminGetLandImprovementTypeArchies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LandImprovementTypesActions.getLandImprovementTypeArchive),
            switchMap(({ id }) => {
                return this.adminApiService.getLandImprovementTypeHistoryById({ id }).pipe(
                    map((landImprovementTypeArchives) => {
                        return LandImprovementTypesActions.getLandImprovementTypeArchiveSuccess({
                            id,
                            landImprovementTypeArchives
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            LandImprovementTypesActions.getLandImprovementTypeArchiveFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );

    AdminEditLandImprovementTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LandImprovementTypesActions.editLandImprovementTypes),
            switchMap(({ id, categoryName, multiplier, labourCost, value }) => {
                return this.adminApiService
                    .updateLandImprovementType({
                        id,
                        body: { categoryName, multiplier, labourCost, value }
                    })
                    .pipe(
                        map((newValueData: LandImprovementTypeEntity) => {
                            return LandImprovementTypesActions.editLandImprovementTypesSuccess({
                                id,
                                categoryName: newValueData.categoryName!,
                                multiplier: newValueData.multiplier,
                                labourCost: newValueData.labourCost,
                                value: newValueData.value
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                LandImprovementTypesActions.editLandImprovementTypesFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LandImprovementTypesActions.logout),
            map(() => LandImprovementTypesActions.clearLandImprovementTypesData())
        )
    );
}
