import { createReducer, on } from '@ngrx/store';
import {
    clearFenceDevTypesData,
    getFenceDevApplianceTypeArchive,
    getFenceDevApplianceTypeArchiveFailure,
    getFenceDevApplianceTypeArchiveSuccess,
    getFenceDevApplianceTypes,
    getFenceDevApplianceTypesFailure,
    getFenceDevApplianceTypesSuccess
} from './fence-dev.actions';
import { FenceDevState } from './fence-dev.state';

export const initialState: FenceDevState = {
    fenceDevApplianceTypes: [],
    fenceDevApplianceTypesInProgress: false,
    fenceDevApplianceTypesError: null,
    fenceDevApplianceTypeArchives: [],
    fenceDevApplianceTypeArchivesInProgress: false,
    fenceDevApplianceTypeArchivesError: null
};

export const fenceDevReducer = createReducer(
    initialState,
    /* Get Appliance */
    on(getFenceDevApplianceTypes, (state) => ({
        ...state,
        fenceDevApplianceTypesInProgress: true,
        fenceDevApplianceTypesError: null
    })),
    on(getFenceDevApplianceTypesSuccess, (state, { fenceDevApplianceTypes }) => ({
        ...state,
        fenceDevApplianceTypes,
        fenceDevApplianceTypesInProgress: false,
        fenceDevApplianceTypesError: null
    })),
    on(getFenceDevApplianceTypesFailure, (state, { error }) => ({
        ...state,
        fenceDevApplianceTypesInProgress: false,
        fenceDevApplianceTypesError: error
    })),
    /* Get Appliance Archives */
    on(getFenceDevApplianceTypeArchive, (state, { id }) => ({
        ...state,
        fenceDevApplianceTypeArchivesInProgress: true,
        fenceDevApplianceTypeArchivesError: null
    })),
    on(getFenceDevApplianceTypeArchiveSuccess, (state, { fenceDevApplianceTypeArchives }) => ({
        ...state,
        fenceDevApplianceTypeArchives,
        fenceDevApplianceTypeArchivesInProgress: false,
        fenceDevApplianceTypeArchivesError: null
    })),
    on(getFenceDevApplianceTypeArchiveFailure, (state, { error }) => ({
        ...state,
        fenceDevApplianceTypeArchivesInProgress: false,
        fenceDevApplianceTypeArchivesError: error
    })),
    on(clearFenceDevTypesData, (state) => ({
        ...initialState
    }))
);
