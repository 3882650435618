import { createReducer, on } from '@ngrx/store';
import {
    clearItemManagementData,
    editTypeValues,
    editTypeValuesFailure,
    editTypeValuesSuccess,
    getTypeValueArchive,
    getTypeValueArchiveFailure,
    getTypeValueArchiveSuccess,
    getTypeValues,
    getTypeValuesFailure,
    getTypeValuesSuccess
} from './item-management.actions';
import { ItemManagementState } from './item-management.state';

export const initialState: ItemManagementState = {
    typeValues: [],
    typeValuesInProgress: false,
    typeValuesError: null,
    typeValueArchives: [],
    typeValueArchivesInProgress: false,
    typeValueArchivesError: null
};

export const itemManagementReducer = createReducer(
    initialState,
    on(getTypeValues, (state) => ({
        ...state,
        typeValuesInProgress: true,
        typeValuesError: null
    })),
    on(getTypeValuesSuccess, (state, { typeValues }) => ({
        ...state,
        typeValues,
        typeValuesInProgress: false,
        typeValuesError: null
    })),
    on(getTypeValuesFailure, (state, { error }) => ({
        ...state,
        typeValuesInProgress: false,
        typeValuesError: error
    })),

    on(getTypeValueArchive, (state, { id }) => ({
        ...state,
        typeValueArchiveInProgress: true,
        typeValueArchiveError: null
    })),
    on(getTypeValueArchiveSuccess, (state, { typeValueArchives }) => ({
        ...state,
        typeValueArchives,
        typeValuesInProgress: false,
        typeValueArchiveError: null
    })),
    on(getTypeValueArchiveFailure, (state, { error }) => ({
        ...state,
        typeValuesInProgress: false,
        typeValueArchiveError: error
    })),
    on(editTypeValues, (state) => ({
        ...state,
        typeValuesInProgress: true,
        typeValuesError: null
    })),
    on(editTypeValuesSuccess, (state, { value }) => ({
        ...state,
        value,
        typeValuesInProgress: false,
        typeValuesError: null
    })),
    on(editTypeValuesFailure, (state, { error }) => ({
        ...state,
        typeValuesInProgress: false,
        typeValuesError: error
    })),
    on(clearItemManagementData, (state) => ({
        ...initialState
    }))
);
