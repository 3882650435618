<ng-container *ngIf="summaryTableService.summaryTableComponent">
    <mat-checkbox
        *ngIf="!element.isCategory"
        (click)="$event.stopPropagation()"
        (change)="onShadowedChange($event.checked, element)"
        [checked]="element.shadowed"
        [disabled]="
            summaryTableService.summaryTableComponent.readonly ||
            element.id !== summaryTableService.summaryTableComponent.editedRow?.id
        "
        color="primary"
    >
    </mat-checkbox>
</ng-container>
