import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
    Translation,
    translocoConfig,
    TranslocoLoader,
    TranslocoModule,
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER
} from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { environment } from '../environments/environment';
import { BrowserLanguageCode } from './store/language/language-code.enum';
import {
    selectActiveLanguageCode,
    selectAvailableLanguages
} from './store/language/language.selectors';
@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}
    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }
}
@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            provide: TRANSLOCO_CONFIG,
            useFactory: (store: Store) => {
                let availableLangs: Array<BrowserLanguageCode> = [];
                store
                    .select(selectAvailableLanguages)
                    .pipe(take(1))
                    .subscribe((langs) => (availableLangs = langs));

                let activeLang: BrowserLanguageCode = BrowserLanguageCode.ENGLISH;
                store
                    .select(selectActiveLanguageCode)
                    .pipe(take(1))
                    .subscribe((lang) => (activeLang = lang));

                return translocoConfig({
                    availableLangs: availableLangs,
                    defaultLang: activeLang,
                    fallbackLang: 'hu',
                    missingHandler: {
                        useFallbackTranslation: true
                    },
                    // Remove this option if your application doesn't support changing language in runtime.
                    reRenderOnLangChange: true,
                    prodMode: environment.production
                });
            },
            deps: [Store]
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
    ]
})
export class TranslocoRootModule {}
