/* eslint-disable no-unused-vars */
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MockApiInterceptor implements HttpInterceptor {
    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let mockResponse$: Observable<any> | undefined = undefined;

        /* if (req.url.includes('/project')) {
            mockResponse$ = this.handleMockGetProjects();
        } */

        if (!mockResponse$) {
            return next.handle(req);
        } else {
            return timer(2000).pipe(
                first(),
                switchMap(() => {
                    return mockResponse$!;
                }),
                first(),
                map(
                    (response) =>
                        new HttpResponse({
                            body: response,
                            status: 200,
                            statusText: 'ok'
                        })
                )
            );
        }
    }

    private handleMockLogin(): Observable<any> {
        return of({
            access_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c'
        });
    }
    private handleMockGetProjects(): Observable<any> {
        return of({
            total: 0,
            page: 0,
            perPage: 0,
            data: [
                {
                    id: 'string',
                    name: 'string',
                    createdAt: '2023-03-22T09:30:35.565Z',
                    updatedAt: '2023-03-22T09:30:35.565Z',
                    ownerId: 'string',
                    size: 0,
                    designYear: 0,
                    planYear: 0,
                    plotSize: 0,
                    zipCode: 'string',
                    city: 'string',
                    street: 'string',
                    streetNo: 'string',
                    lotNo: 'string',
                    currencyId: 'string',
                    buildingStructureId: 'string',
                    plotTypeId: 'string',
                    buildingFunctionId: 'string',
                    geoRegionId: 'string',
                    designBudget: 0,
                    ifcId: 'string'
                },
                {
                    id: 'string',
                    name: 'string',
                    createdAt: '2023-03-22T09:30:35.565Z',
                    updatedAt: '2023-03-22T09:30:35.565Z',
                    ownerId: 'string',
                    size: 0,
                    designYear: 0,
                    planYear: 0,
                    plotSize: 0,
                    zipCode: 'string',
                    city: 'string',
                    street: 'string',
                    streetNo: 'string',
                    lotNo: 'string',
                    currencyId: 'string',
                    buildingStructureId: 'string',
                    plotTypeId: 'string',
                    buildingFunctionId: 'string',
                    geoRegionId: 'string',
                    designBudget: 0,
                    ifcId: 'string'
                }
            ]
        });
    }
}
