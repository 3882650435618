/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseApiService } from '../base-api-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FeedbackDto } from '../models/feedback-dto';
import { ProfileUserEntity } from '../models/profile-user-entity';
import { PublicUserEntity } from '../models/public-user-entity';
import { UpdateArchitectProfileDto } from '../models/update-architect-profile-dto';
import { UpdateProfileDto } from '../models/update-profile-dto';

@Injectable({
    providedIn: 'root'
})
export class UserApiService extends BaseApiService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Path part for operation getCurrentUser
     */
    static readonly GetCurrentUserPath = '/user/me';

    /**
     * Returns the currently logged in user.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getCurrentUser()` instead.
     *
     * This method doesn't expect any request body.
     */
    getCurrentUser$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<PublicUserEntity>> {
        const rb = new RequestBuilder(this.rootUrl, UserApiService.GetCurrentUserPath, 'get');
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<PublicUserEntity>;
                })
            );
    }

    /**
     * Returns the currently logged in user.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getCurrentUser$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getCurrentUser(params?: { context?: HttpContext }): Observable<PublicUserEntity> {
        return this.getCurrentUser$Response(params).pipe(
            map((r: StrictHttpResponse<PublicUserEntity>) => r.body as PublicUserEntity)
        );
    }

    /**
     * Path part for operation inquireProfileDelete
     */
    static readonly InquireProfileDeletePath = '/user/inquire-profile-delete';

    /**
     * Inquires a profile delete.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `inquireProfileDelete()` instead.
     *
     * This method doesn't expect any request body.
     */
    inquireProfileDelete$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            UserApiService.InquireProfileDeletePath,
            'post'
        );
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Inquires a profile delete.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `inquireProfileDelete$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    inquireProfileDelete(params?: { context?: HttpContext }): Observable<void> {
        return this.inquireProfileDelete$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation updateProfile
     */
    static readonly UpdateProfilePath = '/user';

    /**
     * Updates currently logged in user profile.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateProfile()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateProfile$Response(params: {
        context?: HttpContext;
        body: UpdateProfileDto;
    }): Observable<StrictHttpResponse<PublicUserEntity>> {
        const rb = new RequestBuilder(this.rootUrl, UserApiService.UpdateProfilePath, 'patch');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<PublicUserEntity>;
                })
            );
    }

    /**
     * Updates currently logged in user profile.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateProfile$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateProfile(params: {
        context?: HttpContext;
        body: UpdateProfileDto;
    }): Observable<PublicUserEntity> {
        return this.updateProfile$Response(params).pipe(
            map((r: StrictHttpResponse<PublicUserEntity>) => r.body as PublicUserEntity)
        );
    }

    /**
     * Path part for operation updateArchitectProfile
     */
    static readonly UpdateArchitectProfilePath = '/user/architect-profile';

    /**
     * Updates currently logged in user's architect profile.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateArchitectProfile()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateArchitectProfile$Response(params: {
        context?: HttpContext;
        body: UpdateArchitectProfileDto;
    }): Observable<StrictHttpResponse<ProfileUserEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            UserApiService.UpdateArchitectProfilePath,
            'patch'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<ProfileUserEntity>;
                })
            );
    }

    /**
     * Updates currently logged in user's architect profile.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateArchitectProfile$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateArchitectProfile(params: {
        context?: HttpContext;
        body: UpdateArchitectProfileDto;
    }): Observable<ProfileUserEntity> {
        return this.updateArchitectProfile$Response(params).pipe(
            map((r: StrictHttpResponse<ProfileUserEntity>) => r.body as ProfileUserEntity)
        );
    }

    /**
     * Path part for operation sendUserFeedback
     */
    static readonly SendUserFeedbackPath = '/user/feedback';

    /**
     * Sends user feedback to customer service.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `sendUserFeedback()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    sendUserFeedback$Response(params: {
        context?: HttpContext;
        body: FeedbackDto;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(this.rootUrl, UserApiService.SendUserFeedbackPath, 'post');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Sends user feedback to customer service.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `sendUserFeedback$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    sendUserFeedback(params: { context?: HttpContext; body: FeedbackDto }): Observable<void> {
        return this.sendUserFeedback$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation sendUpgradeInquiryEmail
     */
    static readonly SendUpgradeInquiryEmailPath = '/user/upgrade-inquiry';

    /**
     * Sends inquiry mail about the upgrade option to customer service.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `sendUpgradeInquiryEmail()` instead.
     *
     * This method doesn't expect any request body.
     */
    sendUpgradeInquiryEmail$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            UserApiService.SendUpgradeInquiryEmailPath,
            'post'
        );
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Sends inquiry mail about the upgrade option to customer service.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `sendUpgradeInquiryEmail$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    sendUpgradeInquiryEmail(params?: { context?: HttpContext }): Observable<void> {
        return this.sendUpgradeInquiryEmail$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }
}
