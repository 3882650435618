import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BarionApiService } from 'app/api/services/barion-api.service';
import { selectSubscriptionTypeInfo } from 'app/store/base/base.selectors';
import { BaseState } from 'app/store/base/base.state';
import { paymentPopupRejection } from 'app/store/payment-popup/payment-popup.actions';
import { selectPaymentPopupShow } from 'app/store/payment-popup/payment-popup.selectors';
import * as UserSelectors from 'app/store/user/user.selectors';
import { UserState } from 'app/store/user/user.state';
import { Subject, combineLatest, firstValueFrom, take } from 'rxjs';

@UntilDestroy()
@Component({
    selector: 'app-payment-popup',
    templateUrl: './payment-popup.component.html',
    styleUrls: ['./payment-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentPopupComponent {
    @Input() showPopup!: Subject<boolean>;
    @Input() initShowPopup: boolean = true;

    @ViewChild('paymentPopup')
    public readonly paymentPopup!: TemplateRef<void>;

    public selectSubscriptionTypeInfo = selectSubscriptionTypeInfo;

    public dialogRef!: any;

    public user!: UserState;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly dialog: MatDialog,
        private readonly barionApiService: BarionApiService,
        public store: Store<BaseState>
    ) {}

    ngOnInit() {
        this.showPopup.pipe(untilDestroyed(this)).subscribe((v) => {
            this.showPopupProcess();
        });
    }

    async ngAfterViewInit() {
        if (this.initShowPopup)
            this.store
                .select(selectPaymentPopupShow)
                .pipe(take(1), untilDestroyed(this))
                .subscribe((paymentPopupShow) => {
                    if (paymentPopupShow) this.showPopupProcess();
                });
    }

    private showPopupProcess() {
        combineLatest([
            this.store.select(UserSelectors.selectUserState),
            this.store.select(selectSubscriptionTypeInfo)
        ])
            .pipe(take(1), untilDestroyed(this))
            .subscribe(async ([user, subscriptionTypeInfo]) => {
                this.user = user;

                this.dialogRef = this.dialog.open(this.paymentPopup, {
                    width: '500px'
                });
                const onPayPaymentPopup: string | boolean = await firstValueFrom(
                    this.dialogRef.afterClosed()
                );
                if (onPayPaymentPopup) {
                    try {
                        const data = await firstValueFrom(
                            this.barionApiService.initializePayment({
                                body: { subscriptionTypeId: subscriptionTypeInfo[1].id }
                            })
                        );

                        window.open(data.barionGatewayUrl, '_self');
                    } catch (error) {
                        console.log(error);
                    }
                } else this.store.dispatch(paymentPopupRejection());
            });
    }

    ngOnDestroy(): void {
        if (this.dialogRef) this.dialogRef.close();
    }
}
