import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AccountType, DraftPhaseApiService } from 'app/api';
import { RouteSegment } from 'app/enums/route-segments.enum';
import { setActiveLanguageCode } from 'app/store/language/language.actions';
import {
    selectActiveLanguageCode,
    selectAvailableLanguages
} from 'app/store/language/language.selectors';
import { selectUrl } from 'app/store/router/router.selector';
import { logout } from 'app/store/user/user.actions';
import * as UserSelectors from 'app/store/user/user.selectors';
import dayjs from 'dayjs';
import { combineLatest, map, Observable, of, Subject, switchMap } from 'rxjs';
import { selectSelectedProject } from '../../../store/projects/projects.selectors';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as DraftPhaseDataSelectors from '../../../store/draft-phase-creation/draft-phase-creation.selectors';
import * as TrialDraftPhaseDataSelectors from '../../../store/trial-draft-phase-creation/trial-draft-phase-creation.selectors';
import { ActivatedRoute } from '@angular/router';

export interface linkTreeInterface {
    text?: string;
    translations$?: Observable<string>;
    route: string;
}

@UntilDestroy()
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
    @Input() public userType!: string;
    @Output() public readonly sideMenuToggle = new EventEmitter<boolean>();

    public RouteSegment = RouteSegment;
    public AccountType = AccountType;

    public userAccountTypeIsUnlimited = false;
    public userAccountTypeIsPremium = false;
    public userAccountTypeIsTrial = true;
    public userAccountValidToDate!: string;

    public languageSelectOpenState = false;
    public menuOpen = false;

    public selectedLanguageTitleEnglish$!: Observable<string>;
    public selectedLanguageTitleHungarian$!: Observable<string>;

    public changingValue: Subject<boolean> = new Subject();

    public setActiveLanguageCode = setActiveLanguageCode;
    public selectUserState = UserSelectors.selectUserState;
    public selectAvailableLanguages = selectAvailableLanguages;
    public activeLanguageCode$ = this.store.select(selectActiveLanguageCode);
    public linkTree$ = combineLatest([
        this.store.select(selectUrl),
        // THE HTTP call is slow WE NEED TO PASS draft phase name here
        this.route.queryParams.pipe(map((queryParams) => queryParams['projectId'])),
        this.store.select(selectSelectedProject),
        this.store.select(DraftPhaseDataSelectors.selectDraftPhaseData),
        this.store.select(TrialDraftPhaseDataSelectors.selectDraftPhaseData)
    ]).pipe(
        switchMap(
            ([url, draftPhaseId, currentProject, currentDraftPhase, trialCurrentDraftPhase]) => {
                const draftPhase = !url.includes(RouteSegment.DraftPhaseCreation)
                    ? of(null)
                    : currentDraftPhase
                    ? of(currentDraftPhase)
                    : trialCurrentDraftPhase
                    ? of(trialCurrentDraftPhase)
                    : draftPhaseId
                    ? this.draftPhaseApiService.getDraftPhase({ id: draftPhaseId })
                    : of(null);

                return combineLatest([of(url), of(currentProject), draftPhase]);
            }
        ),
        map(([url, currentProject, currentDraftPhase]) => {
            console.log({ url, currentProject, currentDraftPhase });
            const currentDraftPhaseName = currentDraftPhase?.name ?? '';
            let link: linkTreeInterface[] = [];
            if (url.includes(RouteSegment.DraftPhaseCreation)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.projects'),
                        route: `/${RouteSegment.Dashboard}`
                    },
                    {
                        text: currentProject?.name,
                        route: `/${RouteSegment.Dashboard}/${RouteSegment.DraftPhase}`
                    },
                    {
                        translations$: this.translocoService.selectTranslate('menu.phases'),
                        route: `/${RouteSegment.Dashboard}/${RouteSegment.DraftPhase}`
                    },
                    {
                        text: currentDraftPhaseName,
                        translations$: !currentDraftPhaseName
                            ? this.translocoService.selectTranslate('menu.new-phases')
                            : undefined,
                        route: `./${RouteSegment.DraftPhaseCreation}/${RouteSegment.Questions}`
                    }
                ];
            } else if (url.includes(RouteSegment.DraftPhase)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.projects'),
                        route: `/${RouteSegment.Dashboard}`
                    },
                    {
                        text: currentProject?.name,
                        route: `/${RouteSegment.Dashboard}/${RouteSegment.DraftPhase}`
                    },
                    {
                        translations$: this.translocoService.selectTranslate('menu.phases'),
                        route: `/${RouteSegment.Dashboard}/${RouteSegment.DraftPhase}`
                    }
                ];
            } else if (url.includes(RouteSegment.Dashboard)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.projects'),
                        route: `/${RouteSegment.Dashboard}`
                    }
                ];
            } else if (url.includes(RouteSegment.MapView)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.map-view'),
                        route: `/${RouteSegment.MapView}`
                    }
                ];
            } else if (url.includes(RouteSegment.SalesManagement)) {
                link = [
                    {
                        translations$:
                            this.translocoService.selectTranslate('menu.sales-management'),
                        route: `/${RouteSegment.SalesManagement}`
                    }
                ];
            } else if (url.includes(RouteSegment.UserManagement)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.analytics'),
                        route: `/${RouteSegment.UserManagement}`
                    }
                ];
            } else if (url.includes(RouteSegment.ItemManagement)) {
                link = [
                    {
                        translations$:
                            this.translocoService.selectTranslate('menu.item-management'),
                        route: `/${RouteSegment.ItemManagement}`
                    }
                ];
            } else if (url.includes(RouteSegment.Profile)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.profile'),
                        route: `/${RouteSegment.Profile}`
                    }
                ];
            } else if (url.includes(RouteSegment.CompareTables)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate('menu.projects'),
                        route: `/${RouteSegment.Dashboard}`
                    },
                    {
                        translations$: this.translocoService.selectTranslate(
                            'projects.table.menu.compare'
                        ),
                        route: `/${RouteSegment.CompareTables}`
                    }
                ];
            } else if (url.includes(RouteSegment.ContactPage)) {
                link = [
                    {
                        translations$: this.translocoService.selectTranslate(
                            'contact-list.name-list-link'
                        ),
                        route: `/${RouteSegment.ContactPage}`
                    }
                ];
            }
            /*  else {
              return getDefaultTitle()
            } */

            if (this.deviceService.isMobile()) {
                const parts = link.filter((l) => l.text);
                if (parts.length < 2) {
                    return [...parts, link[link.length - 1]];
                } else {
                    return parts;
                }
            }

            return link.filter(Boolean);
        })
    );

    constructor(
        private readonly translocoService: TranslocoService,
        readonly store: Store,
        private readonly cdr: ChangeDetectorRef,
        protected readonly deviceService: DeviceDetectorService,
        private readonly draftPhaseApiService: DraftPhaseApiService,
        private readonly route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.store
            .select(UserSelectors.selectUserState)
            .pipe(untilDestroyed(this))
            .subscribe((user) => {
                /* TODO too many varibles */
                if (user.accountType === AccountType.Unlimited) {
                    this.userAccountTypeIsUnlimited = true;
                }
                if (user.accountType === AccountType.Premium) {
                    this.userAccountTypeIsPremium = true;
                }
                if (user.accountType === AccountType.Trial) {
                    this.userAccountTypeIsTrial = false;
                }

                if (user.subscription[0] && user.subscription[0].validTo) {
                    this.userAccountValidToDate = dayjs(user.subscription[0].validTo).format(
                        'YYYY.MM.DD HH:mm'
                    );
                }

                this.cdr.markForCheck();
            });

        this.selectedLanguageTitleEnglish$ = this.translocoService.selectTranslate(
            'header.languages.english'
        );
        this.selectedLanguageTitleHungarian$ = this.translocoService.selectTranslate(
            'header.languages.hungarian'
        );
    }

    onLogout(): void {
        this.store.dispatch(logout());
    }

    onClickShowPopup() {
        this.changingValue.next(true);
    }

    logoClick() {
        this.sideMenuToggle.next(true);
    }
}
