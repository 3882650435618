/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseApiService } from '../base-api-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApplianceItemArchiveEntity } from '../models/appliance-item-archive-entity';
import { ApplianceItemEntity } from '../models/appliance-item-entity';
import { ApplianceTypeEntity } from '../models/appliance-type-entity';
import { ApplianceTypeWithArchiveEntity } from '../models/appliance-type-with-archive-entity';
import { BuildMethodStatEntity } from '../models/build-method-stat-entity';
import { BuildingStructureArchiveEntity } from '../models/building-structure-archive-entity';
import { BuildingStructureEntity } from '../models/building-structure-entity';
import { BuildingStructureProductEntity } from '../models/building-structure-product-entity';
import { CreateApplianceItemDto } from '../models/create-appliance-item-dto';
import { CreateApplianceTypeDto } from '../models/create-appliance-type-dto';
import { CreateBuildingStructureDto } from '../models/create-building-structure-dto';
import { CreatePlotTypeDto } from '../models/create-plot-type-dto';
import { CreateProductDto } from '../models/create-product-dto';
import { CreateSupplierDto } from '../models/create-supplier-dto';
import { CreateTypeValueDto } from '../models/create-type-value-dto';
import { DateFilterDto } from '../models/date-filter-dto';
import { DesignBudgetCategoryStatEntity } from '../models/design-budget-category-stat-entity';
import { DraftPhaseGeoRegionEntity } from '../models/draft-phase-geo-region-entity';
import { FilterUsersDto } from '../models/filter-users-dto';
import { FilterWorktypesDto } from '../models/filter-worktypes-dto';
import { GeoRegionArchiveEntity } from '../models/geo-region-archive-entity';
import { GeoRegionEntity } from '../models/geo-region-entity';
import { GeoRegionStatDetailsEntity } from '../models/geo-region-stat-details-entity';
import { LandImprovementTypeArchiveEntity } from '../models/land-improvement-type-archive-entity';
import { LandImprovementTypeEntity } from '../models/land-improvement-type-entity';
import { PlotTypeArchiveEntity } from '../models/plot-type-archive-entity';
import { PlotTypeEntity } from '../models/plot-type-entity';
import { PlotTypeStatEntity } from '../models/plot-type-stat-entity';
import { ProductEntity } from '../models/product-entity';
import { PublicUserEntity } from '../models/public-user-entity';
import { RenewableStatEntity } from '../models/renewable-stat-entity';
import { RoleUserStatEntity } from '../models/role-user-stat-entity';
import { StatQueryDto } from '../models/stat-query-dto';
import { StructurePopularityStatEntity } from '../models/structure-popularity-stat-entity';
import { SubscriptionTypeUserStatEntity } from '../models/subscription-type-user-stat-entity';
import { SupplierEntity } from '../models/supplier-entity';
import { SupplierPopularityStatEntity } from '../models/supplier-popularity-stat-entity';
import { SupplierStructureStatEntity } from '../models/supplier-structure-stat-entity';
import { TypeValueArchiveEntity } from '../models/type-value-archive-entity';
import { TypeValueEntity } from '../models/type-value-entity';
import { TypeValueWithMappingEntity } from '../models/type-value-with-mapping-entity';
import { UpdateApplianceItemDto } from '../models/update-appliance-item-dto';
import { UpdateBuildingStructureDto } from '../models/update-building-structure-dto';
import { UpdateLandImprovementTypeDto } from '../models/update-land-improvement-type-dto';
import { UpdateMultiplierDto } from '../models/update-multiplier-dto';
import { UpdateNameAndMultiplierDto } from '../models/update-name-and-multiplier-dto';
import { UpdateProductDto } from '../models/update-product-dto';
import { UpdateProfileDto } from '../models/update-profile-dto';
import { UpdateSupplierDto } from '../models/update-supplier-dto';
import { UpdateUserArchitectProfileStatusDto } from '../models/update-user-architect-profile-status-dto';
import { UpdateValueDto } from '../models/update-value-dto';
import { UpdateWorktypeFunctionDto } from '../models/update-worktype-function-dto';
import { UpdateWorktypeMappingDto } from '../models/update-worktype-mapping-dto';
import { UpsertBuildingStructureProductDto } from '../models/upsert-building-structure-product-dto';
import { UserProjectStatEntity } from '../models/user-project-stat-entity';
import { WorkTypeEntity } from '../models/work-type-entity';
import { WorkTypeWithFunctionAppliancesEntity } from '../models/work-type-with-function-appliances-entity';
import { WorkTypeWithMappingEntity } from '../models/work-type-with-mapping-entity';

@Injectable({
    providedIn: 'root'
})
export class AdminApiService extends BaseApiService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Path part for operation filterUsers
     */
    static readonly FilterUsersPath = '/admin/user';

    /**
     * Filters users, paginated, sorted and filtered.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterUsers()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterUsers$Response(params: { context?: HttpContext; body: FilterUsersDto }): Observable<
        StrictHttpResponse<{
            total?: number;
            page?: number;
            perPage?: number;
            data?: Array<PublicUserEntity>;
        }>
    > {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.FilterUsersPath, 'post');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<{
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<PublicUserEntity>;
                    }>;
                })
            );
    }

    /**
     * Filters users, paginated, sorted and filtered.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterUsers$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterUsers(params: { context?: HttpContext; body: FilterUsersDto }): Observable<{
        total?: number;
        page?: number;
        perPage?: number;
        data?: Array<PublicUserEntity>;
    }> {
        return this.filterUsers$Response(params).pipe(
            map(
                (
                    r: StrictHttpResponse<{
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<PublicUserEntity>;
                    }>
                ) =>
                    r.body as {
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<PublicUserEntity>;
                    }
            )
        );
    }

    /**
     * Path part for operation getUser
     */
    static readonly GetUserPath = '/admin/user/{id}';

    /**
     * Returns a user.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getUser()` instead.
     *
     * This method doesn't expect any request body.
     */
    getUser$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<PublicUserEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.GetUserPath, 'get');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<PublicUserEntity>;
                })
            );
    }

    /**
     * Returns a user.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getUser$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getUser(params: { id: string; context?: HttpContext }): Observable<PublicUserEntity> {
        return this.getUser$Response(params).pipe(
            map((r: StrictHttpResponse<PublicUserEntity>) => r.body as PublicUserEntity)
        );
    }

    /**
     * Path part for operation softDeleteUser
     */
    static readonly SoftDeleteUserPath = '/admin/user/{id}';

    /**
     * Soft deletes / anonymizes a user by their ID.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `softDeleteUser()` instead.
     *
     * This method doesn't expect any request body.
     */
    softDeleteUser$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.SoftDeleteUserPath, 'delete');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Soft deletes / anonymizes a user by their ID.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `softDeleteUser$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    softDeleteUser(params: { id: string; context?: HttpContext }): Observable<void> {
        return this.softDeleteUser$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation updateUserProfile
     */
    static readonly UpdateUserProfilePath = '/admin/user/{id}';

    /**
     * Updates a user profile.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateUserProfile()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateUserProfile$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateProfileDto;
    }): Observable<StrictHttpResponse<PublicUserEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.UpdateUserProfilePath, 'patch');
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<PublicUserEntity>;
                })
            );
    }

    /**
     * Updates a user profile.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateUserProfile$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateUserProfile(params: {
        id: string;
        context?: HttpContext;
        body: UpdateProfileDto;
    }): Observable<PublicUserEntity> {
        return this.updateUserProfile$Response(params).pipe(
            map((r: StrictHttpResponse<PublicUserEntity>) => r.body as PublicUserEntity)
        );
    }

    /**
     * Path part for operation setUserArchitectProfileStatus
     */
    static readonly SetUserArchitectProfileStatusPath = '/admin/user-architect-profile-status/{id}';

    /**
     * Sets the status of an architect profile of a user. Returns the updated user.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `setUserArchitectProfileStatus()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    setUserArchitectProfileStatus$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateUserArchitectProfileStatusDto;
    }): Observable<StrictHttpResponse<PublicUserEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.SetUserArchitectProfileStatusPath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<PublicUserEntity>;
                })
            );
    }

    /**
     * Sets the status of an architect profile of a user. Returns the updated user.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `setUserArchitectProfileStatus$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    setUserArchitectProfileStatus(params: {
        id: string;
        context?: HttpContext;
        body: UpdateUserArchitectProfileStatusDto;
    }): Observable<PublicUserEntity> {
        return this.setUserArchitectProfileStatus$Response(params).pipe(
            map((r: StrictHttpResponse<PublicUserEntity>) => r.body as PublicUserEntity)
        );
    }

    /**
     * Path part for operation getGeoRegionHistory
     */
    static readonly GetGeoRegionHistoryPath = '/admin/geo-region-archive/{id}';

    /**
     * Returns history data for a geo region.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getGeoRegionHistory()` instead.
     *
     * This method doesn't expect any request body.
     */
    getGeoRegionHistory$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<GeoRegionArchiveEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.GetGeoRegionHistoryPath, 'get');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<GeoRegionArchiveEntity>>;
                })
            );
    }

    /**
     * Returns history data for a geo region.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getGeoRegionHistory$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getGeoRegionHistory(params: {
        id: string;
        context?: HttpContext;
    }): Observable<Array<GeoRegionArchiveEntity>> {
        return this.getGeoRegionHistory$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<GeoRegionArchiveEntity>>) =>
                    r.body as Array<GeoRegionArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation filterGeoRegionsByDate
     */
    static readonly FilterGeoRegionsByDatePath = '/admin/geo-regions';

    /**
     * Filters geo regions by date.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterGeoRegionsByDate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterGeoRegionsByDate$Response(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<StrictHttpResponse<Array<GeoRegionArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.FilterGeoRegionsByDatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<GeoRegionArchiveEntity>>;
                })
            );
    }

    /**
     * Filters geo regions by date.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterGeoRegionsByDate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterGeoRegionsByDate(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<Array<GeoRegionArchiveEntity>> {
        return this.filterGeoRegionsByDate$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<GeoRegionArchiveEntity>>) =>
                    r.body as Array<GeoRegionArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation updateGeoRegion
     */
    static readonly UpdateGeoRegionPath = '/admin/geo-region/{id}';

    /**
     * Updates a geo region.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateGeoRegion()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateGeoRegion$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateMultiplierDto;
    }): Observable<StrictHttpResponse<GeoRegionEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.UpdateGeoRegionPath, 'patch');
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<GeoRegionEntity>;
                })
            );
    }

    /**
     * Updates a geo region.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateGeoRegion$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateGeoRegion(params: {
        id: string;
        context?: HttpContext;
        body: UpdateMultiplierDto;
    }): Observable<GeoRegionEntity> {
        return this.updateGeoRegion$Response(params).pipe(
            map((r: StrictHttpResponse<GeoRegionEntity>) => r.body as GeoRegionEntity)
        );
    }

    /**
     * Path part for operation getPlotTypeHistory
     */
    static readonly GetPlotTypeHistoryPath = '/admin/plot-type-archive/{id}';

    /**
     * Returns history data for a plot type.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getPlotTypeHistory()` instead.
     *
     * This method doesn't expect any request body.
     */
    getPlotTypeHistory$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<PlotTypeArchiveEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.GetPlotTypeHistoryPath, 'get');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<PlotTypeArchiveEntity>>;
                })
            );
    }

    /**
     * Returns history data for a plot type.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getPlotTypeHistory$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getPlotTypeHistory(params: {
        id: string;
        context?: HttpContext;
    }): Observable<Array<PlotTypeArchiveEntity>> {
        return this.getPlotTypeHistory$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<PlotTypeArchiveEntity>>) =>
                    r.body as Array<PlotTypeArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation filterPlotTypesByDate
     */
    static readonly FilterPlotTypesByDatePath = '/admin/plot-types';

    /**
     * Filters plot types by date.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterPlotTypesByDate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterPlotTypesByDate$Response(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<StrictHttpResponse<Array<PlotTypeArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.FilterPlotTypesByDatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<PlotTypeArchiveEntity>>;
                })
            );
    }

    /**
     * Filters plot types by date.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterPlotTypesByDate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterPlotTypesByDate(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<Array<PlotTypeArchiveEntity>> {
        return this.filterPlotTypesByDate$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<PlotTypeArchiveEntity>>) =>
                    r.body as Array<PlotTypeArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation deletePlotType
     */
    static readonly DeletePlotTypePath = '/admin/plot-type/{id}';

    /**
     * Deletes a plot type with history.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `deletePlotType()` instead.
     *
     * This method doesn't expect any request body.
     */
    deletePlotType$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.DeletePlotTypePath, 'delete');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Deletes a plot type with history.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `deletePlotType$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    deletePlotType(params: { id: string; context?: HttpContext }): Observable<void> {
        return this.deletePlotType$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation updatePlotType
     */
    static readonly UpdatePlotTypePath = '/admin/plot-type/{id}';

    /**
     * Updates a plot type.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updatePlotType()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updatePlotType$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateNameAndMultiplierDto;
    }): Observable<StrictHttpResponse<PlotTypeEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.UpdatePlotTypePath, 'patch');
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<PlotTypeEntity>;
                })
            );
    }

    /**
     * Updates a plot type.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updatePlotType$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updatePlotType(params: {
        id: string;
        context?: HttpContext;
        body: UpdateNameAndMultiplierDto;
    }): Observable<PlotTypeEntity> {
        return this.updatePlotType$Response(params).pipe(
            map((r: StrictHttpResponse<PlotTypeEntity>) => r.body as PlotTypeEntity)
        );
    }

    /**
     * Path part for operation createPlotType
     */
    static readonly CreatePlotTypePath = '/admin/plot-type/new';

    /**
     * Creates a new plot type.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createPlotType()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createPlotType$Response(params: {
        context?: HttpContext;
        body: CreatePlotTypeDto;
    }): Observable<StrictHttpResponse<PlotTypeEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.CreatePlotTypePath, 'post');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<PlotTypeEntity>;
                })
            );
    }

    /**
     * Creates a new plot type.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createPlotType$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createPlotType(params: {
        context?: HttpContext;
        body: CreatePlotTypeDto;
    }): Observable<PlotTypeEntity> {
        return this.createPlotType$Response(params).pipe(
            map((r: StrictHttpResponse<PlotTypeEntity>) => r.body as PlotTypeEntity)
        );
    }

    /**
     * Path part for operation getBuildingStructureHistory
     */
    static readonly GetBuildingStructureHistoryPath = '/admin/building-structure-archive/{id}';

    /**
     * Returns history data for a building structure.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getBuildingStructureHistory()` instead.
     *
     * This method doesn't expect any request body.
     */
    getBuildingStructureHistory$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<BuildingStructureArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetBuildingStructureHistoryPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<BuildingStructureArchiveEntity>>;
                })
            );
    }

    /**
     * Returns history data for a building structure.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getBuildingStructureHistory$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getBuildingStructureHistory(params: {
        id: string;
        context?: HttpContext;
    }): Observable<Array<BuildingStructureArchiveEntity>> {
        return this.getBuildingStructureHistory$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<BuildingStructureArchiveEntity>>) =>
                    r.body as Array<BuildingStructureArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation filterBuildingStructuresByDate
     */
    static readonly FilterBuildingStructuresByDatePath = '/admin/building-structures';

    /**
     * Filters building structures by date.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterBuildingStructuresByDate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterBuildingStructuresByDate$Response(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<StrictHttpResponse<Array<BuildingStructureArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.FilterBuildingStructuresByDatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<BuildingStructureArchiveEntity>>;
                })
            );
    }

    /**
     * Filters building structures by date.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterBuildingStructuresByDate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterBuildingStructuresByDate(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<Array<BuildingStructureArchiveEntity>> {
        return this.filterBuildingStructuresByDate$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<BuildingStructureArchiveEntity>>) =>
                    r.body as Array<BuildingStructureArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation deleteBuildingStructure
     */
    static readonly DeleteBuildingStructurePath = '/admin/building-structure/{id}';

    /**
     * Deletes a building structure with history.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `deleteBuildingStructure()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteBuildingStructure$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.DeleteBuildingStructurePath,
            'delete'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Deletes a building structure with history.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `deleteBuildingStructure$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteBuildingStructure(params: { id: string; context?: HttpContext }): Observable<void> {
        return this.deleteBuildingStructure$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation updateBuildingStructure
     */
    static readonly UpdateBuildingStructurePath = '/admin/building-structure/{id}';

    /**
     * Updates a building structure.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateBuildingStructure()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateBuildingStructure$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateBuildingStructureDto;
    }): Observable<StrictHttpResponse<BuildingStructureEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.UpdateBuildingStructurePath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<BuildingStructureEntity>;
                })
            );
    }

    /**
     * Updates a building structure.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateBuildingStructure$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateBuildingStructure(params: {
        id: string;
        context?: HttpContext;
        body: UpdateBuildingStructureDto;
    }): Observable<BuildingStructureEntity> {
        return this.updateBuildingStructure$Response(params).pipe(
            map(
                (r: StrictHttpResponse<BuildingStructureEntity>) =>
                    r.body as BuildingStructureEntity
            )
        );
    }

    /**
     * Path part for operation createBuildingStructure
     */
    static readonly CreateBuildingStructurePath = '/admin/building-structure/new';

    /**
     * Creates a new building structure.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createBuildingStructure()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createBuildingStructure$Response(params: {
        context?: HttpContext;
        body: CreateBuildingStructureDto;
    }): Observable<StrictHttpResponse<BuildingStructureEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.CreateBuildingStructurePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<BuildingStructureEntity>;
                })
            );
    }

    /**
     * Creates a new building structure.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createBuildingStructure$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createBuildingStructure(params: {
        context?: HttpContext;
        body: CreateBuildingStructureDto;
    }): Observable<BuildingStructureEntity> {
        return this.createBuildingStructure$Response(params).pipe(
            map(
                (r: StrictHttpResponse<BuildingStructureEntity>) =>
                    r.body as BuildingStructureEntity
            )
        );
    }

    /**
     * Path part for operation getWorkTypesWithMapping
     */
    static readonly GetWorkTypesWithMappingPath = '/admin/worktypes';

    /**
     * Retrieves work types with mapping elements array, filtered by mapping elements.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getWorkTypesWithMapping()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getWorkTypesWithMapping$Response(params: {
        context?: HttpContext;
        body: FilterWorktypesDto;
    }): Observable<StrictHttpResponse<Array<WorkTypeWithMappingEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetWorkTypesWithMappingPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkTypeWithMappingEntity>>;
                })
            );
    }

    /**
     * Retrieves work types with mapping elements array, filtered by mapping elements.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getWorkTypesWithMapping$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getWorkTypesWithMapping(params: {
        context?: HttpContext;
        body: FilterWorktypesDto;
    }): Observable<Array<WorkTypeWithMappingEntity>> {
        return this.getWorkTypesWithMapping$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<WorkTypeWithMappingEntity>>) =>
                    r.body as Array<WorkTypeWithMappingEntity>
            )
        );
    }

    /**
     * Path part for operation updateWorkType
     */
    static readonly UpdateWorkTypePath = '/admin/worktype/{id}';

    /**
     * Updates a work type mapping.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateWorkType()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateWorkType$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateWorktypeMappingDto;
    }): Observable<StrictHttpResponse<WorkTypeWithMappingEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.UpdateWorkTypePath, 'patch');
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<WorkTypeWithMappingEntity>;
                })
            );
    }

    /**
     * Updates a work type mapping.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateWorkType$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateWorkType(params: {
        id: string;
        context?: HttpContext;
        body: UpdateWorktypeMappingDto;
    }): Observable<WorkTypeWithMappingEntity> {
        return this.updateWorkType$Response(params).pipe(
            map(
                (r: StrictHttpResponse<WorkTypeWithMappingEntity>) =>
                    r.body as WorkTypeWithMappingEntity
            )
        );
    }

    /**
     * Path part for operation getTypeValues
     */
    static readonly GetTypeValuesPath = '/admin/type-values';

    /**
     * Retrieves type values with work types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getTypeValues()` instead.
     *
     * This method doesn't expect any request body.
     */
    getTypeValues$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<TypeValueWithMappingEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.GetTypeValuesPath, 'get');
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<TypeValueWithMappingEntity>>;
                })
            );
    }

    /**
     * Retrieves type values with work types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getTypeValues$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getTypeValues(params?: {
        context?: HttpContext;
    }): Observable<Array<TypeValueWithMappingEntity>> {
        return this.getTypeValues$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<TypeValueWithMappingEntity>>) =>
                    r.body as Array<TypeValueWithMappingEntity>
            )
        );
    }

    /**
     * Path part for operation filterTypeValuesByDate
     */
    static readonly FilterTypeValuesByDatePath = '/admin/type-values';

    /**
     * Filters type values by date.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterTypeValuesByDate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterTypeValuesByDate$Response(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<StrictHttpResponse<Array<TypeValueArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.FilterTypeValuesByDatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<TypeValueArchiveEntity>>;
                })
            );
    }

    /**
     * Filters type values by date.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterTypeValuesByDate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterTypeValuesByDate(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<Array<TypeValueArchiveEntity>> {
        return this.filterTypeValuesByDate$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<TypeValueArchiveEntity>>) =>
                    r.body as Array<TypeValueArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation getTypeValue
     */
    static readonly GetTypeValuePath = '/admin/type-value/{id}';

    /**
     * Returns a type value.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getTypeValue()` instead.
     *
     * This method doesn't expect any request body.
     */
    getTypeValue$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<TypeValueEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.GetTypeValuePath, 'get');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<TypeValueEntity>;
                })
            );
    }

    /**
     * Returns a type value.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getTypeValue$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getTypeValue(params: { id: string; context?: HttpContext }): Observable<TypeValueEntity> {
        return this.getTypeValue$Response(params).pipe(
            map((r: StrictHttpResponse<TypeValueEntity>) => r.body as TypeValueEntity)
        );
    }

    /**
     * Path part for operation updateTypeValue
     */
    static readonly UpdateTypeValuePath = '/admin/type-value/{id}';

    /**
     * Updates a type value.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateTypeValue()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateTypeValue$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateValueDto;
    }): Observable<StrictHttpResponse<TypeValueEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.UpdateTypeValuePath, 'patch');
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<TypeValueEntity>;
                })
            );
    }

    /**
     * Updates a type value.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateTypeValue$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateTypeValue(params: {
        id: string;
        context?: HttpContext;
        body: UpdateValueDto;
    }): Observable<TypeValueEntity> {
        return this.updateTypeValue$Response(params).pipe(
            map((r: StrictHttpResponse<TypeValueEntity>) => r.body as TypeValueEntity)
        );
    }

    /**
     * Path part for operation getTypeValueHistory
     */
    static readonly GetTypeValueHistoryPath = '/admin/type-value-archive/{id}';

    /**
     * Returns history data for a type value.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getTypeValueHistory()` instead.
     *
     * This method doesn't expect any request body.
     */
    getTypeValueHistory$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<TypeValueArchiveEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.GetTypeValueHistoryPath, 'get');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<TypeValueArchiveEntity>>;
                })
            );
    }

    /**
     * Returns history data for a type value.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getTypeValueHistory$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getTypeValueHistory(params: {
        id: string;
        context?: HttpContext;
    }): Observable<Array<TypeValueArchiveEntity>> {
        return this.getTypeValueHistory$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<TypeValueArchiveEntity>>) =>
                    r.body as Array<TypeValueArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation getWorkTypeWithoutValue
     */
    static readonly GetWorkTypeWithoutValuePath = '/admin/work-type-without-value';

    /**
     * Returns a list of work types with no value.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getWorkTypeWithoutValue()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWorkTypeWithoutValue$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<WorkTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetWorkTypeWithoutValuePath,
            'get'
        );
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkTypeEntity>>;
                })
            );
    }

    /**
     * Returns a list of work types with no value.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getWorkTypeWithoutValue$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWorkTypeWithoutValue(params?: { context?: HttpContext }): Observable<Array<WorkTypeEntity>> {
        return this.getWorkTypeWithoutValue$Response(params).pipe(
            map((r: StrictHttpResponse<Array<WorkTypeEntity>>) => r.body as Array<WorkTypeEntity>)
        );
    }

    /**
     * Path part for operation createTypeValue
     */
    static readonly CreateTypeValuePath = '/admin/type-value';

    /**
     * Creates a type value.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createTypeValue()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createTypeValue$Response(params: {
        context?: HttpContext;
        body: CreateTypeValueDto;
    }): Observable<StrictHttpResponse<TypeValueEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.CreateTypeValuePath, 'put');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<TypeValueEntity>;
                })
            );
    }

    /**
     * Creates a type value.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createTypeValue$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createTypeValue(params: {
        context?: HttpContext;
        body: CreateTypeValueDto;
    }): Observable<TypeValueEntity> {
        return this.createTypeValue$Response(params).pipe(
            map((r: StrictHttpResponse<TypeValueEntity>) => r.body as TypeValueEntity)
        );
    }

    /**
     * Path part for operation getLandImprovementTypeHistory
     */
    static readonly GetLandImprovementTypeHistoryPath = '/admin/land-improvement-type-archive';

    /**
     * Returns history data for land improvement type.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getLandImprovementTypeHistory()` instead.
     *
     * This method doesn't expect any request body.
     */
    getLandImprovementTypeHistory$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<LandImprovementTypeArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetLandImprovementTypeHistoryPath,
            'get'
        );
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<LandImprovementTypeArchiveEntity>>;
                })
            );
    }

    /**
     * Returns history data for land improvement type.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getLandImprovementTypeHistory$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getLandImprovementTypeHistory(params?: {
        context?: HttpContext;
    }): Observable<Array<LandImprovementTypeArchiveEntity>> {
        return this.getLandImprovementTypeHistory$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<LandImprovementTypeArchiveEntity>>) =>
                    r.body as Array<LandImprovementTypeArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation getLandImprovementTypeHistoryById
     */
    static readonly GetLandImprovementTypeHistoryByIdPath =
        '/admin/land-improvement-type-archive/{id}';

    /**
     * Returns history data for a land improvement type.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getLandImprovementTypeHistoryById()` instead.
     *
     * This method doesn't expect any request body.
     */
    getLandImprovementTypeHistoryById$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<LandImprovementTypeArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetLandImprovementTypeHistoryByIdPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<LandImprovementTypeArchiveEntity>>;
                })
            );
    }

    /**
     * Returns history data for a land improvement type.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getLandImprovementTypeHistoryById$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getLandImprovementTypeHistoryById(params: {
        id: string;
        context?: HttpContext;
    }): Observable<Array<LandImprovementTypeArchiveEntity>> {
        return this.getLandImprovementTypeHistoryById$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<LandImprovementTypeArchiveEntity>>) =>
                    r.body as Array<LandImprovementTypeArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation filterLandImprovementTypesByDate
     */
    static readonly FilterLandImprovementTypesByDatePath = '/admin/land-improvement-types';

    /**
     * Filters land improvement types by date.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterLandImprovementTypesByDate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterLandImprovementTypesByDate$Response(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<StrictHttpResponse<Array<LandImprovementTypeArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.FilterLandImprovementTypesByDatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<LandImprovementTypeArchiveEntity>>;
                })
            );
    }

    /**
     * Filters land improvement types by date.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterLandImprovementTypesByDate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterLandImprovementTypesByDate(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<Array<LandImprovementTypeArchiveEntity>> {
        return this.filterLandImprovementTypesByDate$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<LandImprovementTypeArchiveEntity>>) =>
                    r.body as Array<LandImprovementTypeArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation updateLandImprovementType
     */
    static readonly UpdateLandImprovementTypePath = '/admin/land-improvement-type/{id}';

    /**
     * Updates a land improvement type.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateLandImprovementType()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateLandImprovementType$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateLandImprovementTypeDto;
    }): Observable<StrictHttpResponse<LandImprovementTypeEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.UpdateLandImprovementTypePath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<LandImprovementTypeEntity>;
                })
            );
    }

    /**
     * Updates a land improvement type.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateLandImprovementType$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateLandImprovementType(params: {
        id: string;
        context?: HttpContext;
        body: UpdateLandImprovementTypeDto;
    }): Observable<LandImprovementTypeEntity> {
        return this.updateLandImprovementType$Response(params).pipe(
            map(
                (r: StrictHttpResponse<LandImprovementTypeEntity>) =>
                    r.body as LandImprovementTypeEntity
            )
        );
    }

    /**
     * Path part for operation getWorkTypeWithoutApplianceType
     */
    static readonly GetWorkTypeWithoutApplianceTypePath = '/admin/work-type-without-appliance-type';

    /**
     * Returns a list of work types wich has no appliance type.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getWorkTypeWithoutApplianceType()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWorkTypeWithoutApplianceType$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<WorkTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetWorkTypeWithoutApplianceTypePath,
            'get'
        );
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkTypeEntity>>;
                })
            );
    }

    /**
     * Returns a list of work types wich has no appliance type.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getWorkTypeWithoutApplianceType$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWorkTypeWithoutApplianceType(params?: {
        context?: HttpContext;
    }): Observable<Array<WorkTypeEntity>> {
        return this.getWorkTypeWithoutApplianceType$Response(params).pipe(
            map((r: StrictHttpResponse<Array<WorkTypeEntity>>) => r.body as Array<WorkTypeEntity>)
        );
    }

    /**
     * Path part for operation createApplianceType
     */
    static readonly CreateApplianceTypePath = '/admin/appliance-type/new';

    /**
     * Creates a new appliance and one type and item for each category.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createApplianceType()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createApplianceType$Response(params: {
        context?: HttpContext;
        body: CreateApplianceTypeDto;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.CreateApplianceTypePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeEntity>>;
                })
            );
    }

    /**
     * Creates a new appliance and one type and item for each category.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createApplianceType$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createApplianceType(params: {
        context?: HttpContext;
        body: CreateApplianceTypeDto;
    }): Observable<Array<ApplianceTypeEntity>> {
        return this.createApplianceType$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeEntity>>) =>
                    r.body as Array<ApplianceTypeEntity>
            )
        );
    }

    /**
     * Path part for operation filterApplianceItemsByDate
     */
    static readonly FilterApplianceItemsByDatePath = '/admin/appliance-items';

    /**
     * Filters appliance items by date.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterApplianceItemsByDate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterApplianceItemsByDate$Response(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.FilterApplianceItemsByDatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>;
                })
            );
    }

    /**
     * Filters appliance items by date.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterApplianceItemsByDate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterApplianceItemsByDate(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<Array<ApplianceTypeWithArchiveEntity>> {
        return this.filterApplianceItemsByDate$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>) =>
                    r.body as Array<ApplianceTypeWithArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation deleteApplianceItem
     */
    static readonly DeleteApplianceItemPath = '/admin/appliance-item/{id}';

    /**
     * Deletes an appliance item with history.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `deleteApplianceItem()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteApplianceItem$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.DeleteApplianceItemPath,
            'delete'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Deletes an appliance item with history.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `deleteApplianceItem$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteApplianceItem(params: { id: string; context?: HttpContext }): Observable<void> {
        return this.deleteApplianceItem$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation updateApplianceItem
     */
    static readonly UpdateApplianceItemPath = '/admin/appliance-item/{id}';

    /**
     * Updates an appliance item.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateApplianceItem()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateApplianceItem$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateApplianceItemDto;
    }): Observable<StrictHttpResponse<ApplianceItemEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.UpdateApplianceItemPath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<ApplianceItemEntity>;
                })
            );
    }

    /**
     * Updates an appliance item.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateApplianceItem$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateApplianceItem(params: {
        id: string;
        context?: HttpContext;
        body: UpdateApplianceItemDto;
    }): Observable<ApplianceItemEntity> {
        return this.updateApplianceItem$Response(params).pipe(
            map((r: StrictHttpResponse<ApplianceItemEntity>) => r.body as ApplianceItemEntity)
        );
    }

    /**
     * Path part for operation createApplianceItem
     */
    static readonly CreateApplianceItemPath = '/admin/appliance-item/new';

    /**
     * Creates a new appliance item.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createApplianceItem()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createApplianceItem$Response(params: {
        context?: HttpContext;
        body: CreateApplianceItemDto;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.CreateApplianceItemPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeEntity>>;
                })
            );
    }

    /**
     * Creates a new appliance item.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createApplianceItem$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createApplianceItem(params: {
        context?: HttpContext;
        body: CreateApplianceItemDto;
    }): Observable<Array<ApplianceTypeEntity>> {
        return this.createApplianceItem$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeEntity>>) =>
                    r.body as Array<ApplianceTypeEntity>
            )
        );
    }

    /**
     * Path part for operation getApplianceItemHistory
     */
    static readonly GetApplianceItemHistoryPath = '/admin/appliance-item-archive/{id}';

    /**
     * Returns history data for an appliance item.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getApplianceItemHistory()` instead.
     *
     * This method doesn't expect any request body.
     */
    getApplianceItemHistory$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceItemArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetApplianceItemHistoryPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceItemArchiveEntity>>;
                })
            );
    }

    /**
     * Returns history data for an appliance item.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getApplianceItemHistory$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getApplianceItemHistory(params: {
        id: string;
        context?: HttpContext;
    }): Observable<Array<ApplianceItemArchiveEntity>> {
        return this.getApplianceItemHistory$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceItemArchiveEntity>>) =>
                    r.body as Array<ApplianceItemArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation getWorkTypeWithoutProduct
     */
    static readonly GetWorkTypeWithoutProductPath = '/admin/work-type-without-product';

    /**
     * Returns a list of work types with no product.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getWorkTypeWithoutProduct()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWorkTypeWithoutProduct$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<WorkTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetWorkTypeWithoutProductPath,
            'get'
        );
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkTypeEntity>>;
                })
            );
    }

    /**
     * Returns a list of work types with no product.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getWorkTypeWithoutProduct$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWorkTypeWithoutProduct(params?: {
        context?: HttpContext;
    }): Observable<Array<WorkTypeEntity>> {
        return this.getWorkTypeWithoutProduct$Response(params).pipe(
            map((r: StrictHttpResponse<Array<WorkTypeEntity>>) => r.body as Array<WorkTypeEntity>)
        );
    }

    /**
     * Path part for operation filterProductsByDate
     */
    static readonly FilterProductsByDatePath = '/admin/products';

    /**
     * Filters products by date.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterProductsByDate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterProductsByDate$Response(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<StrictHttpResponse<Array<ProductEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.FilterProductsByDatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ProductEntity>>;
                })
            );
    }

    /**
     * Filters products by date.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterProductsByDate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterProductsByDate(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<Array<ProductEntity>> {
        return this.filterProductsByDate$Response(params).pipe(
            map((r: StrictHttpResponse<Array<ProductEntity>>) => r.body as Array<ProductEntity>)
        );
    }

    /**
     * Path part for operation createProduct
     */
    static readonly CreateProductPath = '/admin/product/new';

    /**
     * Creates a new product.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createProduct()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createProduct$Response(params: {
        context?: HttpContext;
        body: CreateProductDto;
    }): Observable<StrictHttpResponse<Array<ProductEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.CreateProductPath, 'post');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ProductEntity>>;
                })
            );
    }

    /**
     * Creates a new product.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createProduct$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createProduct(params: {
        context?: HttpContext;
        body: CreateProductDto;
    }): Observable<Array<ProductEntity>> {
        return this.createProduct$Response(params).pipe(
            map((r: StrictHttpResponse<Array<ProductEntity>>) => r.body as Array<ProductEntity>)
        );
    }

    /**
     * Path part for operation deleteProduct
     */
    static readonly DeleteProductPath = '/admin/product/{id}';

    /**
     * Deletes a product.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `deleteProduct()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteProduct$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.DeleteProductPath, 'delete');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Deletes a product.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `deleteProduct$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteProduct(params: { id: string; context?: HttpContext }): Observable<void> {
        return this.deleteProduct$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation updateProduct
     */
    static readonly UpdateProductPath = '/admin/product/{id}';

    /**
     * Updates a product.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateProduct()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateProduct$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateProductDto;
    }): Observable<StrictHttpResponse<ProductEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.UpdateProductPath, 'patch');
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<ProductEntity>;
                })
            );
    }

    /**
     * Updates a product.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateProduct$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateProduct(params: {
        id: string;
        context?: HttpContext;
        body: UpdateProductDto;
    }): Observable<ProductEntity> {
        return this.updateProduct$Response(params).pipe(
            map((r: StrictHttpResponse<ProductEntity>) => r.body as ProductEntity)
        );
    }

    /**
     * Path part for operation getFunctionWorkTypes
     */
    static readonly GetFunctionWorkTypesPath = '/admin/work-type-function';

    /**
     * Returns function type work types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getFunctionWorkTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getFunctionWorkTypes$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<WorkTypeWithFunctionAppliancesEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetFunctionWorkTypesPath,
            'get'
        );
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkTypeWithFunctionAppliancesEntity>>;
                })
            );
    }

    /**
     * Returns function type work types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getFunctionWorkTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getFunctionWorkTypes(params?: {
        context?: HttpContext;
    }): Observable<Array<WorkTypeWithFunctionAppliancesEntity>> {
        return this.getFunctionWorkTypes$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<WorkTypeWithFunctionAppliancesEntity>>) =>
                    r.body as Array<WorkTypeWithFunctionAppliancesEntity>
            )
        );
    }

    /**
     * Path part for operation getFunctionWorkTypeById
     */
    static readonly GetFunctionWorkTypeByIdPath = '/admin/work-type-function/{id}';

    /**
     * Returns a function type work type by id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getFunctionWorkTypeById()` instead.
     *
     * This method doesn't expect any request body.
     */
    getFunctionWorkTypeById$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<WorkTypeWithFunctionAppliancesEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetFunctionWorkTypeByIdPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<WorkTypeWithFunctionAppliancesEntity>;
                })
            );
    }

    /**
     * Returns a function type work type by id.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getFunctionWorkTypeById$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getFunctionWorkTypeById(params: {
        id: string;
        context?: HttpContext;
    }): Observable<WorkTypeWithFunctionAppliancesEntity> {
        return this.getFunctionWorkTypeById$Response(params).pipe(
            map(
                (r: StrictHttpResponse<WorkTypeWithFunctionAppliancesEntity>) =>
                    r.body as WorkTypeWithFunctionAppliancesEntity
            )
        );
    }

    /**
     * Path part for operation updateWorkTypeFunction
     */
    static readonly UpdateWorkTypeFunctionPath = '/admin/worktype-function/{id}';

    /**
     * Updates a function type work type appliance assignment.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateWorkTypeFunction()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateWorkTypeFunction$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateWorktypeFunctionDto;
    }): Observable<StrictHttpResponse<Array<WorkTypeWithFunctionAppliancesEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.UpdateWorkTypeFunctionPath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkTypeWithFunctionAppliancesEntity>>;
                })
            );
    }

    /**
     * Updates a function type work type appliance assignment.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateWorkTypeFunction$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateWorkTypeFunction(params: {
        id: string;
        context?: HttpContext;
        body: UpdateWorktypeFunctionDto;
    }): Observable<Array<WorkTypeWithFunctionAppliancesEntity>> {
        return this.updateWorkTypeFunction$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<WorkTypeWithFunctionAppliancesEntity>>) =>
                    r.body as Array<WorkTypeWithFunctionAppliancesEntity>
            )
        );
    }

    /**
     * Path part for operation createHeatPumpApplianceItem
     */
    static readonly CreateHeatPumpApplianceItemPath = '/admin/heat-pump-item/new';

    /**
     * Creates a new heat pump appliance item.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createHeatPumpApplianceItem()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createHeatPumpApplianceItem$Response(params: {
        context?: HttpContext;
        body: CreateApplianceItemDto;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.CreateHeatPumpApplianceItemPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeEntity>>;
                })
            );
    }

    /**
     * Creates a new heat pump appliance item.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createHeatPumpApplianceItem$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createHeatPumpApplianceItem(params: {
        context?: HttpContext;
        body: CreateApplianceItemDto;
    }): Observable<Array<ApplianceTypeEntity>> {
        return this.createHeatPumpApplianceItem$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeEntity>>) =>
                    r.body as Array<ApplianceTypeEntity>
            )
        );
    }

    /**
     * Path part for operation filterHeatPumpApplianceItemsByDate
     */
    static readonly FilterHeatPumpApplianceItemsByDatePath = '/admin/heat-pump-items';

    /**
     * Filters heat pump appliance items by date.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterHeatPumpApplianceItemsByDate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterHeatPumpApplianceItemsByDate$Response(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.FilterHeatPumpApplianceItemsByDatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>;
                })
            );
    }

    /**
     * Filters heat pump appliance items by date.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterHeatPumpApplianceItemsByDate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterHeatPumpApplianceItemsByDate(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<Array<ApplianceTypeWithArchiveEntity>> {
        return this.filterHeatPumpApplianceItemsByDate$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>) =>
                    r.body as Array<ApplianceTypeWithArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation createSolarCollectorApplianceItem
     */
    static readonly CreateSolarCollectorApplianceItemPath = '/admin/solar-collector-item/new';

    /**
     * Creates a new solar collector appliance item.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createSolarCollectorApplianceItem()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createSolarCollectorApplianceItem$Response(params: {
        context?: HttpContext;
        body: CreateApplianceItemDto;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.CreateSolarCollectorApplianceItemPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeEntity>>;
                })
            );
    }

    /**
     * Creates a new solar collector appliance item.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createSolarCollectorApplianceItem$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createSolarCollectorApplianceItem(params: {
        context?: HttpContext;
        body: CreateApplianceItemDto;
    }): Observable<Array<ApplianceTypeEntity>> {
        return this.createSolarCollectorApplianceItem$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeEntity>>) =>
                    r.body as Array<ApplianceTypeEntity>
            )
        );
    }

    /**
     * Path part for operation filterSolarCollectorApplianceItemsByDate
     */
    static readonly FilterSolarCollectorApplianceItemsByDatePath = '/admin/solar-collector-items';

    /**
     * Filters solar collector appliance items by date.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterSolarCollectorApplianceItemsByDate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterSolarCollectorApplianceItemsByDate$Response(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.FilterSolarCollectorApplianceItemsByDatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>;
                })
            );
    }

    /**
     * Filters solar collector appliance items by date.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterSolarCollectorApplianceItemsByDate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterSolarCollectorApplianceItemsByDate(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<Array<ApplianceTypeWithArchiveEntity>> {
        return this.filterSolarCollectorApplianceItemsByDate$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>) =>
                    r.body as Array<ApplianceTypeWithArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation createWindEnergyApplianceItem
     */
    static readonly CreateWindEnergyApplianceItemPath = '/admin/wind-energy-item/new';

    /**
     * Creates a new wind energy appliance item.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createWindEnergyApplianceItem()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createWindEnergyApplianceItem$Response(params: {
        context?: HttpContext;
        body: CreateApplianceItemDto;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.CreateWindEnergyApplianceItemPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeEntity>>;
                })
            );
    }

    /**
     * Creates a new wind energy appliance item.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createWindEnergyApplianceItem$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createWindEnergyApplianceItem(params: {
        context?: HttpContext;
        body: CreateApplianceItemDto;
    }): Observable<Array<ApplianceTypeEntity>> {
        return this.createWindEnergyApplianceItem$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeEntity>>) =>
                    r.body as Array<ApplianceTypeEntity>
            )
        );
    }

    /**
     * Path part for operation filterWindEnergyApplianceItemsByDate
     */
    static readonly FilterWindEnergyApplianceItemsByDatePath = '/admin/wind-energy-items';

    /**
     * Filters wind energy appliance items by date.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterWindEnergyApplianceItemsByDate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterWindEnergyApplianceItemsByDate$Response(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.FilterWindEnergyApplianceItemsByDatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>;
                })
            );
    }

    /**
     * Filters wind energy appliance items by date.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterWindEnergyApplianceItemsByDate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterWindEnergyApplianceItemsByDate(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<Array<ApplianceTypeWithArchiveEntity>> {
        return this.filterWindEnergyApplianceItemsByDate$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>) =>
                    r.body as Array<ApplianceTypeWithArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation createFenceApplianceItem
     */
    static readonly CreateFenceApplianceItemPath = '/admin/fence-item/new';

    /**
     * Creates a new fence appliance item.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createFenceApplianceItem()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createFenceApplianceItem$Response(params: {
        context?: HttpContext;
        body: CreateApplianceItemDto;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.CreateFenceApplianceItemPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeEntity>>;
                })
            );
    }

    /**
     * Creates a new fence appliance item.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createFenceApplianceItem$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createFenceApplianceItem(params: {
        context?: HttpContext;
        body: CreateApplianceItemDto;
    }): Observable<Array<ApplianceTypeEntity>> {
        return this.createFenceApplianceItem$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeEntity>>) =>
                    r.body as Array<ApplianceTypeEntity>
            )
        );
    }

    /**
     * Path part for operation filterFenceApplianceItemsByDate
     */
    static readonly FilterFenceApplianceItemsByDatePath = '/admin/fence-items';

    /**
     * Filters fence appliance items by date.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterFenceApplianceItemsByDate()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterFenceApplianceItemsByDate$Response(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.FilterFenceApplianceItemsByDatePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>;
                })
            );
    }

    /**
     * Filters fence appliance items by date.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterFenceApplianceItemsByDate$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterFenceApplianceItemsByDate(params: {
        context?: HttpContext;
        body: DateFilterDto;
    }): Observable<Array<ApplianceTypeWithArchiveEntity>> {
        return this.filterFenceApplianceItemsByDate$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeWithArchiveEntity>>) =>
                    r.body as Array<ApplianceTypeWithArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation deleteRenewableApplianceItem
     */
    static readonly DeleteRenewableApplianceItemPath = '/admin/renewable-item/{id}';

    /**
     * Deletes a renewable appliance item with history.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `deleteRenewableApplianceItem()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteRenewableApplianceItem$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.DeleteRenewableApplianceItemPath,
            'delete'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Deletes a renewable appliance item with history.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `deleteRenewableApplianceItem$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteRenewableApplianceItem(params: { id: string; context?: HttpContext }): Observable<void> {
        return this.deleteRenewableApplianceItem$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation updateRenewableApplianceItem
     */
    static readonly UpdateRenewableApplianceItemPath = '/admin/renewable-item/{id}';

    /**
     * Updates a renewable appliance item.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateRenewableApplianceItem()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateRenewableApplianceItem$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateApplianceItemDto;
    }): Observable<StrictHttpResponse<ApplianceItemEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.UpdateRenewableApplianceItemPath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<ApplianceItemEntity>;
                })
            );
    }

    /**
     * Updates a renewable appliance item.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateRenewableApplianceItem$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateRenewableApplianceItem(params: {
        id: string;
        context?: HttpContext;
        body: UpdateApplianceItemDto;
    }): Observable<ApplianceItemEntity> {
        return this.updateRenewableApplianceItem$Response(params).pipe(
            map((r: StrictHttpResponse<ApplianceItemEntity>) => r.body as ApplianceItemEntity)
        );
    }

    /**
     * Path part for operation getRenewableApplianceItemHistory
     */
    static readonly GetRenewableApplianceItemHistoryPath = '/admin/renewable-item-archive/{id}';

    /**
     * Returns history data for a renewable appliance item.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getRenewableApplianceItemHistory()` instead.
     *
     * This method doesn't expect any request body.
     */
    getRenewableApplianceItemHistory$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceItemArchiveEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetRenewableApplianceItemHistoryPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceItemArchiveEntity>>;
                })
            );
    }

    /**
     * Returns history data for a renewable appliance item.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getRenewableApplianceItemHistory$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getRenewableApplianceItemHistory(params: {
        id: string;
        context?: HttpContext;
    }): Observable<Array<ApplianceItemArchiveEntity>> {
        return this.getRenewableApplianceItemHistory$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceItemArchiveEntity>>) =>
                    r.body as Array<ApplianceItemArchiveEntity>
            )
        );
    }

    /**
     * Path part for operation getStatistics
     */
    static readonly GetStatisticsPath = '/admin/statistics';

    /**
     * Returns statistics for users's projects.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getStatistics()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getStatistics$Response(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<StrictHttpResponse<Array<UserProjectStatEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.GetStatisticsPath, 'post');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<UserProjectStatEntity>>;
                })
            );
    }

    /**
     * Returns statistics for users's projects.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getStatistics$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getStatistics(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<Array<UserProjectStatEntity>> {
        return this.getStatistics$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<UserProjectStatEntity>>) =>
                    r.body as Array<UserProjectStatEntity>
            )
        );
    }

    /**
     * Path part for operation getUserRoleStatistics
     */
    static readonly GetUserRoleStatisticsPath = '/admin/user-role-statistics';

    /**
     * Returns users's role statistics.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getUserRoleStatistics()` instead.
     *
     * This method doesn't expect any request body.
     */
    getUserRoleStatistics$Response(params?: {
        /**
         * true: retrieves only active user&#x27;s roles statistics, false/undefined: retrieves all user&#x27;s roles statistics
         */
        onlyActive?: boolean;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<RoleUserStatEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetUserRoleStatisticsPath,
            'get'
        );
        if (params) {
            rb.query('onlyActive', params.onlyActive, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<RoleUserStatEntity>>;
                })
            );
    }

    /**
     * Returns users's role statistics.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getUserRoleStatistics$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getUserRoleStatistics(params?: {
        /**
         * true: retrieves only active user&#x27;s roles statistics, false/undefined: retrieves all user&#x27;s roles statistics
         */
        onlyActive?: boolean;
        context?: HttpContext;
    }): Observable<Array<RoleUserStatEntity>> {
        return this.getUserRoleStatistics$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<RoleUserStatEntity>>) =>
                    r.body as Array<RoleUserStatEntity>
            )
        );
    }

    /**
     * Path part for operation getUserSubscriptionStatistics
     */
    static readonly GetUserSubscriptionStatisticsPath = '/admin/user-subscription-statistics';

    /**
     * Returns users's subscription statistics.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getUserSubscriptionStatistics()` instead.
     *
     * This method doesn't expect any request body.
     */
    getUserSubscriptionStatistics$Response(params?: {
        /**
         * true: retrieves only active user&#x27;s subscriptions statistics, false/undefined: retrieves all user&#x27;s subscriptions statistics
         */
        onlyActive?: boolean;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<SubscriptionTypeUserStatEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetUserSubscriptionStatisticsPath,
            'get'
        );
        if (params) {
            rb.query('onlyActive', params.onlyActive, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<SubscriptionTypeUserStatEntity>>;
                })
            );
    }

    /**
     * Returns users's subscription statistics.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getUserSubscriptionStatistics$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getUserSubscriptionStatistics(params?: {
        /**
         * true: retrieves only active user&#x27;s subscriptions statistics, false/undefined: retrieves all user&#x27;s subscriptions statistics
         */
        onlyActive?: boolean;
        context?: HttpContext;
    }): Observable<Array<SubscriptionTypeUserStatEntity>> {
        return this.getUserSubscriptionStatistics$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<SubscriptionTypeUserStatEntity>>) =>
                    r.body as Array<SubscriptionTypeUserStatEntity>
            )
        );
    }

    /**
     * Path part for operation getDraftPhaseRenewableStatistics
     */
    static readonly GetDraftPhaseRenewableStatisticsPath =
        '/admin/draft-phase-renewable-statistics';

    /**
     * Returns draftphases renewable energy statistics.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getDraftPhaseRenewableStatistics()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getDraftPhaseRenewableStatistics$Response(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<StrictHttpResponse<RenewableStatEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetDraftPhaseRenewableStatisticsPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<RenewableStatEntity>;
                })
            );
    }

    /**
     * Returns draftphases renewable energy statistics.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getDraftPhaseRenewableStatistics$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getDraftPhaseRenewableStatistics(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<RenewableStatEntity> {
        return this.getDraftPhaseRenewableStatistics$Response(params).pipe(
            map((r: StrictHttpResponse<RenewableStatEntity>) => r.body as RenewableStatEntity)
        );
    }

    /**
     * Path part for operation getDraftPhasePlotTypeStatistics
     */
    static readonly GetDraftPhasePlotTypeStatisticsPath = '/admin/draft-phase-plot-type-statistics';

    /**
     * Returns draftphases plot type statistics.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getDraftPhasePlotTypeStatistics()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getDraftPhasePlotTypeStatistics$Response(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<StrictHttpResponse<Array<PlotTypeStatEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetDraftPhasePlotTypeStatisticsPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<PlotTypeStatEntity>>;
                })
            );
    }

    /**
     * Returns draftphases plot type statistics.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getDraftPhasePlotTypeStatistics$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getDraftPhasePlotTypeStatistics(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<Array<PlotTypeStatEntity>> {
        return this.getDraftPhasePlotTypeStatistics$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<PlotTypeStatEntity>>) =>
                    r.body as Array<PlotTypeStatEntity>
            )
        );
    }

    /**
     * Path part for operation getDraftPhaseBuildMethodStatistics
     */
    static readonly GetDraftPhaseBuildMethodStatisticsPath =
        '/admin/draft-phase-build-method-statistics';

    /**
     * Returns draftphases build method statistics.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getDraftPhaseBuildMethodStatistics()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getDraftPhaseBuildMethodStatistics$Response(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<StrictHttpResponse<Array<BuildMethodStatEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetDraftPhaseBuildMethodStatisticsPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<BuildMethodStatEntity>>;
                })
            );
    }

    /**
     * Returns draftphases build method statistics.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getDraftPhaseBuildMethodStatistics$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getDraftPhaseBuildMethodStatistics(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<Array<BuildMethodStatEntity>> {
        return this.getDraftPhaseBuildMethodStatistics$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<BuildMethodStatEntity>>) =>
                    r.body as Array<BuildMethodStatEntity>
            )
        );
    }

    /**
     * Path part for operation getDraftPhaseDesignBudgetStatistics
     */
    static readonly GetDraftPhaseDesignBudgetStatisticsPath =
        '/admin/draft-phase-design-budget-statistics';

    /**
     * Returns draftphases design budget statistics.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getDraftPhaseDesignBudgetStatistics()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getDraftPhaseDesignBudgetStatistics$Response(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<StrictHttpResponse<Array<DesignBudgetCategoryStatEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetDraftPhaseDesignBudgetStatisticsPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<DesignBudgetCategoryStatEntity>>;
                })
            );
    }

    /**
     * Returns draftphases design budget statistics.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getDraftPhaseDesignBudgetStatistics$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getDraftPhaseDesignBudgetStatistics(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<Array<DesignBudgetCategoryStatEntity>> {
        return this.getDraftPhaseDesignBudgetStatistics$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<DesignBudgetCategoryStatEntity>>) =>
                    r.body as Array<DesignBudgetCategoryStatEntity>
            )
        );
    }

    /**
     * Path part for operation getGeoRegionProjectDetailsStatistics
     */
    static readonly GetGeoRegionProjectDetailsStatisticsPath =
        '/admin/projects-geo-regions-statistics';

    /**
     * Retrieve the sum of total costs and total area, including building functions (if applicable), for all completed and closed phases within a given region.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getGeoRegionProjectDetailsStatistics()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getGeoRegionProjectDetailsStatistics$Response(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<StrictHttpResponse<GeoRegionStatDetailsEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetGeoRegionProjectDetailsStatisticsPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<GeoRegionStatDetailsEntity>;
                })
            );
    }

    /**
     * Retrieve the sum of total costs and total area, including building functions (if applicable), for all completed and closed phases within a given region.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getGeoRegionProjectDetailsStatistics$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getGeoRegionProjectDetailsStatistics(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<GeoRegionStatDetailsEntity> {
        return this.getGeoRegionProjectDetailsStatistics$Response(params).pipe(
            map(
                (r: StrictHttpResponse<GeoRegionStatDetailsEntity>) =>
                    r.body as GeoRegionStatDetailsEntity
            )
        );
    }

    /**
     * Path part for operation getProjectsPerGeoRegionsAllStatistics
     */
    static readonly GetProjectsPerGeoRegionsAllStatisticsPath =
        '/admin/projects-geo-regions-all-statistics';

    /**
     * Returns all geo regions and count of closed draft phases for each region with the sum of total costs.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getProjectsPerGeoRegionsAllStatistics()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getProjectsPerGeoRegionsAllStatistics$Response(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<StrictHttpResponse<Array<DraftPhaseGeoRegionEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetProjectsPerGeoRegionsAllStatisticsPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<DraftPhaseGeoRegionEntity>>;
                })
            );
    }

    /**
     * Returns all geo regions and count of closed draft phases for each region with the sum of total costs.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getProjectsPerGeoRegionsAllStatistics$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getProjectsPerGeoRegionsAllStatistics(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<Array<DraftPhaseGeoRegionEntity>> {
        return this.getProjectsPerGeoRegionsAllStatistics$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<DraftPhaseGeoRegionEntity>>) =>
                    r.body as Array<DraftPhaseGeoRegionEntity>
            )
        );
    }

    /**
     * Path part for operation getSupplierBuildingStructureStatistics
     */
    static readonly GetSupplierBuildingStructureStatisticsPath =
        '/admin/supplier-building-structure-statistics';

    /**
     * Returns suppliers and building structure statistics.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getSupplierBuildingStructureStatistics()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getSupplierBuildingStructureStatistics$Response(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<StrictHttpResponse<Array<SupplierStructureStatEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetSupplierBuildingStructureStatisticsPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<SupplierStructureStatEntity>>;
                })
            );
    }

    /**
     * Returns suppliers and building structure statistics.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getSupplierBuildingStructureStatistics$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getSupplierBuildingStructureStatistics(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<Array<SupplierStructureStatEntity>> {
        return this.getSupplierBuildingStructureStatistics$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<SupplierStructureStatEntity>>) =>
                    r.body as Array<SupplierStructureStatEntity>
            )
        );
    }

    /**
     * Path part for operation getSupplierPopularityStatistics
     */
    static readonly GetSupplierPopularityStatisticsPath = '/admin/supplier-popularity-statistics';

    /**
     * Returns suppliers poularity statistics.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getSupplierPopularityStatistics()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getSupplierPopularityStatistics$Response(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<StrictHttpResponse<Array<SupplierPopularityStatEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetSupplierPopularityStatisticsPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<SupplierPopularityStatEntity>>;
                })
            );
    }

    /**
     * Returns suppliers poularity statistics.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getSupplierPopularityStatistics$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getSupplierPopularityStatistics(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<Array<SupplierPopularityStatEntity>> {
        return this.getSupplierPopularityStatistics$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<SupplierPopularityStatEntity>>) =>
                    r.body as Array<SupplierPopularityStatEntity>
            )
        );
    }

    /**
     * Path part for operation getStructurePopularityStatistics
     */
    static readonly GetStructurePopularityStatisticsPath = '/admin/structure-popularity-statistics';

    /**
     * Returns structure poularity statistics.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getStructurePopularityStatistics()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getStructurePopularityStatistics$Response(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<StrictHttpResponse<Array<StructurePopularityStatEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.GetStructurePopularityStatisticsPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<StructurePopularityStatEntity>>;
                })
            );
    }

    /**
     * Returns structure poularity statistics.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getStructurePopularityStatistics$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    getStructurePopularityStatistics(params: {
        context?: HttpContext;
        body: StatQueryDto;
    }): Observable<Array<StructurePopularityStatEntity>> {
        return this.getStructurePopularityStatistics$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<StructurePopularityStatEntity>>) =>
                    r.body as Array<StructurePopularityStatEntity>
            )
        );
    }

    /**
     * Path part for operation getSuppliers
     */
    static readonly GetSuppliersPath = '/admin/suppliers';

    /**
     * Returns all suppliers.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getSuppliers()` instead.
     *
     * This method doesn't expect any request body.
     */
    getSuppliers$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<SupplierEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.GetSuppliersPath, 'get');
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<SupplierEntity>>;
                })
            );
    }

    /**
     * Returns all suppliers.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getSuppliers$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getSuppliers(params?: { context?: HttpContext }): Observable<Array<SupplierEntity>> {
        return this.getSuppliers$Response(params).pipe(
            map((r: StrictHttpResponse<Array<SupplierEntity>>) => r.body as Array<SupplierEntity>)
        );
    }

    /**
     * Path part for operation getSupplier
     */
    static readonly GetSupplierPath = '/admin/supplier/{id}';

    /**
     * Returns a supplier by it's id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getSupplier()` instead.
     *
     * This method doesn't expect any request body.
     */
    getSupplier$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<SupplierEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.GetSupplierPath, 'get');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<SupplierEntity>;
                })
            );
    }

    /**
     * Returns a supplier by it's id.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getSupplier$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getSupplier(params: { id: string; context?: HttpContext }): Observable<SupplierEntity> {
        return this.getSupplier$Response(params).pipe(
            map((r: StrictHttpResponse<SupplierEntity>) => r.body as SupplierEntity)
        );
    }

    /**
     * Path part for operation deleteSupplier
     */
    static readonly DeleteSupplierPath = '/admin/supplier/{id}';

    /**
     * Soft deletes a supplier (active = false).
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `deleteSupplier()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteSupplier$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.DeleteSupplierPath, 'delete');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Soft deletes a supplier (active = false).
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `deleteSupplier$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteSupplier(params: { id: string; context?: HttpContext }): Observable<void> {
        return this.deleteSupplier$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation updateSupplier
     */
    static readonly UpdateSupplierPath = '/admin/supplier/{id}';

    /**
     * Updates a supplier.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateSupplier()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateSupplier$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateSupplierDto;
    }): Observable<StrictHttpResponse<SupplierEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.UpdateSupplierPath, 'patch');
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<SupplierEntity>;
                })
            );
    }

    /**
     * Updates a supplier.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateSupplier$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateSupplier(params: {
        id: string;
        context?: HttpContext;
        body: UpdateSupplierDto;
    }): Observable<SupplierEntity> {
        return this.updateSupplier$Response(params).pipe(
            map((r: StrictHttpResponse<SupplierEntity>) => r.body as SupplierEntity)
        );
    }

    /**
     * Path part for operation createSupplier
     */
    static readonly CreateSupplierPath = '/admin/supplier/new';

    /**
     * Creates a new supplier.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createSupplier()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createSupplier$Response(params: {
        context?: HttpContext;
        body: CreateSupplierDto;
    }): Observable<StrictHttpResponse<SupplierEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.CreateSupplierPath, 'post');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<SupplierEntity>;
                })
            );
    }

    /**
     * Creates a new supplier.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createSupplier$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createSupplier(params: {
        context?: HttpContext;
        body: CreateSupplierDto;
    }): Observable<SupplierEntity> {
        return this.createSupplier$Response(params).pipe(
            map((r: StrictHttpResponse<SupplierEntity>) => r.body as SupplierEntity)
        );
    }

    /**
     * Path part for operation getFeatures
     */
    static readonly GetFeaturesPath = '/admin/features';

    /**
     * Returns all a features with optional search.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getFeatures()` instead.
     *
     * This method doesn't expect any request body.
     */
    getFeatures$Response(params?: {
        search?: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(this.rootUrl, AdminApiService.GetFeaturesPath, 'get');
        if (params) {
            rb.query('search', params.search, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Returns all a features with optional search.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getFeatures$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getFeatures(params?: { search?: string; context?: HttpContext }): Observable<void> {
        return this.getFeatures$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation upsertBuildingStructureProduct
     */
    static readonly UpsertBuildingStructureProductPath = '/admin/building-structure-product';

    /**
     * Updates a BuildingStructureProductEntity.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `upsertBuildingStructureProduct()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    upsertBuildingStructureProduct$Response(params: {
        context?: HttpContext;
        body: UpsertBuildingStructureProductDto;
    }): Observable<StrictHttpResponse<BuildingStructureProductEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AdminApiService.UpsertBuildingStructureProductPath,
            'put'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<BuildingStructureProductEntity>;
                })
            );
    }

    /**
     * Updates a BuildingStructureProductEntity.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `upsertBuildingStructureProduct$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    upsertBuildingStructureProduct(params: {
        context?: HttpContext;
        body: UpsertBuildingStructureProductDto;
    }): Observable<BuildingStructureProductEntity> {
        return this.upsertBuildingStructureProduct$Response(params).pipe(
            map(
                (r: StrictHttpResponse<BuildingStructureProductEntity>) =>
                    r.body as BuildingStructureProductEntity
            )
        );
    }
}
