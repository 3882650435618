<ng-container *ngIf="summaryTableService.summaryTableComponent">
    <div
        *ngLet="element.id === summaryTableService.summaryTableComponent.editedRow?.id as isEdited"
        class="flex w-full items-center justify-start pl-2"
    >
        <ng-container *ngLet="element.workType | invoke : isMainCategory as isMainCategory">
            <ng-container *ngLet="element.workType as categoryToUse">
                <ng-container
                    *ngIf="
                        !(
                            element.isCategory ||
                            element.shadowed ||
                            !isEdited ||
                            summaryTableService.summaryTableComponent.readonly
                        );
                        else showValueValue
                    "
                >
                    <input
                        *ngLet="
                            element.isCategory
                                ? aggregateValuesFor(categoryToUse, 'labourCost') || 0
                                : +element.labourCost ?? 0 as labourCost
                        "
                        type="text"
                        [disabled]="
                            element.isCategory ||
                            element.shadowed ||
                            !isEdited ||
                            summaryTableService.summaryTableComponent.readonly
                        "
                        [value]="
                            isEdited
                                ? labourCost
                                : ($any(labourCost)
                                  | appCurrency
                                      : summaryTableService.summaryTableComponent.project.currency)
                        "
                        (input)="onEditCell($any($event.target).value, 'labourCost', element, true)"
                        class="w-4/5 text-left"
                    />
                </ng-container>
                <ng-template #showValueValue>
                    <div
                        class="flex w-full justify-start pl-2"
                        [class.font-bold]="!element.minor && element.isCategory"
                        *ngLet="
                            element.isCategory
                                ? aggregateValuesFor(categoryToUse, 'labourCost') || 0
                                : +element.labourCost ?? 0 as labourCost
                        "
                    >
                        {{
                            isEdited
                                ? (labourCost
                                  | appCurrency
                                      : summaryTableService.summaryTableComponent.project.currency)
                                : ($any(labourCost)
                                  | appCurrency
                                      : summaryTableService.summaryTableComponent.project.currency)
                        }}
                    </div>
                </ng-template>
            </ng-container>
        </ng-container>

        <mat-icon
            *ngIf="isEdited && (summaryTableService.summaryTableComponent.editErrors?.['labourCost'] || summaryTableService.summaryTableComponent.editErrors?.['numericFieldExclusion'])"
            [matTooltip]="summaryTableService.summaryTableComponent.editErrors?.['numericFieldExclusion'] ?? ('project-creation.table.incorrect-value' | transloco)"
        >
            info
        </mat-icon>
    </div>
</ng-container>
