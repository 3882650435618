import { Component, Input } from '@angular/core';
import { SummaryTableService } from '../../summary-table.service';

@Component({
    selector: 'app-column-cost-info',
    templateUrl: './column-cost-info.component.html',
    styleUrls: ['./column-cost-info.component.scss']
})
export class ColumnCostInfoComponent {
    @Input() element!: any;
    public readonly getEntriesFor = this.summaryTableService.getEntriesFor.bind(
        this.summaryTableService
    );
    public readonly getSubcategoriesFor = this.summaryTableService.getSubcategoriesFor.bind(
        this.summaryTableService
    );

    constructor(public readonly summaryTableService: SummaryTableService) {}
}
