/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseApiService } from '../base-api-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AssetEntity } from '../models/asset-entity';
import { AssetUploadDto } from '../models/asset-upload-dto';

@Injectable({
    providedIn: 'root'
})
export class AssetApiService extends BaseApiService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Path part for operation uploadFile
     */
    static readonly UploadFilePath = '/asset';

    /**
     * Saves file to S3 bucket and returns the asset object.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `uploadFile()` instead.
     *
     * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
     */
    uploadFile$Response(params: {
        context?: HttpContext;
        body: AssetUploadDto;
    }): Observable<StrictHttpResponse<AssetEntity>> {
        const rb = new RequestBuilder(this.rootUrl, AssetApiService.UploadFilePath, 'post');
        if (params) {
            rb.body(params.body, 'multipart/form-data');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<AssetEntity>;
                })
            );
    }

    /**
     * Saves file to S3 bucket and returns the asset object.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `uploadFile$Response()` instead.
     *
     * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
     */
    uploadFile(params: { context?: HttpContext; body: AssetUploadDto }): Observable<AssetEntity> {
        return this.uploadFile$Response(params).pipe(
            map((r: StrictHttpResponse<AssetEntity>) => r.body as AssetEntity)
        );
    }
}
