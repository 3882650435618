<ng-container *ngIf="summaryTableService.summaryTableComponent">
    <div
        *ngLet="element.id === summaryTableService.summaryTableComponent.editedRow?.id as isEdited"
        class="flex w-full items-center justify-start"
    >
        <ng-container *ngLet="element.workType | invoke : isMainCategory as isMainCategory">
            <ng-container *ngLet="element.workType as categoryToUse">
                <ng-container
                    *ngIf="
                        !(
                            element.isCategory ||
                            element.shadowed ||
                            !isEdited ||
                            summaryTableService.summaryTableComponent.readonly
                        );
                        else showAmountValue
                    "
                >
                    <input
                        *ngLet="
                            element.isCategory
                                ? aggregateValuesFor(categoryToUse, 'amount') || 0
                                : +element.amount ?? 0 as value
                        "
                        type="text"
                        [disabled]="
                            element.isCategory ||
                            element.shadowed ||
                            !isEdited ||
                            summaryTableService.summaryTableComponent.readonly
                        "
                        [value]="isEdited ? value : ($any(value) | number : '1.0-2')"
                        (input)="onEditCell($any($event.target).value, 'amount', element, true)"
                        class="w-4/5 text-left"
                    />
                </ng-container>
                <ng-template #showAmountValue>
                    <div
                        class="flex w-full justify-start"
                        [class.font-bold]="!element.minor && element.isCategory"
                        *ngLet="
                            element.isCategory
                                ? aggregateValuesFor(categoryToUse, 'amount') || 0
                                : +element.amount ?? 0 as value
                        "
                    >
                        {{
                            element.isCategory && !aggregateValuesFor(categoryToUse, 'amount')
                                ? ''
                                : isEdited
                                ? value
                                : ($any(value) | number : '1.0-2')
                        }}
                    </div>
                </ng-template>
            </ng-container>
        </ng-container>

        <mat-icon
            *ngIf="isEdited && summaryTableService.summaryTableComponent.editErrors?.['amount']"
            [matTooltip]="'project-creation.table.incorrect-quantity' | transloco"
        >
            info
        </mat-icon>
    </div>
</ng-container>
