import { Store } from '@ngrx/store';
import { selectInitialUserLoadInProgress, selectUserState } from 'app/store/user/user.selectors';
import dayjs from 'dayjs';
import { Observable, debounceTime, filter, map, switchMap, take } from 'rxjs';

export const expiredUser$ = (store: Store): Observable<boolean> => {
    const initialUserIsLoaded$ = store.select(selectInitialUserLoadInProgress).pipe(
        debounceTime(0),
        filter((inProgress) => !inProgress),
        take(1)
    );

    const userIsExpired$ = store.select(selectUserState).pipe(
        map((user) => {
            const validTo = user.subscription[0]?.validTo;

            if (!validTo) {
                return false;
            }

            const validToDayjs = dayjs(validTo);
            const now = dayjs();

            const invalid = !validToDayjs.isValid();

            if (invalid) {
                return false;
            }

            return validToDayjs.isBefore(now);
        }),
        take(1)
    );

    return initialUserIsLoaded$.pipe(switchMap(() => userIsExpired$));
};
