import { createReducer, on } from '@ngrx/store';
import {
    clearPlotTypesData,
    deletePlotTypes,
    deletePlotTypesFailure,
    deletePlotTypesSuccess,
    editPlotTypes,
    editPlotTypesFailure,
    editPlotTypesSuccess,
    getPlotTypeArchive,
    getPlotTypeArchiveFailure,
    getPlotTypeArchiveSuccess,
    getPlotTypes,
    getPlotTypesFailure,
    getPlotTypesSuccess,
    savePlotTypes,
    savePlotTypesFailure,
    savePlotTypesSuccess
} from './plot-types.actions';
import { PlotTypesState } from './plot-types.state';

export const initialState: PlotTypesState = {
    plotTypes: [],
    plotTypesInProgress: false,
    plotTypesError: null,
    plotTypeArchives: [],
    plotTypeArchivesInProgress: false,
    plotTypeArchivesError: null,
    editPlotTypeInProgress: false,
    editPlotTypeError: null,
    savePlotTypeInProgress: false,
    savePlotTypeError: null,
    deletePlotTypeInProgress: false,
    deletePlotTypeError: null
};

export const plotTypesReducer = createReducer(
    initialState,
    on(getPlotTypes, (state) => ({
        ...state,
        plotTypesInProgress: true,
        plotTypesError: null
    })),
    on(getPlotTypesSuccess, (state, { plotTypes }) => ({
        ...state,
        plotTypes,
        plotTypesInProgress: false,
        plotTypesError: null
    })),
    on(getPlotTypesFailure, (state, { error }) => ({
        ...state,
        plotTypesInProgress: false,
        plotTypesError: error
    })),

    on(getPlotTypeArchive, (state, { id }) => ({
        ...state,
        equipmentTypeArchiveInProgress: true,
        equipmentTypeArchiveError: null
    })),
    on(getPlotTypeArchiveSuccess, (state, { plotTypeArchives }) => ({
        ...state,
        plotTypeArchives,
        plotTypesInProgress: false,
        equipmentTypeArchiveError: null
    })),
    on(getPlotTypeArchiveFailure, (state, { error }) => ({
        ...state,
        plotTypesInProgress: false,
        equipmentTypeArchiveError: error
    })),
    on(editPlotTypes, (state) => ({
        ...state,
        editPlotTypeInProgress: true,
        editPlotTypeError: null
    })),
    on(editPlotTypesSuccess, (state, { id, multiplier }) => ({
        ...state,
        id,
        multiplier,
        editPlotTypeInProgress: false,
        editPlotTypeError: null
    })),
    on(editPlotTypesFailure, (state, { error }) => ({
        ...state,
        editPlotTypeInProgress: false,
        editPlotTypeError: error
    })),
    on(savePlotTypes, (state) => ({
        ...state,
        editPlotTypeInProgress: true,
        editPlotTypeError: null
    })),
    on(savePlotTypesSuccess, (state, { plotTypeData }) => ({
        ...state,
        editPlotTypeInProgress: false,
        editPlotTypeError: null
    })),
    on(savePlotTypesFailure, (state, { error }) => ({
        ...state,
        editPlotTypeInProgress: false,
        editPlotTypeError: error
    })),
    on(deletePlotTypes, (state, { id }) => ({
        ...state,
        id,
        deletePlotTypeInProgress: true,
        deletePlotTypeError: null
    })),
    on(deletePlotTypesSuccess, (state) => ({
        ...state,
        deletePlotTypeInProgress: false,
        deletePlotTypeError: null
    })),
    on(deletePlotTypesFailure, (state, { error }) => ({
        ...state,
        deletePlotTypeInProgress: false,
        deletePlotTypeError: error
    })),
    on(clearPlotTypesData, (state) => ({
        ...initialState
    }))
);
