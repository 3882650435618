import { createAction, props } from '@ngrx/store';
import { ProductEntity, WorkTypeEntity } from 'app/api';

export const getSalesProducts = createAction(
    '[Sales Management Page] Returns all products from backend',
    props<{ date: string | null }>()
);
export const getSalesProductsSuccess = createAction(
    '[Sales Management Page] Returns all products from backend success',
    props<{ salesProduct: Array<ProductEntity> }>()
);
export const getSalesProductsFailure = createAction(
    '[Sales Management Page] Returns all products from backend failure',
    props<{ error: string | null }>()
);

export const editSalesProductItem = createAction(
    '[Sales Management Page] Edit equipment types to backend',
    props<{ id: string; name: string; value: number; labourCost: number }>()
);
export const editSalesProductItemSuccess = createAction(
    '[Sales Management Page] Edit equipment types to backend success',
    props<{ id: string; name: string; value: number; labourCost: number }>()
);
export const editSalesProductItemFailure = createAction(
    '[Sales Management Page] Edit equipment types to backend failure',
    props<{ error: string | null }>()
);

export const saveSalesProduct = createAction(
    '[Sales Management Page] Save sales product to backend',
    props<{ typeId: string; name: string; value: number; labourCost: number }>()
);
export const saveSalesProductSuccess = createAction(
    '[Sales Management Page] Save sales product to backend success'
);
export const saveSalesProductFailure = createAction(
    '[Sales Management Page] Save sales product to backend failure',
    props<{ error: string | null }>()
);

export const getWorkTypeWithoutProduct = createAction(
    '[Sales Management Page] Sales Management worktype without product To Select from backend'
);
export const getWorkTypeWithoutProductSuccess = createAction(
    '[Sales Management Page] Sales Management worktype without product To Select from backend success',
    props<{ data: Array<WorkTypeEntity> }>()
);
export const getWorkTypeWithoutProductFailure = createAction(
    '[Sales Management Page] Sales Management worktype without product To Select from backend failure',
    props<{ error: string | null }>()
);

export const deleteSalesProductItem = createAction(
    '[Sales Management Page] Delete sales management item to backend',
    props<{ id: string }>()
);
export const deleteSalesProductItemSuccess = createAction(
    '[Sales Management Page] Delete sales management item to backend success',
    props<{ id: string }>()
);
export const deleteSalesProductItemFailure = createAction(
    '[Sales Management Page] Delete sales management item to backend failure',
    props<{ error: string | null }>()
);

export const logout = createAction('[Header] Logout');
export const clearSalesManagementData = createAction(
    '[Sales Management Page] Clear sales management data'
);
