export enum RouteSegment {
    Root = '',
    Login = '',
    Projects = '',
    NotFound = 'not-found',
    Dashboard = 'projects',
    DraftPhase = 'draft-phase',
    MapView = 'map-view',
    DraftPhaseCreation = 'draft-phase-creation',
    NamingAndType = '',
    IfcUpload = 'ifc-upload',
    ProjectData = 'project-data',
    PreviewSummary = 'preview-summary',
    Questions = 'questions',
    BuildingFunctions = 'building-functions',
    Summary = 'summary',
    TrialDraftPhaseCreation = 'trial-draft-phase-creation',
    IfcModelFilter = 'ifc-model-filter',
    ResetPassword = 'reset-password',
    ForgotPassword = 'forgot-password',
    Register = 'register',
    Profile = 'profile',
    ContactPage = 'contact-list',
    ContactUsPopup = 'contact-us',
    ModifyContact = 'modify',
    Admin = 'admin',
    ThankYou = 'thank-you',
    SalesManagement = 'sales-management',
    UserManagement = 'user-management',
    UserAnalytics = 'user-analytics',
    ItemManagement = 'item-management',
    LastModifiedPriceHistory = 'last-modified-price-history',
    ShowCategoryMappingElement = 'show-category-mapping-elements',
    CompareTables = 'compare-tables',
    ExpiredFeedback = 'expired-feedback'
}
