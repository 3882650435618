import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TablesCompareState } from './tables-compare.state';

export const selectTablesCompare = createFeatureSelector<TablesCompareState>('tablesCompare');
export const selectTableProjectIds = createSelector(
    selectTablesCompare,
    (state) => state.projectIds
);
export const selectProjectsForTable = createSelector(
    selectTablesCompare,
    (state) => state.projects
);
export const selectProjectsLoadingForTable = createSelector(
    selectTablesCompare,
    (state) => state.projectsLoading
);
export const selectTableEventLoadingInTable = createSelector(
    selectTablesCompare,
    (state) => state.tableEventLoading
);
