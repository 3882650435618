<div class="min-h-screen bg-offwhite3">
    <div class="flex h-screen items-center justify-center">
        <form
            [formGroup]="loginForm"
            (ngSubmit)="onSubmit()"
            class="flex w-full max-w-[860px] flex-col gap-1 rounded-lg bg-white p-8 shadow-card"
        >
            <mat-icon svgIcon="keystone-logo" class="mx-auto mb-4 !h-[60px] !w-[100px]"></mat-icon>
            <input
                formControlName="email"
                type="text"
                [placeholder]="'forms.email' | transloco"
                class="mb-4 rounded-sm border-[1px] border-solid border-mediumgray bg-white p-3 outline-none transition focus:border-[1px] focus:border-solid focus:border-brand1"
            />
            <div class="relative">
                <div
                    *ngIf="loginForm.controls.email.touched"
                    class="min-h-16 leading-4 absolute -bottom-1 left-0 flex flex-col text-xs text-error"
                >
                    <span *ngIf="loginForm.controls.email.errors?.['required']">
                        {{ 'messages.authentication.thisInputIsRequired' | transloco }}
                    </span>
                    <span *ngIf="loginForm.controls.email.errors?.['email']">
                        {{ 'messages.authentication.emailIsIncorrect' | transloco }}
                    </span>
                </div>
            </div>
            <input
                formControlName="password"
                type="password"
                [placeholder]="'forms.password' | transloco"
                class="mb-4 rounded-sm border-[1px] border-solid border-mediumgray bg-white p-3 outline-none transition focus:border-[1px] focus:border-solid focus:border-brand1"
            />
            <div class="relative">
                <div
                    *ngIf="loginForm.controls.password.touched"
                    class="min-h-16 leading-4 absolute -bottom-1 left-0 flex flex-col text-xs text-error"
                >
                    <span *ngIf="loginForm.controls.password.errors?.['required']">
                        {{ 'messages.authentication.thisInputIsRequired' | transloco }}
                    </span>
                </div>
            </div>
            <ng-container *ngIf="(errorMessage$ | async)![0] || (errorMessage$ | async)![1]">
                <p class="text-center text-error">
                    {{ 'messages.authentication.emailOrPasswordIsIncorrect' | transloco }}
                </p>
            </ng-container>
            <button
                type="submit"
                [disabled]="(isLoading$ | async)![0] || (isLoading$ | async)![1]"
                class="w-full max-w-[250px] cursor-pointer self-center rounded-full bg-brand1 py-3 px-4 text-white disabled:opacity-75"
            >
                <ng-container
                    *ngIf="!(isLoading$ | async)![0] || !(isLoading$ | async)![1]; else inProgress"
                >
                    {{ 'login.title' | transloco }}
                </ng-container>
                <ng-template #inProgress>
                    <div class="flex justify-center">
                        <mat-spinner [diameter]="24"></mat-spinner>
                    </div>
                </ng-template>
            </button>
            <a class="my-2 text-center hover:underline" [routerLink]="routeSegment.ForgotPassword">
                {{ 'forgot-password.link' | transloco }}
            </a>
            <a class="my-2 text-center hover:underline" [routerLink]="routeSegment.Register">
                {{ 'forms.register.link' | transloco }}
            </a>
        </form>
    </div>
</div>
