import { Component, Input } from '@angular/core';
import { SummaryTableService } from '../../summary-table.service';

@Component({
    selector: 'app-column-name',
    templateUrl: './column-name.component.html',
    styleUrls: ['./column-name.component.scss']
})
export class ColumnNameComponent {
    @Input() element!: any;

    public readonly onEditCell = this.summaryTableService.onEditCell.bind(this.summaryTableService);
    constructor(public readonly summaryTableService: SummaryTableService) {}
}
