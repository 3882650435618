/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseApiService } from '../base-api-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BarionGatewayUrlEntity } from '../models/barion-gateway-url-entity';
import { InitializePaymentDto } from '../models/initialize-payment-dto';

@Injectable({
    providedIn: 'root'
})
export class BarionApiService extends BaseApiService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Path part for operation initializePayment
     */
    static readonly InitializePaymentPath = '/barion';

    /**
     * Initializes a barion payment currently.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `initializePayment()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    initializePayment$Response(params: {
        context?: HttpContext;
        body: InitializePaymentDto;
    }): Observable<StrictHttpResponse<BarionGatewayUrlEntity>> {
        const rb = new RequestBuilder(this.rootUrl, BarionApiService.InitializePaymentPath, 'post');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<BarionGatewayUrlEntity>;
                })
            );
    }

    /**
     * Initializes a barion payment currently.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `initializePayment$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    initializePayment(params: {
        context?: HttpContext;
        body: InitializePaymentDto;
    }): Observable<BarionGatewayUrlEntity> {
        return this.initializePayment$Response(params).pipe(
            map((r: StrictHttpResponse<BarionGatewayUrlEntity>) => r.body as BarionGatewayUrlEntity)
        );
    }

    /**
     * Path part for operation barionControllerBarionCallback
     */
    static readonly BarionControllerBarionCallbackPath = '/barion/callback';

    /**
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `barionControllerBarionCallback()` instead.
     *
     * This method doesn't expect any request body.
     */
    barionControllerBarionCallback$Response(params: {
        paymentId: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            BarionApiService.BarionControllerBarionCallbackPath,
            'post'
        );
        if (params) {
            rb.query('paymentId', params.paymentId, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `barionControllerBarionCallback$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    barionControllerBarionCallback(params: {
        paymentId: string;
        context?: HttpContext;
    }): Observable<void> {
        return this.barionControllerBarionCallback$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }
}
