import { createAction, props } from '@ngrx/store';
import { PublicUserEntity } from 'app/api';

export const adminGetWaitingUsers = createAction('[Admin Page] Get Waiting Users from backend');
export const adminGetWaitingUsersSuccess = createAction(
    '[Admin Page] Get Waiting Users from backend success',
    props<{ waitingUsers: Array<PublicUserEntity> }>()
);
export const adminGetWaitingUsersFailure = createAction(
    '[Admin Page] Get Waiting Users from backend failure',
    props<{ error: string | null }>()
);

export const adminGetActiveUsers = createAction('[Admin Page] Get Active Users from backend');
export const adminGetActiveUsersSuccess = createAction(
    '[Admin Page] Get Active Users from backend success',
    props<{ activeUsers: Array<PublicUserEntity> }>()
);
export const adminGetActiveUsersFailure = createAction(
    '[Admin Page] Get Active Users from backend failure',
    props<{ error: string | null }>()
);

export const adminDeleteWaitingUser = createAction(
    '[Admin Page] Delete Waiting UserId from backend',
    props<{ userId: string }>()
);
export const adminDeleteWaitingUserSuccess = createAction(
    '[Admin Page] Delete Waiting UserId from backend success'
);
export const adminDeleteWaitingUserFailure = createAction(
    '[Admin Page] Delete Waiting UserId from backend failure',
    props<{ error: string | null }>()
);

export const adminSetActiveArchitectProfile = createAction(
    '[Admin Page] Set Active Architect Profile by UserId from backend',
    props<{ userId: string; setActiveArchitectProfile: boolean }>()
);
export const adminSetActiveArchitectProfileSuccess = createAction(
    '[Admin Page] Set Active Architect Profile by UserId from backend success'
);
export const adminSetActiveArchitectProfileFailure = createAction(
    '[Admin Page] Set Active Architect Profile by UserId from backend failure',
    props<{ error: string | null }>()
);

export const adminActivateWaitingUser = createAction(
    '[Admin Page] Activate Waiting UserId from backend',
    props<{ userId: string }>()
);
export const adminActivateWaitingUserSuccess = createAction(
    '[Admin Page] Activate Waiting UserId from backend success'
);
export const adminActivateWaitingUserFailure = createAction(
    '[Admin Page] Activate Waiting UserId from backend failure',
    props<{ error: string | null }>()
);
