import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.state';

export const selectUserState = createFeatureSelector<UserState>('user');

export const selectUserId = createSelector(selectUserState, (state) => state.id);
export const selectEmail = createSelector(selectUserState, (state) => state.email);
export const selectAccessToken = createSelector(selectUserState, (state) => state.accessToken);
export const selectUserLoginInProgress = createSelector(
    selectUserState,
    (state) => state.loginInProgress
);
export const selectUserIsAdmin = createSelector(selectUserState, (state) => state.isAdmin);
export const selectUserLoginFailure = createSelector(selectUserState, (state) => state.error);
export const selectUserRegisterInProgress = createSelector(
    selectUserState,
    (state) => state.registerInProgress
);
export const selectUserRegisterFailure = createSelector(selectUserState, (state) => state.error);

export const selectGetUserProgress = createSelector(
    selectUserState,
    (state) => state.getUserInProgress
);
export const selectGetUserFailure = createSelector(selectUserState, (state) => state.getUserError);

export const selectGetUpdateProfileInProgress = createSelector(
    selectUserState,
    (state) => state.getUpdateProfileInprogress
);

export const selectIsRegisterSuccessMessage = createSelector(
    selectUserState,
    (state) => state.isRegisterSuccessMessage
);

export const selectIsTrialRegisterSuccessMessage = createSelector(
    selectUserState,
    (state) => state.isTrialRegisterSuccessMessage
);

export const selectInitialUserLoadInProgress = createSelector(
    selectUserLoginInProgress,
    selectGetUserProgress,
    (loginInProgress, getUserInProgress) => {
        return loginInProgress || getUserInProgress;
    }
);

export const selectIsAdmin = createSelector(selectUserState, (state) => state.isAdmin);
