import { createReducer, on } from '@ngrx/store';
import {
    addDraftPhaseIfc,
    addDraftPhaseIfcFailure,
    addDraftPhaseIfcSuccess,
    addDraftPhaseName,
    clearDraftPhaseStore,
    draftPhase,
    draftPhaseFailure,
    draftPhaseSuccess,
    getIfcMetadataFailure,
    getIfcMetadataSuccess,
    setDraftPhaseData,
    setGeoRegionIdInQuestionnaire,
    setQuestionnaireData,
    setUpdateQuestionsData,
    updateDraftPhase,
    updateQuestionsData,
    updateQuestionsDataFailure,
    updateQuestionsDataSuccess
} from './draft-phase-creation.actions';
import { DraftPhaseCreationState } from './draft-phase-creation.state';

export const initialState: DraftPhaseCreationState = {
    sendIfcInProgress: false,
    sendIfcError: null,
    draftPhaseData: null,
    ifcMessageWithData: [],
    draftPhaseInProgress: false,
    draftPhaseError: null,
    updateQuestionsDataInProgress: false,
    updateQuestionsDataError: null,
    summaryDraftPhaseData: null
};

export const draftPhaseCreationReducer = createReducer(
    initialState,
    on(addDraftPhaseName, (state, { name }) => ({
        ...state,
        draftPhaseData: {
            name,
            size: null,
            designYear: null,
            planYear: null,
            plotSize: null,
            zipCode: null,
            city: null,
            street: null,
            streetNo: null,
            geoRegionId: null,
            lotNo: null,
            currency: null,
            currencyId: null,
            ifcId: null,
            id: null,
            type: null,
            projectId: null,
            status: null
        }
    })),
    on(addDraftPhaseIfc, (state) => ({
        ...state,
        sendIfcInProgress: true,
        sendIfcError: null,
        ifcMessageWithData: []
    })),
    on(addDraftPhaseIfcSuccess, (state, { ifcId, ifcMessageWithData }) => ({
        ...state,
        draftPhaseData: {
            ...state.draftPhaseData!,
            ifcId
        },
        ifcMessageWithData,
        sendIfcInProgress: false
    })),
    on(addDraftPhaseIfcFailure, (state, { error }) => ({
        ...state,
        draftPhaseData: {
            ...state.draftPhaseData!,
            ifcId: null
        },
        ifcMessageWithData: [],
        sendIfcInProgress: false,
        sendIfcError: error
    })),
    on(setDraftPhaseData, (state, { draftPhaseData }) => ({
        ...state,
        draftPhaseData: {
            ...state.draftPhaseData!,
            ...draftPhaseData
        }
    })),
    on(getIfcMetadataSuccess, (state, { ifcMetaData }) => ({
        ...state,
        draftPhaseData: {
            ...state.draftPhaseData!,
            city: ifcMetaData.address ? ifcMetaData.address.city : '',
            lotNo: ifcMetaData.address ? ifcMetaData.address.lotNo : '',
            street: ifcMetaData.address ? ifcMetaData.address.street : '',
            streetNo: ifcMetaData.address ? ifcMetaData.address.streetNo : '',
            zipCode: ifcMetaData.address ? ifcMetaData.address.zipCode : '',
            geoRegionId: ifcMetaData.geoRegionId ? ifcMetaData.geoRegionId : '',
            plotSize: ifcMetaData.plot.area,
            size: Math.floor(ifcMetaData.buildingArea)
        },
        sendIfcInProgress: false,
        sendIfcError: null
    })),
    on(getIfcMetadataFailure, (state, { error }) => ({
        ...state,
        sendIfcInProgress: false,
        sendIfcError: error
    })),
    on(draftPhase, (state, { draftPhaseData }) => ({
        ...state,
        draftPhaseInProgress: true,
        draftPhaseError: null,
        draftPhaseData: { ...state.draftPhaseData!, ...draftPhaseData }
    })),
    on(updateDraftPhase, (state, { draftPhaseData }) => ({
        ...state,
        draftPhaseInProgress: true,
        draftPhaseError: null,
        draftPhaseData: { ...state.draftPhaseData!, ...draftPhaseData }
    })),
    on(draftPhaseSuccess, (state, { draftPhaseData }) => ({
        ...state,
        draftPhaseInProgress: false,
        draftPhaseError: null,
        draftPhaseData: { ...state.draftPhaseData!, ...draftPhaseData },
        summaryDraftPhaseData: {
            ...state.summaryDraftPhaseData!,
            draftPhaseData,
            questionnaire: { ...state.summaryDraftPhaseData?.questionnaire }
        }
    })),
    on(draftPhaseFailure, (state, { error }) => ({
        ...state,
        draftPhaseInProgress: false,
        draftPhaseError: error
    })),
    on(updateQuestionsData, (state) => ({
        ...state,
        updateQuestionsDataInProgress: true,
        updateQuestionsDataError: null
    })),
    on(setUpdateQuestionsData, (state, { summaryDraftPhaseData }) => ({
        ...state,
        updateQuestionsDataInProgress: false,
        updateQuestionsDataError: null,
        summaryDraftPhaseData: {
            ...state.summaryDraftPhaseData!,
            ...summaryDraftPhaseData,
            questionnaire: { ...state.summaryDraftPhaseData?.questionnaire }
        }
    })),
    on(updateQuestionsDataSuccess, (state, { summaryDraftPhaseData }) => ({
        ...state,
        updateQuestionsDataInProgress: false,
        updateQuestionsDataError: null,
        summaryDraftPhaseData: {
            ...state.summaryDraftPhaseData!,
            ...summaryDraftPhaseData,
            questionnaire: { ...state.summaryDraftPhaseData!.questionnaire }
        }
    })),
    on(updateQuestionsDataFailure, (state, { error }) => ({
        ...state,
        updateQuestionsDataInProgress: false,
        updateQuestionsDataError: error
    })),
    on(setQuestionnaireData, (state, { questionnaire }) => ({
        ...state,
        updateQuestionsDataInProgress: false,
        updateQuestionsDataError: null,
        summaryDraftPhaseData: {
            ...state.summaryDraftPhaseData!,
            questionnaire
        }
    })),
    on(setGeoRegionIdInQuestionnaire, (state, { geoRegionId }) => ({
        ...state,
        summaryDraftPhaseData: {
            ...state.summaryDraftPhaseData!,
            questionnaire: {
                ...state.summaryDraftPhaseData?.questionnaire,
                geoRegionId
            }
        }
    })),
    on(clearDraftPhaseStore, (state) => ({
        ...initialState
    }))
);
