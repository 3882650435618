import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BaseState } from './base.state';

export const selectBaseState = createFeatureSelector<BaseState>('base');
export const selectCurrencies = createSelector(selectBaseState, (state) => state.currencies);
export const selectRoles = createSelector(selectBaseState, (state) => state.roles);
export const selectSubscriptionTypeInfo = createSelector(
    selectBaseState,
    (state) => state.subscriptionTypeInfo
);
