import { createReducer, on } from '@ngrx/store';
import {
    addEntry,
    addEntryFailure,
    addEntrySuccess,
    addProjectIdToCompare,
    clearTablesCompareData,
    deleteEntry,
    deleteEntryFailure,
    deleteEntrySuccess,
    deleteProjectIdFromCompare,
    deleteSelectedProjectIdsFromCompare,
    getProjectsToTables,
    getProjectsToTablesFailure,
    getProjectsToTablesSuccess,
    saveCategoryVatRateChange,
    saveCategoryVatRateChangeFailure,
    saveCategoryVatRateChangeSuccess,
    saveEntryChanges,
    saveEntryChangesFailure,
    saveEntryChangesSuccess,
    saveProjectBudgetChange,
    saveProjectBudgetChangeFailure,
    saveProjectBudgetChangeSuccess
} from './tables-compare.actions';
import { TablesCompareState } from './tables-compare.state';

export const initialState: TablesCompareState = {
    projectIds: [],
    projects: null,
    projectsLoading: true,
    projectsError: null,
    tableEventLoading: false,
    tableEventError: null
};

export const tablesCompareReducer = createReducer(
    initialState,
    on(addProjectIdToCompare, (state, { projectId }) => {
        if (state.projectIds.length < 4 && !state.projectIds.includes(projectId)) {
            return {
                ...state,
                projectIds: [...state.projectIds, projectId]
            };
        }
        return state;
    }),
    on(deleteProjectIdFromCompare, (state, { projectId }) => ({
        ...state,
        projectIds: state.projectIds.filter((id) => id !== projectId)
    })),
    on(deleteSelectedProjectIdsFromCompare, (state) => ({
        ...state,
        projectIds: []
    })),
    on(getProjectsToTables, (state) => ({
        ...state,
        projectsLoading: true,
        projectsError: null
    })),
    on(getProjectsToTablesSuccess, (state, { projects }) => ({
        ...state,
        projects,
        projectsLoading: false,
        projectsError: null
    })),
    on(getProjectsToTablesFailure, (state, { error }) => ({
        ...state,
        projects: null,
        projectsLoading: false,
        projectsError: error
    })),
    on(addEntry, (state) => ({
        ...state,
        tableEventLoading: true,
        tableEventError: null
    })),
    on(addEntrySuccess, (state, { projectId, projectEntries }) => ({
        ...state,
        projects: {
            ...state.projects,
            [projectId]: {
                ...state.projects![projectId],
                entries: projectEntries
            }
        },
        tableEventLoading: false,
        tableEventError: null
    })),
    on(addEntryFailure, (state, { error }) => ({
        ...state,
        tableEventLoading: false,
        tableEventError: error
    })),
    on(saveEntryChanges, (state) => ({
        ...state,
        tableEventLoading: true,
        tableEventError: null
    })),
    on(saveEntryChangesSuccess, (state, { projectId, projectEntries }) => ({
        ...state,
        projects: {
            ...state.projects,
            [projectId]: {
                ...state.projects![projectId],
                entries: projectEntries
            }
        },
        tableEventLoading: false,
        tableEventError: null
    })),
    on(saveEntryChangesFailure, (state, { error }) => ({
        ...state,
        tableEventLoading: false,
        tableEventError: error
    })),
    on(deleteEntry, (state) => ({
        ...state,
        tableEventLoading: true,
        tableEventError: null
    })),
    on(deleteEntrySuccess, (state, { projectId, projectEntries }) => ({
        ...state,
        projects: {
            ...state.projects,
            [projectId]: {
                ...state.projects![projectId],
                entries: projectEntries
            }
        },
        tableEventLoading: false,
        tableEventError: null
    })),
    on(deleteEntryFailure, (state, { error }) => ({
        ...state,
        tableEventLoading: false,
        tableEventError: error
    })),
    on(saveProjectBudgetChange, (state) => ({
        ...state,
        tableEventLoading: true,
        tableEventError: null
    })),
    on(saveProjectBudgetChangeSuccess, (state, { projectId, projectData }) => ({
        ...state,
        projects: {
            ...state.projects,
            [projectId]: {
                ...state.projects![projectId],
                projectData
            }
        },
        tableEventLoading: false,
        tableEventError: null
    })),
    on(saveProjectBudgetChangeFailure, (state, { error }) => ({
        ...state,
        tableEventLoading: false,
        tableEventError: error
    })),
    on(saveCategoryVatRateChange, (state) => ({
        ...state,
        tableEventLoading: true,
        tableEventError: null
    })),
    on(saveCategoryVatRateChangeSuccess, (state, { projectId, projectEntries }) => ({
        ...state,
        projects: {
            ...state.projects,
            [projectId]: {
                ...state.projects![projectId],
                entries: projectEntries
            }
        },
        tableEventLoading: false,
        tableEventError: null
    })),
    on(saveCategoryVatRateChangeFailure, (state, { error }) => ({
        ...state,
        tableEventLoading: false,
        tableEventError: error
    })),
    on(clearTablesCompareData, (state) => ({
        ...initialState
    }))
);
