import { createReducer, on } from '@ngrx/store';
import {
    clearProducerManagementData,
    createSupplierManagement,
    createSupplierManagementFailure,
    createSupplierManagementSuccess,
    deleteSupplierManagement,
    deleteSupplierManagementFailure,
    deleteSupplierManagementSuccess,
    editSupplierManagement,
    editSupplierManagementFailure,
    editSupplierManagementSuccess,
    getSupplierManagement,
    getSupplierManagementFailure,
    getSupplierManagementSuccess
} from './admin-producer-management.actions';
import { AdminProducerManagementState } from './admin-producer-management.state';

export const initialState: AdminProducerManagementState = {
    producerManagement: [],
    producerManagementInProgress: false,
    producerManagementError: null,
    /* buildingStructureArchives: [],
    buildingStructureArchivesInProgress: false,
    buildingStructureArchivesError: null, */
    editProducerManagementInProgress: false,
    editProducerManagementError: null,
    createProducerManagementInProgress: false,
    createProducerManagementError: null,
    deleteProducerManagementInProgress: false,
    deleteProducerManagementError: null
};

export const adminProducerManagementReducer = createReducer(
    initialState,
    on(getSupplierManagement, (state) => ({
        ...state,
        producerManagementInProgress: true,
        producerManagementError: null
    })),
    on(getSupplierManagementSuccess, (state, { producerManagement }) => ({
        ...state,
        producerManagement,
        producerManagementInProgress: false,
        producerManagementError: null
    })),
    on(getSupplierManagementFailure, (state, { error }) => ({
        ...state,
        producerManagementInProgress: false,
        producerManagementError: error
    })),

    /* on(buildingStructureArchives, (state, { id }) => ({
        ...state,
        buildingStructureArchivesInProgress: true,
        buildingStructureArchivesError: null
    })),
    on(buildingStructureArchivesSuccess, (state, { buildingStructureArchives }) => ({
        ...state,
        buildingStructureArchives,
        buildingStructureArchivesInProgress: false,
        buildingStructureArchivesError: null
    })),
    on(buildingStructureArchivesFailure, (state, { error }) => ({
        ...state,
        buildingStructureArchivesInProgress: false,
        buildingStructureArchivesError: error
    })), */
    on(editSupplierManagement, (state) => ({
        ...state,
        editProducerManagementInProgress: true,
        editProducerManagementError: null
    })),
    on(editSupplierManagementSuccess, (state) => ({
        ...state,
        editProducerManagementInProgress: false,
        editProducerManagementError: null
    })),
    on(editSupplierManagementFailure, (state, { error }) => ({
        ...state,
        editProducerManagementInProgress: false,
        editProducerManagementError: error
    })),
    on(createSupplierManagement, (state) => ({
        ...state,
        createProducerManagementInProgress: true,
        createProducerManagementError: null
    })),
    on(createSupplierManagementSuccess, (state, { producerManagementData }) => ({
        ...state,
        /* producerManagementData, */
        createProducerManagementInProgress: false,
        createProducerManagementError: null
    })),
    on(createSupplierManagementFailure, (state, { error }) => ({
        ...state,
        createProducerManagementInProgress: false,
        createProducerManagementError: error
    })),
    on(deleteSupplierManagement, (state, { id }) => ({
        ...state,
        id,
        deleteProducerManagementInProgress: true,
        deleteProducerManagementError: null
    })),
    on(deleteSupplierManagementSuccess, (state) => ({
        ...state,
        deleteProducerManagementInProgress: false,
        deleteProducerManagementError: null
    })),
    on(deleteSupplierManagementFailure, (state, { error }) => ({
        ...state,
        deleteProducerManagementInProgress: false,
        deleteProducerManagementError: error
    })),
    on(clearProducerManagementData, (state) => ({
        ...initialState
    }))
);
