import { createAction, props } from '@ngrx/store';
import { AccountStatus, AccountType } from 'app/api';
import { PublicUserEntity } from 'app/api/models/public-user-entity';

export const userLogin = createAction(
    '[Login Page] Login data send to backend',
    props<{ email: string; password: string }>()
);
export const userLoginSuccess = createAction(
    '[Login Page] Login success',
    props<{ accessToken: string }>()
);
export const userLoginFailure = createAction(
    '[Login Page] Login failure',
    props<{ error: string | null }>()
);

export const userRegister = createAction(
    '[Register Page] Register data send to backend',
    props<{
        email: string;
        firstName: string;
        lastName: string;
        newsLetter: boolean;
        company: string;
        telephone: string;
        taxNumber: string;
        password: string;
        password_confirm: string;
        roleId: string;
    }>()
);
export const userRegisterSuccess = createAction('[Register Page] Register success');
export const userRegisterFailure = createAction(
    '[Register Page] Register failure',
    props<{ error: string | null }>()
);

export const getUser = createAction(
    '[Login Page] User data get from backend',
    props<{ automaticLogin?: boolean }>()
);

export const getUserSuccess = createAction(
    '[Login Page] User data get success',
    props<{ user: PublicUserEntity; automaticLogin?: boolean }>()
);
export const getUserFailure = createAction(
    '[Login Page] User data get failure',
    props<{ error: string }>()
);

export const logout = createAction('[Header] Logout');
export const clearUser = createAction('[User] Clear User');

export const updateProfile = createAction(
    '[Update Profile] Update user profile',
    props<{
        profile: {
            company: string;
            firstName: string;
            lastName: string;
            newsLetter: boolean;
            roleId: string;
            taxNumber: string;
            telephone: string;
            city: string;
            street: string;
            streetNo: string;
            zipCode: string;
            status: AccountStatus;
            type: AccountType;
        };
    }>()
);

export const updateProfileSuccess = createAction(
    '[Update Profile] Update user profile success',
    props<{ user: PublicUserEntity }>()
);
export const updateProfileFailure = createAction(
    '[Update Profile] Update user profile failure',
    props<{ error: string | null }>()
);

export const trialUserRegister = createAction(
    '[Register Page] Trial register data send to backend',
    props<{
        email: string;
        firstName: string;
        lastName: string;
        newsLetter: boolean;
        company: string;
        telephone: string;
        city: string;
        street: string;
        streetNo: string;
        zipCode: string;
        taxNumber: string;
        roleId: string;
        password: string;
        password_confirm: string;
        accountType: AccountType;
    }>()
);

export const trialUserRegisterSuccess = createAction('[Register Page] Trial register success');
export const trialUserRegisterFailure = createAction(
    '[Register Page] Trial register failure',
    props<{ error: string | null }>()
);
