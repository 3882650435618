import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    DraftPhaseApiService,
    DraftPhaseEntity,
    IfcMetadataEntity,
    PhaseSourceType,
    PlanType,
    ProjectApiService
} from 'app/api';
import { IIfcIdWithMessageEntity } from 'app/api/models/ifc-id-with-message-entity';
import { RouteSegment } from 'app/enums/route-segments.enum';
import { of } from 'rxjs';
import { catchError, delay, map, switchMap, take, tap } from 'rxjs/operators';
import { getUser } from '../user/user.actions';
import { selectAccessToken } from '../user/user.selectors';
import { UserState } from '../user/user.state';
import * as DraftPhaseCreationActions from './draft-phase-creation.actions';

@Injectable()
export class DraftPhaseEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly projectApiService: ProjectApiService,
        private readonly draftPhaseApiService: DraftPhaseApiService,
        private readonly router: Router,
        private readonly store: Store<UserState>
    ) {
        /* this.actions$.pipe(tap((action) => console.log(action))).subscribe(); */
    }

    addProjectIfc$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DraftPhaseCreationActions.addDraftPhaseIfc),
            switchMap(({ file }) => {
                return this.projectApiService.uploadIfc({ body: { file } }).pipe(
                    map((data: IIfcIdWithMessageEntity) => {
                        return DraftPhaseCreationActions.addDraftPhaseIfcSuccess({
                            ifcId: data.id,
                            ifcMessageWithData: data.messages
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        console.log('IFC Error', error);

                        return of(
                            DraftPhaseCreationActions.addDraftPhaseIfcFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        );
    });

    getIfcMetadata$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DraftPhaseCreationActions.addDraftPhaseIfcSuccess),
            switchMap(({ ifcId }) => {
                return this.projectApiService.getIfcMetadata({ ifcId }).pipe(
                    map((ifcMetaData: IfcMetadataEntity) => {
                        return DraftPhaseCreationActions.getIfcMetadataSuccess({
                            ifcMetaData
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            DraftPhaseCreationActions.getIfcMetadataFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        );
    });
    newProjectData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DraftPhaseCreationActions.draftPhase),
            switchMap(({ draftPhaseData }) => {
                return this.draftPhaseApiService
                    .createDraftPhase({
                        body: {
                            /* TODO geoRegionId */
                            source: PhaseSourceType.Ifc,
                            type:
                                (draftPhaseData.type as any) === ''
                                    ? PlanType.Greenfield
                                    : draftPhaseData.type,
                            city: draftPhaseData.city,
                            currencyId: draftPhaseData.currencyId,
                            designYear: draftPhaseData.designYear,
                            ifcId: draftPhaseData.ifcId,
                            lotNo: draftPhaseData.lotNo,
                            name: draftPhaseData.name,
                            fenceStreetFront: draftPhaseData.fenceStreetFront,
                            planYear: draftPhaseData.planYear,
                            plotSize: draftPhaseData.plotSize,
                            size: draftPhaseData.size,
                            geoRegionId: draftPhaseData.geoRegionId,
                            street: draftPhaseData.street,
                            streetNo: draftPhaseData.streetNo,
                            zipCode: draftPhaseData.zipCode,
                            projectId: draftPhaseData.projectId
                        }
                    })
                    .pipe(
                        map((projectData: DraftPhaseEntity) =>
                            DraftPhaseCreationActions.draftPhaseSuccess({
                                draftPhaseData: projectData
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                DraftPhaseCreationActions.draftPhaseFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    );
            })
        );
    });
    public newProjectSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(DraftPhaseCreationActions.draftPhaseSuccess),
                tap(() => {
                    this.router.navigate([
                        '/',
                        RouteSegment.DraftPhaseCreation,
                        RouteSegment.Questions
                    ]);
                    this.store
                        .select(selectAccessToken)
                        .pipe(take(1))
                        .subscribe((accessToken) => {
                            if (accessToken)
                                this.store.dispatch(
                                    getUser({
                                        automaticLogin: true
                                    })
                                );
                        });
                })
            );
        },
        {
            dispatch: false
        }
    );

    updateQuestions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DraftPhaseCreationActions.updateQuestionsData),
            delay(2000),
            switchMap(({ id, body }) => {
                return this.draftPhaseApiService
                    .updateDraftPhaseQuestions({
                        id,
                        body: {
                            ...body,
                            designBudget: body.designBudget === 0 ? 20 : body.designBudget
                        }
                    })
                    .pipe(
                        map((data) => {
                            return DraftPhaseCreationActions.updateQuestionsDataSuccess({
                                summaryDraftPhaseData: data
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                DraftPhaseCreationActions.updateQuestionsDataFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        );
    });
    public updateQuestionsSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(DraftPhaseCreationActions.updateQuestionsDataSuccess),
                tap(() => {
                    this.router.navigate([
                        '/',
                        RouteSegment.DraftPhaseCreation,
                        RouteSegment.Summary
                    ]);
                })
            );
        },
        {
            dispatch: false
        }
    );

    updateNewProjectData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DraftPhaseCreationActions.updateDraftPhase),
            switchMap(({ draftPhaseData }) => {
                return this.draftPhaseApiService
                    .updateDraftPhase({
                        id: draftPhaseData.id,
                        body: {
                            type: draftPhaseData.type,
                            city: draftPhaseData.city,
                            currencyId: draftPhaseData.currencyId,
                            designYear: draftPhaseData.designYear,
                            lotNo: draftPhaseData.lotNo,
                            fenceStreetFront: draftPhaseData.fenceStreetFront,
                            name: draftPhaseData.name,
                            geoRegionId: draftPhaseData.geoRegionId,
                            planYear: draftPhaseData.planYear,
                            plotSize: draftPhaseData.plotSize,
                            size: draftPhaseData.size,
                            street: draftPhaseData.street,
                            streetNo: draftPhaseData.streetNo,
                            zipCode: draftPhaseData.zipCode
                        }
                    })
                    .pipe(
                        map((draftPhaseData: DraftPhaseEntity) => {
                            return DraftPhaseCreationActions.draftPhaseSuccess({
                                draftPhaseData
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                DraftPhaseCreationActions.draftPhaseFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        );
    });

    public logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(DraftPhaseCreationActions.logout),
            map(() => DraftPhaseCreationActions.clearDraftPhaseStore())
        );
    });
}
