import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminApiService, SupplierEntity } from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as ProducerManagementActions from './admin-producer-management.actions';

@Injectable({ providedIn: 'any' })
export class AdminProducerManagementEffects {
    constructor(
        private readonly actions$: Actions,
        public adminApiService: AdminApiService,
        private readonly router: Router
    ) {}

    AdminGetProducerManagement$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProducerManagementActions.getSupplierManagement),
            switchMap(() => {
                return this.adminApiService.getSuppliers().pipe(
                    map((producerManagementData: SupplierEntity[]) => {
                        return ProducerManagementActions.getSupplierManagementSuccess({
                            producerManagement: producerManagementData
                        });
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            ProducerManagementActions.getSupplierManagementFailure({
                                error: error.error.message
                            })
                        )
                    )
                );
            })
        );
    });

    /* AdminGetBuildingStructureArchives$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProducerManagementActions.buildingStructureArchives),
            switchMap(({ id }) => {
                return this.adminApiService.getBuildingStructureHistory({ id }).pipe(
                    map((buildingStructureArchives) => {
                        return ProducerManagementActions.buildingStructureArchivesSuccess({
                            id,
                            buildingStructureArchives: buildingStructureArchives
                        });
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            ProducerManagementActions.buildingStructureArchivesFailure({
                                error: error.error.message
                            })
                        )
                    )
                );
            })
        )
    ); */

    AdminEditProducerManagement$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProducerManagementActions.editSupplierManagement),
            switchMap(({ id, producerManagement }) => {
                return this.adminApiService
                    .updateSupplier({
                        id,
                        body: {
                            id,
                            active: producerManagement.active,
                            location: producerManagement.location,
                            logoId: producerManagement.logoId,
                            name: producerManagement.name,
                            email: producerManagement.email,
                            telephone: producerManagement.telephone,
                            website: producerManagement.website
                        }
                    })
                    .pipe(
                        map((newValueData) => {
                            return ProducerManagementActions.editSupplierManagementSuccess();
                        }),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                ProducerManagementActions.editSupplierManagementFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    );
            })
        );
    });

    /* editProducerManagementSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProducerManagementActions.editProducerManagementSuccess),
            switchMap((action) => of(ProducerManagementActions.getProducerManagement()))
        );
    }); */

    AdminCreateProducerManagement$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProducerManagementActions.createSupplierManagement),
            switchMap(({ producerManagement }) =>
                this.adminApiService
                    .createSupplier({
                        body: {
                            name: producerManagement.name,
                            email: producerManagement.email,
                            telephone: producerManagement.telephone,
                            website: producerManagement.website,
                            location: producerManagement.location,
                            logoId: producerManagement.logoId
                        }
                    })
                    .pipe(
                        map((newValueData) => {
                            return ProducerManagementActions.createSupplierManagementSuccess({
                                producerManagementData: newValueData
                            });
                        }),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                ProducerManagementActions.createSupplierManagementFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    )
            )
        );
    });

    createProducerManagementSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProducerManagementActions.createSupplierManagementSuccess),
            switchMap(() => of(ProducerManagementActions.getSupplierManagement()))
        );
    });

    DeleteProducerManagementAdmin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProducerManagementActions.deleteSupplierManagement),
            switchMap(({ id }) =>
                this.adminApiService.deleteSupplier({ id }).pipe(
                    switchMap(() =>
                        of(ProducerManagementActions.deleteSupplierManagementSuccess())
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            ProducerManagementActions.deleteSupplierManagementFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        );
    });

    DeletePlotTypesSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProducerManagementActions.deleteSupplierManagementSuccess),
            switchMap((action) => of(ProducerManagementActions.getSupplierManagement()))
        );
    });

    public logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProducerManagementActions.logout),
            map(() => ProducerManagementActions.clearProducerManagementData())
        );
    });
}
