import { createAction, props } from '@ngrx/store';
import { CreateSupplierDto, SupplierEntity, UpdateSupplierDto } from 'app/api';

export const getSupplierManagement = createAction(
    '[Supplier Management Tab] Get Supplier Managements from backend'
);
export const getSupplierManagementSuccess = createAction(
    '[Supplier Management Tab] Get Supplier Managements from backend success',
    props<{ producerManagement: SupplierEntity[] }>()
);
export const getSupplierManagementFailure = createAction(
    '[Supplier Management Tab] Get Supplier Managements from backend failure',
    props<{ error: string | null }>()
);

export const editSupplierManagement = createAction(
    '[Supplier Management Tab] Edit Producer Managements from backend',
    props<{ id: string; producerManagement: UpdateSupplierDto }>()
);
export const editSupplierManagementSuccess = createAction(
    '[Supplier Management Tab] Edit Supplier Managements from backend success'
);
export const editSupplierManagementFailure = createAction(
    '[Supplier Management Tab] Edit Supplier Managements from backend failure',
    props<{ error: string | null }>()
);

export const createSupplierManagement = createAction(
    '[Supplier Management Tab] Create Supplier Management to backend',
    props<{ producerManagement: CreateSupplierDto }>()
);
export const createSupplierManagementSuccess = createAction(
    '[Supplier Management Tab] Create Supplier Management to backend success',
    props<{ producerManagementData: SupplierEntity }>() /* SupplierEntity */
);
export const createSupplierManagementFailure = createAction(
    '[Supplier Management Tab] Create Supplier Management to backend failure',
    props<{ error: string | null }>()
);

export const deleteSupplierManagement = createAction(
    '[Producer Management Tab] Delete Supplier Managements to backend',
    props<{ id: string }>()
);
export const deleteSupplierManagementSuccess = createAction(
    '[Supplier Management Tab] Delete Supplier Managements to backend success'
);
export const deleteSupplierManagementFailure = createAction(
    '[Supplier Management Tab] Delete Supplier Managements to backend failure',
    props<{ error: string | null }>()
);

export const logout = createAction('[Header] Logout');
export const clearProducerManagementData = createAction(
    '[Producer Management Tab] Clear Producer Managements data'
);
