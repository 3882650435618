import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminApiService, BuildingStructureArchiveEntity, BuildingStructureEntity } from 'app/api';
import dayjs from 'dayjs';
import { catchError, map, of, switchMap } from 'rxjs';
import * as BuildingStructuresActions from './building-structures.actions';
import { getBuildingStructures } from './building-structures.actions';

@Injectable()
export class BuildingStructuresEffects {
    constructor(
        private readonly actions$: Actions,
        public adminApiService: AdminApiService,
        private readonly router: Router
    ) {}

    AdminBuildingStructures$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BuildingStructuresActions.getBuildingStructures),
            switchMap(({ date }) => {
                return this.adminApiService
                    .filterBuildingStructuresByDate({ body: { queryDate: date } })
                    .pipe(
                        map((buildingStructureData: BuildingStructureArchiveEntity[]) => {
                            return BuildingStructuresActions.getBuildingStructuresSuccess({
                                buildingStructures: buildingStructureData
                            });
                        }),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                BuildingStructuresActions.getBuildingStructuresFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    );
            })
        );
    });

    AdminGetBuildingStructureArchives$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BuildingStructuresActions.buildingStructureArchives),
            switchMap(({ id }) => {
                return this.adminApiService.getBuildingStructureHistory({ id }).pipe(
                    map((buildingStructureArchives) => {
                        return BuildingStructuresActions.buildingStructureArchivesSuccess({
                            id,
                            buildingStructureArchives
                        });
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            BuildingStructuresActions.buildingStructureArchivesFailure({
                                error: error.error.message
                            })
                        )
                    )
                );
            })
        );
    });

    AdminEditBuildingStructures$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BuildingStructuresActions.editBuildingStructures),
            switchMap(({ id, name, multiplier, installationType, nameTranslation, icon }) => {
                return this.adminApiService
                    .updateBuildingStructure({
                        id,
                        body: {
                            name,
                            multiplier,
                            installationType,
                            nameTranslation: {
                                hu: nameTranslation.hu,
                                en: nameTranslation.en
                            },
                            icon
                        }
                    })
                    .pipe(
                        map((newValueData: BuildingStructureEntity) => {
                            return BuildingStructuresActions.editBuildingStructuresSuccess({
                                id,
                                name: newValueData.name,
                                multiplier: newValueData.multiplier
                            });
                        }),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                BuildingStructuresActions.editBuildingStructuresFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    );
            })
        );
    });

    SavePlotTypesAdmin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BuildingStructuresActions.saveBuildingStructures),
            switchMap(({ name, multiplier, installationType, nameTranslation, icon }) =>
                this.adminApiService
                    .createBuildingStructure({
                        body: {
                            name,
                            multiplier,
                            installationType,
                            nameTranslation: {
                                hu: nameTranslation.hu,
                                en: nameTranslation.en
                            },
                            icon
                        }
                    })
                    .pipe(
                        map((newValueData: BuildingStructureEntity) => {
                            return BuildingStructuresActions.saveBuildingStructuresSuccess({
                                buildingStructureData: newValueData
                            });
                        }),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                BuildingStructuresActions.saveBuildingStructuresFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    )
            )
        );
    });

    savePlotTypesSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BuildingStructuresActions.saveBuildingStructuresSuccess),
            switchMap((action) => of(getBuildingStructures({ date: dayjs().format() })))
        );
    });

    DeleteBuildingStructuresAdmin$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BuildingStructuresActions.deleteBuildingStructures),
            switchMap(({ id }) =>
                this.adminApiService.deleteBuildingStructure({ id }).pipe(
                    switchMap(() => {
                        // Üres observable visszaadása, mivel a válasz Void típusú
                        return of(BuildingStructuresActions.deleteBuildingStructuresSuccess());
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            BuildingStructuresActions.deleteBuildingStructuresFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        );
    });

    DeletePlotTypesSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BuildingStructuresActions.deleteBuildingStructuresSuccess),
            switchMap((action) => of(getBuildingStructures({ date: dayjs().format() })))
        );
    });

    public logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(BuildingStructuresActions.logout),
            map(() => BuildingStructuresActions.clearBuildingStructureData())
        );
    });
}
