<!-- TEMPLATES -->
<ng-template #paymentPopup>
    <mat-dialog-content
        *ngLet="store.select(selectSubscriptionTypeInfo) | async as subsriptionType"
        class="!text-black"
    >
        <div *ngIf="subsriptionType" class="flex h-full flex-col">
            <img src="/assets/imgs/barion_official_logo.webp" class="h-16 object-contain" />
            <p class="mt-6 mb-3 text-center text-lg font-bold">
                {{ 'payment.payment-title' | transloco }}
            </p>
            <p class="mb-3 text-center text-md">
                {{ 'payment.premium-title' | transloco }}
            </p>

            <p class="mb-2 text-center text-md font-bold">
                {{ 'payment.whats-included' | transloco }}
            </p>

            <div class="grid grid-cols-2 gap-1 md:grid-cols-1">
                <div
                    *ngFor="
                        let translation of subsriptionType[1].descriptionTranslation!
                            | objectTranslation
                            | keyvalue
                    "
                    class="flex items-center gap-2 md:justify-center"
                >
                    <mat-icon fontIcon="check_circle" class="shrink-0 text-brand1"></mat-icon>
                    <p>{{ translation.key }}</p>
                </div>
            </div>

            <div class="mb-6 mt-8 flex flex-col text-center text-md font-bold">
                <p>
                    {{ 'payment.pay-with-baron' | transloco }}
                    {{
                        subsriptionType[1].fee +
                            ('forms.register.subscription.money-per-month' | transloco)
                    }}
                </p>
                <p>
                    ({{ 'forms.register.subscription.gross' | transloco }}
                    {{ subsriptionType[1].fee * 1.27 | currency : ' ' : 'symbol' : '3.0' }}
                    {{ 'forms.register.subscription.currency' | transloco }})
                </p>
            </div>

            <div class="flex justify-center gap-4">
                <button
                    mat-button
                    [mat-dialog-close]="false"
                    class="ml-2 h-[36px] !rounded-full hover:!bg-cancelBtnHover"
                >
                    {{ 'project-creation.table.cancel' | transloco }}
                </button>
                <button
                    mat-button
                    [mat-dialog-close]="true"
                    class="mr-2 flex h-[36px] !rounded-full !bg-brand1 !px-4 !text-white !text-brand1 hover:!bg-[var(--basic-colors---primary--focused)]"
                >
                    <mat-icon svgIcon="lot-of-money" class="text-white"></mat-icon>
                    {{ 'payment.pay' | transloco }}
                </button>
            </div>

            <!-- <p class="text-xs">
                {{ 'payment.you-agree-text' | transloco }}
            </p> -->
        </div>
    </mat-dialog-content>
</ng-template>
