import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ArchitectProfileApiService } from 'app/api/services/architect-profile-api.service';
import { UserApiService } from 'app/api/services/user-api.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ContactListActions from './contact-list.actions';

@Injectable()
export class ContactListEffects {
    constructor(
        private actions$: Actions,
        private architectProfileApiService: ArchitectProfileApiService,
        private userApiService: UserApiService
    ) {}

    getContactList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContactListActions.getContactList),
            switchMap(() => {
                const body: any = {
                    page: 0,
                    perPage: 999,
                    filter: null,
                    sort: {
                        field: 'company',
                        dir: 'asc'
                    }
                };
                return this.architectProfileApiService.filterArchitectProfiles({ body }).pipe(
                    map((contactList) => {
                        return ContactListActions.getContactListSuccess({
                            data: contactList.data!
                        });
                    }),
                    catchError((error) => {
                        return of(
                            ContactListActions.getContactListFailure({ error: error.error.message })
                        );
                    })
                );
            })
        )
    );

    updateContactList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ContactListActions.updateContactList),
            switchMap(({ user }) => {
                return this.userApiService
                    .updateArchitectProfile({
                        body: {
                            ...user
                        }
                    })
                    .pipe(
                        map((data) => {
                            return ContactListActions.updateContactListSuccess({
                                user: data
                            });
                        }),
                        catchError((error) => {
                            return of(
                                ContactListActions.updateContactListFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );
}
