import { createReducer, on } from '@ngrx/store';
import {
    clearWindEnergyTypesData,
    getWindEnergyApplianceTypeArchive,
    getWindEnergyApplianceTypeArchiveFailure,
    getWindEnergyApplianceTypeArchiveSuccess,
    getWindEnergyApplianceTypes,
    getWindEnergyApplianceTypesFailure,
    getWindEnergyApplianceTypesSuccess
} from './wind-energy.actions';
import { WindEnergyState } from './wind-energy.state';

export const initialState: WindEnergyState = {
    windEnergyApplianceTypes: [],
    windEnergyApplianceTypesInProgress: false,
    windEnergyApplianceTypesError: null,
    windEnergyApplianceTypeArchives: [],
    windEnergyApplianceTypeArchivesInProgress: false,
    windEnergyApplianceTypeArchivesError: null
};

export const windEnergyReducer = createReducer(
    initialState,
    /* Get Appliance */
    on(getWindEnergyApplianceTypes, (state) => ({
        ...state,
        windEnergyApplianceTypesInProgress: true,
        windEnergyApplianceTypesError: null
    })),
    on(getWindEnergyApplianceTypesSuccess, (state, { windEnergyApplianceTypes }) => ({
        ...state,
        windEnergyApplianceTypes,
        windEnergyApplianceTypesInProgress: false,
        windEnergyApplianceTypesError: null
    })),
    on(getWindEnergyApplianceTypesFailure, (state, { error }) => ({
        ...state,
        windEnergyApplianceTypesInProgress: false,
        windEnergyApplianceTypesError: error
    })),
    /* Get Appliance Archives */
    on(getWindEnergyApplianceTypeArchive, (state, { id }) => ({
        ...state,
        windEnergyApplianceTypeArchivesInProgress: true,
        windEnergyApplianceTypeArchivesError: null
    })),
    on(getWindEnergyApplianceTypeArchiveSuccess, (state, { windEnergyApplianceTypeArchives }) => ({
        ...state,
        windEnergyApplianceTypeArchives,
        windEnergyApplianceTypeArchivesInProgress: false,
        windEnergyApplianceTypeArchivesError: null
    })),
    on(getWindEnergyApplianceTypeArchiveFailure, (state, { error }) => ({
        ...state,
        windEnergyApplianceTypeArchivesInProgress: false,
        windEnergyApplianceTypeArchivesError: error
    })),
    on(clearWindEnergyTypesData, (state) => ({
        ...initialState
    }))
);
