import { createReducer, on } from '@ngrx/store';
import {
    clearGeoRegionsData,
    editGeoRegions,
    editGeoRegionsFailure,
    editGeoRegionsSuccess,
    geoRegionArchives,
    geoRegionArchivesFailure,
    geoRegionArchivesSuccess,
    getGeoRegions,
    getGeoRegionsFailure,
    getGeoRegionsSuccess
} from './geo-regions.actions';
import { GeoRegionsState } from './geo-regions.state';

export const initialState: GeoRegionsState = {
    geoRegions: [],
    geoRegionsInProgress: false,
    geoRegionsError: null,
    geoRegionArchives: [],
    geoRegionArchivesInProgress: false,
    geoRegionArchivesError: null,
    editGeoRegionsInProgress: false,
    editGeoRegionsError: null
};

export const geoRegionsReducer = createReducer(
    initialState,
    on(getGeoRegions, (state) => ({
        ...state,
        getGeoRegionsInProgress: true,
        getGeoRegionsError: null
    })),
    on(getGeoRegionsSuccess, (state, { geoRegions }) => ({
        ...state,
        geoRegions,
        geoRegionsInProgress: false,
        geoRegionsError: null
    })),
    on(getGeoRegionsFailure, (state, { error }) => ({
        ...state,
        geoRegionsInProgress: false,
        geoRegionsError: error
    })),

    on(geoRegionArchives, (state, { id }) => ({
        ...state,
        geoRegionArchivesInProgress: true,
        equipmentTypeArchiveError: null
    })),
    on(geoRegionArchivesSuccess, (state, { geoRegionArchives }) => ({
        ...state,
        geoRegionArchives,
        geoRegionArchivesInProgress: false,
        equipmentTypeArchiveError: null
    })),
    on(geoRegionArchivesFailure, (state, { error }) => ({
        ...state,
        geoRegionArchivesInProgress: false,
        equipmentTypeArchiveError: error
    })),
    on(editGeoRegions, (state) => ({
        ...state,
        editGeoRegionsInProgress: true,
        editGeoRegionsError: null
    })),
    on(editGeoRegionsSuccess, (state, { id, multiplier }) => ({
        ...state,
        id,
        multiplier,
        editGeoRegionsInProgress: false,
        editGeoRegionsError: null
    })),
    on(editGeoRegionsFailure, (state, { error }) => ({
        ...state,
        editGeoRegionsInProgress: false,
        editGeoRegionsError: error
    })),
    on(clearGeoRegionsData, (state) => ({
        ...initialState
    }))
);
