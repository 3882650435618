import { createAction, props } from '@ngrx/store';
import { ProfileUserEntity } from 'app/api/models/profile-user-entity';
import { ProfileUserListEntity } from 'app/api/models/profile-user-list-entity';
import { UpdateArchitectProfileDto } from 'app/api/models/update-architect-profile-dto';

export const getContactList = createAction('[Contact List] Get Contact List from backend');
export const getContactListSuccess = createAction(
    '[Contact List] Get Contact List from backend success',
    props<{ data: ProfileUserListEntity[] }>()
);
export const getContactListFailure = createAction(
    '[Contact List] Get Contact List from backend failure',
    props<{ error: string | null }>()
);

export const updateContactList = createAction(
    '[Contact List] Update Contact from backend',
    props<{ user: UpdateArchitectProfileDto }>()
);
export const updateContactListSuccess = createAction(
    '[Contact List] Update Contact from backend success',
    props<{ user: ProfileUserEntity }>()
);
export const updateContactListFailure = createAction(
    '[Contact List] Update Contact from backend failure',
    props<{ error: string | null }>()
);
