import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    AdminApiService,
    ApplianceItemArchiveEntity,
    ApplianceTypeEntity,
    GeneralApiService
} from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as FenceDevActions from './fence-dev.actions';
import { getFenceDevApplianceTypes } from './fence-dev.actions';

@Injectable()
export class FenceDevEffects {
    constructor(
        private actions$: Actions,
        public adminApiService: AdminApiService,
        public generalApiService: GeneralApiService,
        private readonly router: Router
    ) {}

    AdminGetFenceDevApplianceTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FenceDevActions.getFenceDevApplianceTypes),
            switchMap(() => {
                return this.generalApiService
                    .getFenceApplianceTypes(/* { body: { queryDate: date } } */)
                    .pipe(
                        map((typeData: ApplianceTypeEntity[]) => {
                            return FenceDevActions.getFenceDevApplianceTypesSuccess({
                                fenceDevApplianceTypes: typeData
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                FenceDevActions.getFenceDevApplianceTypesFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    AdminGetFenceDevApplianceTypeArchive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FenceDevActions.getFenceDevApplianceTypeArchive),
            switchMap(({ id }) => {
                return this.adminApiService.getRenewableApplianceItemHistory({ id }).pipe(
                    map((fenceDevApplianceTypeArchives: ApplianceItemArchiveEntity[]) => {
                        return FenceDevActions.getFenceDevApplianceTypeArchiveSuccess({
                            id,
                            fenceDevApplianceTypeArchives
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            FenceDevActions.getFenceDevApplianceTypeArchiveFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );

    AdminEditFenceDevApplianceItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FenceDevActions.editFenceDevApplianceItem),
            switchMap(({ id, name, value, labourCost, nameTranslation }) => {
                return this.adminApiService
                    .updateRenewableApplianceItem({
                        id,
                        body: {
                            name,
                            value,
                            labourCost,
                            nameTranslation: {
                                hu: nameTranslation.hu,
                                en: nameTranslation.en
                            }
                        }
                    })
                    .pipe(
                        map((newValueData) => {
                            return FenceDevActions.editFenceDevApplianceItemSuccess({
                                id,
                                name: newValueData.name,
                                value: newValueData.value
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                FenceDevActions.editFenceDevApplianceItemFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    editditFenceDevApplianceItemSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FenceDevActions.editFenceDevApplianceItemSuccess),
            switchMap((action) => of(getFenceDevApplianceTypes({ date: null })))
        )
    );

    SaveFenceDevApplianceItemAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FenceDevActions.saveFenceDevApplianceItem),
            switchMap(({ id, name, value, labourCost, nameTranslation }) => {
                return this.adminApiService
                    .createFenceApplianceItem({
                        body: {
                            name,
                            value,
                            typeId: id,
                            labourCost,
                            nameTranslation: {
                                hu: nameTranslation.hu,
                                en: nameTranslation.en
                            }
                        }
                    })
                    .pipe(
                        map((applianceTypeData: ApplianceTypeEntity[]) =>
                            FenceDevActions.getFenceDevApplianceTypesSuccess({
                                fenceDevApplianceTypes: applianceTypeData
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                FenceDevActions.saveFenceDevApplianceItemFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    );
            })
        )
    );

    DeleteFenceDevApplianceItemAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FenceDevActions.deleteFenceDevApplianceItem),
            switchMap(({ id }) =>
                this.adminApiService.deleteRenewableApplianceItem({ id }).pipe(
                    map((newValueData) =>
                        FenceDevActions.deleteFenceDevapplianceItemSuccess({
                            id
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            FenceDevActions.deleteFenceDevApplianceItemFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        )
    );

    deleteFenceDevApplianceItemSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FenceDevActions.deleteFenceDevapplianceItemSuccess),
            switchMap((action) => of(getFenceDevApplianceTypes({ date: null })))
        )
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FenceDevActions.logout),
            map(() => FenceDevActions.clearFenceDevTypesData())
        )
    );
}
