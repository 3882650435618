import { Pipe, PipeTransform } from '@angular/core';
import { ICurrencyEntity } from 'app/api/models/currency-entity';

@Pipe({ name: 'appCurrency' })
export class AppCurrencyPipe implements PipeTransform {
    public transform(
        value: number | string | null | undefined,
        currency: ICurrencyEntity
    ): string | null {
        if (!isValue(value)) return null;
        if (typeof value === 'string') return value + ' ' + currency.name;

        const currencyFormatter = new Intl.NumberFormat('hu-HU', {
            style: 'currency',
            currency: currency.name,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });

        return currencyFormatter.format(+value);
    }
}

function isValue(value: number | string | null | undefined): value is number | string {
    return !(value == null || value === '' || value !== value);
}
