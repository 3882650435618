import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { RouteSegment } from 'app/enums/route-segments.enum';
import { Observable, map } from 'rxjs';
import { expiredUser$ } from './expired-guard-common';

@Injectable({
    providedIn: 'root'
})
export class ExpiredUserGuard implements CanActivate {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public canActivate(): Observable<boolean | UrlTree> {
        return this.onlyAllowExpiredUser$();
    }

    public canActivateChild(): Observable<boolean | UrlTree> {
        return this.onlyAllowExpiredUser$();
    }

    public canLoad(): Observable<boolean | UrlTree> {
        return this.onlyAllowExpiredUser$();
    }

    private onlyAllowExpiredUser$(): Observable<boolean | UrlTree> {
        return expiredUser$(this.store).pipe(
            map((expired) => {
                if (expired) {
                    return true;
                } else {
                    return this.router.createUrlTree(['/', RouteSegment.Dashboard]);
                }
            })
        );
    }
}
