import { createAction, props } from '@ngrx/store';
import { BrowserLanguageCode } from './language-code.enum';

export const setActiveLanguageCode = createAction(
    '[Language] Set Active Language Code',
    props<{ languageCode: BrowserLanguageCode }>()
);

export const setAvailableLanguages = createAction(
    '[Language] Set Available Languages',
    props<{ languageCodes: Array<BrowserLanguageCode> }>()
);
