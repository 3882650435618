import { createReducer, on } from '@ngrx/store';
import {
    buildingStructureArchives,
    buildingStructureArchivesFailure,
    buildingStructureArchivesSuccess,
    clearBuildingStructureData,
    deleteBuildingStructures,
    deleteBuildingStructuresFailure,
    deleteBuildingStructuresSuccess,
    editBuildingStructures,
    editBuildingStructuresFailure,
    editBuildingStructuresSuccess,
    getBuildingStructures,
    getBuildingStructuresFailure,
    getBuildingStructuresSuccess,
    saveBuildingStructures,
    saveBuildingStructuresFailure,
    saveBuildingStructuresSuccess
} from './building-structures.actions';
import { BuildingStructuresState } from './building-structures.state';

export const initialState: BuildingStructuresState = {
    buildingStructures: [],
    buildingStructuresInProgress: false,
    buildingStructuresError: null,
    buildingStructureArchives: [],
    buildingStructureArchivesInProgress: false,
    buildingStructureArchivesError: null,
    editBuildingStructuresInProgress: false,
    editBuildingStructuresError: null,
    saveBuildingStructuresInProgress: false,
    saveBuildingStructuresError: null,
    deleteBuildingStructuresInProgress: false,
    deleteBuildingStructuresError: null
};

export const buildingStructuresReducer = createReducer(
    initialState,
    on(getBuildingStructures, (state) => ({
        ...state,
        buildingStructuresInProgress: true,
        buildingStructuresError: null
    })),
    on(getBuildingStructuresSuccess, (state, { buildingStructures }) => ({
        ...state,
        buildingStructures,
        buildingStructuresInProgress: false,
        buildingStructuresError: null
    })),
    on(getBuildingStructuresFailure, (state, { error }) => ({
        ...state,
        buildingStructuresInProgress: false,
        buildingStructuresError: error
    })),

    on(buildingStructureArchives, (state, { id }) => ({
        ...state,
        buildingStructureArchivesInProgress: true,
        buildingStructureArchivesError: null
    })),
    on(buildingStructureArchivesSuccess, (state, { buildingStructureArchives }) => ({
        ...state,
        buildingStructureArchives,
        buildingStructureArchivesInProgress: false,
        buildingStructureArchivesError: null
    })),
    on(buildingStructureArchivesFailure, (state, { error }) => ({
        ...state,
        buildingStructureArchivesInProgress: false,
        buildingStructureArchivesError: error
    })),
    on(editBuildingStructures, (state) => ({
        ...state,
        editBuildingStructuresInProgress: true,
        editBuildingStructuresError: null
    })),
    on(editBuildingStructuresSuccess, (state, { id, multiplier }) => ({
        ...state,
        id,
        multiplier,
        editBuildingStructuresInProgress: false,
        editBuildingStructuresError: null
    })),
    on(editBuildingStructuresFailure, (state, { error }) => ({
        ...state,
        editBuildingStructuresInProgress: false,
        editBuildingStructuresError: error
    })),
    on(saveBuildingStructures, (state) => ({
        ...state,
        saveBuildingStructuresInProgress: true,
        saveBuildingStructuresError: null
    })),
    on(saveBuildingStructuresSuccess, (state, { buildingStructureData }) => ({
        ...state,
        buildingStructureData,
        saveBuildingStructuresInProgress: false,
        saveBuildingStructuresError: null
    })),
    on(saveBuildingStructuresFailure, (state, { error }) => ({
        ...state,
        saveBuildingStructuresInProgress: false,
        saveBuildingStructuresError: error
    })),
    on(deleteBuildingStructures, (state, { id }) => ({
        ...state,
        id,
        deleteBuildingStructuresInProgress: true,
        deleteBuildingStructuresError: null
    })),
    on(deleteBuildingStructuresSuccess, (state) => ({
        ...state,
        deleteBuildingStructuresInProgress: false,
        deleteBuildingStructuresError: null
    })),
    on(deleteBuildingStructuresFailure, (state, { error }) => ({
        ...state,
        deleteBuildingStructuresInProgress: false,
        deleteBuildingStructuresError: error
    })),
    on(clearBuildingStructureData, (state) => ({
        ...initialState
    }))
);
