import { createReducer, on } from '@ngrx/store';
import {
    clearEquipmentTypesData,
    editApplianceItem,
    editApplianceItemFailure,
    editApplianceItemSuccess,
    getApplianceTypesToSelectSuccess,
    getEquipmentTypeArchive,
    getEquipmentTypeArchiveFailure,
    getEquipmentTypeArchiveSuccess,
    getEquipmentTypes,
    getEquipmentTypesFailure,
    getEquipmentTypesSuccess
} from './equipment-types.actions';
import { EquipmentTypesState } from './equipment-types.state';

export const initialState: EquipmentTypesState = {
    equipmentTypes: [],
    equipmentTypesInProgress: false,
    equipmentTypesError: null,
    equipmentTypeArchives: [],
    equipmentTypeArchivesInProgress: false,
    equipmentTypeArchivesError: null,
    workTypesToSelectType: []
};

export const equipmentTypesReducer = createReducer(
    initialState,
    on(getEquipmentTypes, (state) => ({
        ...state,
        equipmentTypesInProgress: true,
        equipmentTypesError: null
    })),
    on(getEquipmentTypesSuccess, (state, { equipmentTypes }) => ({
        ...state,
        equipmentTypes,
        equipmentTypesInProgress: false,
        equipmentTypesError: null
    })),
    on(getEquipmentTypesFailure, (state, { error }) => ({
        ...state,
        equipmentTypesInProgress: false,
        equipmentTypesError: error
    })),

    on(getEquipmentTypeArchive, (state, { id }) => ({
        ...state,
        equipmentTypeArchiveInProgress: true,
        equipmentTypeArchiveError: null
    })),
    on(getEquipmentTypeArchiveSuccess, (state, { equipmentTypeArchives }) => ({
        ...state,
        equipmentTypeArchives,
        equipmentTypesInProgress: false,
        equipmentTypeArchiveError: null
    })),
    on(getEquipmentTypeArchiveFailure, (state, { error }) => ({
        ...state,
        equipmentTypesInProgress: false,
        equipmentTypeArchiveError: error
    })),
    on(editApplianceItem, (state) => ({
        ...state,
        equipmentTypesInProgress: true,
        equipmentTypesError: null
    })),
    on(editApplianceItemSuccess, (state, { id, name, value }) => ({
        ...state,
        /* id,
        categoryName, */
        equipmentTypesInProgress: false,
        equipmentTypesError: null
    })),
    on(editApplianceItemFailure, (state, { error }) => ({
        ...state,
        equipmentTypesInProgress: false,
        equipmentTypesError: error
    })),
    on(getApplianceTypesToSelectSuccess, (state, { data }) => ({
        ...state,
        workTypesToSelectType: data
    })),
    on(clearEquipmentTypesData, (state) => ({
        ...initialState
    }))
);
