import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AdminApiService, PlotTypeArchiveEntity, PlotTypeEntity } from 'app/api';
import dayjs from 'dayjs';
import { catchError, map, of, switchMap } from 'rxjs';
import * as PlotTypesActions from './plot-types.actions';
import { getPlotTypes } from './plot-types.actions';

@Injectable()
export class PlotTypesEffects {
    constructor(
        private actions$: Actions,
        public adminApiService: AdminApiService,
        private readonly router: Router,
        private readonly store: Store
    ) {}

    AdminGetPlotTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlotTypesActions.getPlotTypes),
            switchMap(({ date }) =>
                this.adminApiService.filterPlotTypesByDate({ body: { queryDate: date } }).pipe(
                    map((plotTypeData: PlotTypeArchiveEntity[]) => {
                        return PlotTypesActions.getPlotTypesSuccess({
                            plotTypes: plotTypeData
                        });
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            PlotTypesActions.getPlotTypesFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        )
    );

    AdminGetPlotTypeArchives$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlotTypesActions.getPlotTypeArchive),
            switchMap(({ id }) =>
                this.adminApiService.getPlotTypeHistory({ id }).pipe(
                    map((plotTypeArchives) => {
                        return PlotTypesActions.getPlotTypeArchiveSuccess({
                            id,
                            plotTypeArchives: plotTypeArchives
                        });
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            PlotTypesActions.getPlotTypeArchiveFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        )
    );

    AdminEditPlotTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlotTypesActions.editPlotTypes),
            switchMap(({ id, name, multiplier, nameTranslation }) =>
                this.adminApiService
                    .updatePlotType({
                        id,
                        body: {
                            name,
                            multiplier,
                            nameTranslation: {
                                hu: nameTranslation.hu,
                                en: nameTranslation.en
                            }
                        }
                    })
                    .pipe(
                        map((newValueData: PlotTypeEntity) => {
                            return PlotTypesActions.editPlotTypesSuccess({
                                id,
                                name: newValueData.name,
                                multiplier: newValueData.multiplier
                            });
                        }),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                PlotTypesActions.editPlotTypesFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    )
            )
        )
    );

    SavePlotTypesAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlotTypesActions.savePlotTypes),
            switchMap(({ name, multiplier, nameTranslation }) =>
                this.adminApiService
                    .createPlotType({
                        body: {
                            name,
                            multiplier,
                            nameTranslation: {
                                hu: nameTranslation.hu,
                                en: nameTranslation.en
                            }
                        }
                    })
                    .pipe(
                        map((newValueData: PlotTypeEntity) => {
                            return PlotTypesActions.savePlotTypesSuccess({
                                plotTypeData: newValueData
                            });
                        }),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                PlotTypesActions.savePlotTypesFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    )
            )
        )
    );
    savePlotTypesSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PlotTypesActions.savePlotTypesSuccess),
            switchMap((action) => {
                return of(getPlotTypes({ date: dayjs().format() }));
            })
        );
    });

    DeletePlotTypesAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlotTypesActions.deletePlotTypes),
            switchMap(({ id }) =>
                this.adminApiService.deletePlotType({ id }).pipe(
                    switchMap(() => {
                        // Üres observable visszaadása, mivel a válasz Void típusú
                        return of(PlotTypesActions.deletePlotTypesSuccess());
                    }),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            PlotTypesActions.deletePlotTypesFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        )
    );
    DeletePlotTypesSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PlotTypesActions.deletePlotTypesSuccess),
            switchMap((action) => {
                return of(getPlotTypes({ date: dayjs().format() }));
            })
        );
    });

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PlotTypesActions.logout),
            map(() => PlotTypesActions.clearPlotTypesData())
        )
    );
}
