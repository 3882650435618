import { createReducer, on } from '@ngrx/store';
import {
    clearProjects,
    draftPhaseDataSuccess,
    projectsData,
    projectsDataFailure,
    projectsDataSuccess,
    selectProjectFile
} from './projects.actions';
import { ProjectsState } from './projects.state';

export const initialState: ProjectsState = {
    projectData: null,
    projectError: null,
    projectLoading: false,
    selectedProject: null,
    draftPhaseData: null
};

export const projectsReducer = createReducer(
    initialState,
    on(
        projectsData,
        (state): ProjectsState => ({
            ...state,
            projectLoading: true,
            projectError: null
        })
    ),
    on(
        projectsDataSuccess,
        (state, { data }): ProjectsState => ({
            ...state,
            projectData: data,
            projectLoading: false
        })
    ),
    on(
        projectsDataFailure,
        (state, { error }): ProjectsState => ({
            ...state,
            projectError: error,
            projectLoading: false
        })
    ),
    on(
        selectProjectFile,
        (state, { projectFile }): ProjectsState => ({
            ...state,
            selectedProject: projectFile
        })
    ),
    on(
        draftPhaseDataSuccess,
        (state, { draftPhase }): ProjectsState => ({
            ...state,
            draftPhaseData: draftPhase
        })
    ),
    on(
        clearProjects,
        (state): ProjectsState => ({
            ...initialState
        })
    )
);
