import { createReducer, on } from '@ngrx/store';
import { BrowserLanguageCode } from './language-code.enum';
import { setActiveLanguageCode, setAvailableLanguages } from './language.actions';
import { LanguageState } from './language.state';

export const initialState: LanguageState = {
    availableLanguages: Object.values(BrowserLanguageCode).filter((v) => isNaN(Number(v))),
    activeLanguageCode: BrowserLanguageCode.HUNGARIAN
};

export const languageReducer = createReducer(
    initialState,
    on(setActiveLanguageCode, (state, { languageCode }) => ({
        ...state,
        activeLanguageCode: languageCode
    })),
    on(setAvailableLanguages, (state, { languageCodes }) => ({
        ...state,
        availableLanguages: languageCodes
    }))
);
