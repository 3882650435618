import { createReducer, on } from '@ngrx/store';
import {
    getAllQualityCategoriesWithFunctions,
    getAllQualityCategoriesWithFunctionsFailure,
    getAllQualityCategoriesWithFunctionsSuccess,
    getQualityCategoriesWithFunctions,
    getQualityCategoriesWithFunctionsFailure,
    getQualityCategoriesWithFunctionsSuccess,
    getSelectedQualityCategoriesWithFunctions,
    getSelectedQualityCategoriesWithFunctionsFailure,
    getSelectedQualityCategoriesWithFunctionsSuccess
} from './quality-categories-with-functions.actions';
import { QualityCategoriesWithFunctionsState } from './quality-categories-with-functions.state';

export const initialState: QualityCategoriesWithFunctionsState = {
    qualityCategoriesWithFunctions: [],
    qualityCategoriesWithFunctionsInProgress: false,
    qualityCategoriesWithFunctionsError: null,
    /* All Categories */
    allQualityCategoriesWithFunctions: [],
    allQualityCategoriesWithFunctionsInProgress: false,
    allQualityCategoriesWithFunctionsError: null,
    /* Selected Categories */
    selectedQualityCategoriesWithFunctions: [],
    selectedQualityCategoriesWithFunctionsInProgress: false,
    selectedQualityCategoriesWithFunctionsError: null
};

export const qualityCategoriesWithFunctionsReducer = createReducer(
    initialState,
    on(getQualityCategoriesWithFunctions, (state) => ({
        ...state,
        qualityCategoriesWithFunctionsInProgress: true,
        qualityCategoriesWithFunctionsError: null
    })),
    on(getQualityCategoriesWithFunctionsSuccess, (state, { qualityCategoriesWithFunctions }) => ({
        ...state,
        qualityCategoriesWithFunctions,
        qualityCategoriesWithFunctionsInProgress: false,
        qualityCategoriesWithFunctionsError: null
    })),
    on(getQualityCategoriesWithFunctionsFailure, (state, { error }) => ({
        ...state,
        qualityCategoriesWithFunctionsInProgress: false,
        qualityCategoriesWithFunctionsError: error
    })),
    /* All Categories */
    on(getAllQualityCategoriesWithFunctions, (state) => ({
        ...state,
        allQualityCategoriesWithFunctionsInProgress: true,
        allQualityCategoriesWithFunctionsError: null
    })),
    on(
        getAllQualityCategoriesWithFunctionsSuccess,
        (state, { allQualityCategoriesWithFunctions }) => ({
            ...state,
            allQualityCategoriesWithFunctions,
            allQualityCategoriesWithFunctionsInProgress: false,
            allQualityCategoriesWithFunctionsError: null
        })
    ),
    on(getAllQualityCategoriesWithFunctionsFailure, (state, { error }) => ({
        ...state,
        allQualityCategoriesWithFunctionsInProgress: false,
        allQualityCategoriesWithFunctionsError: error
    })),
    /* Selected Categories */
    on(getSelectedQualityCategoriesWithFunctions, (state) => ({
        ...state,
        selectedQualityCategoriesWithFunctionsInProgress: true,
        selectedQualityCategoriesWithFunctionsError: null
    })),
    on(
        getSelectedQualityCategoriesWithFunctionsSuccess,
        (state, { qualityCategoriesWithFunctions }) => ({
            ...state,
            qualityCategoriesWithFunctions,
            selectedQualityCategoriesWithFunctionsInProgress: false,
            selectedQualityCategoriesWithFunctionsError: null
        })
    ),
    on(getSelectedQualityCategoriesWithFunctionsFailure, (state, { error }) => ({
        ...state,
        selectedQualityCategoriesWithFunctionsInProgress: false,
        selectedQualityCategoriesWithFunctionsError: error
    }))
);
