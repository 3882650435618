import { createAction, props } from '@ngrx/store';
import { DraftPhaseEntity, ProjectEntity } from 'app/api';

export const projectsData = createAction('[Project data] Project data');
export const logout = createAction('[Header] Logout');
export const clearProjects = createAction('[Project data] Clear User');

export const projectsDataSuccess = createAction(
    '[Project data] Project success',
    props<{ data: ProjectEntity[] }>()
);

export const projectsDataFailure = createAction(
    '[Project data] Project failure',
    props<{ error: string | null }>()
);

export const draftPhaseData = createAction(
    '[Project data] Draft Phase data',
    props<{ projectId: string; phase: ('DRAFT' | 'TRIALDRAFT')[] }>()
);
export const draftPhaseDataSuccess = createAction(
    '[Project data] Draft Phase success',
    props<{ draftPhase: DraftPhaseEntity[] }>()
);
export const draftPhaseDataFailure = createAction(
    '[Project data] Draft Phase failure',
    props<{ error: string | null }>()
);

export const addProjectFileName = createAction(
    '[Project Page] Add Project File Name to backend',
    props<{
        projectName: string;
        projectAddress?: string;
        projectBuildingFunction?: string;
        skipNavigation?: boolean;
    }>()
);
export const selectProjectFile = createAction(
    '[Project Page] Select Project File',
    props<{ projectFile: ProjectEntity; skipNavigation?: boolean }>()
);
export const addProjectFileFailure = createAction(
    '[Project Page] Add Project File Name to backend failure',
    props<{ error: string | null }>()
);
