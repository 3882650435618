import { Component, Input } from '@angular/core';
import { SummaryTableService } from '../../summary-table.service';

@Component({
    selector: 'app-column-shadowed',
    templateUrl: './column-shadowed.component.html',
    styleUrls: ['./column-shadowed.component.scss']
})
export class ColumnShadowedComponent {
    @Input() element!: any;

    public readonly onShadowedChange = this.summaryTableService.onShadowedChange.bind(
        this.summaryTableService
    );
    constructor(public readonly summaryTableService: SummaryTableService) {}
}
