import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectsState } from './projects.state';

export const selectProjectsState = createFeatureSelector<ProjectsState>('projects');
export const selectProjectsData = createSelector(selectProjectsState, (state) => state.projectData);
export const selectLoading = createSelector(selectProjectsState, (state) => state.projectLoading);
export const selectProjectById = (id: string) =>
    createSelector(selectProjectsData, (projects) =>
        projects?.find((project) => project.id === id)
    );
export const selectSelectedProject = createSelector(
    selectProjectsState,
    (state) => state.selectedProject
);
export const selectDraftPhaseData = createSelector(
    selectProjectsState,
    (state) => state.draftPhaseData
);
