import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { setActiveLanguageCode } from './language.actions';

@Injectable()
export class LanguageEffects {
    constructor(private actions$: Actions, private translocoService: TranslocoService) {}

    setActiveLanguageCode$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(setActiveLanguageCode),
                switchMap(({ languageCode }) => {
                    this.translocoService.setActiveLang(languageCode);
                    return languageCode;
                })
            ),
        { dispatch: false }
    );
}
