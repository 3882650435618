import { createAction, props } from '@ngrx/store';
import {
    BuildingStructureArchiveEntity,
    BuildingStructureEntity,
    StructureInstallationType
} from 'app/api';

export const getBuildingStructures = createAction(
    '[Building Structure Tab] Get building structures from backend',
    props<{ date: string | null }>()
);
export const getBuildingStructuresSuccess = createAction(
    '[Building Structure Tab] Get building structures from backend success',
    props<{ buildingStructures: BuildingStructureArchiveEntity[] }>()
);
export const getBuildingStructuresFailure = createAction(
    '[Building Structure Tab] Get building structures from backend failure',
    props<{ error: string | null }>()
);

export const buildingStructureArchives = createAction(
    '[Building Structure Tab] Get geo building structures archives from backend',
    props<{ id: string }>()
);
export const buildingStructureArchivesSuccess = createAction(
    '[Building Structure Tab] Get geo building structures archives from backend success',
    props<{ id: string; buildingStructureArchives: BuildingStructureArchiveEntity[] }>()
);
export const buildingStructureArchivesFailure = createAction(
    '[Building Structure Tab] Get geo building structures archives from backend failure',
    props<{ error: string | null }>()
);

export const editBuildingStructures = createAction(
    '[Building Structure Tab] Edit building structures from backend',
    props<{
        icon: string;
        id: string;
        name: string;
        installationType: StructureInstallationType | null;
        multiplier: number;
        nameTranslation: { hu: string; en: string };
    }>()
);
export const editBuildingStructuresSuccess = createAction(
    '[Building Structure Tab] Edit building structures from backend success',
    props<{ id: string; name: string; multiplier: number }>()
);
export const editBuildingStructuresFailure = createAction(
    '[Building Structure Tab] Edit building structures from backend failure',
    props<{ error: string | null }>()
);

export const saveBuildingStructures = createAction(
    '[Building Structure Tab] Save building structures to backend',
    props<{
        name: string;
        multiplier: number;
        installationType: StructureInstallationType | null;
        nameTranslation: { hu: string; en: string };
        icon: string;
    }>()
);
export const saveBuildingStructuresSuccess = createAction(
    '[Building Structure Tab] Save building structures to backend success',
    props<{ buildingStructureData: BuildingStructureEntity }>()
);
export const saveBuildingStructuresFailure = createAction(
    '[Building Structure Tab] Save building structures to backend failure',
    props<{ error: string | null }>()
);

export const deleteBuildingStructures = createAction(
    '[Building Structure Tab] Delete building structures to backend',
    props<{ id: string }>()
);
export const deleteBuildingStructuresSuccess = createAction(
    '[Building Structure Tab] Delete building structures to backend success'
);
export const deleteBuildingStructuresFailure = createAction(
    '[Building Structure Tab] Delete building structures to backend failure',
    props<{ error: string | null }>()
);

export const logout = createAction('[Header] Logout');
export const clearBuildingStructureData = createAction(
    '[Building Structure Tab] Clear building structures data'
);
