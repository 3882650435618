import { createAction, props } from '@ngrx/store';
import { DraftPhaseEntity, IfcMessageWithData, IfcMetadataEntity, PlanType } from 'app/api';
import { UpdateDraftPhaseQuestionnaireApplianceDto } from 'app/api/models/update-draft-phase-questionnaire-appliance-dto';
import { DraftPhaseData } from './draft-phase-creation.state';

export const addDraftPhaseName = createAction(
    '[Draft phase Page] Add Draft phase Name',
    props<{ name: string }>()
);

export const addDraftPhaseIfc = createAction(
    '[Draft phase Page] Add Draft phase IFC to backend',
    props<{ file: File }>()
);
export const addDraftPhaseIfcSuccess = createAction(
    '[Draft phase Page] Add Draft phase IFC to backend success',
    props<{ ifcId: string; ifcMessageWithData: IfcMessageWithData[] }>()
);
export const addDraftPhaseIfcFailure = createAction(
    '[Draft phase Page] Add Draft phase IFC to backend failure',
    props<{ error: string | null }>()
);

export const getIfcMetadataSuccess = createAction(
    '[Draft phase Page] Get ifc meta data to backend success',
    props<{ ifcMetaData: IfcMetadataEntity }>()
);
export const getIfcMetadataFailure = createAction(
    '[Draft phase Page] Get ifc meta data to backend failure',
    props<{ error: string | null }>()
);

export const draftPhase = createAction(
    '[Draft phase Page] Draft phase data send to backend',
    props<{
        draftPhaseData: {
            id: string | null;
            ifcId: string;
            name: string;
            currencyId: string;
            projectId: string;
            size: number;
            designYear: number;
            fenceStreetFront: number;
            geoRegionId: string;
            planYear: number;
            plotSize: number;
            zipCode: string;
            city: string;
            street: string;
            streetNo: string;
            lotNo: string;
            type: PlanType;
        };
    }>()
);
export const updateDraftPhase = createAction(
    '[Draft phase Page] Update draft phase data send to backend',
    props<{
        draftPhaseData: {
            id: string;
            ifcId: string;
            name: string;
            currencyId: string;
            projectId: string;
            size: number;
            designYear: number;
            fenceStreetFront: number;
            geoRegionId: string;
            planYear: number;
            plotSize: number;
            zipCode: string;
            city: string;
            street: string;
            streetNo: string;
            lotNo: string;
            type: PlanType;
        };
    }>()
);
export const draftPhaseSuccess = createAction(
    '[Draft phase Page] Draft phase data send success',
    props<{ draftPhaseData: DraftPhaseEntity }>()
);
export const draftPhaseFailure = createAction(
    '[Draft phase Page] Draft phase data send failure',
    props<{ error: string | null }>()
);

export const setDraftPhaseData = createAction(
    '[Draft phase Page] Set Project Data',
    props<{ draftPhaseData: DraftPhaseData }>()
);

export const setUpdateQuestionsData = createAction(
    '[Draft phase Page] Set Update Questions Data',
    props<{ summaryDraftPhaseData: DraftPhaseEntity }>()
);
export const updateQuestionsData = createAction(
    '[Draft phase Page] Update Questions Data',
    props<{ id: string; body: UpdateDraftPhaseQuestionnaireApplianceDto }>()
);
export const updateQuestionsDataSuccess = createAction(
    '[Draft phase Page] Update Questions Data Send Success',
    props<{ summaryDraftPhaseData: DraftPhaseEntity }>()
);
export const updateQuestionsDataFailure = createAction(
    '[Draft phase Page] Update Questions Data Send Failure',
    props<{ error: string | null }>()
);
export const setGeoRegionIdInQuestionnaire = createAction(
    '[Trial Draft phase Page] Set Update georegion id in Questionnaire',
    props<{ geoRegionId: string }>()
);

export const setQuestionnaireData = createAction(
    '[Draft phase Page] Set Questions Data to store',
    props<{ questionnaire: UpdateDraftPhaseQuestionnaireApplianceDto }>()
);

export const logout = createAction('[Header] Logout');
export const clearDraftPhaseStore = createAction('[Clear Draft phase] Clear Draft phase Store');
