import { createReducer, on } from '@ngrx/store';
import { setTableConfigValue } from './table-column-config.actions';
import { TableConfigState } from './table-column-config.state';

export const initialState: TableConfigState = {
    tableColumn: 'omniclass'
};

export const tableConfigReducer = createReducer(
    initialState,
    on(setTableConfigValue, (state, { tableColumn }) => ({
        ...state,
        tableColumn
    }))
);
