import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminApiService, TypeValueArchiveEntity } from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as ItemManagementActions from './item-management.actions';

@Injectable()
export class ItemManagementEffects {
    constructor(
        private actions$: Actions,
        public adminApiService: AdminApiService,
        private readonly router: Router
    ) {}

    adminGetItemManagementTypeValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ItemManagementActions.getTypeValues),
            switchMap(({ date }) => {
                return this.adminApiService
                    .filterTypeValuesByDate({ body: { queryDate: date } })
                    .pipe(
                        map((typeData: TypeValueArchiveEntity[]) => {
                            return ItemManagementActions.getTypeValuesSuccess({
                                typeValues: typeData
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                ItemManagementActions.getTypeValuesFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    adminGetItemManagementTypeValueArchies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ItemManagementActions.getTypeValueArchive),
            switchMap(({ id }) => {
                return this.adminApiService.getTypeValueHistory({ id }).pipe(
                    map((typeValueArchiveData) => {
                        return ItemManagementActions.getTypeValueArchiveSuccess({
                            id,
                            typeValueArchives: typeValueArchiveData
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            ItemManagementActions.getTypeValueArchiveFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );

    adminEditItemManagementTypeValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ItemManagementActions.editTypeValues),
            switchMap(({ id, value, grossHourlyRate, labourCost }) => {
                return this.adminApiService
                    .updateTypeValue({
                        id,
                        body: {
                            value: value,
                            grossHourlyRate: grossHourlyRate,
                            labourCost: labourCost
                        }
                    })
                    .pipe(
                        map((newValueData) => {
                            return ItemManagementActions.editTypeValuesSuccess({
                                id,
                                value: newValueData,
                                labourCost: newValueData.labourCost,
                                grossHourlyRate: newValueData.grossHourlyRate
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                ItemManagementActions.editTypeValuesFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ItemManagementActions.logout),
            map(() => ItemManagementActions.clearItemManagementData())
        )
    );
}
