import { createReducer, on } from '@ngrx/store';
import {
    adminGetBuildMethodsStatistics,
    adminGetBuildMethodsStatisticsFailure,
    adminGetBuildMethodsStatisticsSuccess,
    adminGetDesignBudgetstatistics,
    adminGetDesignBudgetstatisticsFailure,
    adminGetDesignBudgetstatisticsSuccess,
    adminGetGeoRegionProjectDetailsStatistics,
    adminGetGeoRegionProjectDetailsStatisticsFailure,
    adminGetGeoRegionProjectDetailsStatisticsSuccess,
    adminGetPlotTypesStatistics,
    adminGetPlotTypesStatisticsFailure,
    adminGetPlotTypesStatisticsSuccess,
    adminGetRenewableEnergeticsStatistics,
    adminGetRenewableEnergeticsStatisticsFailure,
    adminGetRenewableEnergeticsStatisticsSuccess,
    adminGetUserProjectStats,
    adminGetUserProjectStatsFailure,
    adminGetUserProjectStatsSuccess,
    adminGetUserRoleStatistics,
    adminGetUserRoleStatisticsFailure,
    adminGetUserRoleStatisticsSuccess,
    adminGetUserSubscriptionStatistics,
    adminGetUserSubscriptionStatisticsFailure,
    adminGetUserSubscriptionStatisticsSuccess
} from './admin-user-analytics.actions';
import { AdminUserAnalyticsState } from './admin-user-analytics.state';

export const initialState: AdminUserAnalyticsState = {
    userProjectStats: null,
    userProjectStatsInProgress: false,
    userProjectStatsError: null,

    roleUserStat: null,
    roleUserStatInProgress: false,
    roleUserStatError: null,

    subscriptionUserStat: null,
    subscriptionUserStatInProgress: false,
    subscriptionUserStatError: null,

    draftphaseRenewableEnergyStat: null,
    draftphaseRenewableEnergyStatInProgress: false,
    draftphaseRenewableEnergyStatError: null,

    draftphasePlotTypesStat: null,
    draftphasePlotTypesStatInProgress: false,
    draftphasePlotTypesStatError: null,

    draftphaseBuildMethodsStat: null,
    draftphaseBuildMethodsStatInProgress: false,
    draftphaseBuildMethodsStatError: null,

    draftphaseDesignBudgetsStat: null,
    draftphaseDesignBudgetsStatInProgress: false,
    draftphaseDesignBudgetsStatError: null,

    geoRegionProjectDetailsStat: null,
    geoRegionProjectDetailsStatInProgress: false,
    geoRegionProjectDetailsStatError: null
};

export const adminUserAnalyticsReducer = createReducer(
    initialState,
    on(adminGetUserProjectStats, (state) => ({
        ...state,
        userProjectStatsInProgress: true,
        userProjectStatsError: null
    })),
    on(adminGetUserProjectStatsSuccess, (state, { userProjectStats }) => ({
        ...state,
        userProjectStats,
        userProjectStatsInProgress: false
    })),
    on(adminGetUserProjectStatsFailure, (state, { error }) => ({
        ...state,
        userProjectStatsInProgress: false,
        userProjectStatsError: error
    })),

    on(adminGetUserRoleStatistics, (state) => ({
        ...state,
        roleUserStatInProgress: true,
        roleUserStatError: null
    })),
    on(adminGetUserRoleStatisticsSuccess, (state, { roleUserStat }) => ({
        ...state,
        roleUserStat,
        roleUserStatInProgress: false
    })),
    on(adminGetUserRoleStatisticsFailure, (state, { error }) => ({
        ...state,
        roleUserStatInProgress: false,
        roleUserStatError: error
    })),

    on(adminGetUserSubscriptionStatistics, (state) => ({
        ...state,
        subscriptionUserStatInProgress: true,
        subscriptionUserStatError: null
    })),
    on(adminGetUserSubscriptionStatisticsSuccess, (state, { subscriptionUserStat }) => ({
        ...state,
        subscriptionUserStat,
        subscriptionUserStatInProgress: false
    })),
    on(adminGetUserSubscriptionStatisticsFailure, (state, { error }) => ({
        ...state,
        subscriptionUserStatInProgress: false,
        subscriptionUserStatError: error
    })),

    /* Draftphase Renewable energetics */
    on(adminGetRenewableEnergeticsStatistics, (state) => ({
        ...state,
        draftphaseRenewableEnergyStatInProgress: true,
        draftphaseRenewableEnergyStatError: null
    })),
    on(
        adminGetRenewableEnergeticsStatisticsSuccess,
        (state, { draftphaseRenewableEnergyStat }) => ({
            ...state,
            draftphaseRenewableEnergyStat,
            draftphaseRenewableEnergyStatInProgress: false
        })
    ),
    on(adminGetRenewableEnergeticsStatisticsFailure, (state, { error }) => ({
        ...state,
        draftphaseRenewableEnergyStatInProgress: false,
        draftphaseRenewableEnergyStatError: error
    })),

    /* Draftphase Plot types */
    on(adminGetPlotTypesStatistics, (state) => ({
        ...state,
        draftphasePlotTypesStatInProgress: true,
        draftphasePlotTypesStatError: null
    })),
    on(adminGetPlotTypesStatisticsSuccess, (state, { draftphasePlotTypesStat }) => ({
        ...state,
        draftphasePlotTypesStat,
        draftphasePlotTypesStatInProgress: false
    })),
    on(adminGetPlotTypesStatisticsFailure, (state, { error }) => ({
        ...state,
        draftphasePlotTypesStatInProgress: false,
        draftphasePlotTypesStatError: error
    })),

    /* Draftphase Build methods */
    on(adminGetBuildMethodsStatistics, (state) => ({
        ...state,
        draftphaseBuildMethodsStatInProgress: true,
        draftphaseBuildMethodsStatError: null
    })),
    on(adminGetBuildMethodsStatisticsSuccess, (state, { draftphaseBuildMethodsStat }) => ({
        ...state,
        draftphaseBuildMethodsStat,
        draftphaseBuildMethodsStatInProgress: false
    })),
    on(adminGetBuildMethodsStatisticsFailure, (state, { error }) => ({
        ...state,
        draftphaseBuildMethodsStatInProgress: false,
        draftphaseBuildMethodsStatError: error
    })),

    /* Draftphase Design budgets */
    on(adminGetDesignBudgetstatistics, (state) => ({
        ...state,
        draftphaseDesignBudgetsStatInProgress: true,
        draftphaseDesignBudgetsStatError: null
    })),
    on(adminGetDesignBudgetstatisticsSuccess, (state, { draftphaseDesignBudgetsStat }) => ({
        ...state,
        draftphaseDesignBudgetsStat,
        draftphaseDesignBudgetsStatInProgress: false
    })),
    on(adminGetDesignBudgetstatisticsFailure, (state, { error }) => ({
        ...state,
        draftphaseDesignBudgetsStatInProgress: false,
        draftphaseDesignBudgetsStatError: error
    })),

    /* Geo region project details */
    on(adminGetGeoRegionProjectDetailsStatistics, (state) => ({
        ...state,
        geoRegionProjectDetailsStatInProgress: true,
        geoRegionProjectDetailsStatError: null
    })),
    on(
        adminGetGeoRegionProjectDetailsStatisticsSuccess,
        (state, { geoRegionProjectDetailsStat }) => ({
            ...state,
            geoRegionProjectDetailsStat,
            geoRegionProjectDetailsStatInProgress: false
        })
    ),
    on(adminGetGeoRegionProjectDetailsStatisticsFailure, (state, { error }) => ({
        ...state,
        geoRegionProjectDetailsStatInProgress: false,
        geoRegionProjectDetailsStatError: error
    }))
);
