import { Component, Input } from '@angular/core';
import { SummaryTableService } from '../../summary-table.service';

@Component({
    selector: 'app-column-total-cost',
    templateUrl: './column-total-cost.component.html',
    styleUrls: ['./column-total-cost.component.scss']
})
export class ColumnTotalCostComponent {
    @Input() element!: any;

    public readonly onEditCell = this.summaryTableService.onEditCell.bind(this.summaryTableService);
    public readonly aggregateValuesFor = this.summaryTableService.aggregateValuesFor.bind(
        this.summaryTableService
    );
    public readonly isMainCategory = this.summaryTableService.isMainCategory.bind(
        this.summaryTableService
    );
    constructor(public readonly summaryTableService: SummaryTableService) {}
}
