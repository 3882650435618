import { createReducer, on } from '@ngrx/store';
import {
    clearSolarCollectorTypesData,
    getSolarCollectorApplianceTypeArchive,
    getSolarCollectorApplianceTypeArchiveFailure,
    getSolarCollectorApplianceTypeArchiveSuccess,
    getSolarCollectorApplianceTypes,
    getSolarCollectorApplianceTypesFailure,
    getSolarCollectorApplianceTypesSuccess
} from './solar-collector.actions';
import { SolarCollectorsState } from './solar-collector.state';

export const initialState: SolarCollectorsState = {
    solarCollectorApplianceTypes: [],
    solarCollectorApplianceTypesInProgress: false,
    solarCollectorApplianceTypesError: null,
    solarCollectorApplianceTypeArchives: [],
    solarCollectorApplianceTypeArchivesInProgress: false,
    solarCollectorApplianceTypeArchivesError: null
};

export const solarCollectorReducer = createReducer(
    initialState,
    /* Get Appliance */
    on(getSolarCollectorApplianceTypes, (state) => ({
        ...state,
        solarCollectorApplianceTypesInProgress: true,
        solarCollectorApplianceTypesError: null
    })),
    on(getSolarCollectorApplianceTypesSuccess, (state, { solarCollectorApplianceTypes }) => ({
        ...state,
        solarCollectorApplianceTypes,
        solarCollectorApplianceTypesInProgress: false,
        solarCollectorApplianceTypesError: null
    })),
    on(getSolarCollectorApplianceTypesFailure, (state, { error }) => ({
        ...state,
        solarCollectorApplianceTypesInProgress: false,
        solarCollectorApplianceTypesError: error
    })),
    /* Get Appliance Archives */
    on(getSolarCollectorApplianceTypeArchive, (state, { id }) => ({
        ...state,
        solarCollectorApplianceTypeArchivesInProgress: true,
        solarCollectorApplianceTypeArchivesError: null
    })),
    on(
        getSolarCollectorApplianceTypeArchiveSuccess,
        (state, { solarCollectorApplianceTypeArchives }) => ({
            ...state,
            solarCollectorApplianceTypeArchives,
            solarCollectorApplianceTypeArchivesInProgress: false,
            solarCollectorApplianceTypeArchivesError: null
        })
    ),
    on(getSolarCollectorApplianceTypeArchiveFailure, (state, { error }) => ({
        ...state,
        solarCollectorApplianceTypeArchivesInProgress: false,
        solarCollectorApplianceTypeArchivesError: error
    })),
    on(clearSolarCollectorTypesData, (state) => ({
        ...initialState
    }))
);
