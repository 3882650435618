import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminApiService, GeoRegionArchiveEntity, GeoRegionEntity } from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as GeoRegionsActions from './geo-regions.actions';

@Injectable()
export class GeoRegionsEffects {
    constructor(
        private actions$: Actions,
        public adminApiService: AdminApiService,
        private readonly router: Router
    ) {}

    AdminGetGeoRegions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeoRegionsActions.getGeoRegions),
            switchMap(({ date }) => {
                return this.adminApiService
                    .filterGeoRegionsByDate({ body: { queryDate: date } })
                    .pipe(
                        map((geoRegionData: GeoRegionArchiveEntity[]) => {
                            return GeoRegionsActions.getGeoRegionsSuccess({
                                geoRegions: geoRegionData
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                GeoRegionsActions.getGeoRegionsFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    AdminGetGeoRegionArchives$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeoRegionsActions.geoRegionArchives),
            switchMap(({ id }) => {
                return this.adminApiService.getGeoRegionHistory({ id }).pipe(
                    map((geoRegionArchives) => {
                        return GeoRegionsActions.geoRegionArchivesSuccess({
                            id,
                            geoRegionArchives: geoRegionArchives
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            GeoRegionsActions.geoRegionArchivesFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );

    AdminEditGeoRegions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeoRegionsActions.editGeoRegions),
            switchMap(({ id, multiplier }) => {
                return this.adminApiService.updateGeoRegion({ id, body: { multiplier } }).pipe(
                    map((newValueData: GeoRegionEntity) => {
                        return GeoRegionsActions.editGeoRegionsSuccess({
                            id,
                            multiplier: newValueData.multiplier
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            GeoRegionsActions.editGeoRegionsFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeoRegionsActions.logout),
            map(() => GeoRegionsActions.clearGeoRegionsData())
        )
    );
}
