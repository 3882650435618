import { Component, Input } from '@angular/core';
import { SummaryTableService } from '../../summary-table.service';

@Component({
    selector: 'app-column-actions',
    templateUrl: './column-actions.component.html',
    styleUrls: ['./column-actions.component.scss']
})
export class ColumnActionsComponent {
    @Input() element!: any;
    public readonly getEntriesFor = this.summaryTableService.getEntriesFor.bind(
        this.summaryTableService
    );
    public readonly getSubcategoriesFor = this.summaryTableService.getSubcategoriesFor.bind(
        this.summaryTableService
    );
    public readonly onAddNewRow = this.summaryTableService.onAddNewRow.bind(
        this.summaryTableService
    );
    public readonly onEditRow = this.summaryTableService.onEditRow.bind(this.summaryTableService);
    public readonly onSaveCategoryVatRate = this.summaryTableService.onSaveCategoryVatRate.bind(
        this.summaryTableService
    );
    public readonly onUndoCategoryVatRateEdit =
        this.summaryTableService.onUndoCategoryVatRateEdit.bind(this.summaryTableService);
    public readonly onDeleteNewRow = this.summaryTableService.onDeleteNewRow.bind(
        this.summaryTableService
    );
    public readonly onSaveRow = this.summaryTableService.onSaveRow.bind(this.summaryTableService);
    public readonly onUndoRow = this.summaryTableService.onUndoRow.bind(this.summaryTableService);

    constructor(public readonly summaryTableService: SummaryTableService) {}
}
