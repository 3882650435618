import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DraftPhaseApiService, PatchWorkEntryDto, WorkEntryEntity, WorkTypeEntity } from 'app/api';
import { concat, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as TablesCompareActions from './tables-compare.actions';
import { TableDataSource } from './tables-compare.state';

@Injectable()
export class TablesComparesEffects {
    constructor(
        private actions$: Actions,
        private readonly draftPhaseApiService: DraftPhaseApiService,
        private readonly router: Router
    ) {}

    getProjectsToTables$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TablesCompareActions.getProjectsToTables),
            switchMap(({ projectIds }) => {
                const workTypes$ = this.draftPhaseApiService.getWorkTypes();
                const projectIdReponses$ = forkJoin(
                    projectIds.map((projectId) =>
                        forkJoin([
                            this.draftPhaseApiService.getDraftPhaseEntries({ id: projectId }),
                            this.draftPhaseApiService.getDraftPhase({ id: projectId }),
                            this.draftPhaseApiService.getDraftPhaseStructures({ id: projectId })
                        ])
                    )
                );

                return forkJoin([workTypes$, projectIdReponses$]).pipe(
                    map(([workTypes, [...projectIdReponses]]) => {
                        return {
                            workTypes,
                            projectIdReponses,
                            projectIds
                        };
                    })
                );
            }),
            map(({ workTypes, projectIdReponses, projectIds }) => {
                // This currently remove every "function type" workType. These should be removed as per customer agreement,
                //  and they should be separated to WorkFunction model. To counter this issue for now this filter does the job.
                const filteredWorkTypes = workTypes.filter(
                    (wt) => !(wt.omniclass?.startsWith('33') && wt.major == 2)
                );

                const projects: Record<string, TableDataSource> = {};
                projectIdReponses.forEach(([entries, draftPhase, buildingFunction], index) => {
                    projects[projectIds[index]] = {
                        entries,
                        workTypes: this.addTopMainCategoryHighlightToBackend(filteredWorkTypes),
                        projectData: draftPhase,
                        buildingFunctions: buildingFunction
                    };
                });

                return TablesCompareActions.getProjectsToTablesSuccess({
                    projects
                });
            }),
            catchError((error) => of(TablesCompareActions.getProjectsToTablesFailure({ error })))
        )
    );

    private addTopMainCategoryHighlightToBackend(filteredWorkTypes: WorkTypeEntity[]) {
        return filteredWorkTypes.map((workType) => ({
            ...workType,
            topMainCategoryHighlight: false
        }));
    }

    addEntry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TablesCompareActions.addEntry),
            switchMap(({ projectId, entry }) => {
                return this.draftPhaseApiService
                    .createWorkEntry({
                        id: projectId,
                        body: entry
                    })
                    .pipe(
                        map((projectEntries: WorkEntryEntity[]) =>
                            TablesCompareActions.addEntrySuccess({
                                projectId,
                                projectEntries
                            })
                        ),
                        catchError((error) => of(TablesCompareActions.addEntryFailure({ error })))
                    );
            })
        )
    );

    saveEntryChanges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TablesCompareActions.saveEntryChanges),
            switchMap(({ projectId, entryId, changes }) =>
                concat(
                    ...Object.entries(changes).map(([key, value]) =>
                        this.draftPhaseApiService.patchWorkEntry({
                            id: projectId,
                            entryId: entryId,
                            body: {
                                [key]: value
                            } as unknown as PatchWorkEntryDto
                        })
                    )
                ).pipe(
                    map((updatedEntries) =>
                        TablesCompareActions.addEntrySuccess({
                            projectId: projectId,
                            projectEntries: updatedEntries
                        })
                    ),
                    catchError((error) => of(TablesCompareActions.addEntryFailure({ error })))
                )
            )
        )
    );

    deleteEntry$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TablesCompareActions.deleteEntry),
            switchMap(({ projectId, entryId }) => {
                return this.draftPhaseApiService
                    .deleteWorkEntry({
                        id: projectId,
                        entryId
                    })
                    .pipe(
                        map((projectEntries: WorkEntryEntity[]) =>
                            TablesCompareActions.deleteEntrySuccess({
                                projectId,
                                projectEntries
                            })
                        ),
                        catchError((error) =>
                            of(TablesCompareActions.deleteEntryFailure({ error }))
                        )
                    );
            })
        )
    );

    saveProjectBudgetChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TablesCompareActions.saveProjectBudgetChange),
            switchMap(({ projectId, updatePropName, percent }) => {
                return this.draftPhaseApiService
                    .updateDraftPhaseCosts({
                        id: projectId,
                        body: {
                            [updatePropName]: percent
                        } as any
                    })
                    .pipe(
                        map((projectData) =>
                            TablesCompareActions.saveProjectBudgetChangeSuccess({
                                projectId,
                                projectData
                            })
                        ),
                        catchError((error) =>
                            of(TablesCompareActions.saveProjectBudgetChangeFailure({ error }))
                        )
                    );
            })
        )
    );

    saveCategoryVatRateChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TablesCompareActions.saveCategoryVatRateChange),
            switchMap(({ projectId, typeId, vatRate }) => {
                return this.draftPhaseApiService
                    .patchDraftPhaseWorkEntryVatRateByCategory({
                        id: projectId,
                        typeId: typeId,
                        body: {
                            vatRate: vatRate
                        } as any
                    })
                    .pipe(
                        map((projectEntries) =>
                            TablesCompareActions.saveCategoryVatRateChangeSuccess({
                                projectId,
                                projectEntries
                            })
                        ),
                        catchError((error) =>
                            of(TablesCompareActions.saveCategoryVatRateChangeFailure({ error }))
                        )
                    );
            })
        )
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TablesCompareActions.logout),
            map(() => TablesCompareActions.clearTablesCompareData())
        )
    );
}
