import { createAction, props } from '@ngrx/store';
import { AdminTab } from 'app/enums/admin-tab.enum';

export const setTabName = createAction(
    '[Admin tab config] Set tab name value',
    props<{ tabName: AdminTab }>()
);

export const setDateTimeValue = createAction(
    '[Admin tab config] Set Date picker config value',
    props<{ date: string | null }>()
);

export const isSetDateTimeValue = createAction(
    '[Admin tab config] Is set Date picker config value',
    props<{
        isDateTimeValueChecked: boolean;
    }>()
);
