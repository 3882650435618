/* tslint:disable */
/* eslint-disable */
import { UpsertCertificateDto } from './upsert-certificate-dto';
import { UpsertFeatureDto } from './upsert-feature-dto';
export interface IUpsertBuildingStructureProductDto {
    certificates: Array<UpsertCertificateDto>;
    descriptionTranslation: {};
    features: Array<UpsertFeatureDto>;
    id: string;
    images: Array<String>;
    multiplier: number;
    name: string;
    nameTranslation: {};
    recommended: boolean;
    score_comfort: number;
    score_durability: number;
    score_ecological: number;
    score_mobility: number;
    score_price: number;
    score_speed: number;
    structureId: string;
    supplierId: string;
}
export class UpsertBuildingStructureProductDto implements IUpsertBuildingStructureProductDto {
    certificates!: Array<UpsertCertificateDto>;
    descriptionTranslation!: {};
    features!: Array<UpsertFeatureDto>;
    id!: string;
    images!: Array<String>;
    multiplier!: number;
    name!: string;
    nameTranslation!: {};
    recommended!: boolean;
    score_comfort!: number;
    score_durability!: number;
    score_ecological!: number;
    score_mobility!: number;
    score_price!: number;
    score_speed!: number;
    structureId!: string;
    supplierId!: string;
    constructor(data: IUpsertBuildingStructureProductDto) {
        Object.assign(this, data);
    }
}
