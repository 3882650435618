import { createReducer, on } from '@ngrx/store';
import {
    clearUser,
    getUser,
    getUserFailure,
    getUserSuccess,
    logout,
    trialUserRegister,
    trialUserRegisterFailure,
    trialUserRegisterSuccess,
    updateProfile,
    updateProfileFailure,
    updateProfileSuccess,
    userLogin,
    userLoginFailure,
    userLoginSuccess,
    userRegister,
    userRegisterFailure,
    userRegisterSuccess
} from './user.actions';
import { UserState } from './user.state';

export const initialState: UserState = {
    accessToken: null,
    loginInProgress: false,
    registerInProgress: false,
    error: null,
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    newsLetter: false,
    createdAt: null,
    getUserInProgress: false,
    getUserError: null,
    company: null,
    roleId: null,
    taxNumber: null,
    telephone: null,
    zipCode: null,
    city: null,
    street: null,
    streetNo: null,
    active: false,
    accountType: null,
    getUpdateProfileInprogress: false,
    isRegisterSuccessMessage: false,
    isTrialRegisterSuccessMessage: false,
    isAdmin: false,
    initialUserHasBeenDetermined: false,
    subscription: [],
    _count: { payment: 0 }
};

export const userReducer = createReducer(
    initialState,
    on(userLogin, (state) => ({
        ...state,
        loginInProgress: true,
        error: null
    })),
    on(userLoginSuccess, (state, { accessToken }) => ({
        ...state,
        accessToken,
        loginInProgress: false
    })),
    on(userLoginFailure, (state, { error }) => ({
        ...state,
        error,
        loginInProgress: false
    })),
    on(userRegister, (state) => ({
        ...state,
        isRegisterSuccessMessage: false,
        registerInProgress: true,
        error: null
    })),
    on(userRegisterSuccess, (state) => ({
        ...state,
        isRegisterSuccessMessage: true,
        registerInProgress: false
    })),
    on(userRegisterFailure, (state, { error }) => ({
        ...state,
        error,
        isRegisterSuccessMessage: false,
        registerInProgress: false
    })),
    on(logout, (state) => ({
        ...state
    })),
    on(clearUser, (state) => ({
        ...initialState
    })),
    on(getUser, (state) => ({
        ...state,
        getUserInProgress: true,
        getUserError: null,
        initialUserHasBeenDetermined: false
    })),
    on(getUserSuccess, (state, { user }) => ({
        ...state,
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        newsLetter: user.newsLetter,
        company: user.company,
        roleId: user.roleId,
        taxNumber: user.taxNumber,
        telephone: user.telephone,
        zipCode: user.zipCode,
        city: user.city,
        street: user.street,
        streetNo: user.streetNo,
        active: user.active,
        isAdmin: user.isAdmin,
        accountType: user.accountType,
        /* createdAt: user.createdAt, */
        getUserInProgress: false,
        initialUserHasBeenDetermined: true,
        subscription: user.subscription,
        _count: user._count
    })),
    on(getUserFailure, (state, { error }) => ({
        ...state,
        error,
        getUserInProgress: false,
        accessToken: null
    })),
    on(updateProfile, (state) => ({
        ...state,
        getUpdateProfileInprogress: true
    })),
    on(updateProfileSuccess, (state, { user }) => ({
        ...state,
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        newsLetter: user.newsLetter,
        company: user.company,
        roleId: user.roleId,
        taxNumber: user.taxNumber,
        telephone: user.telephone,
        getUpdateProfileInprogress: false
    })),
    on(updateProfileFailure, (state, { error }) => ({
        ...state,
        error,
        getUpdateProfileInprogress: false
    })),
    on(trialUserRegister, (state) => ({
        ...state,
        isTrialRegisterSuccessMessage: false,
        registerInProgress: true,
        error: null
    })),
    on(trialUserRegisterSuccess, (state) => ({
        ...state,
        isTrialRegisterSuccessMessage: true,
        registerInProgress: false
    })),
    on(trialUserRegisterFailure, (state, { error }) => ({
        ...state,
        error,
        isTrialRegisterSuccessMessage: false,
        registerInProgress: false
    }))
);
