import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectCurrencies } from '../base/base.selectors';
import { DraftPhaseCreationState } from './draft-phase-creation.state';

export const selectDraftPhaseState =
    createFeatureSelector<DraftPhaseCreationState>('draftPhaseCreation');

export const selectDraftPhaseData = createSelector(
    selectDraftPhaseState,
    (state) => state.draftPhaseData
);
export const selectSummaryDraftPhaseData = createSelector(
    selectDraftPhaseState,
    (state) => state.summaryDraftPhaseData
);
export const selectQuestionnaireData = createSelector(
    selectDraftPhaseState,
    (state) => state.summaryDraftPhaseData?.questionnaire
);
export const selectDraftPhaseInProgress = createSelector(
    selectDraftPhaseState,
    (state) => state.draftPhaseInProgress
);
export const selectDraftPhaseError = createSelector(
    selectDraftPhaseState,
    (state) => state.draftPhaseError
);
export const selectUpdateQuestionsDataInProgress = createSelector(
    selectDraftPhaseState,
    (state) => state.updateQuestionsDataInProgress
);
export const selectUpdateQuestionsDataError = createSelector(
    selectDraftPhaseState,
    (state) => state.updateQuestionsDataError
);
export const selectSendIfcInProgresss = createSelector(
    selectDraftPhaseState,
    (state) => state.sendIfcInProgress
);
export const selectSendIfcError = createSelector(
    selectDraftPhaseState,
    (state) => state.sendIfcError
);

export const selectIfcWarningMessages = createSelector(
    selectDraftPhaseState,
    (state) => state.ifcMessageWithData
);

export const selectProjectCurrencyName = createSelector(
    selectDraftPhaseState,
    (state) => state.summaryDraftPhaseData?.currency?.name
);

export const selectValidProjectData = createSelector(
    selectDraftPhaseState,
    selectCurrencies,
    (DraftPhaseState, currencies) => {
        if (!currencies || !currencies.length || !DraftPhaseState) return null;

        if (!DraftPhaseState.draftPhaseData) return [DraftPhaseState.draftPhaseData, null];

        let validProjectData: any = {
            ...Object.fromEntries(
                Object.entries(DraftPhaseState.draftPhaseData).filter(([key, value]) => value)
            ),
            currency: currencies.find(
                (currency) => currency.id === DraftPhaseState.draftPhaseData!.currencyId
            )!
        };
        return [DraftPhaseState.draftPhaseData, validProjectData];
    }
);
