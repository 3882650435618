import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminApiService, PublicUserEntity } from 'app/api';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import * as AdminActions from './admin.actions';

@Injectable()
export class AdminEffects {
    constructor(private actions$: Actions, private adminApiService: AdminApiService) {}

    private adminGetUsers(active: boolean): Observable<{
        total?: number | undefined;
        page?: number | undefined;
        perPage?: number | undefined;
        data?: PublicUserEntity[] | undefined;
    }> {
        return this.adminApiService.filterUsers({
            body: {
                page: 0,
                perPage: 5000,
                filter: {
                    search: ''
                } as any,
                sort: {
                    field: 'createdAt',
                    dir: 'desc'
                }
            }
        });
    }

    adminGetWaitingUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.adminGetWaitingUsers),
            switchMap(() => {
                return this.adminGetUsers(false).pipe(
                    map((waitingUsersData) => {
                        return AdminActions.adminGetWaitingUsersSuccess({
                            waitingUsers: waitingUsersData.data!
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            AdminActions.adminGetWaitingUsersFailure({ error: error.error.message })
                        );
                    })
                );
            })
        )
    );

    adminGetActiveUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.adminGetActiveUsers),
            switchMap(() => {
                return this.adminGetUsers(true).pipe(
                    map((activeUsersData) => {
                        return AdminActions.adminGetActiveUsersSuccess({
                            activeUsers: activeUsersData.data!
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            AdminActions.adminGetActiveUsersFailure({ error: error.error.message })
                        );
                    })
                );
            })
        )
    );

    adminDeleteWaitingUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.adminDeleteWaitingUser),
            switchMap(({ userId }) => {
                return this.adminApiService.softDeleteUser({ id: userId }).pipe(
                    map(() => {
                        return AdminActions.adminDeleteWaitingUserSuccess();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            AdminActions.adminDeleteWaitingUserFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );

    adminDeleteWaitingUserSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.adminDeleteWaitingUserSuccess),
            switchMap(() => {
                return [AdminActions.adminGetWaitingUsers(), AdminActions.adminGetActiveUsers()];
            })
        )
    );

    adminSetActiveArchitectProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.adminSetActiveArchitectProfile),
            switchMap(({ userId, setActiveArchitectProfile }) => {
                return this.adminApiService
                    .setUserArchitectProfileStatus({
                        id: userId,
                        body: { active: setActiveArchitectProfile }
                    })
                    .pipe(
                        map(() => {
                            return AdminActions.adminSetActiveArchitectProfileSuccess();
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                AdminActions.adminSetActiveArchitectProfileFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    adminSetActiveArchitectProfileSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminActions.adminSetActiveArchitectProfileSuccess),
            switchMap(() => {
                return [AdminActions.adminGetWaitingUsers(), AdminActions.adminGetActiveUsers()];
            })
        )
    );
}
