import { METHOD_INVOKER_PIPE_HOST } from '@adroit-group/ng-utils';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    TrackByFunction,
    ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { TranslocoService } from '@ngneat/transloco';
import { DraftPhaseEntity, PlanType, WorkTypeEntity } from 'app/api';
import { IWorkTypeEntity } from 'app/api/models/work-type-entity';
import {
    getProjectDesignBudgetValueOnlyCostBuilding,
    getProjectFinalTotalValue,
    getProjectFinalTotalValueWithTax,
    getProjectGeneralPlanningCost,
    getProjectSubTotal,
    getProjectSubTotalLabourCost,
    getProjectSubTotalLabourCostOnlyCostBuilding,
    getProjectSubTotalOnlyCostBuilding
} from 'app/shared/helpers/project.helpers';
import { tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { IWorkEntryEntity } from '../../../api/models/work-entry-entity';
import { SummaryTableService } from './summary-table.service';

export interface WorkEntry extends Partial<IWorkEntryEntity> {
    isCategory: boolean;
    percent?: number;
    /**
     * Custom work type id on sub elements of a work type category
     */
    labourCost: number;
    versionId?: string;
    version?: string;
    temporaryVatRate?: number;
}

export interface AddWorkEntryDto {
    projectId: string;
    entry: WorkEntry;
}

export interface SaveWorkEntryChangesDto {
    projectId: string;
    entryId: string;
    changes: Partial<WorkEntry>;
}

export interface DeleteWorkEntryDto {
    projectId: string;
    entryId: string;
}

export interface SaveProjectBudgetChangesDto {
    projectId: string;
    updatePropName: string;
    percent: number;
}

export interface SaveCategoryVatRateChangesDto {
    projectId: string;
    typeId: string;
    vatRate: number;
}

export interface IFooterRowConfig {
    id?: string;
    canEditPercent?: boolean;
    wasEdited?: boolean;
    name: string;
    unit: string;
    amount: number | string | ((...args: any[]) => number);
    updatePropName?: string;
    unitPrice: number | string | ((...args: any[]) => number);
    value: number | string | ((...args: any[]) => number);
    labourCost: number | string | ((...args: any[]) => number);
    percent?: number | string | ((...args: any[]) => number);
    vatRate?: number | string | ((...args: any[]) => number);
}

@Component({
    selector: 'app-summary-table',
    templateUrl: './summary-table.component.html',
    styleUrls: ['./summary-table.component.scss'],
    providers: [
        SummaryTableService,
        {
            provide: METHOD_INVOKER_PIPE_HOST,
            useExisting: SummaryTableComponent
        }
    ]
})
export class SummaryTableComponent {
    @Input() public get readonly(): boolean | null {
        return this._readonly;
    }

    public set readonly(value: boolean | null) {
        this._readonly = value ?? false;

        this.displayedColumns = this._readonly
            ? this.displayedColumns.filter(
                  (column) => column !== 'actions' && column !== 'shadowed'
              )
            : [...new Set([...this.displayedColumns, 'actions'])];
    }

    @Input() public get loading(): boolean | null {
        return this._loading;
    }

    public set loading(value: boolean | null) {
        this._loading = value ?? false;
        this.summaryTableService.onUndoRow();
    }

    @Input() public get entries(): WorkEntry[] {
        return this._entries;
    }

    public set entries(value: WorkEntry[]) {
        this._entries = value;
        this._originalEntries = [...value];
        this.dataSource.data = value;
        this.summaryTableService.calculateTableData();
        this.cdr.markForCheck();
    }

    @Input() public workTypes!: WorkTypeEntity[];
    @Input() public project!: DraftPhaseEntity;

    @Input() public smallerTableOnPc: boolean = false;
    @Input() public topMainCategoryHighlight: any = [];
    @Input() public topMainCategoryId!: string;

    @Output()
    public readonly addEntry = new EventEmitter<AddWorkEntryDto>();
    @Output()
    public readonly saveEntry = new EventEmitter<SaveWorkEntryChangesDto>();
    @Output()
    public readonly projectBudgetChange = new EventEmitter<SaveProjectBudgetChangesDto>();
    @Output()
    public readonly deleteEntry = new EventEmitter<DeleteWorkEntryDto>();
    @Output()
    public readonly saveCategoryVatRate = new EventEmitter<SaveCategoryVatRateChangesDto>();

    @ViewChild('deleteRowConfirmDialogTpl')
    public readonly deleteRowConfirmDialogTpl!: TemplateRef<unknown>;
    @ViewChild('saveRowConfirmDialogTpl')
    public readonly saveRowConfirmDialogTpl!: TemplateRef<unknown>;
    @ViewChild('savePercentRowConfirmDialogTpl')
    public readonly savePercentRowConfirmDialogTpl!: TemplateRef<unknown>;
    @ViewChild('saveShadowConfirmDialogTpl')
    public readonly saveShadowConfirmDialogTpl!: TemplateRef<unknown>;
    @ViewChild('editOverrideConfirmDialogTpl')
    public readonly editOverrideConfirmDialogTpl!: TemplateRef<unknown>;
    @ViewChild('addNewItemToCategoryDialogTpl')
    public readonly addNewItemToCategoryDialogTpl!: TemplateRef<unknown>;
    @ViewChild('workTypeSubcategorySelect')
    public workTypeSubcategorySelect!: MatSelect;

    public readonly dataSource = new MatTableDataSource<WorkEntry>([]);

    public displayedColumns: string[] = [
        'costInfo',
        'shadowed',
        'id',
        'acquisitionCost',
        'name',
        'unit',
        'amount',
        'materialUnitPrice',
        'materialCost',
        'labourUnitPrice',
        'labourCost',
        'totalCost',
        'percent',
        'vatRate',
        'actions'
    ];

    public tableFooterRowConfig!: IFooterRowConfig[];

    public readonly showShadowedRowsControl = new FormControl(true);
    public readonly showEmptyCategoriesControl = new FormControl(true);

    public readonly getProjectSubTotal = getProjectSubTotal;

    public editedRow: { id?: string } | null = null;
    public originalRow: {
        id?: string;
        percent?: number;
        value?: number;
        vatRate?: number;
        shadowed?: boolean;
    } | null = null;
    public editErrors: Record<string, string> | null = null;
    public footerRowNewPercent: number | null = null;
    public readonly toggledCategories = new Set<string | undefined>();

    private _readonly = false;
    private _loading = false;
    private _entries: WorkEntry[] = [];
    public _originalEntries: WorkEntry[] = [];
    public readonly hasDifferentUnits = this.summaryTableService.hasDifferentUnits.bind(
        this.summaryTableService
    );
    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly translocoService: TranslocoService,
        public readonly summaryTableService: SummaryTableService
    ) {}

    public ngOnInit(): void {
        this.summaryTableService.summaryTableComponent = this;
        this.dataSource.data = this.entries;
        this.summaryTableService.calculateTableData();

        if (this.project.type !== PlanType.Renovation) {
            this.displayedColumns = this.displayedColumns.filter((column) => column !== 'shadowed');
        }

        this.tableFooterRowConfig = [
            {
                id: uuidv4(),
                canEditPercent: true,
                wasEdited: false,
                name: 'project-creation.table.design-budget',
                updatePropName: 'designBudget',
                unit: '%',
                amount: () => this.project.designBudget ?? 0,
                unitPrice: '',
                value: () =>
                    getProjectDesignBudgetValueOnlyCostBuilding(this.project, this.dataSource.data),
                labourCost: 0,
                percent: () => this.project.designBudget! / 100,
                vatRate: ''
            },
            {
                id: uuidv4(),
                canEditPercent: true,
                wasEdited: false,
                name: 'project-creation.table.general-planning-cost',
                updatePropName: 'generalPlanningCost',
                unit: '%',
                amount: () => this.project.generalPlanningCost,
                unitPrice: '',
                value: () => getProjectGeneralPlanningCost(this.project, this.dataSource.data),
                labourCost: 0,
                percent: () => this.project.generalPlanningCost / 100,
                vatRate: ''
            },
            {
                id: uuidv4(),
                name: 'project-creation.table.building-sub-total',
                unit: '',
                amount: '',
                unitPrice: '',
                value: () =>
                    getProjectSubTotalOnlyCostBuilding(this.dataSource.data) +
                    getProjectSubTotalLabourCostOnlyCostBuilding(this.dataSource.data) +
                    getProjectDesignBudgetValueOnlyCostBuilding(this.project, this.dataSource.data),
                labourCost: 0,
                percent: '',
                vatRate: ''
            },
            {
                id: uuidv4(),
                name: 'project-creation.table.other-sub-total',
                unit: '',
                amount: '',
                unitPrice: '',
                value: () =>
                    // prettier-ignore
                    (getProjectSubTotal(this.dataSource.data) - getProjectSubTotalOnlyCostBuilding(this.dataSource.data)) +
                    (getProjectSubTotalLabourCost(this.dataSource.data) - getProjectSubTotalLabourCostOnlyCostBuilding(this.dataSource.data)) +
                    getProjectGeneralPlanningCost(this.project, this.dataSource.data),
                labourCost: 0,
                percent: '',
                vatRate: ''
            },
            {
                id: uuidv4(),
                name: 'project-creation.table.total-net',
                unit: '',
                amount: '',
                unitPrice: '',
                value: () => getProjectFinalTotalValue(this.project, this.dataSource.data),
                labourCost: 0,
                percent: '',
                vatRate: ''
            },
            {
                id: uuidv4(),
                name: 'project-creation.table.total-gross',
                unit: '',
                amount: '',
                unitPrice: '',
                value: () => getProjectFinalTotalValueWithTax(this.project, this.dataSource.data),
                labourCost: 0,
                percent: '',
                vatRate: ''
            }
        ];

        this.showEmptyCategoriesControl.valueChanges
            .pipe(tap(() => this.summaryTableService.calculateTableData()))
            .subscribe();

        this.showShadowedRowsControl.valueChanges
            .pipe(tap(() => this.summaryTableService.calculateTableData()))
            .subscribe();
    }

    public getTypeOf(value: unknown): string {
        return typeof value;
    }

    public trackByFn: TrackByFunction<WorkEntry> = (_, element) => {
        element.id;
    };

    public onEditPercent(newValue: string, entry: WorkEntry): void {
        const editedEntry = this.summaryTableService.findEntryToEdit(entry);
        if (!editedEntry) {
            return;
        }

        if (+newValue <= 0 || +newValue >= 100) {
            this.editErrors = {
                percent: this.translocoService.translate(
                    'project-creation.table.incorrect-percent-value'
                )
            };
        } else {
            this.editErrors = null;
        }

        const updatedEntry: WorkEntry = {
            ...editedEntry,
            percent: +newValue
        };

        this.summaryTableService.updateTableDataWith(updatedEntry);
    }

    public onEditVatRate(newValue: string, entry: WorkEntry): void {
        const editedEntry = this.summaryTableService.findEntryToEdit(entry);
        if (!editedEntry) {
            return;
        }

        if (+newValue < 0 || +newValue > 100) {
            this.editErrors = {
                vatRate: this.translocoService.translate(
                    'project-creation.table.incorrect-vat-value'
                )
            };
        } else {
            this.editErrors = null;
        }

        const updatedEntry: WorkEntry = {
            ...editedEntry,
            temporaryVatRate: +newValue,
            vatRate: +newValue
        };

        this.summaryTableService.updateTableDataWith(updatedEntry);
    }

    public onEditFooterRow(entry: { id?: string }): void {
        this.summaryTableService.onUndoRow();
        this.editedRow = { ...entry };
        this.originalRow = { ...entry };
        this.cdr.markForCheck();
    }

    public async onSaveFooterRow(rowConfig: IFooterRowConfig): Promise<void> {
        const { updatePropName } = rowConfig;
        const percent = this.footerRowNewPercent!;
        if (percent === undefined || percent >= 1e12 || percent == null || isNaN(percent)) {
            this.editErrors = {
                percent: this.translocoService.translate('project-creation.table.incorrect-value')
            };
            return;
        } else if (percent < 0) {
            this.editErrors = {
                percent: this.translocoService.translate(
                    'project-creation.table.percent-must-be-positive'
                )
            };
            return;
        } else {
            this.editErrors = null;
        }

        this.projectBudgetChange.emit({
            projectId: this.project.id!,
            updatePropName: updatePropName!,
            percent
        });
    }

    public toggleCategory(row: WorkEntry): void {
        const rowCategory = row.workType;
        if (!rowCategory?.id) {
            return;
        }

        const rowVersion = rowCategory?.minor
            ? `${rowCategory?.major}.${rowCategory?.minor}`
            : rowCategory.major.toString();
        if (!rowCategory.minor) {
            if (this.toggledCategories.has(rowVersion)) {
                this.toggledCategories.delete(rowVersion);
                this.toggledCategories.forEach((ver) => {
                    if (ver && ver.startsWith(rowVersion + '.')) {
                        this.toggledCategories.delete(ver);
                    }
                });
            } else {
                this.toggledCategories.add(rowVersion);
            }
        } else {
            const isCategoryToggled = this.toggledCategories.has(rowVersion);
            if (isCategoryToggled) {
                this.toggledCategories.delete(rowVersion);
            } else {
                this.toggledCategories.add(rowVersion);
            }
        }
        this.summaryTableService.calculateTableData(this.dataSource.data);
    }

    public getEntriesFor(workType: IWorkTypeEntity, fromList?: WorkEntry[]): WorkEntry[] {
        this.cdr.markForCheck();
        return (Array.isArray(fromList) ? fromList : this.entries)
            .filter((entry) => !entry.isCategory)
            .filter((entry) => entry.typeId === workType.id)
            .filter((entry) => this.showShadowedRowsControl.value || !entry.shadowed)
            .map((entry, i) => ({
                ...entry,
                name: entry.name ?? workType.name,
                versionId: workType.major + '.' + (workType.minor ?? 0 + (i + 1)),
                version: workType.minor
                    ? `${workType.major}.${workType.minor}`
                    : workType.major.toString()
            }));
    }

    public getSubcategoriesFor(workType: IWorkTypeEntity): WorkTypeEntity[] {
        if (workType.minor != null) {
            return [];
        }
        this.cdr.markForCheck();

        return this.workTypes
            .filter((wt) => wt.minor != null)
            .filter((wt) => {
                return wt.major === workType.major;
            });
    }

    public searchWorkTypeInDataSource(id: string): any {
        return this.dataSource.data.find((workType) => workType.id === id);
    }

    public displayShowMatRow(row: any) {
        const getSubcategories = this.getSubcategoriesFor(row.workType);

        return ((this.getEntriesFor(row.workType)?.length > 0 || getSubcategories?.length > 0
            ? row.workType.minor === null
                ? getSubcategories.some(
                      (category) =>
                          (this.getEntriesFor(category)?.length > 0 ||
                              this.getSubcategoriesFor(category)?.length > 0) === true
                  )
                : true
            : false) ||
            this.getEntriesFor(row.workType)?.length > 0) &&
            row.isCategory
            ? row.workType?.minor == null
                ? 'flex'
                : this.toggledCategories.has(row.version) ||
                  this.toggledCategories.has(row.workType?.major.toString())
                ? 'flex'
                : 'none'
            : this.toggledCategories.has(row.version)
            ? 'flex'
            : 'none';
    }
}
