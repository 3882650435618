import { createReducer, on } from '@ngrx/store';
import {
    getContactList,
    getContactListFailure,
    getContactListSuccess,
    updateContactList,
    updateContactListFailure,
    updateContactListSuccess
} from './contact-list.actions';
import { ContactListState } from './contact-list.state';

export const initialState: ContactListState = {
    profileUserList: [],
    profileUserListLoading: true,
    profileUserListError: null,
    updateContactList: null,
    updateContactListLoading: false,
    updateContactListSuccess: false,
    updateContactListError: null
};

export const contactListReducer = createReducer(
    initialState,
    on(getContactList, (state) => ({
        ...state,
        profileUserListLoading: true,
        updateContactListSuccess: false,
        profileUserListError: null
    })),
    on(getContactListSuccess, (state, { data }) => ({
        ...state,
        profileUserList: data,
        profileUserListLoading: false
    })),
    on(getContactListFailure, (state, { error }) => ({
        ...state,
        profileUserListError: error,
        profileUserListLoading: false
    })),
    on(updateContactList, (state) => ({
        ...state,
        updateContactListLoading: true,
        updateContactListSuccess: false,
        updateContactListError: null
    })),
    on(updateContactListSuccess, (state, { user }) => ({
        ...state,
        updateContactList: user,
        updateContactListSuccess: true,
        updateContactListLoading: false
    })),
    on(updateContactListFailure, (state, { error }) => ({
        ...state,
        updateContactListError: error,
        updateContactListLoading: false
    }))
);
