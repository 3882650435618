import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CurrencyEntity, GeneralApiService, RoleResponseDto } from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as BaseActions from './base.actions';

@Injectable()
export class BaseEffects {
    constructor(private actions$: Actions, private generalApiService: GeneralApiService) {}

    currencies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseActions.getCurrencies),
            switchMap(() => {
                return this.generalApiService.getCurrencies().pipe(
                    map((currencies: CurrencyEntity[]) => {
                        return BaseActions.getCurrenciesSuccess({ currencies });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(BaseActions.getCurrenciesFailure({ error: error.error.message }));
                    })
                );
            })
        )
    );

    getRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseActions.getRoles),
            switchMap(() => {
                return this.generalApiService.getRoles().pipe(
                    map((roles: RoleResponseDto[]) => {
                        return BaseActions.getRolesSuccess({ roles });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(BaseActions.getRolesFailure({ error: error.error.message }));
                    })
                );
            })
        )
    );
    getSubscriptionTypeInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseActions.getSubscriptionTypeInfo),
            switchMap(() => {
                return this.generalApiService.getSubscriptionTypes().pipe(
                    map((subscriptionTypeInfo) => {
                        return BaseActions.getSubscriptionTypeInfoSuccess({ subscriptionTypeInfo });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            BaseActions.getSubscriptionTypeInfoFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );
}
