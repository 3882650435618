<ng-container *ngIf="summaryTableService.summaryTableComponent">
    <div
        *ngLet="element.id === summaryTableService.summaryTableComponent.editedRow?.id as isEdited"
        class="flex w-full items-center justify-start pl-2"
    >
        <ng-container *ngLet="element.workType | invoke : isMainCategory as isMainCategory">
            <ng-container *ngLet="element.workType as categoryToUse">
                <div
                    class="flex w-full justify-start pl-2"
                    [class.font-bold]="!element.minor && element.isCategory"
                    *ngLet="
                        element.isCategory
                            ? aggregateValuesFor(categoryToUse, 'labourCost') +
                                  aggregateValuesFor(categoryToUse, 'value') || 0
                            : +element.labourCost + +element.value ?? 0 as totalCost
                    "
                >
                    {{
                        isEdited
                            ? (totalCost
                              | appCurrency
                                  : summaryTableService.summaryTableComponent.project.currency)
                            : ($any(totalCost)
                              | appCurrency
                                  : summaryTableService.summaryTableComponent.project.currency)
                    }}
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
