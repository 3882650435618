import { Directive, ElementRef } from '@angular/core';
import { MatTable } from '@angular/material/table';

@Directive({
    selector: '[mat-footer-cell]',
    exportAs: 'matFooterRowAnchor'
})
export class TableFooterRowAnchorDirective {
    public get index(): number {
        return this.table
            ._getRenderedRows(this.table._footerRowOutlet)
            .findIndex((rowEl) => rowEl.contains(this.el.nativeElement));
    }

    constructor(private readonly table: MatTable<unknown>, private readonly el: ElementRef) {}
}
