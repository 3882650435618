import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AdminApiService, GeneralApiService, ProductEntity, WorkTypeEntity } from 'app/api';
import dayjs from 'dayjs';
import { catchError, map, of, switchMap } from 'rxjs';
import * as SalesManagementActions from './sales-management.actions';
import { getSalesProducts } from './sales-management.actions';

@Injectable()
export class SalesManagementEffect {
    constructor(
        private actions$: Actions,
        public adminApiService: AdminApiService,
        public generalApiService: GeneralApiService,
        private readonly router: Router
    ) {}

    getSalesProducts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SalesManagementActions.getSalesProducts),
            switchMap(({ date }) => {
                return this.adminApiService
                    .filterProductsByDate({ body: { queryDate: date } })
                    .pipe(
                        map((salesProductData: ProductEntity[]) => {
                            return SalesManagementActions.getSalesProductsSuccess({
                                salesProduct: salesProductData
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                SalesManagementActions.getSalesProductsFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    editSalesProductItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SalesManagementActions.editSalesProductItem),
            switchMap(({ id, name, value, labourCost }) => {
                return this.adminApiService
                    .updateProduct({ id, body: { name, value, labourCost } })
                    .pipe(
                        map((newValueData) => {
                            return SalesManagementActions.editSalesProductItemSuccess({
                                id,
                                name: newValueData.name,
                                value: newValueData.value,
                                labourCost: newValueData.labourCost
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                SalesManagementActions.editSalesProductItemFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    editSalesProductItemSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SalesManagementActions.editSalesProductItemSuccess),
            switchMap((action) => of(getSalesProducts({ date: null })))
        )
    );

    saveSalesProductAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SalesManagementActions.saveSalesProduct),
            switchMap(({ typeId, name, value, labourCost }) =>
                this.adminApiService
                    .createProduct({
                        body: { typeId, name, value, labourCost }
                    })
                    .pipe(
                        map((salesProductData: ProductEntity[]) =>
                            SalesManagementActions.getSalesProductsSuccess({
                                salesProduct: salesProductData
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                SalesManagementActions.saveSalesProductFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    )
            )
        )
    );

    getWorkTypeWithoutProduct$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SalesManagementActions.getWorkTypeWithoutProduct),
            switchMap(() =>
                this.adminApiService.getWorkTypeWithoutProduct().pipe(
                    map((workTypeEntityData: Array<WorkTypeEntity>) =>
                        SalesManagementActions.getWorkTypeWithoutProductSuccess({
                            data: workTypeEntityData
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            SalesManagementActions.getWorkTypeWithoutProductFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        )
    );

    deleteSalesProductItemAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SalesManagementActions.deleteSalesProductItem),
            switchMap(({ id }) =>
                this.adminApiService.deleteProduct({ id }).pipe(
                    map((newValueData) =>
                        SalesManagementActions.deleteSalesProductItemSuccess({
                            id
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            SalesManagementActions.deleteSalesProductItemFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        )
    );

    deletePlotTypesSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SalesManagementActions.deleteSalesProductItemSuccess),
            switchMap((action) => of(getSalesProducts({ date: dayjs().format() })))
        );
    });

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SalesManagementActions.logout),
            map(() => SalesManagementActions.clearSalesManagementData())
        )
    );
}
