import { createReducer, on } from '@ngrx/store';
import { clearPaymentPopupStore, paymentPopupRejection } from './payment-popup.actions';
import { PaymentPopupState } from './payment-popup.state';

export const initialState: PaymentPopupState = {
    paymentPopupShow: true
};

export const paymentPopupReducer = createReducer(
    initialState,
    on(paymentPopupRejection, (state) => ({
        ...state,
        paymentPopupShow: false
    })),
    on(clearPaymentPopupStore, (state) => ({
        ...initialState
    }))
);
