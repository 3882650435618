import { APP_INITIALIZER, isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AdroitNgUtilsModule } from '@adroit-group/ng-utils';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
/* import * as Sentry from '@sentry/angular'; */
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatFormFieldDefaultOptions
} from '@angular/material/form-field';
import { environment } from 'environments/environment';
import { take } from 'rxjs';
import { ApiModule } from './api';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthTokenInterceptorService } from './interceptors/auth-token-interceptor.service';
import { MockApiInterceptor } from './interceptors/mock-api-interceptor.service';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { BaseLayoutComponent } from './layouts/base/base-layout.component';
import { localStorageSyncReducer, sessionStorageSyncReducer } from './meta.reducer';
import { SharedModule } from './shared/shared.module';
import { AdminProducerAnalyticsEffects } from './store/admin-producer-analytics/admin-producer-analytics.effects';
import { adminProducerAnalyticsReducer } from './store/admin-producer-analytics/admin-producer-analytics.reducer';
import { AdminProducerManagementEffects } from './store/admin-producer-management/admin-producer-management.effects';
import { adminProducerManagementReducer } from './store/admin-producer-management/admin-producer-management.reducer';
import { tabConfigReducer } from './store/admin-tab-config/admin-tab-config.reducer';
import { AdminUserAnalyticsEffects } from './store/admin-user-analytics/admin-user-analytics.effects';
import { adminUserAnalyticsReducer } from './store/admin-user-analytics/admin-user-analytics.reducer';
import { AdminEffects } from './store/admin/admin.effects';
import { adminReducer } from './store/admin/admin.reducer';
import { getCurrencies, getRoles, getSubscriptionTypeInfo } from './store/base/base.actions';
import { BaseEffects } from './store/base/base.effects';
import { baseReducer } from './store/base/base.reducer';
import { BuildingStructuresEffects } from './store/building-structures/building-structures.effects';
import { buildingStructuresReducer } from './store/building-structures/building-structures.reducer';
import { ContactListEffects } from './store/contact-list/contact-list.effects';
import { contactListReducer } from './store/contact-list/contact-list.reducer';
import { DraftPhaseEffects } from './store/draft-phase-creation/draft-phase-creation.effects';
import { draftPhaseCreationReducer } from './store/draft-phase-creation/draft-phase-creation.reducer';
import { EquipmentTypesEffects } from './store/equipment-types/equipment-types.effects';
import { equipmentTypesReducer } from './store/equipment-types/equipment-types.reducer';
import { FenceDevEffects } from './store/fence-dev/fence-dev.effects';
import { fenceDevReducer } from './store/fence-dev/fence-dev.reducer';
import { GeoRegionsEffects } from './store/geo-regions/geo-regions.effects';
import { geoRegionsReducer } from './store/geo-regions/geo-regions.reducer';
import { HeatPumpEffects } from './store/heat-pump/heat-pump.effects';
import { heatPumpReducer } from './store/heat-pump/heat-pump.reducer';
import { ItemManagementEffects } from './store/item-management/item-management.effects';
import { itemManagementReducer } from './store/item-management/item-management.reducer';
import { LandImprovementTypesEffects } from './store/land-improvement-types/land-improvement-types.effects';
import { landImprovementTypesReducer } from './store/land-improvement-types/land-improvement-types.reducer';
import { LanguageEffects } from './store/language/language.effects';
import { languageReducer } from './store/language/language.reducer';
import { PaymentPopupEffects } from './store/payment-popup/payment-popup.effects';
import { paymentPopupReducer } from './store/payment-popup/payment-popup.reducer';
import { PlotTypesEffects } from './store/plot-types/plot-types.effects';
import { plotTypesReducer } from './store/plot-types/plot-types.reducer';
import { ProjectsEffects } from './store/projects/projects.effects';
import { projectsReducer } from './store/projects/projects.reducer';
import { QualityCategoriesWithFunctionsEffects } from './store/quality-categories-with-functions/quality-categories-with-functions.effects';
import { qualityCategoriesWithFunctionsReducer } from './store/quality-categories-with-functions/quality-categories-with-functions.reducer';
import { SalesManagementEffect } from './store/sales-management/sales-management.effects';
import { salesManagementReducer } from './store/sales-management/sales-management.reducer';
import { SolarCollectorEffects } from './store/solar-collector/solar-collector.effects';
import { solarCollectorReducer } from './store/solar-collector/solar-collector.reducer';
import { tableConfigReducer } from './store/table-config/table-column-config.reducer';
import { TablesComparesEffects } from './store/tables-compare/tables-compare.effects';
import { tablesCompareReducer } from './store/tables-compare/tables-compare.reducer';
import { TrialDraftPhaseEffects } from './store/trial-draft-phase-creation/trial-draft-phase-creation.effects';
import { trialDraftPhaseCreationReducer } from './store/trial-draft-phase-creation/trial-draft-phase-creation.reducer';
import { getUser } from './store/user/user.actions';
import { UserEffects } from './store/user/user.effects';
import { userReducer } from './store/user/user.reducer';
import { selectAccessToken } from './store/user/user.selectors';
import { UserState } from './store/user/user.state';
import { WindEnergyEffects } from './store/wind-energy/wind-energy.effects';
import { windEnergyReducer } from './store/wind-energy/wind-energy.reducer';
import { TranslocoRootModule } from './transloco-root.module';

/* Sentry.init({
    dsn: environment.sentry_dns,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}); */

@NgModule({
    declarations: [AppComponent, BaseLayoutComponent, AuthLayoutComponent],
    imports: [
        BrowserModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        TranslocoRootModule,
        HttpClientModule,
        AdroitNgUtilsModule.forRoot(),
        ApiModule.forRoot({ rootUrl: environment.apiUrl }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable'
        }),
        SharedModule,
        StoreModule.forRoot(
            {
                router: routerReducer,
                user: userReducer,
                language: languageReducer,
                projects: projectsReducer,
                draftPhaseCreation: draftPhaseCreationReducer,
                trialDraftPhaseCreation: trialDraftPhaseCreationReducer,
                paymentPopup: paymentPopupReducer,
                base: baseReducer,
                admin: adminReducer,
                salesManagement: salesManagementReducer,
                itemManagement: itemManagementReducer,
                equipmentTypes: equipmentTypesReducer,
                landImprovementTypes: landImprovementTypesReducer,
                plotTypes: plotTypesReducer,
                contactList: contactListReducer,
                geoRegions: geoRegionsReducer,
                buildingStructures: buildingStructuresReducer,
                tableConfig: tableConfigReducer,
                tablesCompare: tablesCompareReducer,
                tabConfig: tabConfigReducer,
                qualityCategoriesWithFunctions: qualityCategoriesWithFunctionsReducer,
                windEnergy: windEnergyReducer,
                solarCollector: solarCollectorReducer,
                heatPump: heatPumpReducer,
                fenceDev: fenceDevReducer,
                adminUserAnalytics: adminUserAnalyticsReducer,
                adminProducerAnalytics: adminProducerAnalyticsReducer,
                adminProducerManagement: adminProducerManagementReducer
            },
            {
                metaReducers: [localStorageSyncReducer, sessionStorageSyncReducer]
            }
        ),
        EffectsModule.forRoot([
            UserEffects,
            LanguageEffects,
            ProjectsEffects,
            DraftPhaseEffects,
            BaseEffects,
            AdminEffects,
            SalesManagementEffect,
            ItemManagementEffects,
            EquipmentTypesEffects,
            LandImprovementTypesEffects,
            PlotTypesEffects,
            ContactListEffects,
            GeoRegionsEffects,
            BuildingStructuresEffects,
            TablesComparesEffects,
            TrialDraftPhaseEffects,
            PaymentPopupEffects,
            QualityCategoriesWithFunctionsEffects,
            WindEnergyEffects,
            SolarCollectorEffects,
            HeatPumpEffects,
            FenceDevEffects,
            AdminUserAnalyticsEffects,
            AdminProducerAnalyticsEffects,
            AdminProducerManagementEffects
        ]),
        StoreDevtoolsModule.instrument({
            maxAge: 25
        }),
        BrowserAnimationsModule,
        StoreRouterConnectingModule.forRoot()
    ],
    exports: [BrowserModule, BrowserAnimationsModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: (store: Store) => () => {
                store.dispatch(getCurrencies());
                store.dispatch(getRoles());
                store.dispatch(getSubscriptionTypeInfo());
            },
            deps: [Store]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MockApiInterceptor,
            multi: true
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline'
            } as MatFormFieldDefaultOptions
        } /* ,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: true
            })
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true
        } */
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private readonly store: Store<UserState>) {
        this.store
            .select(selectAccessToken)
            .pipe(take(1))
            .subscribe((accessToken) => {
                if (accessToken)
                    this.store.dispatch(
                        getUser({
                            automaticLogin: true
                        })
                    );
            });
    }
}
