import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription, startWith } from 'rxjs';

@Pipe({ name: 'objectTranslation', pure: false })
export class ObjectTranslationPipe implements PipeTransform, OnDestroy {
    private previousTransformValue = '';
    private sub?: Subscription;
    private cachedResult: object = {};

    constructor(private translocoService: TranslocoService, private cdr: ChangeDetectorRef) {}

    transform(value: string | {} | undefined): object {
        if (this.previousTransformValue === value) {
            return this.cachedResult;
        }

        this.previousTransformValue = (value ?? '') as string;
        this.cachedResult = {};

        this.subscribeToLangChange();
        return this.cachedResult;
    }

    public ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    private subscribeToLangChange(): void {
        this.sub?.unsubscribe();
        this.sub = this.translocoService.langChanges$
            .pipe(startWith(this.translocoService.getActiveLang()))
            .subscribe((activeLang) => {
                this.cachedResult =
                    this.previousTransformValue &&
                    JSON.parse(this.previousTransformValue.replace(/'/g, '"'))[activeLang];
                this.cdr.markForCheck();
            });
    }
}
