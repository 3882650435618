import { createAction, props } from '@ngrx/store';
import { CreateWorkEntryDto, DraftPhaseEntity, PatchWorkEntryDto, WorkEntryEntity } from 'app/api';
import { TableDataSource } from './tables-compare.state';

/* export const getProjectIdsToCompare = createAction(
    '[Tables Compare page] Get Project Ids To Compare',
    props<{ projectIds: string[] }>()
); */
export const addProjectIdToCompare = createAction(
    '[Tables Compare page] Add Project Id To Compare',
    props<{ projectId: string }>()
);

export const deleteProjectIdFromCompare = createAction(
    '[Tables Compare page] Delete Project Id From Compare',
    props<{ projectId: string }>()
);

export const deleteSelectedProjectIdsFromCompare = createAction(
    '[Tables Compare page] Delete select Project Ids From Compare'
);

export const getProjectsToTables = createAction(
    '[Tables Compare page] Get Project Datas To Tables from backend',
    props<{ projectIds: string[] }>()
);
export const getProjectsToTablesSuccess = createAction(
    '[Tables Compare page] Get Project Datas To Tables from backend success',
    props<{ projects: Record<string, TableDataSource> }>()
);
export const getProjectsToTablesFailure = createAction(
    '[Tables Compare page] Get Project Datas To Tables from backend failure',
    props<{ error: string }>()
);

export const addEntry = createAction(
    '[Tables Compare page] Add Entry By Id To backend',
    props<{ projectId: string; entry: CreateWorkEntryDto }>()
);
export const addEntrySuccess = createAction(
    '[Tables Compare page] Add Entry By Id To backend success',
    props<{ projectId: string; projectEntries: WorkEntryEntity[] }>()
);
export const addEntryFailure = createAction(
    '[Tables Compare page] Add Entry By Id To backend failure',
    props<{ error: string }>()
);

export const saveEntryChanges = createAction(
    '[Tables Compare page] Save Entry Changes By Id To backend',
    props<{ projectId: string; entryId: string; changes: PatchWorkEntryDto }>()
);
export const saveEntryChangesSuccess = createAction(
    '[Tables Compare page] Save Entry Changes By Id To backend success',
    props<{ projectId: string; projectEntries: WorkEntryEntity[] }>()
);
export const saveEntryChangesFailure = createAction(
    '[Tables Compare page] Save Entry Changes By Id To backend failure',
    props<{ error: string }>()
);

export const deleteEntry = createAction(
    '[Tables Compare page] Delete Entry By Id To backend',
    props<{ projectId: string; entryId: string }>()
);
export const deleteEntrySuccess = createAction(
    '[Tables Compare page] Delete Entry By Id To backend success',
    props<{ projectId: string; projectEntries: WorkEntryEntity[] }>()
);
export const deleteEntryFailure = createAction(
    '[Tables Compare page] Delete Entry By Id To backend failure',
    props<{ error: string }>()
);

export const saveProjectBudgetChange = createAction(
    '[Tables Compare page] Save Project Budget Change By Id To backend',
    props<{ projectId: string; updatePropName: string; percent: number }>()
);
export const saveProjectBudgetChangeSuccess = createAction(
    '[Tables Compare page] Save Project Budget Change By Id To backend success',
    props<{ projectId: string; projectData: DraftPhaseEntity }>()
);
export const saveProjectBudgetChangeFailure = createAction(
    '[Tables Compare page] Save Project Budget Change By Id To backend failure',
    props<{ error: string }>()
);

export const saveCategoryVatRateChange = createAction(
    '[Tables Compare page] Save Category Vat Rate Change By Id To backend',
    props<{ projectId: string; typeId: string; vatRate: number }>()
);
export const saveCategoryVatRateChangeSuccess = createAction(
    '[Tables Compare page] Save Category Vat Rate Change By Id To backend success',
    props<{ projectId: string; projectEntries: WorkEntryEntity[] }>()
);
export const saveCategoryVatRateChangeFailure = createAction(
    '[Tables Compare page] Save Category Vat Rate Change By Id To backend failure',
    props<{ error: string }>()
);

export const logout = createAction('[Header] Logout');
export const clearTablesCompareData = createAction(
    '[Tables Compare page] Clear tables compare data'
);
