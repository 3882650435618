/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseApiService } from '../base-api-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateDraftPhaseDto } from '../models/create-draft-phase-dto';
import { CreateWorkEntryDto } from '../models/create-work-entry-dto';
import { DraftPhaseEntity } from '../models/draft-phase-entity';
import { DraftPhaseExtendedEntity } from '../models/draft-phase-extended-entity';
import { FilterDraftPhasesDto } from '../models/filter-draft-phases-dto';
import { PatchWorkEntryDto } from '../models/patch-work-entry-dto';
import { StructureElementWithAppliancesEntity } from '../models/structure-element-with-appliances-entity';
import { UpdateDraftPhaseCostsDto } from '../models/update-draft-phase-costs-dto';
import { UpdateDraftPhaseDto } from '../models/update-draft-phase-dto';
import { UpdateDraftPhaseQuestionnaireApplianceDto } from '../models/update-draft-phase-questionnaire-appliance-dto';
import { UpdateDraftPhaseQuestionnaireCacheDto } from '../models/update-draft-phase-questionnaire-cache-dto';
import { UpdateDraftPhaseStatusDto } from '../models/update-draft-phase-status-dto';
import { UpdateDraftPhaseWorkEntryVatRatesDto } from '../models/update-draft-phase-work-entry-vat-rates-dto';
import { WorkEntryEntity } from '../models/work-entry-entity';
import { WorkTypeEntity } from '../models/work-type-entity';

@Injectable({
    providedIn: 'root'
})
export class DraftPhaseApiService extends BaseApiService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Path part for operation filterDraftPhases
     */
    static readonly FilterDraftPhasesPath = '/draft-phase';

    /**
     * Filters draft phases, paginated, sorted and filtered.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterDraftPhases()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterDraftPhases$Response(params: {
        context?: HttpContext;
        body: FilterDraftPhasesDto;
    }): Observable<
        StrictHttpResponse<{
            total?: number;
            page?: number;
            perPage?: number;
            data?: Array<DraftPhaseEntity>;
        }>
    > {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.FilterDraftPhasesPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<{
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<DraftPhaseEntity>;
                    }>;
                })
            );
    }

    /**
     * Filters draft phases, paginated, sorted and filtered.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterDraftPhases$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterDraftPhases(params: { context?: HttpContext; body: FilterDraftPhasesDto }): Observable<{
        total?: number;
        page?: number;
        perPage?: number;
        data?: Array<DraftPhaseEntity>;
    }> {
        return this.filterDraftPhases$Response(params).pipe(
            map(
                (
                    r: StrictHttpResponse<{
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<DraftPhaseEntity>;
                    }>
                ) =>
                    r.body as {
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<DraftPhaseEntity>;
                    }
            )
        );
    }

    /**
     * Path part for operation createDraftPhase
     */
    static readonly CreateDraftPhasePath = '/draft-phase/new';

    /**
     * Creates a new draft phase.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createDraftPhase()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createDraftPhase$Response(params: {
        context?: HttpContext;
        body: CreateDraftPhaseDto;
    }): Observable<StrictHttpResponse<DraftPhaseEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.CreateDraftPhasePath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<DraftPhaseEntity>;
                })
            );
    }

    /**
     * Creates a new draft phase.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createDraftPhase$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createDraftPhase(params: {
        context?: HttpContext;
        body: CreateDraftPhaseDto;
    }): Observable<DraftPhaseEntity> {
        return this.createDraftPhase$Response(params).pipe(
            map((r: StrictHttpResponse<DraftPhaseEntity>) => r.body as DraftPhaseEntity)
        );
    }

    /**
     * Path part for operation getDraftPhaseEntries
     */
    static readonly GetDraftPhaseEntriesPath = '/draft-phase/{id}/entries';

    /**
     * Retrieves work entries for a draft phase.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getDraftPhaseEntries()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhaseEntries$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<WorkEntryEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.GetDraftPhaseEntriesPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkEntryEntity>>;
                })
            );
    }

    /**
     * Retrieves work entries for a draft phase.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getDraftPhaseEntries$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhaseEntries(params: {
        id: string;
        context?: HttpContext;
    }): Observable<Array<WorkEntryEntity>> {
        return this.getDraftPhaseEntries$Response(params).pipe(
            map((r: StrictHttpResponse<Array<WorkEntryEntity>>) => r.body as Array<WorkEntryEntity>)
        );
    }

    /**
     * Path part for operation createWorkEntry
     */
    static readonly CreateWorkEntryPath = '/draft-phase/{id}/entries';

    /**
     * Creates a new work entry.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `createWorkEntry()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createWorkEntry$Response(params: {
        id: string;
        context?: HttpContext;
        body: CreateWorkEntryDto;
    }): Observable<StrictHttpResponse<Array<WorkEntryEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.CreateWorkEntryPath,
            'post'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkEntryEntity>>;
                })
            );
    }

    /**
     * Creates a new work entry.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `createWorkEntry$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    createWorkEntry(params: {
        id: string;
        context?: HttpContext;
        body: CreateWorkEntryDto;
    }): Observable<Array<WorkEntryEntity>> {
        return this.createWorkEntry$Response(params).pipe(
            map((r: StrictHttpResponse<Array<WorkEntryEntity>>) => r.body as Array<WorkEntryEntity>)
        );
    }

    /**
     * Path part for operation getDraftPhase
     */
    static readonly GetDraftPhasePath = '/draft-phase/{id}';

    /**
     * Gets a draft phase by id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getDraftPhase()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhase$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<DraftPhaseExtendedEntity>> {
        const rb = new RequestBuilder(this.rootUrl, DraftPhaseApiService.GetDraftPhasePath, 'get');
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<DraftPhaseExtendedEntity>;
                })
            );
    }

    /**
     * Gets a draft phase by id.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getDraftPhase$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhase(params: {
        id: string;
        context?: HttpContext;
    }): Observable<DraftPhaseExtendedEntity> {
        return this.getDraftPhase$Response(params).pipe(
            map(
                (r: StrictHttpResponse<DraftPhaseExtendedEntity>) =>
                    r.body as DraftPhaseExtendedEntity
            )
        );
    }

    /**
     * Path part for operation updateDraftPhase
     */
    static readonly UpdateDraftPhasePath = '/draft-phase/{id}';

    /**
     * Updates a draft phase by id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateDraftPhase()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateDraftPhase$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateDraftPhaseDto;
    }): Observable<StrictHttpResponse<DraftPhaseEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.UpdateDraftPhasePath,
            'put'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<DraftPhaseEntity>;
                })
            );
    }

    /**
     * Updates a draft phase by id.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateDraftPhase$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateDraftPhase(params: {
        id: string;
        context?: HttpContext;
        body: UpdateDraftPhaseDto;
    }): Observable<DraftPhaseEntity> {
        return this.updateDraftPhase$Response(params).pipe(
            map((r: StrictHttpResponse<DraftPhaseEntity>) => r.body as DraftPhaseEntity)
        );
    }

    /**
     * Path part for operation deleteDraftPhase
     */
    static readonly DeleteDraftPhasePath = '/draft-phase/{id}';

    /**
     * Deletes a draft phase by id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `deleteDraftPhase()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteDraftPhase$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.DeleteDraftPhasePath,
            'delete'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Deletes a draft phase by id.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `deleteDraftPhase$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteDraftPhase(params: { id: string; context?: HttpContext }): Observable<void> {
        return this.deleteDraftPhase$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation updateDraftPhaseQuestions
     */
    static readonly UpdateDraftPhaseQuestionsPath = '/draft-phase/{id}';

    /**
     * Updates a draftphase questionnaire.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateDraftPhaseQuestions()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateDraftPhaseQuestions$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateDraftPhaseQuestionnaireApplianceDto;
    }): Observable<StrictHttpResponse<DraftPhaseExtendedEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.UpdateDraftPhaseQuestionsPath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<DraftPhaseExtendedEntity>;
                })
            );
    }

    /**
     * Updates a draftphase questionnaire.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateDraftPhaseQuestions$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateDraftPhaseQuestions(params: {
        id: string;
        context?: HttpContext;
        body: UpdateDraftPhaseQuestionnaireApplianceDto;
    }): Observable<DraftPhaseExtendedEntity> {
        return this.updateDraftPhaseQuestions$Response(params).pipe(
            map(
                (r: StrictHttpResponse<DraftPhaseExtendedEntity>) =>
                    r.body as DraftPhaseExtendedEntity
            )
        );
    }

    /**
     * Path part for operation deleteWorkEntry
     */
    static readonly DeleteWorkEntryPath = '/draft-phase/{id}/entries/{entryId}';

    /**
     * Deletes a work entry.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `deleteWorkEntry()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteWorkEntry$Response(params: {
        id: string;
        entryId: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<WorkEntryEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.DeleteWorkEntryPath,
            'delete'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.path('entryId', params.entryId, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkEntryEntity>>;
                })
            );
    }

    /**
     * Deletes a work entry.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `deleteWorkEntry$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    deleteWorkEntry(params: {
        id: string;
        entryId: string;
        context?: HttpContext;
    }): Observable<Array<WorkEntryEntity>> {
        return this.deleteWorkEntry$Response(params).pipe(
            map((r: StrictHttpResponse<Array<WorkEntryEntity>>) => r.body as Array<WorkEntryEntity>)
        );
    }

    /**
     * Path part for operation patchWorkEntry
     */
    static readonly PatchWorkEntryPath = '/draft-phase/{id}/entries/{entryId}';

    /**
     * Patches a work entry.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `patchWorkEntry()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    patchWorkEntry$Response(params: {
        id: string;
        entryId: string;
        context?: HttpContext;
        body: PatchWorkEntryDto;
    }): Observable<StrictHttpResponse<Array<WorkEntryEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.PatchWorkEntryPath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.path('entryId', params.entryId, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkEntryEntity>>;
                })
            );
    }

    /**
     * Patches a work entry.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `patchWorkEntry$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    patchWorkEntry(params: {
        id: string;
        entryId: string;
        context?: HttpContext;
        body: PatchWorkEntryDto;
    }): Observable<Array<WorkEntryEntity>> {
        return this.patchWorkEntry$Response(params).pipe(
            map((r: StrictHttpResponse<Array<WorkEntryEntity>>) => r.body as Array<WorkEntryEntity>)
        );
    }

    /**
     * Path part for operation getWorkTypes
     */
    static readonly GetWorkTypesPath = '/draft-phase/worktypes';

    /**
     * Retrieves work types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getWorkTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWorkTypes$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<WorkTypeEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, DraftPhaseApiService.GetWorkTypesPath, 'get');
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkTypeEntity>>;
                })
            );
    }

    /**
     * Retrieves work types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getWorkTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWorkTypes(params?: { context?: HttpContext }): Observable<Array<WorkTypeEntity>> {
        return this.getWorkTypes$Response(params).pipe(
            map((r: StrictHttpResponse<Array<WorkTypeEntity>>) => r.body as Array<WorkTypeEntity>)
        );
    }

    /**
     * Path part for operation getDraftPhasePdf
     */
    static readonly GetDraftPhasePdfPath = '/draft-phase/{id}/pdf';

    /**
     * Retrieves draft phase data as PDF export.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getDraftPhasePdf()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhasePdf$Response(params: {
        id: string;

        /**
         * If true, the overview PDF is returned
         */
        overview?: boolean;

        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Blob>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.GetDraftPhasePdfPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.query('overview', params.overview, {});
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'blob',
                    accept: 'application/pdf',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Blob>;
                })
            );
    }

    /**
     * Retrieves draft phase data as PDF export.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getDraftPhasePdf$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhasePdf(params: {
        id: string;

        /**
         * If true, the overview PDF is returned
         */
        overview?: boolean;

        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Blob> {
        return this.getDraftPhasePdf$Response(params).pipe(
            map((r: StrictHttpResponse<Blob>) => r.body as Blob)
        );
    }

    /**
     * Path part for operation getDraftPhaseQuestionnaire
     */
    static readonly GetDraftPhaseQuestionnairePath = '/draft-phase/{id}/questionnaire';

    /**
     * Gets a draft phase questionnaire by id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getDraftPhaseQuestionnaire()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhaseQuestionnaire$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<UpdateDraftPhaseQuestionnaireApplianceDto>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.GetDraftPhaseQuestionnairePath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<UpdateDraftPhaseQuestionnaireApplianceDto>;
                })
            );
    }

    /**
     * Gets a draft phase questionnaire by id.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getDraftPhaseQuestionnaire$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhaseQuestionnaire(params: {
        id: string;
        context?: HttpContext;
    }): Observable<UpdateDraftPhaseQuestionnaireApplianceDto> {
        return this.getDraftPhaseQuestionnaire$Response(params).pipe(
            map(
                (r: StrictHttpResponse<UpdateDraftPhaseQuestionnaireApplianceDto>) =>
                    r.body as UpdateDraftPhaseQuestionnaireApplianceDto
            )
        );
    }

    /**
     * Path part for operation updateDraftPhaseQuestionData
     */
    static readonly UpdateDraftPhaseQuestionDataPath = '/draft-phase/{id}/questionnaire';

    /**
     * Updates draftphase questionnaire data.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateDraftPhaseQuestionData()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateDraftPhaseQuestionData$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateDraftPhaseQuestionnaireCacheDto;
    }): Observable<StrictHttpResponse<DraftPhaseEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.UpdateDraftPhaseQuestionDataPath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<DraftPhaseEntity>;
                })
            );
    }

    /**
     * Updates draftphase questionnaire data.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateDraftPhaseQuestionData$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateDraftPhaseQuestionData(params: {
        id: string;
        context?: HttpContext;
        body: UpdateDraftPhaseQuestionnaireCacheDto;
    }): Observable<DraftPhaseEntity> {
        return this.updateDraftPhaseQuestionData$Response(params).pipe(
            map((r: StrictHttpResponse<DraftPhaseEntity>) => r.body as DraftPhaseEntity)
        );
    }

    /**
     * Path part for operation getDraftPhaseStructures
     */
    static readonly GetDraftPhaseStructuresPath = '/draft-phase/{id}/structures';

    /**
     * Retrieves building functions for a draft phase.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getDraftPhaseStructures()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhaseStructures$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<StructureElementWithAppliancesEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.GetDraftPhaseStructuresPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<StructureElementWithAppliancesEntity>>;
                })
            );
    }

    /**
     * Retrieves building functions for a draft phase.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getDraftPhaseStructures$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhaseStructures(params: {
        id: string;
        context?: HttpContext;
    }): Observable<Array<StructureElementWithAppliancesEntity>> {
        return this.getDraftPhaseStructures$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<StructureElementWithAppliancesEntity>>) =>
                    r.body as Array<StructureElementWithAppliancesEntity>
            )
        );
    }

    /**
     * Path part for operation getDraftPhaseExcel
     */
    static readonly GetDraftPhaseExcelPath = '/draft-phase/{id}/excel';

    /**
     * Retrieves draft phase data as Excel export.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getDraftPhaseExcel()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhaseExcel$Response(params: {
        id: string;

        /**
         * Hides empty categories if true, otherwise retrieves only categories with amount OR value &gt; 0
         */
        hideEmptyCategories?: boolean;

        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Blob>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.GetDraftPhaseExcelPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.query('hideEmptyCategories', params.hideEmptyCategories, {});
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'blob',
                    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Blob>;
                })
            );
    }

    /**
     * Retrieves draft phase data as Excel export.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getDraftPhaseExcel$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getDraftPhaseExcel(params: {
        id: string;

        /**
         * Hides empty categories if true, otherwise retrieves only categories with amount OR value &gt; 0
         */
        hideEmptyCategories?: boolean;

        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Blob> {
        return this.getDraftPhaseExcel$Response(params).pipe(
            map((r: StrictHttpResponse<Blob>) => r.body as Blob)
        );
    }

    /**
     * Path part for operation updateDraftPhaseStatus
     */
    static readonly UpdateDraftPhaseStatusPath = '/draft-phase/{id}/status';

    /**
     * Updates draftphase status.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateDraftPhaseStatus()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateDraftPhaseStatus$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateDraftPhaseStatusDto;
    }): Observable<StrictHttpResponse<DraftPhaseEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.UpdateDraftPhaseStatusPath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<DraftPhaseEntity>;
                })
            );
    }

    /**
     * Updates draftphase status.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateDraftPhaseStatus$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateDraftPhaseStatus(params: {
        id: string;
        context?: HttpContext;
        body: UpdateDraftPhaseStatusDto;
    }): Observable<DraftPhaseEntity> {
        return this.updateDraftPhaseStatus$Response(params).pipe(
            map((r: StrictHttpResponse<DraftPhaseEntity>) => r.body as DraftPhaseEntity)
        );
    }

    /**
     * Path part for operation updateDraftPhaseCosts
     */
    static readonly UpdateDraftPhaseCostsPath = '/draft-phase/{id}/costs';

    /**
     * Updates draftphase costs like design budget and general planning cost.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `updateDraftPhaseCosts()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateDraftPhaseCosts$Response(params: {
        id: string;
        context?: HttpContext;
        body: UpdateDraftPhaseCostsDto;
    }): Observable<StrictHttpResponse<DraftPhaseExtendedEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.UpdateDraftPhaseCostsPath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<DraftPhaseExtendedEntity>;
                })
            );
    }

    /**
     * Updates draftphase costs like design budget and general planning cost.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `updateDraftPhaseCosts$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    updateDraftPhaseCosts(params: {
        id: string;
        context?: HttpContext;
        body: UpdateDraftPhaseCostsDto;
    }): Observable<DraftPhaseExtendedEntity> {
        return this.updateDraftPhaseCosts$Response(params).pipe(
            map(
                (r: StrictHttpResponse<DraftPhaseExtendedEntity>) =>
                    r.body as DraftPhaseExtendedEntity
            )
        );
    }

    /**
     * Path part for operation closeDraftPhase
     */
    static readonly CloseDraftPhasePath = '/draft-phase/{id}/close';

    /**
     * Closes a draft phase.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `closeDraftPhase()` instead.
     *
     * This method doesn't expect any request body.
     */
    closeDraftPhase$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<DraftPhaseEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.CloseDraftPhasePath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<DraftPhaseEntity>;
                })
            );
    }

    /**
     * Closes a draft phase.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `closeDraftPhase$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    closeDraftPhase(params: { id: string; context?: HttpContext }): Observable<DraftPhaseEntity> {
        return this.closeDraftPhase$Response(params).pipe(
            map((r: StrictHttpResponse<DraftPhaseEntity>) => r.body as DraftPhaseEntity)
        );
    }

    /**
     * Path part for operation patchDraftPhaseWorkEntryVatRateByCategory
     */
    static readonly PatchDraftPhaseWorkEntryVatRateByCategoryPath =
        '/draft-phase/{id}/category/{typeId}';

    /**
     * Patches vat rates of all work entries of the specified category in the draft phase.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `patchDraftPhaseWorkEntryVatRateByCategory()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    patchDraftPhaseWorkEntryVatRateByCategory$Response(params: {
        id: string;
        typeId: string;
        context?: HttpContext;
        body: UpdateDraftPhaseWorkEntryVatRatesDto;
    }): Observable<StrictHttpResponse<Array<WorkEntryEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            DraftPhaseApiService.PatchDraftPhaseWorkEntryVatRateByCategoryPath,
            'patch'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.path('typeId', params.typeId, {});
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<WorkEntryEntity>>;
                })
            );
    }

    /**
     * Patches vat rates of all work entries of the specified category in the draft phase.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `patchDraftPhaseWorkEntryVatRateByCategory$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    patchDraftPhaseWorkEntryVatRateByCategory(params: {
        id: string;
        typeId: string;
        context?: HttpContext;
        body: UpdateDraftPhaseWorkEntryVatRatesDto;
    }): Observable<Array<WorkEntryEntity>> {
        return this.patchDraftPhaseWorkEntryVatRateByCategory$Response(params).pipe(
            map((r: StrictHttpResponse<Array<WorkEntryEntity>>) => r.body as Array<WorkEntryEntity>)
        );
    }
}
