import { createAction, props } from '@ngrx/store';
import { ApplianceSimpleEntity, WorkTypeWithFunctionAppliancesEntity } from 'app/api';

export const getQualityCategoriesWithFunctions = createAction(
    '[Quality categories with functions Tab] Get quality categories with functions from backend'
);
export const getQualityCategoriesWithFunctionsSuccess = createAction(
    '[Quality categories with functions Tab] Get quality categories with functions from backend success',
    props<{ qualityCategoriesWithFunctions: Array<WorkTypeWithFunctionAppliancesEntity> }>()
);
export const getQualityCategoriesWithFunctionsFailure = createAction(
    '[Quality categories with functions Tab] Get quality categories with functions from backend failure',
    props<{ error: string | null }>()
);
/* All Categories */
export const getAllQualityCategoriesWithFunctions = createAction(
    '[Quality categories with functions Tab] Get all quality categories with functions from backend'
);
export const getAllQualityCategoriesWithFunctionsSuccess = createAction(
    '[Quality categories with functions Tab] Get all quality categories with functions from backend success',
    props<{ allQualityCategoriesWithFunctions: Array<ApplianceSimpleEntity> }>()
);
export const getAllQualityCategoriesWithFunctionsFailure = createAction(
    '[Quality categories with functions Tab] Get all quality categories with functions from backend failure',
    props<{ error: string | null }>()
);
/* Select Categories */
export const getSelectedQualityCategoriesWithFunctions = createAction(
    '[Quality categories with functions Tab] Get selected quality categories with functions from backend',
    props<{ id: string; selectedQualityCategoriesWithFunctions: Array<string> }>()
);
export const getSelectedQualityCategoriesWithFunctionsSuccess = createAction(
    '[Quality categories with functions Tab] Get selected quality categories with functions from backend success',
    props<{ qualityCategoriesWithFunctions: Array<WorkTypeWithFunctionAppliancesEntity> }>()
);
export const getSelectedQualityCategoriesWithFunctionsFailure = createAction(
    '[Quality categories with functions Tab] Get selected quality categories with functions from backend failure',
    props<{ error: string | null }>()
);
