/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseApiService } from '../base-api-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LoginRequestDto } from '../models/login-request-dto';
import { LoginResponseDto } from '../models/login-response-dto';
import { PasswordResetDto } from '../models/password-reset-dto';
import { PasswordResetInquiryDto } from '../models/password-reset-inquiry-dto';
import { RegisterRequestDto } from '../models/register-request-dto';

@Injectable({
    providedIn: 'root'
})
export class AuthApiService extends BaseApiService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Path part for operation login
     */
    static readonly LoginPath = '/auth/login';

    /**
     * Returns an access token for the given credentials.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `login()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    login$Response(params: {
        context?: HttpContext;
        body: LoginRequestDto;
    }): Observable<StrictHttpResponse<LoginResponseDto>> {
        const rb = new RequestBuilder(this.rootUrl, AuthApiService.LoginPath, 'post');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<LoginResponseDto>;
                })
            );
    }

    /**
     * Returns an access token for the given credentials.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `login$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    login(params: { context?: HttpContext; body: LoginRequestDto }): Observable<LoginResponseDto> {
        return this.login$Response(params).pipe(
            map((r: StrictHttpResponse<LoginResponseDto>) => r.body as LoginResponseDto)
        );
    }

    /**
     * Path part for operation registrationWithEmailVerification
     */
    static readonly RegistrationWithEmailVerificationPath = '/auth/email-verified-registration';

    /**
     * Sends a validation email with invitation code while registering a new user.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `registrationWithEmailVerification()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    registrationWithEmailVerification$Response(params: {
        context?: HttpContext;
        body: RegisterRequestDto;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AuthApiService.RegistrationWithEmailVerificationPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Sends a validation email with invitation code while registering a new user.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `registrationWithEmailVerification$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    registrationWithEmailVerification(params: {
        context?: HttpContext;
        body: RegisterRequestDto;
    }): Observable<void> {
        return this.registrationWithEmailVerification$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation authActivate
     */
    static readonly AuthActivatePath = '/auth/activate/{code}';

    /**
     * Activates a user, using an invitation code.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `authActivate()` instead.
     *
     * This method doesn't expect any request body.
     */
    authActivate$Response(params: {
        code: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(this.rootUrl, AuthApiService.AuthActivatePath, 'get');
        if (params) {
            rb.path('code', params.code, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Activates a user, using an invitation code.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `authActivate$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    authActivate(params: { code: string; context?: HttpContext }): Observable<void> {
        return this.authActivate$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation inquirePasswordReset
     */
    static readonly InquirePasswordResetPath = '/auth/inquire-password-reset';

    /**
     * Inquires a password reset.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `inquirePasswordReset()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    inquirePasswordReset$Response(params: {
        context?: HttpContext;
        body: PasswordResetInquiryDto;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            AuthApiService.InquirePasswordResetPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Inquires a password reset.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `inquirePasswordReset$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    inquirePasswordReset(params: {
        context?: HttpContext;
        body: PasswordResetInquiryDto;
    }): Observable<void> {
        return this.inquirePasswordReset$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation resetPassword
     */
    static readonly ResetPasswordPath = '/auth/reset-password';

    /**
     * Resets a password.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `resetPassword()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    resetPassword$Response(params: {
        context?: HttpContext;
        body: PasswordResetDto;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(this.rootUrl, AuthApiService.ResetPasswordPath, 'post');
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Resets a password.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `resetPassword$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    resetPassword(params: { context?: HttpContext; body: PasswordResetDto }): Observable<void> {
        return this.resetPassword$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }
}
