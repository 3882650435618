import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { RouteSegment } from 'app/enums/route-segments.enum';
import { INavLinkOption } from 'app/models/ui/nav-link-option.model';
import { selectUrl } from 'app/store/router/router.selector';
import { deleteSelectedProjectIdsFromCompare } from 'app/store/tables-compare/tables-compare.actions';
import { selectTableProjectIds } from 'app/store/tables-compare/tables-compare.selectors';
import { DeviceDetectorService } from 'ngx-device-detector';

@UntilDestroy()
@Component({
    selector: 'app-auth-layout',
    templateUrl: './auth-layout.component.html',
    styleUrls: ['./auth-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthLayoutComponent implements OnInit {
    public navLinkOptions!: INavLinkOption[];
    public RouteSegment = RouteSegment;
    public selectTableProjectIds = selectTableProjectIds;
    public deleteSelectedProjectIdsFromCompare = deleteSelectedProjectIdsFromCompare;
    public linkIsAdmin = false;
    public linkIsProjectCreation = false;

    constructor(
        private readonly translocoService: TranslocoService,
        readonly router: Router,
        readonly store: Store,
        readonly route: ActivatedRoute,
        protected readonly cdr: ChangeDetectorRef,
        protected readonly deviceService: DeviceDetectorService
    ) {}
    ngOnInit(): void {
        this.store
            .select(selectUrl)
            .pipe(untilDestroyed(this))
            .subscribe((url) => {
                this.linkIsAdmin = url.includes(RouteSegment.Admin);
                this.linkIsProjectCreation =
                    url.includes(RouteSegment.TrialDraftPhaseCreation) ||
                    url.includes(RouteSegment.DraftPhaseCreation);
            });

        this.navLinkOptions = [
            {
                text$: this.translocoService.selectTranslate('menu.user-management'),
                link: `/${RouteSegment.Admin}/${RouteSegment.UserManagement}`,
                icon: 'users',
                userIsAdmin: true
            },
            {
                text$: this.translocoService.selectTranslate('menu.projects'),
                link: `/${RouteSegment.Dashboard}`,
                icon: 'projects'
            },
            {
                text$: this.translocoService.selectTranslate('menu.map-view'),
                link: `/${RouteSegment.MapView}`,
                icon: 'map'
            },
            {
                text$: this.translocoService.selectTranslate('contact-list.name-list-link'),
                link: `/${RouteSegment.ContactPage}`,
                icon: 'contact_list'
            },
            {
                text$: this.translocoService.selectTranslate('menu.sales-management'),
                link: `/${RouteSegment.Admin}/${RouteSegment.SalesManagement}`,
                icon: 'sales',
                userIsAdmin: true
            },
            {
                text$: this.translocoService.selectTranslate('menu.item-management'),
                link: `/${RouteSegment.Admin}/${RouteSegment.ItemManagement}`,
                icon: 'itemmanagement',
                userIsAdmin: true
            },
            {
                text$: this.translocoService.selectTranslate('menu.contact-us'),
                link: RouteSegment.ContactUsPopup,
                icon: 'green-email',
                userIsAdmin: true
            }
        ];
    }
}
