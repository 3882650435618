import { createAction, props } from '@ngrx/store';
import { ApplianceItemArchiveEntity, ApplianceTypeEntity } from 'app/api';

/* Get Appliance */
export const getFenceDevApplianceTypes = createAction(
    '[Fence Dev Types Tab] Get fence dev types from backend',
    props<{ date: string | null }>()
);
export const getFenceDevApplianceTypesSuccess = createAction(
    '[Fence Dev Types Tab] Get fence dev types from backend success',
    props<{ fenceDevApplianceTypes: Array<ApplianceTypeEntity> }>()
);
export const getFenceDevApplianceTypesFailure = createAction(
    '[Fence Dev Types Tab] Get fence dev types from backend failure',
    props<{ error: string | null }>()
);

/* Get Appliance Archives */
export const getFenceDevApplianceTypeArchive = createAction(
    '[Fence Dev Types Tab] Get fence dev type archives from backend',
    props<{ id: string }>()
);
export const getFenceDevApplianceTypeArchiveSuccess = createAction(
    '[Fence Dev Types Tab] Get fence dev type archives from backend success',
    props<{ id: string; fenceDevApplianceTypeArchives: ApplianceItemArchiveEntity[] }>()
);
export const getFenceDevApplianceTypeArchiveFailure = createAction(
    '[Fence Dev Types Tab] Get fence dev type archives from backend failure',
    props<{ error: string | null }>()
);

/* Edit Appliance item */
export const editFenceDevApplianceItem = createAction(
    '[Fence Dev Types Tab] Edit fence dev type to backend',
    props<{
        id: string;
        name: string;
        value: number;
        labourCost: number;
        nameTranslation: { hu: string; en: string };
    }>()
);
export const editFenceDevApplianceItemSuccess = createAction(
    '[Fence Dev Types Tab] Edit fence dev type to backend success',
    props<{ id: string; name: string; value: number }>()
);
export const editFenceDevApplianceItemFailure = createAction(
    '[Fence Dev Types Tab] Edit fence dev type to backend failure',
    props<{ error: string | null }>()
);

/* Save Appliance item */
export const saveFenceDevApplianceItem = createAction(
    '[Fence Dev Types Tab] Save fence dev appliance Item to backend',
    props<{
        id: string;
        name: string;
        value: number;
        labourCost: number;
        nameTranslation: { hu: string; en: string };
    }>()
);
export const saveFenceDevApplianceItemSuccess = createAction(
    '[Fence Dev Types Tab] Save fence dev appliance Item to backend success',
    props<{ fenceDevApplianceTypes: Array<ApplianceTypeEntity> }>()
);
export const saveFenceDevApplianceItemFailure = createAction(
    '[Fence Dev Types Tab] Save fence dev appliance Item to backend failure',
    props<{ error: string | null }>()
);

/* Delete Appliance item */
export const deleteFenceDevApplianceItem = createAction(
    '[Fence Dev Types Tab] Delete fence dev appliance Item from backend',
    props<{ id: string }>()
);
export const deleteFenceDevapplianceItemSuccess = createAction(
    '[Fence Dev Types Tab] Delete fence dev appliance Item from backend success',
    props<{ id: string }>()
);
export const deleteFenceDevApplianceItemFailure = createAction(
    '[Fence Dev Types Tab] Delete fence dev appliance Item from backend failure',
    props<{ error: string | null }>()
);

/* Clear Appliance items */
export const logout = createAction('[Header] Logout');
export const clearFenceDevTypesData = createAction(
    '[Fence Dev Types Tab] Clear fence dev types data'
);
