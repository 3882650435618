<ng-container *ngIf="summaryTableService.summaryTableComponent">
    <div
        *ngLet="element.id === summaryTableService.summaryTableComponent.editedRow?.id as isEdited"
        class="flex w-full items-center justify-start pl-2"
    >
        <ng-container *ngLet="element.workType | invoke : isMainCategory as isMainCategory">
            <ng-container *ngLet="element.workType as categoryToUse">
                <ng-container
                    *ngIf="
                        !(
                            element.isCategory ||
                            element.shadowed ||
                            !isEdited ||
                            summaryTableService.summaryTableComponent.readonly
                        );
                        else showAmountValue
                    "
                >
                    <input
                        *ngLet="
                            element.isCategory
                                ? aggregateValuesFor(categoryToUse, 'labourCost') /
                                      aggregateValuesFor(categoryToUse, 'amount') || 0
                                : (element.labourCost ?? 0) / element.amount || 0 as labourCost
                        "
                        type="text"
                        [disabled]="
                            element.isCategory ||
                            element.shadowed ||
                            !isEdited ||
                            summaryTableService.summaryTableComponent.readonly
                        "
                        [value]="isEdited ? labourCost : ($any(labourCost) | number : '1.0-2')"
                        (input)="
                            onEditCell(
                                $any($event.target).value * element.amount + '',
                                'labourCost',
                                element,
                                true
                            )
                        "
                        class="w-4/5 text-left"
                    />
                </ng-container>
                <ng-template #showAmountValue>
                    <div
                        class="flex w-full justify-start pl-2"
                        [class.font-bold]="!element.minor && element.isCategory"
                    >
                        {{
                            element.isCategory && !aggregateValuesFor(categoryToUse, 'amount')
                                ? ''
                                : ((element.isCategory
                                      ? aggregateValuesFor(categoryToUse, 'labourCost') /
                                            aggregateValuesFor(categoryToUse, 'amount') || 0
                                      : (element.labourCost ?? 0) / element.amount || 0
                                  )
                                  | appCurrency
                                      : summaryTableService.summaryTableComponent.project.currency)
                        }}
                    </div>
                </ng-template>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
