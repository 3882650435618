import { createAction, props } from '@ngrx/store';
import { PlotTypeArchiveEntity, PlotTypeEntity } from 'app/api';

export const getPlotTypes = createAction(
    '[Plot Types Tab] Get plot types from backend',
    props<{ date: string | null }>()
);
export const getPlotTypesSuccess = createAction(
    '[Plot Types Tab] Get plot types from backend success',
    props<{ plotTypes: Array<PlotTypeArchiveEntity> }>()
);
export const getPlotTypesFailure = createAction(
    '[Plot Types Tab] Get plot types from backend failure',
    props<{ error: string | null }>()
);

export const getPlotTypeArchive = createAction(
    '[Plot Types Tab] Get plot type archives from backend',
    props<{ id: string }>()
);
export const getPlotTypeArchiveSuccess = createAction(
    '[Plot Types Tab] Get plot type archives from backend success',
    props<{ id: string; plotTypeArchives: Array<PlotTypeArchiveEntity> }>()
);
export const getPlotTypeArchiveFailure = createAction(
    '[Plot Types Tab] Get plot type archives from backend failure',
    props<{ error: string | null }>()
);

export const editPlotTypes = createAction(
    '[Plot Types Tab] Edit plot types from backend',
    props<{
        id: string;
        name: string;
        multiplier: number;
        nameTranslation: { hu: string; en: string };
    }>()
);
export const editPlotTypesSuccess = createAction(
    '[Plot Types Tab] Edit plot types from backend success',
    props<{ id: string; name: string; multiplier: number }>()
);
export const editPlotTypesFailure = createAction(
    '[Plot Types Tab] Edit plot types from backend failure',
    props<{ error: string | null }>()
);

export const savePlotTypes = createAction(
    '[Plot Types Tab] Save plot types to backend',
    props<{ name: string; multiplier: number; nameTranslation: { hu: string; en: string } }>()
);
export const savePlotTypesSuccess = createAction(
    '[Plot Types Tab] Save plot types to backend success',
    props<{ plotTypeData: PlotTypeEntity }>()
);
export const savePlotTypesFailure = createAction(
    '[Plot Types Tab] Save plot types to backend failure',
    props<{ error: string | null }>()
);

export const deletePlotTypes = createAction(
    '[Plot Types Tab] Delete plot types to backend',
    props<{ id: string }>()
);
export const deletePlotTypesSuccess = createAction(
    '[Plot Types Tab] Delete plot types to backend success'
);
export const deletePlotTypesFailure = createAction(
    '[Plot Types Tab] Delete plot types to backend failure',
    props<{ error: string | null }>()
);

export const logout = createAction('[Header] Logout');
export const clearPlotTypesData = createAction('[Plot Types Tab] Clear plot types data');
