import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    AdminApiService,
    StatQueryDto,
    StructurePopularityStatEntity,
    SupplierPopularityStatEntity,
    SupplierStructureStatEntity
} from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as AdminProducerAnalyticsActions from './admin-producer-analytics.actions';

@Injectable()
export class AdminProducerAnalyticsEffects {
    constructor(private actions$: Actions, public adminApiService: AdminApiService) {}

    private mapAdminProducerAnalyticsFilterValueToApiBody(filterValue: StatQueryDto): StatQueryDto {
        return { ...filterValue };
    }

    AdminGetProducerProjectStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminProducerAnalyticsActions.adminGetProducerStats),
            switchMap((action) => {
                const { type, ...filterValueWithoutType } = action;

                return this.adminApiService
                    .getSupplierBuildingStructureStatistics({
                        body: this.mapAdminProducerAnalyticsFilterValueToApiBody(
                            filterValueWithoutType
                        )
                    })
                    .pipe(
                        map((data: SupplierStructureStatEntity[]) => {
                            return AdminProducerAnalyticsActions.adminGetProducerStatsSuccess({
                                producerStats: data
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                AdminProducerAnalyticsActions.adminGetProducerStatsFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    /* AdminGetUserRoleStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminProducerAnalyticsActions.adminGetUserRoleStatistics),
            switchMap(() => {
                return this.adminApiService.getUserRoleStatistics().pipe(
                    map((data: RoleUserStatEntity[]) => {
                        return AdminProducerAnalyticsActions.adminGetUserRoleStatisticsSuccess({
                            roleUserStat: data
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            AdminProducerAnalyticsActions.adminGetUserRoleStatisticsFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    ); */

    /* Supplier popularity statistics */
    AdminGetSupplierPopularityStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminProducerAnalyticsActions.adminGetSupplierPopularityStatistics),
            switchMap((action) => {
                const { type, ...filterValueWithoutType } = action;
                return this.adminApiService
                    .getSupplierPopularityStatistics({
                        body: this.mapAdminProducerAnalyticsFilterValueToApiBody(
                            filterValueWithoutType
                        )
                    })
                    .pipe(
                        map((data: SupplierPopularityStatEntity[]) => {
                            return AdminProducerAnalyticsActions.adminGetSupplierPopularityStatisticsSuccess(
                                {
                                    SupplierPopularityStatistics: data
                                }
                            );
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                AdminProducerAnalyticsActions.adminGetSupplierPopularityStatisticsFailure(
                                    {
                                        error: error.error.message
                                    }
                                )
                            );
                        })
                    );
            })
        )
    );

    /* Structure popularity statistics */
    AdminGetStructurePopularityStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminProducerAnalyticsActions.adminGetStructurePopularityStatistics),
            switchMap((action) => {
                const { type, ...filterValueWithoutType } = action;
                return this.adminApiService
                    .getStructurePopularityStatistics({
                        body: this.mapAdminProducerAnalyticsFilterValueToApiBody(
                            filterValueWithoutType
                        )
                    })
                    .pipe(
                        map((data: StructurePopularityStatEntity[]) => {
                            return AdminProducerAnalyticsActions.adminGetStructurePopularityStatisticsSuccess(
                                {
                                    structurePopularityStatistics: data
                                }
                            );
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                AdminProducerAnalyticsActions.adminGetStructurePopularityStatisticsFailure(
                                    {
                                        error: error.error.message
                                    }
                                )
                            );
                        })
                    );
            })
        )
    );
}
