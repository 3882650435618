<mat-dialog-content>
    <h1 class="mb-8 text-lg font-bold">
        {{ 'contact-us.title' | transloco }}
    </h1>

    <div class="flex flex-col gap-8">
        <p>{{ 'contact-us.feedback-text' | transloco }}</p>

        <textarea
            matInput
            [formControl]="form.controls.contactUsText"
            maxlength="2000"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="8"
            cdkAutosizeMaxRows="5"
            class="border-2 border-solid border-black p-2 outline-none focus:border-solid focus:border-brand1"
        ></textarea>

        <ng-container *ngIf="form.controls.contactUsText.touched">
            <mat-error *ngIf="form.controls.contactUsText.errors?.['required']">
                {{ 'project-creation.error-messages.this-input-is-required' | transloco }}
            </mat-error>
        </ng-container>

        <ng-container *ngIf="isUserFeedbackSent">
            <p class="text-brand1">
                {{ 'contact-us.feedback-sent-to-email' | transloco }}
            </p>
        </ng-container>

        <div class="flex justify-end gap-4">
            <button
                mat-button
                [mat-dialog-close]="false"
                class="rounded-s ml-2 h-[36px] hover:!bg-cancelBtnHover"
            >
                {{ 'project-creation.table.cancel' | transloco }}
            </button>
            <button
                mat-button
                type="submit"
                [ngClass]="form.valid ? 'bg-brand2' : 'bg-gray1'"
                (click)="sendFeedbackEmail()"
                class="rounded-s mr-2 h-[36px] !text-brand1 hover:!bg-saveBtnHover"
            >
                {{ 'contact-us.feedback' | transloco }}
            </button>
        </div>
    </div>
</mat-dialog-content>
