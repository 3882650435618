import { ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [
            {
                user: ['accessToken', 'email']
            },
            'language',
            'projects',
            'newProject',
            'base',
            'tableConfig'
        ],
        rehydrate: true
    })(reducer);
}

export function sessionStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [
            'tabConfig',
            'tablesCompare',
            'trialDraftPhaseCreation',
            'draftPhaseCreation',
            'itemManagement',
            'salesManagement',
            'equipmentTypes',
            'landImprovementTypes',
            'qualityCategoriesWithFunctions',
            'windEnergy',
            'solarCollector',
            'heatPump',
            'fenceDev',
            'paymentPopup',
            'adminUserAnalytics',
            'adminProduceAnalytics'
        ],
        rehydrate: true,
        storage: window.sessionStorage
    })(reducer);
}
