<div class="flex h-[80px] w-full items-end justify-between">
    <div class="flex flex-col">
        <div class="flex gap-2">
            <mat-icon
                class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
            >
                home
            </mat-icon>
            {{ 'project-creation.table.building-costs' | transloco }}
        </div>
        <div class="flex gap-2">
            <mat-icon
                class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
            >
                vpn_key
            </mat-icon>
            {{ 'project-creation.table.other-costs' | transloco }}
        </div>
    </div>
    <div *ngIf="project.type === 'RENOVATION'">
        <mat-checkbox [formControl]="showShadowedRowsControl" color="primary">
            {{ 'project-creation.table.show-the-no-cost-lines-as-well' | transloco }}
        </mat-checkbox>

        <!-- TODO: Add back later -->
        <!--
        <mat-checkbox [formControl]="showEmptyCategoriesControl" color="primary">
            {{ 'project-creation.table.also-show-empty-groups' | transloco }}
        </mat-checkbox> -->
    </div>
    <button
        *ngIf="!readonly"
        class="btn-primary position-right flex items-center gap-2"
        (click)="
            summaryTableService.dialog
                .open(addNewItemToCategoryDialogTpl, {
                    width: '500px'
                })
                .afterClosed()
        "
    >
        <mat-icon fontIcon="add" class="ml-1 h-[24px] w-[24px] self-center text-white"></mat-icon>
        {{ 'menu.add-new' | transloco }}
    </button>
</div>

<table mat-table [dataSource]="dataSource" [trackBy]="trackByFn" class="mat-elevation-z8">
    <!-- Shadowed Checkbox Column -->
    <ng-container matColumnDef="costInfo">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[60px] justify-center text-center lg:w-[50px]"
            [ngClass]="{
                '!w-[50px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.cost-signal' | transloco }}
        </th>

        <td
            mat-cell
            *matCellDef="let element; let i = index"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[50px]': smallerTableOnPc
            }"
            [ngStyle]="{
                display: toggledCategories.has(element.version)
                    ? 'flex !important'
                    : 'none !important'
            }"
            class="w-[60px] justify-center text-center lg:w-[50px]"
        >
            <app-column-cost-info [element]="element"></app-column-cost-info>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="category-row w-[60px] justify-center text-center lg:w-[50px]"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id,
                '!w-[50px]': smallerTableOnPc
            }"
        >
            <!-- Intentionally empty -->
        </td>
    </ng-container>

    <!-- Shadowed Checkbox Column -->
    <ng-container matColumnDef="shadowed">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[60px] justify-center text-center lg:w-[50px]"
            [ngClass]="{
                '!w-[50px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.no-cost' | transloco }}
        </th>
        <td
            mat-cell
            *matCellDef="let element; let i = index"
            class="w-[60px] justify-center text-center lg:w-[50px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[50px]': smallerTableOnPc
            }"
            [matTooltip]="
                !element.isCategory && element.id !== editedRow?.id
                    ? ('project-creation.table.it-can-only-be-changed-in-edit-mode' | transloco)
                    : ''
            "
            matTooltipPosition="right"
            [matTooltipShowDelay]="500"
        >
            <app-column-shadowed [element]="element"> </app-column-shadowed>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="category-row w-[60px] lg:w-[50px]"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id,
                '!w-[50px]': smallerTableOnPc
            }"
        >
            <!-- Intentionally empty -->
        </td>
    </ng-container>

    <div matColumnDef="id">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[60px] justify-center text-center lg:w-[55px]"
            [ngClass]="{
                '!w-[55px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.identifier' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="w-[60px] justify-center text-center lg:w-[55px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[55px]': smallerTableOnPc
            }"
        >
            {{
                element.isCategory
                    ? element.workType.major +
                      (element.workType.minor ? '.' + element.workType.minor : '')
                    : ''
            }}
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="category-row w-[60px] justify-center text-center lg:w-[55px]"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id,
                '!w-[55px]': smallerTableOnPc
            }"
        >
            <!-- Intentionally empty -->
        </td>
    </div>

    <ng-container matColumnDef="acquisitionCost">
        <th mat-header-cell *matHeaderCellDef class="w-[90px] justify-center text-center">
            {{ 'project-creation.table.acquisition-cost' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="w-[90px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false
            }"
        >
            <div class="mx-auto" *ngIf="!element.shadowed">
                <mat-icon
                    *ngIf="
                        [1, 2, 3, 4, 6, 7, 8, 9, 10, 12, 13, 17].includes(element.workType.major);
                        else otherCosts
                    "
                    class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
                >
                    home
                </mat-icon>
                <ng-template #otherCosts>
                    <mat-icon
                        class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
                    >
                        vpn_key
                    </mat-icon>
                </ng-template>
            </div>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="category-row w-[90px] justify-center text-center"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id
            }"
        >
            <mat-icon
                *ngIf="
                    tableFooterRowConfig[footerRowAnchor.index]['name'] ===
                        'project-creation.table.design-budget' ||
                    tableFooterRowConfig[footerRowAnchor.index]['name'] ===
                        'project-creation.table.building-sub-total'
                "
                class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
            >
                home
            </mat-icon>
            <mat-icon
                *ngIf="
                    tableFooterRowConfig[footerRowAnchor.index]['name'] ===
                        'project-creation.table.general-planning-cost' ||
                    tableFooterRowConfig[footerRowAnchor.index]['name'] ===
                        'project-creation.table.other-sub-total'
                "
                class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
            >
                vpn_key
            </mat-icon>
        </td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[200px] justify-center lg:w-[160px]"
            [ngClass]="{
                '!w-[160px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.work-type' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="flex w-[200px] flex-col justify-center pl-2 text-left lg:w-[160px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                'justify-end': !!element.function,
                '!w-[160px]': smallerTableOnPc
            }"
        >
            <app-column-name [element]="element"></app-column-name>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="category-row w-[200px] justify-start lg:w-[160px]"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id,
                '!w-[160px]': smallerTableOnPc
            }"
        >
            <span class="pl-2">
                {{ tableFooterRowConfig[footerRowAnchor.index]['name'] | transloco }}
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="unit">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[90px] text-center lg:w-[80px]"
            [ngClass]="{
                '!w-[80px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.unit' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="w-[90px] justify-center text-center lg:w-[80px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[80px]': smallerTableOnPc
            }"
            [class.font-bold]="!element.minor && element.isCategory"
        >
            {{
                hasDifferentUnits(element.workType) && element.isCategory
                    ? ''
                    : element.workType?.unit?.symbol
            }}
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="category-row w-[90px] justify-center lg:w-[80px]"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id,
                '!w-[80px]': smallerTableOnPc
            }"
        >
            <span class="mx-auto">
                {{ tableFooterRowConfig[footerRowAnchor.index]['unit'] }}
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[100px] text-center lg:w-[50px]"
            [ngClass]="{
                '!w-[50px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.amount' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="w-[100px] justify-center lg:w-[50px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[50px]': smallerTableOnPc
            }"
        >
            <app-column-amount [element]="element"></app-column-amount>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="category-row w-[100px] justify-start lg:w-[50px]"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id,
                '!w-[50px]': smallerTableOnPc
            }"
        >
            <ng-container *ngLet="tableFooterRowConfig?.[footerRowAnchor.index] as rowConfig">
                <ng-container
                    *ngLet="
                        (rowConfig?.['amount'] | invoke : getTypeOf) === 'function'
                            ? $any(rowConfig?.['amount'])()
                            : rowConfig?.['amount'] as amountVal
                    "
                >
                    <span
                        *ngLet="{
                          isEdited: rowConfig?.['id'] === editedRow?.id,
                          formattedVal: $any(amountVal) | number : '1.0-2'
                        } as ctx "
                        class="mx-auto"
                    >
                        {{
                            ctx?.isEdited
                                ? footerRowNewPercent ?? ctx?.formattedVal
                                : ctx?.formattedVal
                        }}
                    </span>
                </ng-container>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="materialUnitPrice">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[150px] text-center lg:w-[100px]"
            [ngClass]="{
                '!w-[100px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.material-unit-price' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="w-[150px] lg:w-[100px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[100px]': smallerTableOnPc
            }"
        >
            <app-column-material-unit-price [element]="element"></app-column-material-unit-price>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="category-row w-[150px] pl-2 text-left lg:w-[100px]"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id,
                '!w-[100px]': smallerTableOnPc
            }"
        >
            <!-- <ng-container
                *ngLet="tableFooterRowConfig[footerRowAnchor.index]['unitPrice'] as unitPriceProducer"
            >
                <ng-container
                    *ngLet="
                        (unitPriceProducer | invoke : getTypeOf) === 'function'
                            ? $any(unitPriceProducer)()
                            : unitPriceProducer as unitPriceVal
                    "
                >
                    <span class="pl-2">
                        {{ $any(unitPriceVal) | appCurrency : project.currency }}
                    </span>
                </ng-container>
            </ng-container> -->
        </td>
    </ng-container>

    <ng-container matColumnDef="materialCost">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[150px] text-center lg:w-[100px]"
            [ngClass]="{
                '!w-[100px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.material-cost' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="w-[150px] lg:w-[100px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[100px]': smallerTableOnPc
            }"
        >
            <app-column-material-cost [element]="element"></app-column-material-cost>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="w-[150px] lg:w-[100px]"
            [ngClass]="{
                '!w-[100px]': smallerTableOnPc
            }"
        ></td>
    </ng-container>

    <ng-container matColumnDef="labourUnitPrice">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[150px] text-center lg:w-[100px]"
            [ngClass]="{
                '!w-[100px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.labour-unit-price' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="w-[150px] lg:w-[100px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[100px]': smallerTableOnPc
            }"
        >
            <app-column-labour-unit-price [element]="element"></app-column-labour-unit-price>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="w-[150px] lg:w-[100px]"
            [ngClass]="{
                '!w-[100px]': smallerTableOnPc
            }"
        ></td>
    </ng-container>

    <ng-container matColumnDef="labourCost">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[150px] text-center lg:w-[100px]"
            [ngClass]="{
                '!w-[100px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.labour-cost' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="w-[150px] lg:w-[100px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[100px]': smallerTableOnPc
            }"
        >
            <app-column-labour-cost [element]="element"></app-column-labour-cost>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="w-[150px] lg:w-[100px]"
            [ngClass]="{
                '!w-[100px]': smallerTableOnPc
            }"
        ></td>
    </ng-container>

    <ng-container matColumnDef="totalCost">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[150px] text-center lg:w-[100px]"
            [ngClass]="{
                '!w-[100px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.total-cost' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="w-[150px] lg:w-[100px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[100px]': smallerTableOnPc
            }"
        >
            <app-column-total-cost [element]="element"></app-column-total-cost>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="category-row w-[150px] justify-start pl-2 lg:w-[100px]"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id,
                '!w-[100px]': smallerTableOnPc
            }"
        >
            <ng-container
                *ngLet="{
                    value: tableFooterRowConfig[footerRowAnchor.index]['value'],
                    labourCost: tableFooterRowConfig[footerRowAnchor.index]['labourCost']
                } as ctx"
            >
                <!-- {{ valueProducer }} -->
                <ng-container
                    *ngLet="{
                        value:
                            (ctx!.value | invoke : getTypeOf) === 'function'
                                ? $any(ctx!.value)()
                                : ctx!.value,
                        labourCost:
                            (ctx!.labourCost | invoke : getTypeOf) === 'function'
                                ? $any(ctx!.labourCost)()
                                : ctx!.labourCost
                    } as innerCtx"
                >
                    <span class="pl-2">
                        {{
                            $any(innerCtx?.value + innerCtx?.labourCost ?? 0)
                                | appCurrency : project.currency
                        }}
                    </span>
                </ng-container>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="percent">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[80px] justify-center text-center lg:w-[60px]"
            [ngClass]="{
                '!w-[60px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.percent' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="w-[80px] lg:w-[60px]"
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[60px]': smallerTableOnPc
            }"
        >
            <ng-container *ngIf="!element.shadowed">
                <div
                    *ngLet="element.id === editedRow?.id as isEdited"
                    class="flex w-full items-center justify-start pl-2"
                >
                    <div
                        class="flex w-full justify-start pl-2"
                        [class.font-bold]="!element.minor && element.isCategory"
                    >
                        {{ element.percent ?? 0 | percent : '1.0-1' }}
                    </div>
                </div>
            </ng-container>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="category-row w-[80px] text-left lg:w-[60px]"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id,
                '!w-[60px]': smallerTableOnPc
            }"
        >
            <div
                class="flex w-full justify-start pl-2"
                *ngLet="tableFooterRowConfig?.[footerRowAnchor.index] as rowConfig"
            >
                <ng-container *ngLet="rowConfig?.['id'] === editedRow?.id as isEdited">
                    <ng-container
                        *ngLet="((rowConfig?.['percent'] | invoke : getTypeOf) === 'function'
                            ? $any(rowConfig?.['percent'])()
                            : rowConfig?.['percent'] ?? 0) as percent"
                    >
                        <ng-container *ngIf="!isEdited">
                            <span class="pl-2">
                                {{ percent | percent : '1.0-1' }}
                            </span>
                        </ng-container>

                        <ng-container *ngIf="isEdited">
                            <input
                                *ngLet="rowConfig?.['id'] === editedRow?.id as isEdited"
                                type="number"
                                #input
                                [disabled]="!rowConfig?.['canEditPercent'] || !isEdited"
                                (input)="footerRowNewPercent = +input.value"
                                [value]="percent * 100"
                                class="w-4/5 text-left"
                                pattern="\d+"
                            />
                        </ng-container>

                        <mat-icon
                            *ngIf="isEdited && editErrors?.['percent']"
                            [matTooltip]="editErrors?.['percent'] ?? ('project-creation.table.incorrect-value' | transloco)"
                        >
                            info
                        </mat-icon>
                    </ng-container>
                </ng-container>

                <mat-icon
                    *ngIf="false"
                    [matTooltip]="'project-creation.table.incorrect-value' | transloco"
                >
                    info
                </mat-icon>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="vatRate">
        <th
            mat-header-cell
            *matHeaderCellDef
            class="w-[80px] justify-center text-center lg:w-[60px]"
            [ngClass]="{
                '!w-[60px]': smallerTableOnPc
            }"
        >
            {{ 'project-creation.table.vat-rate' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            class="w-[80px] lg:w-[60px]"
            [ngClass]="{
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false,
                '!w-[60px]': smallerTableOnPc
            }"
        >
            <ng-container *ngIf="!element.shadowed">
                <div
                    *ngLet="element.id === editedRow?.id as isEdited"
                    class="flex w-full items-center justify-start pl-2"
                >
                    <ng-container
                        *ngLet="
                            element.workType
                                | invoke : summaryTableService.isMainCategory as isMainCategory
                        "
                    >
                        <input
                            *ngLet="+element.vatRate ?? 0 as vatRate"
                            type="text"
                            #input
                            [disabled]="
                                element.shadowed || !isEdited || readonly || originalRow?.shadowed
                            "
                            [value]="
                                isEdited && !element.isCategory
                                    ? element.vatRate
                                    : vatRate !== -1
                                    ? vatRate
                                    : ''
                            "
                            (input)="onEditVatRate($any($event.target).value, element)"
                            class="w-4/5 text-left"
                            [class.font-bold]="!element.minor && element.isCategory"
                        />
                    </ng-container>

                    <mat-icon
                        *ngIf="isEdited && (editErrors?.['vatRate'] || editErrors?.['numericFieldExclusion'])"
                        [matTooltip]="editErrors?.['numericFieldExclusion'] ?? ('project-creation.table.incorrect-value' | transloco)"
                    >
                        info
                    </mat-icon>
                </div>
            </ng-container>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            class="category-row w-[80px] justify-center text-center lg:w-[60px]"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id,
                '!w-[60px]': smallerTableOnPc
            }"
        >
            <!-- Intentionally empty -->
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-[70px] justify-center text-center">
            {{ 'project-creation.table.actions' | transloco }}
        </th>

        <td
            *matCellDef="let element; let i = index"
            mat-cell
            [ngClass]="{
                'category-row': element.isCategory,
                'shadowed-row': element.shadowed,
                'edited-row': element.id === editedRow?.id,
                'top-cost-row': element.workType.topMainCategoryHighlight ? true : false
            }"
            class="w-[70px] justify-center text-center"
        >
            <app-column-actions [element]="element"></app-column-actions>
        </td>

        <td
            mat-footer-cell
            *matFooterCellDef
            #footerRowAnchor="matFooterRowAnchor"
            [ngClass]="{
                'edited-row': tableFooterRowConfig[footerRowAnchor.index].id === editedRow?.id
            }"
            class="category-row w-[70px] pl-2 text-left"
        >
            <ng-container *ngLet="tableFooterRowConfig?.[footerRowAnchor.index] as rowConfig">
                <button
                    *ngIf="rowConfig?.['canEditPercent'] && rowConfig?.['id'] !== editedRow?.id"
                    mat-icon-button
                    (click)="onEditFooterRow(rowConfig!)"
                >
                    <mat-icon
                        class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
                        >edit</mat-icon
                    >
                </button>

                <ng-container *ngIf="rowConfig?.['id'] === editedRow?.id">
                    <button mat-icon-button (click)="onSaveFooterRow($any(editedRow))">
                        <mat-icon
                            class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
                            >save</mat-icon
                        >
                    </button>

                    <button mat-icon-button (click)="summaryTableService.onUndoRow()">
                        <mat-icon
                            class="text-iconPrimary hover:text-iconPrimaryHover active:text-iconPrimaryActive"
                            >undo</mat-icon
                        >
                    </button>
                </ng-container>
            </ng-container>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="{
            'invalid-row': row.id === editedRow?.id && !!editErrors
        }"
        attr.data-version="{{ row.version }}"
        [ngStyle]="{
            display: row | invoke : displayShowMatRow
        }"
    ></tr>

    <tr
        mat-footer-row
        *matFooterRowDef="displayedColumns; sticky: true"
        [ngClass]="{
            'invalid-row': $any(editedRow)?.name === 'Design keret'
        }"
    ></tr>
    <tr
        mat-footer-row
        *matFooterRowDef="displayedColumns; sticky: true"
        [ngClass]="{
            'invalid-row': $any(editedRow)?.name === 'Generáltervezési díjak'
        }"
    ></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="highlight-row"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="highlight-row"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="success-row"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true" class="success-row"></tr>
</table>

<div *ngIf="loading" class="loading-spinner">
    <mat-progress-spinner style="opacity: 1" color="primary" mode="indeterminate">
    </mat-progress-spinner>
</div>

<!--- Template -->

<ng-template #deleteRowConfirmDialogTpl let-row>
    <div class="flex flex-col items-center">
        <h4 class="delete-row-dialog-header w-full p-2 py-4 text-center text-md font-bold">
            {{
                'project-creation.table.are-you-sure-you-want-to-delete-the-following-item?'
                    | transloco
            }}
        </h4>
        <p class="text-gray-500 mt-4 text-md font-bold underline">{{ row.name }}</p>
        <p class="text-gray-500 mt-2 text-sm">
            <span class="font-bold"> {{ 'project-creation.table.amount' | transloco }}: </span>
            &nbsp;{{ row.amount | number : '1.0-2' }}
        </p>
        <p class="text-gray-500 mt-2 text-sm">
            <span class="font-bold">
                {{ 'project-creation.table.material-unit-price' | transloco }}:
            </span>
            &nbsp;{{ row.value / row.amount || 0 | appCurrency : project.currency }}
        </p>
        <p class="text-gray-500 mt-2 text-sm">
            <span class="font-bold">
                {{ 'project-creation.table.total-amount' | transloco }}:
            </span>
            &nbsp;{{ row.value | appCurrency : project.currency }}
        </p>
        <p class="text-gray-500 mt-2 text-sm">
            <span class="font-bold"> {{ 'project-creation.table.percent' | transloco }}: </span>
            &nbsp;{{ row.percent | percent : '1.0-1' }}
        </p>
    </div>

    <div class="mt-4 mb-2 flex justify-center">
        <button
            mat-button
            [mat-dialog-close]="true"
            class="rounded-s mr-2 h-[36px] !bg-deleteBtn !text-error hover:!bg-deleteBtnHover"
        >
            {{ 'project-creation.table.delete' | transloco }}
        </button>
        <button
            mat-button
            [mat-dialog-close]="false"
            class="rounded-s ml-2 h-[36px] !bg-cancelBtn hover:!bg-cancelBtnHover"
        >
            {{ 'project-creation.table.cancel' | transloco }}
        </button>
    </div>
</ng-template>

<ng-template #saveRowConfirmDialogTpl let-row>
    <div class="flex flex-col items-center">
        <h4 class="delete-row-dialog-header w-full p-2 py-4 text-center text-md font-bold">
            {{
                'project-creation.table.are-you-sure-you-want-to-save-the-following-item?'
                    | transloco
            }}
        </h4>

        <p class="text-gray-500 mt-4 text-md font-bold underline">{{ row.name }}</p>
        <p class="text-gray-500 mt-2 text-sm">
            <span class="font-bold"> {{ 'project-creation.table.amount' | transloco }}: </span>
            &nbsp;{{ row.amount | number : '1.0-2' }}
        </p>
        <p class="text-gray-500 mt-2 text-sm">
            <span class="font-bold">
                {{ 'project-creation.table.material-unit-price' | transloco }}:
            </span>
            &nbsp;{{ row.value / row.amount || 0 | appCurrency : project.currency }}
        </p>
        <p class="text-gray-500 mt-2 text-sm">
            <span class="font-bold">
                {{ 'project-creation.table.material-cost' | transloco }}:
            </span>
            &nbsp;{{ row.value | appCurrency : project.currency }}
        </p>
        <p class="text-gray-500 mt-2 text-sm">
            <span class="font-bold"> {{ 'project-creation.table.labour-cost' | transloco }}: </span>
            &nbsp;{{ row.labourCost / row.amount || 0 | appCurrency : project.currency }}
        </p>
        <p class="text-gray-500 mt-2 text-sm">
            <span class="font-bold"> {{ 'project-creation.table.labour-cost' | transloco }}: </span>
            &nbsp;{{ row.labourCost | appCurrency : project.currency }}
        </p>
        <p class="text-gray-500 mt-2 text-sm">
            <span class="font-bold"> {{ 'project-creation.table.total-cost' | transloco }}: </span>
            &nbsp;{{ row.value + row.labourCost | appCurrency : project.currency }}
        </p>
        <p
            *ngIf="
                ((originalRow?.percent ?? 0) !== row.percent ||
                    (originalRow?.value ?? 0) === row.value) &&
                !originalRow?.shadowed
            "
            class="text-gray-500 mt-2 text-sm"
        >
            <span class="font-bold"> {{ 'project-creation.table.percent' | transloco }}: </span>
            &nbsp;{{ row.percent | percent : '1.0-1' }}
        </p>
    </div>

    <div class="mt-4 mb-2 flex justify-center">
        <button
            mat-button
            [mat-dialog-close]="true"
            class="rounded-s mr-2 h-[36px] !bg-saveBtn !text-brand1 hover:!bg-saveBtnHover"
        >
            {{ 'project-creation.table.save' | transloco }}
        </button>
        <button
            mat-button
            [mat-dialog-close]="false"
            class="rounded-s ml-2 h-[36px] !bg-cancelBtn hover:!bg-cancelBtnHover"
        >
            {{ 'project-creation.table.cancel' | transloco }}
        </button>
    </div>
</ng-template>

<ng-template #savePercentRowConfirmDialogTpl let-row>
    <div class="flex flex-col items-center">
        <h4 class="delete-row-dialog-header w-full p-2 py-4 text-center text-md font-bold">
            {{ 'project-creation.table.change-of-percentage-value' | transloco }}
        </h4>

        <p class="text-gray-500 mt-4 text-center text-sm">
            {{
                'project-creation.table.this-change-will-affect-all-line-items-in-the-table'
                    | transloco
            }}
        </p>
    </div>

    <div class="mt-4 mb-2 flex justify-center">
        <button
            mat-button
            [mat-dialog-close]="true"
            class="rounded-s mr-2 h-[36px] !bg-saveBtn !text-brand1 hover:!bg-saveBtnHover"
        >
            {{ 'project-creation.table.save' | transloco }}
        </button>
        <button
            mat-button
            [mat-dialog-close]="false"
            class="rounded-s ml-2 h-[36px] !bg-cancelBtn hover:!bg-cancelBtnHover"
        >
            {{ 'project-creation.table.cancel' | transloco }}
        </button>
    </div>
</ng-template>

<ng-template #saveShadowConfirmDialogTpl let-row>
    <div class="flex flex-col items-center">
        <h4 class="delete-row-dialog-header w-full p-2 py-4 text-center text-md font-bold">
            {{ 'project-creation.table.change-of-shadowed-value' | transloco }}
        </h4>

        <p class="text-gray-500 mt-4 text-center text-sm">
            {{ 'project-creation.table.shadowing-removes-item' | transloco }}
        </p>
    </div>

    <div class="mt-4 mb-2 flex justify-center">
        <button
            mat-button
            [mat-dialog-close]="true"
            class="rounded-s mr-2 h-[36px] !bg-saveBtn !text-brand1 hover:!bg-saveBtnHover"
        >
            {{ 'project-creation.table.save' | transloco }}
        </button>
        <button
            mat-button
            [mat-dialog-close]="false"
            class="rounded-s ml-2 h-[36px] !bg-cancelBtn hover:!bg-cancelBtnHover"
        >
            {{ 'project-creation.table.cancel' | transloco }}
        </button>
    </div>
</ng-template>

<ng-template #editOverrideConfirmDialogTpl let-row>
    <div class="flex flex-col items-center">
        <h4 class="delete-row-dialog-header w-full p-2 py-4 text-center text-md font-bold">
            {{ 'project-creation.table.abort-editing' | transloco }}
        </h4>

        <p class="text-gray-500 mt-4 text-center text-sm">
            {{ 'project-creation.table.do-you-want-to-save-before-abort' | transloco }}
        </p>
    </div>

    <div class="mt-4 mb-2 flex justify-center">
        <button
            mat-button
            [mat-dialog-close]="true"
            class="rounded-s mr-2 h-[36px] !bg-saveBtn !text-brand1 hover:!bg-saveBtnHover"
        >
            {{ 'project-creation.table.save' | transloco }}
        </button>
        <button
            mat-button
            [mat-dialog-close]="false"
            class="rounded-s ml-2 h-[36px] !bg-cancelBtn hover:!bg-cancelBtnHover"
        >
            {{ 'project-creation.table.cancel' | transloco }}
        </button>
    </div>
</ng-template>

<ng-template #addNewItemToCategoryDialogTpl let-row>
    <div class="flex flex-col items-center">
        <h4 class="delete-row-dialog-header w-full p-2 py-4 text-center text-md font-bold">
            <!-- {{ 'project-creation.table.abort-editing' | transloco }} -->
            Új tétel hozzáadása
        </h4>

        <p class="text-gray-500 mt-4 text-center text-sm">
            <!-- {{ 'project-creation.table.do-you-want-to-save-before-abort' | transloco }} -->
            Kategoria kikeresése
        </p>
    </div>
    <!-- {{ entries | json }} -->

    <div class="mx-auto block w-96">
        <mat-form-field class="select-identifier mx-auto flex items-center justify-between">
            <mat-select #mainWorkTypeCategorySelect>
                <ng-container *ngFor="let workType of workTypes">
                    <mat-option *ngIf="workType.minor === null" [value]="workType">
                        {{ workType?.major + ' ' + (workType?.name | transloco) }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <mat-form-field
            *ngIf="mainWorkTypeCategorySelect.value"
            class="select-identifier mx-auto flex items-center justify-between"
        >
            <mat-select
                *ngLet="
                    mainWorkTypeCategorySelect.value
                        | invoke : $any(getSubcategoriesFor) as getSubcategories
                "
                #workTypeSubcategorySelect
            >
                <ng-container *ngIf="$any(getSubcategories).length > 0; else noSubcategories">
                    <mat-option *ngFor="let workType of $any(getSubcategories)" [value]="workType">
                        {{ workType?.minor + ' ' + (workType?.name | transloco) }}
                    </mat-option>
                </ng-container>
                <ng-template #noSubcategories>
                    <mat-option [value]="mainWorkTypeCategorySelect.value">
                        {{
                            mainWorkTypeCategorySelect.value?.major +
                                ' ' +
                                (mainWorkTypeCategorySelect.value?.name | transloco)
                        }}
                    </mat-option>
                </ng-template>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="mt-3 mb-2 flex justify-center">
        <button
            mat-button
            [mat-dialog-close]="true"
            [disabled]="!workTypeSubcategorySelect.value"
            (click)="
                summaryTableService.onAddNewRow(
                    searchWorkTypeInDataSource(workTypeSubcategorySelect!.value.id)
                )
            "
            class="rounded-s mr-2 h-[36px] !bg-saveBtn !text-brand1 hover:!bg-saveBtnHover"
        >
            {{ 'project-creation.table.save' | transloco }}
        </button>
        <button
            mat-button
            [mat-dialog-close]="false"
            class="rounded-s ml-2 h-[36px] !bg-cancelBtn hover:!bg-cancelBtnHover"
        >
            {{ 'project-creation.table.cancel' | transloco }}
        </button>
    </div>
</ng-template>
