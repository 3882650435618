import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    AdminApiService,
    BuildMethodStatEntity,
    DesignBudgetCategoryStatEntity,
    GeoRegionStatDetailsEntity,
    PlotTypeStatEntity,
    RenewableStatEntity,
    RoleUserStatEntity,
    StatQueryDto,
    SubscriptionTypeUserStatEntity,
    UserProjectStatEntity
} from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as AdminUserAnalyticsActions from './admin-user-analytics.actions';

@Injectable()
export class AdminUserAnalyticsEffects {
    constructor(private actions$: Actions, public adminApiService: AdminApiService) {}

    private mapAdminUserAnalyticsFilterValueToApiBody(filterValue: StatQueryDto): StatQueryDto {
        return { ...filterValue };
    }

    /* User analytics */
    AdminGetUserProjectStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminUserAnalyticsActions.adminGetUserProjectStats),
            switchMap((action) => {
                const { type, ...filterValueWithoutType } = action;
                return this.adminApiService
                    .getStatistics({
                        body: this.mapAdminUserAnalyticsFilterValueToApiBody(filterValueWithoutType)
                    })
                    .pipe(
                        map((data: UserProjectStatEntity[]) => {
                            return AdminUserAnalyticsActions.adminGetUserProjectStatsSuccess({
                                userProjectStats: data
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                AdminUserAnalyticsActions.adminGetUserProjectStatsFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    /* User role statistics */
    AdminGetUserRoleStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminUserAnalyticsActions.adminGetUserRoleStatistics),
            switchMap(() => {
                return this.adminApiService.getUserRoleStatistics().pipe(
                    map((data: RoleUserStatEntity[]) => {
                        return AdminUserAnalyticsActions.adminGetUserRoleStatisticsSuccess({
                            roleUserStat: data
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            AdminUserAnalyticsActions.adminGetUserRoleStatisticsFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );

    /* User subscription statistics */
    AdminGetUserSubscriptionStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminUserAnalyticsActions.adminGetUserSubscriptionStatistics),
            switchMap(() => {
                return this.adminApiService.getUserSubscriptionStatistics().pipe(
                    map((data: SubscriptionTypeUserStatEntity[]) => {
                        return AdminUserAnalyticsActions.adminGetUserSubscriptionStatisticsSuccess({
                            subscriptionUserStat: data
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            AdminUserAnalyticsActions.adminGetUserSubscriptionStatisticsFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );

    /* Draftphases renewable energy statistics */
    AdminGetRenewableEnergyStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminUserAnalyticsActions.adminGetRenewableEnergeticsStatistics),
            switchMap((action) => {
                const { type, ...filterValueWithoutType } = action;
                return this.adminApiService
                    .getDraftPhaseRenewableStatistics({
                        body: this.mapAdminUserAnalyticsFilterValueToApiBody(filterValueWithoutType)
                    })
                    .pipe(
                        map((data: RenewableStatEntity) =>
                            AdminUserAnalyticsActions.adminGetRenewableEnergeticsStatisticsSuccess({
                                draftphaseRenewableEnergyStat: data
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                AdminUserAnalyticsActions.adminGetRenewableEnergeticsStatisticsFailure(
                                    {
                                        error: error.message
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );

    /* Draftphases plot types statistics */
    AdminGetPlotTypesStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminUserAnalyticsActions.adminGetPlotTypesStatistics),
            switchMap((action) => {
                const { type, ...filterValueWithoutType } = action;
                return this.adminApiService
                    .getDraftPhasePlotTypeStatistics({
                        body: this.mapAdminUserAnalyticsFilterValueToApiBody(filterValueWithoutType)
                    })
                    .pipe(
                        map((data: PlotTypeStatEntity[]) =>
                            AdminUserAnalyticsActions.adminGetPlotTypesStatisticsSuccess({
                                draftphasePlotTypesStat: data
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                AdminUserAnalyticsActions.adminGetPlotTypesStatisticsFailure({
                                    error: error.message
                                })
                            )
                        )
                    );
            })
        )
    );

    /* Draftphases build methods statistics */
    AdminGetBuildMethodsStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminUserAnalyticsActions.adminGetBuildMethodsStatistics),
            switchMap((action) => {
                const { type, ...filterValueWithoutType } = action;
                return this.adminApiService
                    .getDraftPhaseBuildMethodStatistics({
                        body: this.mapAdminUserAnalyticsFilterValueToApiBody(filterValueWithoutType)
                    })
                    .pipe(
                        map((data: BuildMethodStatEntity[]) =>
                            AdminUserAnalyticsActions.adminGetBuildMethodsStatisticsSuccess({
                                draftphaseBuildMethodsStat: data
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                AdminUserAnalyticsActions.adminGetBuildMethodsStatisticsFailure({
                                    error: error.message
                                })
                            )
                        )
                    );
            })
        )
    );

    /* Draftphases design budgets statistics */
    AdminGetDesignBudgetstatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminUserAnalyticsActions.adminGetDesignBudgetstatistics),
            switchMap((action) => {
                const { type, ...filterValueWithoutType } = action;
                return this.adminApiService
                    .getDraftPhaseDesignBudgetStatistics({
                        body: this.mapAdminUserAnalyticsFilterValueToApiBody(filterValueWithoutType)
                    })
                    .pipe(
                        map((data: DesignBudgetCategoryStatEntity[]) =>
                            AdminUserAnalyticsActions.adminGetDesignBudgetstatisticsSuccess({
                                draftphaseDesignBudgetsStat: data
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                AdminUserAnalyticsActions.adminGetDesignBudgetstatisticsFailure({
                                    error: error.message
                                })
                            )
                        )
                    );
            })
        )
    );

    /* Geo region project details statistics */
    AdminGetGeoRegionProjectDetailsStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AdminUserAnalyticsActions.adminGetGeoRegionProjectDetailsStatistics),
            switchMap((action) => {
                const { type, ...filterValueWithoutType } = action;
                return this.adminApiService
                    .getGeoRegionProjectDetailsStatistics({
                        body: this.mapAdminUserAnalyticsFilterValueToApiBody(filterValueWithoutType)
                    })
                    .pipe(
                        map((data: GeoRegionStatDetailsEntity) =>
                            AdminUserAnalyticsActions.adminGetGeoRegionProjectDetailsStatisticsSuccess(
                                {
                                    geoRegionProjectDetailsStat: data
                                }
                            )
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                AdminUserAnalyticsActions.adminGetGeoRegionProjectDetailsStatisticsFailure(
                                    {
                                        error: error.message
                                    }
                                )
                            )
                        )
                    );
            })
        )
    );
}
