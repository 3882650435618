import { createAction, props } from '@ngrx/store';
import { ApplianceItemArchiveEntity, ApplianceTypeEntity } from 'app/api';

/* Get Appliance */
export const getHeatPumpApplianceTypes = createAction(
    '[Heat Pump Types Tab] Get Heat Pump types from backend',
    props<{ date: string | null }>()
);
export const getHeatPumpApplianceTypesSuccess = createAction(
    '[Heat Pump Types Tab] Get Heat Pump types from backend success',
    props<{ heatPumpApplianceTypes: Array<ApplianceTypeEntity> }>()
);
export const getHeatPumpApplianceTypesFailure = createAction(
    '[Heat Pump Types Tab] Get Heat Pump types from backend failure',
    props<{ error: string | null }>()
);

/* Get Appliance Archives */
export const getHeatPumpApplianceTypeArchive = createAction(
    '[Heat Pump Types Tab] Get Heat Pump type archives from backend',
    props<{ id: string }>()
);
export const getHeatPumpApplianceTypeArchiveSuccess = createAction(
    '[Heat Pump Types Tab] Get Heat Pump type archives from backend success',
    props<{ id: string; heatPumpApplianceTypeArchives: ApplianceItemArchiveEntity[] }>()
);
export const getHeatPumpApplianceTypeArchiveFailure = createAction(
    '[Heat Pump Types Tab] Get Heat Pump type archives from backend failure',
    props<{ error: string | null }>()
);

/* Edit Appliance item */
export const editHeatPumpApplianceItem = createAction(
    '[Heat Pump Types Tab] Edit Heat Pump type to backend',
    props<{
        id: string;
        name: string;
        value: number;
        labourCost: number;
        nameTranslation: { hu: string; en: string };
    }>()
);
export const editHeatPumpApplianceItemSuccess = createAction(
    '[Heat Pump Types Tab] Edit Heat Pump type to backend success',
    props<{ id: string; name: string; value: number }>()
);
export const editHeatPumpApplianceItemFailure = createAction(
    '[Heat Pump Types Tab] Edit Heat Pump type to backend failure',
    props<{ error: string | null }>()
);

/* Save Appliance item */
export const saveHeatPumpApplianceItem = createAction(
    '[Heat Pump Types Tab] Save Heat Pump appliance Item to backend',
    props<{
        id: string;
        name: string;
        value: number;
        labourCost: number;
        nameTranslation: { hu: string; en: string };
    }>()
);
export const saveHeatPumpApplianceItemSuccess = createAction(
    '[Heat Pump Types Tab] Save Heat Pump appliance Item to backend success',
    props<{ heatPumpApplianceTypes: Array<ApplianceTypeEntity> }>()
);
export const saveHeatPumpApplianceItemFailure = createAction(
    '[Heat Pump Types Tab] Save Heat Pump appliance Item to backend failure',
    props<{ error: string | null }>()
);

/* Delete Appliance item */
export const deleteHeatPumpApplianceItem = createAction(
    '[Heat Pump Types Tab] Delete Heat Pump appliance Item from backend',
    props<{ id: string }>()
);
export const deleteHeatPumpapplianceItemSuccess = createAction(
    '[Heat Pump Types Tab] Delete Heat Pump appliance Item from backend success',
    props<{ id: string }>()
);
export const deleteHeatPumpApplianceItemFailure = createAction(
    '[Heat Pump Types Tab] Delete Heat Pump appliance Item from backend failure',
    props<{ error: string | null }>()
);

/* Clear Appliance items */
export const logout = createAction('[Header] Logout');
export const clearHeatPumpTypesData = createAction(
    '[Heat Pump Types Tab] Clear Heat Pump types data'
);
