import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    AdminApiService,
    ApplianceItemArchiveEntity,
    ApplianceTypeEntity,
    GeneralApiService
} from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as HeatPumpActions from './heat-pump.actions';
import { getHeatPumpApplianceTypes } from './heat-pump.actions';

@Injectable()
export class HeatPumpEffects {
    constructor(
        private actions$: Actions,
        public adminApiService: AdminApiService,
        public generalApiService: GeneralApiService,
        private readonly router: Router
    ) {}

    AdminGetHeatPumpApplianceTypes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpActions.getHeatPumpApplianceTypes),
            switchMap(() => {
                return this.generalApiService
                    .getHeatPumpApplianceTypes(/* { body: { queryDate: date } } */)
                    .pipe(
                        map((typeData: ApplianceTypeEntity[]) => {
                            return HeatPumpActions.getHeatPumpApplianceTypesSuccess({
                                heatPumpApplianceTypes: typeData
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                HeatPumpActions.getHeatPumpApplianceTypesFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    AdminGetHeatPumpApplianceTypeArchive$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpActions.getHeatPumpApplianceTypeArchive),
            switchMap(({ id }) => {
                return this.adminApiService.getRenewableApplianceItemHistory({ id }).pipe(
                    map((heatPumpApplianceTypeArchives: ApplianceItemArchiveEntity[]) => {
                        return HeatPumpActions.getHeatPumpApplianceTypeArchiveSuccess({
                            id,
                            heatPumpApplianceTypeArchives
                        });
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            HeatPumpActions.getHeatPumpApplianceTypeArchiveFailure({
                                error: error.error.message
                            })
                        );
                    })
                );
            })
        )
    );

    AdminEditHeatPumpApplianceItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpActions.editHeatPumpApplianceItem),
            switchMap(({ id, name, value, labourCost, nameTranslation }) => {
                return this.adminApiService
                    .updateRenewableApplianceItem({
                        id,
                        body: {
                            name,
                            value,
                            labourCost,
                            nameTranslation: {
                                hu: nameTranslation.hu,
                                en: nameTranslation.en
                            }
                        }
                    })
                    .pipe(
                        map((newValueData) => {
                            return HeatPumpActions.editHeatPumpApplianceItemSuccess({
                                id,
                                name: newValueData.name,
                                value: newValueData.value
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                HeatPumpActions.editHeatPumpApplianceItemFailure({
                                    error: error.error.message
                                })
                            );
                        })
                    );
            })
        )
    );

    editditHeatPumpApplianceItemSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpActions.editHeatPumpApplianceItemSuccess),
            switchMap((action) => of(getHeatPumpApplianceTypes({ date: null })))
        )
    );

    SaveHeatPumpApplianceItemAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpActions.saveHeatPumpApplianceItem),
            switchMap(({ id, name, value, labourCost, nameTranslation }) => {
                return this.adminApiService
                    .createHeatPumpApplianceItem({
                        body: {
                            name,
                            value,
                            typeId: id,
                            labourCost,
                            nameTranslation: {
                                hu: nameTranslation.hu,
                                en: nameTranslation.en
                            }
                        }
                    })
                    .pipe(
                        map((applianceTypeData: ApplianceTypeEntity[]) =>
                            HeatPumpActions.getHeatPumpApplianceTypesSuccess({
                                heatPumpApplianceTypes: applianceTypeData
                            })
                        ),
                        catchError((error: HttpErrorResponse) =>
                            of(
                                HeatPumpActions.saveHeatPumpApplianceItemFailure({
                                    error: error.error.message
                                })
                            )
                        )
                    );
            })
        )
    );

    DeleteHeatPumpApplianceItemAdmin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpActions.deleteHeatPumpApplianceItem),
            switchMap(({ id }) =>
                this.adminApiService.deleteRenewableApplianceItem({ id }).pipe(
                    map((newValueData) =>
                        HeatPumpActions.deleteHeatPumpapplianceItemSuccess({
                            id
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(
                            HeatPumpActions.deleteHeatPumpApplianceItemFailure({
                                error: error.error.message
                            })
                        )
                    )
                )
            )
        )
    );

    deleteHeatPumpApplianceItemSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpActions.deleteHeatPumpapplianceItemSuccess),
            switchMap((action) => of(getHeatPumpApplianceTypes({ date: null })))
        )
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HeatPumpActions.logout),
            map(() => HeatPumpActions.clearHeatPumpTypesData())
        )
    );
}
