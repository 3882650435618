import { createAction, props } from '@ngrx/store';
import { CurrencyEntity, RoleResponseDto, SubscriptionTypeEntity } from 'app/api';

export const getCurrencies = createAction('[Base] Get currencies from backend');
export const getCurrenciesSuccess = createAction(
    '[Base] Get currencies from backend success',
    props<{ currencies: CurrencyEntity[] }>()
);
export const getCurrenciesFailure = createAction(
    '[Base] Get currencies from backend failure',
    props<{ error: string | null }>()
);

export const getRoles = createAction('[Base] Roles get from backend');
export const getRolesSuccess = createAction(
    '[Base] Roles get from backend success',
    props<{ roles: RoleResponseDto[] }>()
);
export const getRolesFailure = createAction(
    '[Base] Roles get from backend failure',
    props<{ error: string | null }>()
);

export const getSubscriptionTypeInfo = createAction(
    '[Base] Subscription Type Info get from backend'
);
export const getSubscriptionTypeInfoSuccess = createAction(
    '[Base] Subscription Type Info get from backend success',
    props<{ subscriptionTypeInfo: Array<SubscriptionTypeEntity> }>()
);
export const getSubscriptionTypeInfoFailure = createAction(
    '[Base] Subscription Type Info get from backend failure',
    props<{ error: string | null }>()
);

export const clearBaseData = createAction('[Base] Clear base data');
