export enum AdminTab {
    BuildingAndSuperStructureFunctions = 'buildingAndSuperStructureFunctions',
    ItemsDividedIntoQualityCategories = 'itemsDividedIntoQualityCategories',
    QualityCategoriesWithFunctions = 'qualityCategoriesWithFunctions',
    ElementWithMultiplierEffect = 'elementWithMultiplierEffectTab',
    Usermanagement = 'usermanagement',
    UserAnalytics = 'userAnalytics',
    ProducerAnalytics = 'producerAnalytics',
    ProducerManagement = 'producerManagement',
    ProjectAnalytics = 'projectAnalytics'
}
