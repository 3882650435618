import { createReducer, on } from '@ngrx/store';
import {
    clearLandImprovementTypesData,
    editLandImprovementTypes,
    editLandImprovementTypesFailure,
    editLandImprovementTypesSuccess,
    getLandImprovementTypeArchive,
    getLandImprovementTypeArchiveFailure,
    getLandImprovementTypeArchiveSuccess,
    getLandImprovementTypes,
    getLandImprovementTypesFailure,
    getLandImprovementTypesSuccess
} from './land-improvement-types.actions';
import { LandImprovementTypesState } from './land-improvement-types.state';

export const initialState: LandImprovementTypesState = {
    landImprovementTypes: [],
    landImprovementTypesInProgress: false,
    landImprovementTypesError: null,
    landImprovementTypeArchives: [],
    landImprovementTypeArchivesInProgress: false,
    landImprovementTypeArchivesError: null
};

export const landImprovementTypesReducer = createReducer(
    initialState,
    on(getLandImprovementTypes, (state) => ({
        ...state,
        landImprovementTypesInProgress: true,
        landImprovementTypesError: null
    })),
    on(getLandImprovementTypesSuccess, (state, { landImprovementTypes }) => ({
        ...state,
        landImprovementTypes,
        landImprovementTypesInProgress: false,
        landImprovementTypesError: null
    })),
    on(getLandImprovementTypesFailure, (state, { error }) => ({
        ...state,
        landImprovementTypesInProgress: false,
        landImprovementTypesError: error
    })),

    on(getLandImprovementTypeArchive, (state, { id }) => ({
        ...state,
        landImprovementTypeArchiveInProgress: true,
        landImprovementTypeArchiveError: null
    })),
    on(getLandImprovementTypeArchiveSuccess, (state, { landImprovementTypeArchives }) => ({
        ...state,
        landImprovementTypeArchives,
        landImprovementTypesInProgress: false,
        landImprovementTypeArchiveError: null
    })),
    on(getLandImprovementTypeArchiveFailure, (state, { error }) => ({
        ...state,
        landImprovementTypesInProgress: false,
        landImprovementTypeArchiveError: error
    })),
    on(editLandImprovementTypes, (state) => ({
        ...state,
        landImprovementTypesInProgress: true,
        landImprovementTypesError: null
    })),
    on(editLandImprovementTypesSuccess, (state, { id, categoryName, multiplier }) => ({
        ...state,
        /* id,
        categoryName, */
        landImprovementTypesInProgress: false,
        landImprovementTypesError: null
    })),
    on(editLandImprovementTypesFailure, (state, { error }) => ({
        ...state,
        landImprovementTypesInProgress: false,
        landImprovementTypesError: error
    })),
    on(clearLandImprovementTypesData, (state) => ({
        ...initialState
    }))
);
