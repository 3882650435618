import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthApiService, UserApiService } from 'app/api';
import { RouteSegment } from 'app/enums/route-segments.enum';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as UserActions from './user.actions';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private authApiService: AuthApiService,
        private userApiService: UserApiService,
        private readonly router: Router
    ) {}

    userLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.userLogin),
            switchMap(({ email, password }) => {
                return this.authApiService
                    .login({
                        body: { email, password }
                    })
                    .pipe(
                        map((data) => {
                            return UserActions.userLoginSuccess({
                                accessToken: data.access_token
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(UserActions.userLoginFailure({ error: error.error.message }));
                        })
                    );
            })
        )
    );

    getUserAfterLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.userLoginSuccess),
            map(() => {
                return UserActions.getUser({
                    automaticLogin: false
                });
            })
        )
    );

    /* userRegister$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.userRegister),
            switchMap(
                ({
                    email,
                    firstName,
                    lastName,
                    newsLetter,
                    company,
                    telephone,
                    taxNumber,
                    password,
                    password_confirm,
                    roleId
                }) => {
                    return this.authApiService
                        .registrationWithEmailVerification({
                            body: {
                                accountType: AccountType.Trial,
                                email,
                                firstName,
                                lastName,
                                newsLetter,
                                company,
                                telephone,
                                taxNumber,
                                password,
                                password_confirm,
                                roleId
                            }
                        })
                        .pipe(
                            map(() => {
                                return UserActions.userRegisterSuccess();
                            }),
                            catchError((error: HttpErrorResponse) => {
                                return of(
                                    UserActions.userRegisterFailure({
                                        error: JSON.parse(error.error).message[0]
                                    })
                                );
                            })
                        );
                }
            )
        )
    ); */

    getUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.getUser),
            switchMap(({ automaticLogin }) => {
                return this.userApiService.getCurrentUser().pipe(
                    map((data) =>
                        UserActions.getUserSuccess({
                            user: data,
                            automaticLogin
                        })
                    ),
                    catchError((error: HttpErrorResponse) =>
                        of(UserActions.getUserFailure({ error: error.error.message }))
                    )
                );
            })
        )
    );

    public authenticateSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(UserActions.getUserSuccess),
                map(({ automaticLogin }) => {
                    if (!automaticLogin) {
                        this.router.navigate([RouteSegment.Dashboard]);
                    }
                })
            );
        },
        {
            dispatch: false
        }
    );

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.logout),
            map(() => UserActions.clearUser()),
            tap(() => {
                this.router.navigate([RouteSegment.Root]);
            })
        )
    );

    updateProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.updateProfile),
            switchMap(({ profile }) => {
                return this.userApiService
                    .updateProfile({
                        body: {
                            active: true,
                            ...profile
                        }
                    })
                    .pipe(
                        map((data) => {
                            return UserActions.updateProfileSuccess({
                                user: data
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                UserActions.updateProfileFailure({ error: error.error.message })
                            );
                        })
                    );
            })
        )
    );

    trialUserRegister$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.trialUserRegister),
            switchMap(
                ({
                    email,
                    firstName,
                    lastName,
                    newsLetter,
                    company,
                    telephone,
                    taxNumber,
                    city,
                    street,
                    streetNo,
                    zipCode,
                    roleId,
                    password,
                    password_confirm,
                    accountType
                }) => {
                    return this.authApiService
                        .registrationWithEmailVerification({
                            body: {
                                email,
                                firstName,
                                lastName,
                                newsLetter,
                                company,
                                telephone,
                                city,
                                street,
                                streetNo,
                                zipCode,
                                taxNumber,
                                roleId,
                                password,
                                password_confirm,
                                accountType
                            }
                        })
                        .pipe(
                            map(() => {
                                return UserActions.trialUserRegisterSuccess();
                            }),
                            catchError((error: HttpErrorResponse) => {
                                return of(
                                    UserActions.trialUserRegisterFailure({
                                        error: JSON.parse(error.error).message[0]
                                    })
                                );
                            })
                        );
                }
            )
        )
    );
}
