import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    AdminApiService,
    ApplianceSimpleEntity,
    GeneralApiService,
    WorkTypeWithFunctionAppliancesEntity
} from 'app/api';
import { catchError, map, of, switchMap } from 'rxjs';
import * as QualityCategoriesWithFunctionsActions from './quality-categories-with-functions.actions';

@Injectable()
export class QualityCategoriesWithFunctionsEffects {
    constructor(
        private actions$: Actions,
        public adminApiService: AdminApiService,
        public generalApiService: GeneralApiService,
        private readonly router: Router
    ) {}

    AdminGetQualityCategoriesWithFunctions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QualityCategoriesWithFunctionsActions.getQualityCategoriesWithFunctions),
            switchMap(() => {
                return this.adminApiService.getFunctionWorkTypes().pipe(
                    map((typeData: WorkTypeWithFunctionAppliancesEntity[]) => {
                        return QualityCategoriesWithFunctionsActions.getQualityCategoriesWithFunctionsSuccess(
                            {
                                qualityCategoriesWithFunctions: typeData
                            }
                        );
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            QualityCategoriesWithFunctionsActions.getQualityCategoriesWithFunctionsFailure(
                                {
                                    error: error.error.message
                                }
                            )
                        );
                    })
                );
            })
        )
    );
    /* All Categories */
    AdminGetAllQualityCategoriesWithFunctions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QualityCategoriesWithFunctionsActions.getAllQualityCategoriesWithFunctions),
            switchMap(() => {
                return this.generalApiService.getAppliances().pipe(
                    map((typeData: ApplianceSimpleEntity[]) => {
                        return QualityCategoriesWithFunctionsActions.getAllQualityCategoriesWithFunctionsSuccess(
                            {
                                allQualityCategoriesWithFunctions: typeData
                            }
                        );
                    }),
                    catchError((error: HttpErrorResponse) => {
                        return of(
                            QualityCategoriesWithFunctionsActions.getAllQualityCategoriesWithFunctionsFailure(
                                {
                                    error: error.error.message
                                }
                            )
                        );
                    })
                );
            })
        )
    );

    /* Selected Categories */
    AdminGetSelectedQualityCategoriesWithFunctions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QualityCategoriesWithFunctionsActions.getSelectedQualityCategoriesWithFunctions),
            switchMap(({ id, selectedQualityCategoriesWithFunctions }) => {
                return this.adminApiService
                    .updateWorkTypeFunction({
                        id,
                        body: { applianceIds: selectedQualityCategoriesWithFunctions }
                    })
                    .pipe(
                        map((typeData: WorkTypeWithFunctionAppliancesEntity[]) => {
                            return QualityCategoriesWithFunctionsActions.getSelectedQualityCategoriesWithFunctionsSuccess(
                                {
                                    qualityCategoriesWithFunctions: typeData
                                }
                            );
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                QualityCategoriesWithFunctionsActions.getSelectedQualityCategoriesWithFunctionsFailure(
                                    {
                                        error: error.error.message
                                    }
                                )
                            );
                        })
                    );
            })
        )
    );
}
