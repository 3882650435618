import { createReducer, on } from '@ngrx/store';
import {
    clearSalesManagementData,
    editSalesProductItem,
    editSalesProductItemFailure,
    editSalesProductItemSuccess,
    getSalesProducts,
    getSalesProductsFailure,
    getSalesProductsSuccess,
    getWorkTypeWithoutProductSuccess
} from './sales-management.actions';
import { SalesManagementState } from './sales-management.state';

export const initialState: SalesManagementState = {
    salesProduct: [],
    salesProductInProgress: false,
    salesProductError: null,
    workTypeWithoutProduct: []
};

export const salesManagementReducer = createReducer(
    initialState,
    on(getSalesProducts, (state) => ({
        ...state,
        salesProductInProgress: true,
        salesProductError: null
    })),
    on(getSalesProductsSuccess, (state, { salesProduct }) => ({
        ...state,
        salesProduct,
        salesProductInProgress: false,
        salesProductError: null
    })),
    on(getSalesProductsFailure, (state, { error }) => ({
        ...state,
        salesProductInProgress: false,
        salesProductError: error
    })),
    on(editSalesProductItem, (state) => ({
        ...state,
        salesProductInProgress: true,
        salesProductError: null
    })),
    on(editSalesProductItemSuccess, (state, { id, name, value }) => ({
        ...state,
        /* id,
        categoryName, */
        salesProductInProgress: false,
        salesProductError: null
    })),
    on(editSalesProductItemFailure, (state, { error }) => ({
        ...state,
        salesProductInProgress: false,
        salesProductError: error
    })),
    on(getWorkTypeWithoutProductSuccess, (state, { data }) => ({
        ...state,
        workTypeWithoutProduct: data
    })),
    on(clearSalesManagementData, (state) => ({
        ...initialState
    }))
);
