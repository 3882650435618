import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LanguageState } from './language.state';

export const selectLanguageState = createFeatureSelector<LanguageState>('language');

export const selectAvailableLanguages = createSelector(
    selectLanguageState,
    (state) => state.availableLanguages
);
export const selectActiveLanguageCode = createSelector(
    selectLanguageState,
    (state) => state.activeLanguageCode
);
