import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import * as PaymentPopupActions from './payment-popup.actions';

@Injectable()
export class PaymentPopupEffects {
    constructor(private actions$: Actions) {}

    public logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PaymentPopupActions.logout),
            map(() => PaymentPopupActions.clearPaymentPopupStore())
        )
    );
}
