/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseApiService } from '../base-api-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FilterProfilesDto } from '../models/filter-profiles-dto';
import { ProfileUserEntity } from '../models/profile-user-entity';
import { ProfileUserListEntity } from '../models/profile-user-list-entity';

@Injectable({
    providedIn: 'root'
})
export class ArchitectProfileApiService extends BaseApiService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Path part for operation filterArchitectProfiles
     */
    static readonly FilterArchitectProfilesPath = '/architect-profile';

    /**
     * Filters all active architect profiles, paginated, sorted and filtered.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `filterArchitectProfiles()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterArchitectProfiles$Response(params: {
        context?: HttpContext;
        body: FilterProfilesDto;
    }): Observable<
        StrictHttpResponse<{
            total?: number;
            page?: number;
            perPage?: number;
            data?: Array<ProfileUserListEntity>;
        }>
    > {
        const rb = new RequestBuilder(
            this.rootUrl,
            ArchitectProfileApiService.FilterArchitectProfilesPath,
            'post'
        );
        if (params) {
            rb.body(params.body, 'application/json');
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<{
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<ProfileUserListEntity>;
                    }>;
                })
            );
    }

    /**
     * Filters all active architect profiles, paginated, sorted and filtered.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `filterArchitectProfiles$Response()` instead.
     *
     * This method sends `application/json` and handles request body of type `application/json`.
     */
    filterArchitectProfiles(params: {
        context?: HttpContext;
        body: FilterProfilesDto;
    }): Observable<{
        total?: number;
        page?: number;
        perPage?: number;
        data?: Array<ProfileUserListEntity>;
    }> {
        return this.filterArchitectProfiles$Response(params).pipe(
            map(
                (
                    r: StrictHttpResponse<{
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<ProfileUserListEntity>;
                    }>
                ) =>
                    r.body as {
                        total?: number;
                        page?: number;
                        perPage?: number;
                        data?: Array<ProfileUserListEntity>;
                    }
            )
        );
    }

    /**
     * Path part for operation getArchitectProfileByUser
     */
    static readonly GetArchitectProfileByUserPath = '/architect-profile/user/{id}';

    /**
     * Returns a user's architect profile by user id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getArchitectProfileByUser()` instead.
     *
     * This method doesn't expect any request body.
     */
    getArchitectProfileByUser$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<ProfileUserEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            ArchitectProfileApiService.GetArchitectProfileByUserPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<ProfileUserEntity>;
                })
            );
    }

    /**
     * Returns a user's architect profile by user id.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getArchitectProfileByUser$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getArchitectProfileByUser(params: {
        id: string;
        context?: HttpContext;
    }): Observable<ProfileUserEntity> {
        return this.getArchitectProfileByUser$Response(params).pipe(
            map((r: StrictHttpResponse<ProfileUserEntity>) => r.body as ProfileUserEntity)
        );
    }

    /**
     * Path part for operation getArchitectProfileByProfile
     */
    static readonly GetArchitectProfileByProfilePath = '/architect-profile/profile/{id}';

    /**
     * Returns a user's architect profile by profile id.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getArchitectProfileByProfile()` instead.
     *
     * This method doesn't expect any request body.
     */
    getArchitectProfileByProfile$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<ProfileUserEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            ArchitectProfileApiService.GetArchitectProfileByProfilePath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<ProfileUserEntity>;
                })
            );
    }

    /**
     * Returns a user's architect profile by profile id.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getArchitectProfileByProfile$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getArchitectProfileByProfile(params: {
        id: string;
        context?: HttpContext;
    }): Observable<ProfileUserEntity> {
        return this.getArchitectProfileByProfile$Response(params).pipe(
            map((r: StrictHttpResponse<ProfileUserEntity>) => r.body as ProfileUserEntity)
        );
    }
}
