import { createAction, props } from '@ngrx/store';
import { TypeValueArchiveEntity, UpdateValueDto } from 'app/api';

export const getTypeValues = createAction(
    '[Item Management Page] Get current types from backend',
    props<{ date: string | null }>()
);

export const getTypeValuesSuccess = createAction(
    '[Item Management Page] Get current types from backend success',
    props<{ typeValues: Array<TypeValueArchiveEntity> }>()
);

export const getTypeValuesFailure = createAction(
    '[Item Management Page] Get current types from backend failure',
    props<{ error: string | null }>()
);

export const getTypeValueArchive = createAction(
    '[Item Management Page] Get current types value archives from backend',
    props<{ id: string }>()
);

export const getTypeValueArchiveSuccess = createAction(
    '[Item Management Page] Get current types value archives from backend success',
    props<{ id: string; typeValueArchives: Array<TypeValueArchiveEntity> }>()
);

export const getTypeValueArchiveFailure = createAction(
    '[Item Management Page] Get current types value archives from backend failure',
    props<{ error: string | null }>()
);

export const editTypeValues = createAction(
    '[Item Management Page] Edit current value from backend',
    props<{ id: string; value: number; grossHourlyRate: number; labourCost: number }>()
);

export const editTypeValuesSuccess = createAction(
    '[Item Management Page] Edit current value from backend success',
    props<{ id: string; value: UpdateValueDto; grossHourlyRate: number; labourCost: number }>()
);

export const editTypeValuesFailure = createAction(
    '[Item Management Page] Edit current value from backend failure',
    props<{ error: string | null }>()
);

export const logout = createAction('[Header] Logout');
export const clearItemManagementData = createAction(
    '[Item Management Page] Clear item management data'
);
