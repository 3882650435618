<ng-container *ngIf="summaryTableService.summaryTableComponent">
    <ng-container
        *ngLet="element.id === summaryTableService.summaryTableComponent.editedRow?.id as isEdited"
    >
        <ng-container *ngIf="isEdited; else costInfoTpl">
            <mat-icon
                [matTooltip]="summaryTableService.summaryTableComponent.editErrors?.['numericFieldExclusion'] ?? ''"
                matTooltipPosition="right"
                [matTooltipShowDelay]="500"
            >
                settings
            </mat-icon>
        </ng-container>

        <ng-template #costInfoTpl>
            <ng-container *ngIf="!element.isCategory; else categoryToggleIconTpl">
                <img
                    [src]="
                        element.modified
                            ? 'assets/imgs/icons/danger.png'
                            : element.shadowed
                            ? 'assets/imgs/icons/hidden.png'
                            : 'assets/imgs/icons/keystone-logo.svg'
                    "
                    [matTooltip]="
                        element.modified
                            ? ('project-creation.table.row-values-changed' | transloco)
                            : element.shadowed
                            ? ('project-creation.table.row-values-not-included' | transloco)
                            : ('project-creation.table.row-values-calculated-by-capstone'
                              | transloco)
                    "
                    matTooltipPosition="right"
                    [matTooltipShowDelay]="500"
                    class="h-[24px] w-[24px]"
                />
            </ng-container>
        </ng-template>

        <ng-template #categoryToggleIconTpl>
            <ng-container
                *ngLet="{
                    entriesForCategory: element.workType | invoke : $any(getEntriesFor),
                    subCategories: element.workType | invoke : $any(getSubcategoriesFor)
                } as ctx"
            >
                <ng-container *ngLet="element.workType as categoryToUse">
                    <button
                        *ngIf="
                            $any(ctx?.entriesForCategory)?.length > 0 ||
                            $any(ctx?.subCategories)?.length > 0
                        "
                        mat-icon-button
                        [matTooltip]="
                            summaryTableService.summaryTableComponent.toggledCategories.has(
                                element.version
                            )
                                ? ('project-creation.table.hide-category-entries' | transloco)
                                : ('project-creation.table.show-category-entries' | transloco)
                        "
                        matTooltipPosition="right"
                        [matTooltipShowDelay]="500"
                        (click)="summaryTableService.toggleCategory(element)"
                    >
                        <mat-icon>
                            {{
                                !summaryTableService.summaryTableComponent.toggledCategories.has(
                                    element.version
                                )
                                    ? 'expand_more'
                                    : 'expand_less'
                            }}
                        </mat-icon>
                    </button>
                </ng-container>
            </ng-container>
        </ng-template>
    </ng-container>
</ng-container>
