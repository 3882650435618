<ng-container *ngIf="summaryTableService.summaryTableComponent">
    <div
        *ngLet="element.id === summaryTableService.summaryTableComponent.editedRow?.id as isEdited"
        class="flex w-full justify-start pl-2"
    >
        <ng-container
            *ngIf="
                !(
                    element.isCategory ||
                    element.shadowed ||
                    !isEdited ||
                    summaryTableService.summaryTableComponent.readonly
                );
                else showNameValue
            "
        >
            <input
                type="text"
                [disabled]="
                    element.isCategory ||
                    element.shadowed ||
                    !isEdited ||
                    summaryTableService.summaryTableComponent.readonly
                "
                [value]="element.name"
                (input)="onEditCell($any($event.target).value, 'name', element)"
                class="w-full text-left"
            />
        </ng-container>
        <ng-template #showNameValue>
            <div
                class="flex w-full justify-start pl-2"
                [class.font-bold]="!element.minor && element.isCategory"
            >
                {{
                    element.nameTranslation
                        ? (element.nameTranslation | stringMessageTranslation)
                        : element.name
                }}
            </div>
        </ng-template>

        <mat-icon
            *ngIf="isEdited && summaryTableService.summaryTableComponent.editErrors?.['name']"
            [matTooltip]="'project-creation.table.incorrect-work-entry-name' | transloco"
        >
            info
        </mat-icon>
    </div>
    <div *ngIf="element.function" class="w-full text-center text-3xs italic">
        {{ element.function?.name }}
    </div>
</ng-container>
