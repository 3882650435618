import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { RouteSegment } from 'app/enums/route-segments.enum';
import { selectEmail } from 'app/store/user/user.selectors';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public canActivate(): Observable<boolean | UrlTree> {
        return this.checkAuthentication();
    }

    public canActivateChild(): Observable<boolean | UrlTree> {
        return this.checkAuthentication();
    }

    public canLoad(): Observable<boolean | UrlTree> {
        return this.checkAuthentication();
    }

    private checkAuthentication(): Observable<boolean | UrlTree> {
        return this.store.select(selectEmail).pipe(
            map((email) => {
                if (!!email) {
                    return true;
                } else {
                    return this.router.createUrlTree([RouteSegment.Login]);
                }
            })
        );
    }
}
