/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { UserApiService } from './services/user-api.service';
import { ArchitectProfileApiService } from './services/architect-profile-api.service';
import { SupplierApiService } from './services/supplier-api.service';
import { AuthApiService } from './services/auth-api.service';
import { AssetApiService } from './services/asset-api.service';
import { GeneralApiService } from './services/general-api.service';
import { ProjectApiService } from './services/project-api.service';
import { DraftPhaseApiService } from './services/draft-phase-api.service';
import { AdminApiService } from './services/admin-api.service';
import { BarionApiService } from './services/barion-api.service';
import { ApiApiService } from './services/api-api.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        UserApiService,
        ArchitectProfileApiService,
        SupplierApiService,
        AuthApiService,
        AssetApiService,
        GeneralApiService,
        ProjectApiService,
        DraftPhaseApiService,
        AdminApiService,
        BarionApiService,
        ApiApiService,
        ApiConfiguration
    ]
})
export class ApiModule {
    static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [
                {
                    provide: ApiConfiguration,
                    useValue: params
                }
            ]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error(
                'You need to import the HttpClientModule in your AppModule! \n' +
                    'See also https://github.com/angular/angular/issues/20575'
            );
        }
    }
}
