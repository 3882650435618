import { createAction, props } from '@ngrx/store';
import {
    RoleUserStatEntity,
    StatQueryDto,
    StructurePopularityStatEntity,
    SupplierPopularityStatEntity,
    SupplierStructureStatEntity
} from 'app/api';

export const adminGetProducerStats = createAction(
    '[Admin Producer Analytics Page] Get Producer statistics from backend',
    props<StatQueryDto>()
);

export const adminGetProducerStatsSuccess = createAction(
    '[Admin Producer Analytics Page] Get Producer statistics from backend success',
    props<{ producerStats: Array<SupplierStructureStatEntity> }>()
);

export const adminGetProducerStatsFailure = createAction(
    '[Admin Producer Analytics Page] Get Producer statistics from backend failure',
    props<{ error: string | null }>()
);

export const adminGetUserRoleStatistics = createAction(
    '[Admin User Analytics Page] Get user role statistics from backend'
);

export const adminGetUserRoleStatisticsSuccess = createAction(
    '[Admin User Analytics Page] Get user role statistics from backend success',
    props<{ roleUserStat: Array<RoleUserStatEntity> }>()
);

export const adminGetUserRoleStatisticsFailure = createAction(
    '[Admin User Analytics Page] Get user role statistics from backend failure',
    props<{ error: string | null }>()
);

/* Supplier popularity statistics */
export const adminGetSupplierPopularityStatistics = createAction(
    '[Admin Producer Analytics Page] Get popular supplier statistics from backend',
    props<StatQueryDto>()
);

export const adminGetSupplierPopularityStatisticsSuccess = createAction(
    '[Admin Producer Analytics Page] Get popular supplier statistics from backend success',
    props<{ SupplierPopularityStatistics: Array<SupplierPopularityStatEntity> }>()
);

export const adminGetSupplierPopularityStatisticsFailure = createAction(
    '[Admin Producer Analytics Page] Get popular supplier statistics from backend failure',
    props<{ error: string | null }>()
);

/* Structure popularity statistics */
export const adminGetStructurePopularityStatistics = createAction(
    '[Admin Producer Analytics Page] Get popular structure statistics from backend',
    props<StatQueryDto>()
);

export const adminGetStructurePopularityStatisticsSuccess = createAction(
    '[Admin Producer Analytics Page] Get popular structure statistics from backend success',
    props<{ structurePopularityStatistics: Array<StructurePopularityStatEntity> }>()
);

export const adminGetStructurePopularityStatisticsFailure = createAction(
    '[Admin Producer Analytics Page] Get popular structure statistics from backend failure',
    props<{ error: string | null }>()
);
