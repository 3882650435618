/* tslint:disable */
/* eslint-disable */
export interface IUpsertFeatureDto {
    id: string;
    name: string;
    nameTranslation: {};
}
export class UpsertFeatureDto implements IUpsertFeatureDto {
    id!: string;
    name!: string;
    nameTranslation!: {};
    constructor(data: IUpsertFeatureDto) {
        Object.assign(this, data);
    }
}
