import { createAction, props } from '@ngrx/store';
import {
    BuildMethodStatEntity,
    DesignBudgetCategoryStatEntity,
    GeoRegionStatDetailsEntity,
    PlotTypeStatEntity,
    RenewableStatEntity,
    RoleUserStatEntity,
    StatQueryDto,
    SubscriptionTypeUserStatEntity,
    UserProjectStatEntity
} from 'app/api';

/* User analytics */
export const adminGetUserProjectStats = createAction(
    '[Admin User Analytics Page] Get user statistics from backend',
    props<StatQueryDto>()
);

export const adminGetUserProjectStatsSuccess = createAction(
    '[Admin User Analytics Page] Get user statistics from backend success',
    props<{ userProjectStats: Array<UserProjectStatEntity> }>()
);

export const adminGetUserProjectStatsFailure = createAction(
    '[Admin User Analytics Page] Get user statistics from backend failure',
    props<{ error: string | null }>()
);

/* User role statistics */
export const adminGetUserRoleStatistics = createAction(
    '[Admin User Analytics Page] Get user role statistics from backend'
);

export const adminGetUserRoleStatisticsSuccess = createAction(
    '[Admin User Analytics Page] Get user role statistics from backend success',
    props<{ roleUserStat: Array<RoleUserStatEntity> }>()
);

export const adminGetUserRoleStatisticsFailure = createAction(
    '[Admin User Analytics Page] Get user role statistics from backend failure',
    props<{ error: string | null }>()
);

/* User subscription statistics */
export const adminGetUserSubscriptionStatistics = createAction(
    '[Admin User Analytics Page] Get user subscription statistics from backend'
);

export const adminGetUserSubscriptionStatisticsSuccess = createAction(
    '[Admin User Analytics Page] Get user subscription statistics from backend success',
    props<{ subscriptionUserStat: Array<SubscriptionTypeUserStatEntity> }>()
);

export const adminGetUserSubscriptionStatisticsFailure = createAction(
    '[Admin User Analytics Page] Get user subscription statistics from backend failure',
    props<{ error: string | null }>()
);

/* Draftphases renewable energy statistics */
export const adminGetRenewableEnergeticsStatistics = createAction(
    '[Admin User Analytics Page] Get renewable energetics statistics from backend',
    props<StatQueryDto>()
);

export const adminGetRenewableEnergeticsStatisticsSuccess = createAction(
    '[Admin User Analytics Page] Get renewable energetics statistics from backend success',
    props<{ draftphaseRenewableEnergyStat: RenewableStatEntity }>()
);

export const adminGetRenewableEnergeticsStatisticsFailure = createAction(
    '[Admin User Analytics Page] Get renewable energetics statistics from backend failure',
    props<{ error: string | null }>()
);

/* Draftphases plot types statistics */
export const adminGetPlotTypesStatistics = createAction(
    '[Admin User Analytics Page] Get plot types statistics from backend',
    props<StatQueryDto>()
);

export const adminGetPlotTypesStatisticsSuccess = createAction(
    '[Admin User Analytics Page] Get plot types statistics from backend success',
    props<{ draftphasePlotTypesStat: PlotTypeStatEntity[] }>()
);

export const adminGetPlotTypesStatisticsFailure = createAction(
    '[Admin User Analytics Page] Get plot types statistics from backend failure',
    props<{ error: string | null }>()
);

/* Draftphases build methods statistics */
export const adminGetBuildMethodsStatistics = createAction(
    '[Admin User Analytics Page] Get build methods statistics from backend',
    props<StatQueryDto>()
);

export const adminGetBuildMethodsStatisticsSuccess = createAction(
    '[Admin User Analytics Page] Get build methods statistics from backend success',
    props<{ draftphaseBuildMethodsStat: BuildMethodStatEntity[] }>()
);

export const adminGetBuildMethodsStatisticsFailure = createAction(
    '[Admin User Analytics Page] Get build methods statistics from backend failure',
    props<{ error: string | null }>()
);

/* Draftphases design budgets statistics */
export const adminGetDesignBudgetstatistics = createAction(
    '[Admin User Analytics Page] Get design budgets statistics from backend',
    props<StatQueryDto>()
);

export const adminGetDesignBudgetstatisticsSuccess = createAction(
    '[Admin User Analytics Page] Get design budgets statistics from backend success',
    props<{ draftphaseDesignBudgetsStat: DesignBudgetCategoryStatEntity[] }>()
);

export const adminGetDesignBudgetstatisticsFailure = createAction(
    '[Admin User Analytics Page] Get design budgets statistics from backend failure',
    props<{ error: string | null }>()
);

/* Geo region project details statistics */
export const adminGetGeoRegionProjectDetailsStatistics = createAction(
    '[Admin User Analytics Page] Get geo region project details statistics from backend',
    props<StatQueryDto>()
);

export const adminGetGeoRegionProjectDetailsStatisticsSuccess = createAction(
    '[Admin User Analytics Page] Get geo region project details statistics from backend success',
    props<{ geoRegionProjectDetailsStat: GeoRegionStatDetailsEntity }>()
);

export const adminGetGeoRegionProjectDetailsStatisticsFailure = createAction(
    '[Admin User Analytics Page] Get geo region project details statistics from backend failure',
    props<{ error: string | null }>()
);
