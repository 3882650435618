import { AdroitNgUtilsModule } from '@adroit-group/ng-utils';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { AdminHeaderTabComponent } from './components/admin-header-tab/admin-header-tab.component';
import { ApplianceSliderComponent } from './components/appliance-slider/appliance-slider.component';
import { ButtonDropdownComponent } from './components/button-dropdown/button-dropdown.component';
import { ChartComponent } from './components/chart/chart.component';
import { FileUploadDropzoneComponent } from './components/file-upload-dropzone/file-upload-dropzone.component';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { GeoRegioSelectComponent } from './components/geo-regio-select/geo-regio-select.component';
import { HeaderDropdownComponent } from './components/header-dropdown/header-dropdown.component';
import { HeaderComponent } from './components/header/header.component';
import { InlineSelectComponent } from './components/inline-select/inline-select.component';
import { MenuSideNavComponent } from './components/menu-side-nav/menu-side-nav.component';
import { PaymentPopupComponent } from './components/payment-popup/payment-popup.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RegionMapComponent } from './components/region-map/region-map.component';
import { ColumnActionsComponent } from './components/summary-table/components/column-actions/column-actions.component';
import { ColumnAmountComponent } from './components/summary-table/components/column-amount/column-amount.component';
import { ColumnCostInfoComponent } from './components/summary-table/components/column-cost-info/column-cost-info.component';
import { ColumnLabourCostComponent } from './components/summary-table/components/column-labour-cost/column-labour-cost.component';
import { ColumnLabourUnitPriceComponent } from './components/summary-table/components/column-labour-unit-price/column-labour-unit-price.component';
import { ColumnMaterialCostComponent } from './components/summary-table/components/column-material-cost/column-material-cost.component';
import { ColumnMaterialUnitPriceComponent } from './components/summary-table/components/column-material-unit-price/column-material-unit-price.component';
import { ColumnNameComponent } from './components/summary-table/components/column-name/column-name.component';
import { ColumnShadowedComponent } from './components/summary-table/components/column-shadowed/column-shadowed.component';
import { ColumnTotalCostComponent } from './components/summary-table/components/column-total-cost/column-total-cost.component';
import { SummaryTableComponent } from './components/summary-table/summary-table.component';
import { ToggleButtonsBarComponent } from './components/toggle-buttons-bar/toggle-buttons-bar.component';
import { NgxDropzoneImagePreviewOnPushFixerDirective } from './directives/ngx-dropzone-image-preview-on-push-fixer.directive';
import { TableFooterRowAnchorDirective } from './directives/table-footer-row-anchor.directive';
import { AppCurrencyPipe } from './pipes/currency.pipe';
import { ObjectTranslationPipe } from './pipes/object-translation.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { StringMessageTranslationPipe } from './pipes/string-message-translation.pipe';
import { TotalCostFormatting } from './pipes/totalcost-formatting.pipe';
import { TranslateProjectTypePipe } from './pipes/translate-project-type.pipe';
import { LetModule } from '@ngrx/component';
import { SupplierProductSelectorDialogComponent } from './components/building-structure-supplier-product/supplier-product-selector-dialog/supplier-product-selector-dialog.component';
import { SupplierProductInfoComponent } from './components/building-structure-supplier-product/supplier-product-info/supplier-product-info.component';
import { BuildingStructureSelectorComponent } from './components/building-structure-supplier-product/building-structure-selector/building-structure-selector.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SupplierProductEditorComponent } from './components/building-structure-supplier-product/supplier-product-editor/supplier-product-editor.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { SupplierProductEditorDialogComponent } from './components/building-structure-supplier-product/supplier-product-editor-dialog/supplier-product-editor-dialog.component';
import { RecommendedItemComponent } from './components/building-structure-supplier-product/recommended-item/recommended-item.component';
import {
    LucideAngularModule,
    LayoutPanelTop,
    TreeDeciduous,
    BrickWall,
    Container,
    Fence,
    Landmark
} from 'lucide-angular';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
    imports: [
        CommonModule,
        AdroitNgUtilsModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSidenavModule,
        MatSelectModule,
        MatIconModule,
        MatTooltipModule,
        NgxDropzoneModule,
        MatTableModule,
        MatButtonModule,
        MatCardModule,
        MatRadioModule,
        MatTabsModule,
        MatExpansionModule,
        MatMenuModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatDividerModule,
        MatListModule,
        MatSliderModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        LetModule,
        LucideAngularModule.pick({
            LayoutPanelTop,
            TreeDeciduous,
            BrickWall,
            Container,
            Fence,
            Landmark
        })
    ],
    declarations: [
        MenuSideNavComponent,
        HeaderComponent,
        FileUploadDropzoneComponent,
        HeaderDropdownComponent,
        ButtonDropdownComponent,
        SummaryTableComponent,
        ChartComponent,
        RegionMapComponent,
        NgxDropzoneImagePreviewOnPushFixerDirective,
        FileViewerComponent,
        ToggleButtonsBarComponent,
        TableFooterRowAnchorDirective,
        TranslateProjectTypePipe,
        AppCurrencyPipe,
        StatusPipe,
        StringMessageTranslationPipe,
        ObjectTranslationPipe,
        ColumnActionsComponent,
        ColumnCostInfoComponent,
        ColumnShadowedComponent,
        ColumnNameComponent,
        ColumnAmountComponent,
        ColumnMaterialUnitPriceComponent,
        ColumnMaterialCostComponent,
        ColumnLabourUnitPriceComponent,
        ColumnLabourCostComponent,
        ColumnTotalCostComponent,
        AdminHeaderTabComponent,
        TotalCostFormatting,
        ProgressBarComponent,
        InlineSelectComponent,
        PaymentPopupComponent,
        GeoRegioSelectComponent,
        ApplianceSliderComponent,
        BuildingStructureSelectorComponent,
        SupplierProductSelectorDialogComponent,
        SupplierProductInfoComponent,
        SupplierProductEditorComponent,
        ImageUploadComponent,
        SupplierProductEditorDialogComponent,
        RecommendedItemComponent
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MenuSideNavComponent,
        TranslocoModule,
        MatSidenavModule,
        HeaderComponent,
        FileUploadDropzoneComponent,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTooltipModule,
        MatAutocompleteModule,
        HeaderDropdownComponent,
        ButtonDropdownComponent,
        NgxDropzoneModule,
        SummaryTableComponent,
        ChartComponent,
        RegionMapComponent,
        FileViewerComponent,
        ToggleButtonsBarComponent,
        TranslateProjectTypePipe,
        MatButtonModule,
        AppCurrencyPipe,
        StatusPipe,
        StringMessageTranslationPipe,
        ObjectTranslationPipe,
        MatCheckboxModule,
        MatCardModule,
        MatRadioModule,
        AdroitNgUtilsModule,
        MatTabsModule,
        AdminHeaderTabComponent,
        MatTableModule,
        CommonModule,
        MatExpansionModule,
        MatMenuModule,
        MatChipsModule,
        TotalCostFormatting,
        ProgressBarComponent,
        InlineSelectComponent,
        MatSlideToggleModule,
        MatDividerModule,
        MatListModule,
        PaymentPopupComponent,
        GeoRegioSelectComponent,
        ApplianceSliderComponent,
        MatSliderModule,
        MatNativeDateModule,
        BuildingStructureSelectorComponent,
        SupplierProductSelectorDialogComponent,
        SupplierProductInfoComponent,
        SupplierProductEditorComponent,
        ImageUploadComponent
    ],
    providers: [MatIconRegistry, DatePipe, DecimalPipe]
})
export class SharedModule {
    constructor(
        private readonly matIconRegistry: MatIconRegistry,
        private readonly domSanitizer: DomSanitizer
    ) {
        this.matIconRegistry.addSvgIcon(
            'map_pin',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/map_pin.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'location_pin',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/location-pin.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'electricity',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/electricity.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'fire_protection',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/fireprotection.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'mechanical_engineering',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/mechanicalengineering.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'method_of_contruction',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/methodofcontruction.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'where_the_property',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/whereTheProperty.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'plot_type',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/plotType.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'design_frame',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/designFrame.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'landscape_architecture',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/landscapeArchitecture.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'public_utility',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/publicUtility.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'gross_level_area',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/grossLevelArea.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'planning_year',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/planningYear.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'contact_list',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/contact-list.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'plot_size',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/plotSize.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'design_year',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/designYear.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'currency',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/currency.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'city',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/city.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'street',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/street.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'lot_no',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/lotNo.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'street_no',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/streetNo.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'zip_code',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/zipCode.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'type',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/type.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'lot_no',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/lotNo.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'search',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/search.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'compare_delete',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/deleteCompare.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'close',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/close.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'check_mark',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/checkMark.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'projects',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/menuProjects.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'map',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/menuMap.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'sales',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/menuSales.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'itemmanagement',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/menuItemmanagement.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'users',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/menuUsers.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'profile',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/menuProfile.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'logout',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/menuLogout.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'arrow',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/arrow.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'menu',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/menu.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'selected_language',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/selectedLanguage.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'circle_close',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/circleClose.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'circle_check_mark',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/circleCheckMark.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'upload',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/upload.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'keystone-logo-v2',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/keystone-logo-v2.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'keystone-logo',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/keystone-logo.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'keystone-loading',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/keystone-loading.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'green-circle',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/greenCircle.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'green-circle-checkmark',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/greenCircleCheckMark.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'lot-of-money',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/lotOfMoney.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'kid_star',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/kid_star.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'planner',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/planner.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-blueprint',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-blueprint.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-construction',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-construction.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-destination',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-destination.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-eco-house',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-eco-house.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-home',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-home.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-home-1',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-home-1.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-home-3',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-home-3.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-housing-area',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-housing-area.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-interior-design',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-interior-design.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-location',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-location.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-maintenance',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-maintenance.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-natural-gas',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-natural-gas.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-natural-gas-1',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-natural-gas-1.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-pipe',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-pipe.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-power',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-power.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-rain',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-rain.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-smart-home',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-smart-home.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-urban-planning',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-urban-planning.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-valley',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-valley.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-water',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-water.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-area',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-area.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-wooden-house',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-wooden-house.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-air-flow',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-air-flow.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-downhill-terrain',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-downhill-terrain.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-fence',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-fence.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-fence-star',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-fence-star.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-flat-terrain',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-flat-terrain.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-marshland-area',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-marshland-area.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-solar-panel',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-solar-panel.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-toxic-area',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-toxic-area.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-furniture',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-furniture.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-mining',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-mining.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'unique-smart-home-2',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/unique-smart-home-2.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'model-phase',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/model-phase.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'no-model-phase',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/no-model-phase.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'floor0-0',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/floor0-0.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'floor0-1',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/floor0-1.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'floor0-2',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/floor0-2.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'floor0',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/floor0.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'floor1',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/floor1.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'floor2',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/floor2.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'floor3',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/floor3.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'green-email',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/green-email.svg')
        );
        this.matIconRegistry.addSvgIcon(
            'renew-subscription',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                '/assets/imgs/icons/renew-subscription.svg'
            )
        );
        this.matIconRegistry.addSvgIcon(
            'unique-offer',
            this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/imgs/icons/unique-offer.svg')
        );

        const icons = [
            'plan',
            'diamond',
            'diamond-color',
            'quote',
            'family-house',
            'holiday-resort',
            'other-building',
            'upload-file',
            'umbrella-beach-regular',
            'plan-current-color',
            'new-project',
            'icon-done',
            'icon-info',
            'icon-x',
            'icon-alert',
            'upload-cloud',
            'file'
        ];

        for (const icon of icons) {
            this.matIconRegistry.addSvgIcon(
                icon,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/imgs/icons/${icon}.svg`)
            );
        }
    }
}
