import { createAction, props } from '@ngrx/store';
import { LandImprovementTypeArchiveEntity } from 'app/api';

export const getLandImprovementTypes = createAction(
    '[Land Improvement Types Tab] Get land improvement types from backend',
    props<{ date: string | null }>()
);
export const getLandImprovementTypesSuccess = createAction(
    '[Land Improvement Types Tab] Get land improvement types from backend success',
    props<{ landImprovementTypes: Array<LandImprovementTypeArchiveEntity> }>()
);
export const getLandImprovementTypesFailure = createAction(
    '[Land Improvement Types Tab] Get land improvement types from backend failure',
    props<{ error: string | null }>()
);

export const getLandImprovementTypeArchive = createAction(
    '[Land Improvement Types Tab] Get land improvement type archives from backend',
    props<{ id: string }>()
);
export const getLandImprovementTypeArchiveSuccess = createAction(
    '[Land Improvement Types Tab] Get land improvement type archives from backend success',
    props<{ id: string; landImprovementTypeArchives: Array<LandImprovementTypeArchiveEntity> }>()
);
export const getLandImprovementTypeArchiveFailure = createAction(
    '[Land Improvement Types Tab] Get land improvement type archives from backend failure',
    props<{ error: string | null }>()
);

export const editLandImprovementTypes = createAction(
    '[Land Improvement Types Tab] Edit land improvement types from backend',
    props<{
        id: string;
        categoryName: string;
        value: number;
        multiplier: number;
        labourCost: number;
    }>()
);
export const editLandImprovementTypesSuccess = createAction(
    '[Land Improvement Types Tab] Edit land improvement types from backend success',
    props<{
        id: string;
        categoryName: string;
        value: number;
        multiplier: number;
        labourCost: number;
    }>()
);
export const editLandImprovementTypesFailure = createAction(
    '[Land Improvement Types Tab] Edit land improvement types from backend failure',
    props<{ error: string | null }>()
);

export const logout = createAction('[Header] Logout');
export const clearLandImprovementTypesData = createAction(
    '[Land Improvement Types Tab] Clear land improvement type data'
);
