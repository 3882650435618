import { createReducer, on } from '@ngrx/store';
import {
    adminActivateWaitingUser,
    adminActivateWaitingUserFailure,
    adminActivateWaitingUserSuccess,
    adminDeleteWaitingUser,
    adminDeleteWaitingUserFailure,
    adminDeleteWaitingUserSuccess,
    adminGetActiveUsers,
    adminGetActiveUsersFailure,
    adminGetActiveUsersSuccess,
    adminGetWaitingUsers,
    adminGetWaitingUsersFailure,
    adminGetWaitingUsersSuccess
} from './admin.actions';
import { AdminState } from './admin.state';

export const initialState: AdminState = {
    waitingUsers: null,
    waitingUsersInProgress: false,
    waitingUsersError: null,
    activeUsers: null,
    activeUsersInProgress: false,
    activeUsersError: null,
    deleteWaitingUserInProgress: false,
    deleteWaitingUserError: null,
    activateWaitingUserInProgress: false,
    activateWaitingUserError: null
};

export const adminReducer = createReducer(
    initialState,
    on(adminGetWaitingUsers, (state) => ({
        ...state,
        waitingUsersInProgress: true,
        waitingUsersError: null
    })),
    on(adminGetWaitingUsersSuccess, (state, { waitingUsers }) => ({
        ...state,
        waitingUsers,
        waitingUsersInProgress: false
    })),
    on(adminGetWaitingUsersFailure, (state, { error }) => ({
        ...state,
        waitingUsersInProgress: false,
        waitingUsersError: error
    })),
    on(adminGetActiveUsers, (state) => ({
        ...state,
        activeUsersInProgress: true,
        activeUsersError: null
    })),
    on(adminGetActiveUsersSuccess, (state, { activeUsers }) => ({
        ...state,
        activeUsers,
        activeUsersInProgress: false
    })),
    on(adminGetActiveUsersFailure, (state, { error }) => ({
        ...state,
        activeUsersInProgress: false,
        activeUsersError: error
    })),
    on(adminDeleteWaitingUser, (state, { userId }) => ({
        ...state,
        deleteWaitingUserInProgress: true,
        deleteWaitingUserError: null
    })),
    on(adminDeleteWaitingUserSuccess, (state) => ({
        ...state,
        deleteWaitingUserInProgress: false
    })),
    on(adminDeleteWaitingUserFailure, (state, { error }) => ({
        ...state,
        deleteWaitingUserInProgress: false,
        deleteWaitingUserError: error
    })),
    on(adminActivateWaitingUser, (state, { userId }) => ({
        ...state,
        activateWaitingUserInProgress: true,
        activateWaitingUserError: null
    })),
    on(adminActivateWaitingUserSuccess, (state) => ({
        ...state,
        activateWaitingUserInProgress: false
    })),
    on(adminActivateWaitingUserFailure, (state, { error }) => ({
        ...state,
        activateWaitingUserInProgress: false,
        activateWaitingUserError: error
    }))
);
