/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseApiService } from '../base-api-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApplianceSimpleEntity } from '../models/appliance-simple-entity';
import { ApplianceTypeEntity } from '../models/appliance-type-entity';
import { BuildingStructureEntity } from '../models/building-structure-entity';
import { BuildingStructureProductEntity } from '../models/building-structure-product-entity';
import { BuildingStructureWithSupplierEntity } from '../models/building-structure-with-supplier-entity';
import { CurrencyEntity } from '../models/currency-entity';
import { DraftPhaseGeoRegionEntity } from '../models/draft-phase-geo-region-entity';
import { GeoRegionEntity } from '../models/geo-region-entity';
import { GeoRegionStatDetailsEntity } from '../models/geo-region-stat-details-entity';
import { LandImprovementTypeEntity } from '../models/land-improvement-type-entity';
import { LookupEntity } from '../models/lookup-entity';
import { PlotTypeEntity } from '../models/plot-type-entity';
import { PublicCityEntity } from '../models/public-city-entity';
import { RoleResponseDto } from '../models/role-response-dto';
import { SubscriptionTypeEntity } from '../models/subscription-type-entity';
import { UtilityTypeEntity } from '../models/utility-type-entity';

@Injectable({
    providedIn: 'root'
})
export class GeneralApiService extends BaseApiService {
    constructor(config: ApiConfiguration, http: HttpClient) {
        super(config, http);
    }

    /**
     * Path part for operation getCurrencies
     */
    static readonly GetCurrenciesPath = '/general/currencies';

    /**
     * Returns all currencies.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getCurrencies()` instead.
     *
     * This method doesn't expect any request body.
     */
    getCurrencies$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<CurrencyEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, GeneralApiService.GetCurrenciesPath, 'get');
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<CurrencyEntity>>;
                })
            );
    }

    /**
     * Returns all currencies.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getCurrencies$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getCurrencies(params?: { context?: HttpContext }): Observable<Array<CurrencyEntity>> {
        return this.getCurrencies$Response(params).pipe(
            map((r: StrictHttpResponse<Array<CurrencyEntity>>) => r.body as Array<CurrencyEntity>)
        );
    }

    /**
     * Path part for operation forceRefreshCurrencyRates
     */
    static readonly ForceRefreshCurrencyRatesPath = '/general/force-refresh-currency-rates';

    /**
     * Forceably refreshes currency rates.
     *
     * Soon to be deprecated, don't use this.
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `forceRefreshCurrencyRates()` instead.
     *
     * This method doesn't expect any request body.
     *
     * @deprecated
     */
    forceRefreshCurrencyRates$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.ForceRefreshCurrencyRatesPath,
            'get'
        );
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Forceably refreshes currency rates.
     *
     * Soon to be deprecated, don't use this.
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `forceRefreshCurrencyRates$Response()` instead.
     *
     * This method doesn't expect any request body.
     *
     * @deprecated
     */
    forceRefreshCurrencyRates(params?: { context?: HttpContext }): Observable<void> {
        return this.forceRefreshCurrencyRates$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation getPlotTypes
     */
    static readonly GetPlotTypesPath = '/general/plot-types';

    /**
     * Returns all plot types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getPlotTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getPlotTypes$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<PlotTypeEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, GeneralApiService.GetPlotTypesPath, 'get');
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<PlotTypeEntity>>;
                })
            );
    }

    /**
     * Returns all plot types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getPlotTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getPlotTypes(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<PlotTypeEntity>> {
        return this.getPlotTypes$Response(params).pipe(
            map((r: StrictHttpResponse<Array<PlotTypeEntity>>) => r.body as Array<PlotTypeEntity>)
        );
    }

    /**
     * Path part for operation getCities
     */
    static readonly GetCitiesPath = '/general/cities';

    /**
     * Returns all cities.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getCities()` instead.
     *
     * This method doesn't expect any request body.
     */
    getCities$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<PublicCityEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, GeneralApiService.GetCitiesPath, 'get');
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<PublicCityEntity>>;
                })
            );
    }

    /**
     * Returns all cities.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getCities$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getCities(params?: { context?: HttpContext }): Observable<Array<PublicCityEntity>> {
        return this.getCities$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<PublicCityEntity>>) =>
                    r.body as Array<PublicCityEntity>
            )
        );
    }

    /**
     * Path part for operation getCityByZipCode
     */
    static readonly GetCityByZipCodePath = '/general/city-by-zipcode/{zipcode}';

    /**
     * Returns a city by zip code.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getCityByZipCode()` instead.
     *
     * This method doesn't expect any request body.
     */
    getCityByZipCode$Response(params: {
        zipcode: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<PublicCityEntity>> {
        const rb = new RequestBuilder(this.rootUrl, GeneralApiService.GetCityByZipCodePath, 'get');
        if (params) {
            rb.path('zipcode', params.zipcode, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<PublicCityEntity>;
                })
            );
    }

    /**
     * Returns a city by zip code.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getCityByZipCode$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getCityByZipCode(params: {
        zipcode: string;
        context?: HttpContext;
    }): Observable<PublicCityEntity> {
        return this.getCityByZipCode$Response(params).pipe(
            map((r: StrictHttpResponse<PublicCityEntity>) => r.body as PublicCityEntity)
        );
    }

    /**
     * Path part for operation getGeoRegions
     */
    static readonly GetGeoRegionsPath = '/general/geo-regions';

    /**
     * Returns all geo regions.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getGeoRegions()` instead.
     *
     * This method doesn't expect any request body.
     */
    getGeoRegions$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<GeoRegionEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, GeneralApiService.GetGeoRegionsPath, 'get');
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<GeoRegionEntity>>;
                })
            );
    }

    /**
     * Returns all geo regions.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getGeoRegions$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getGeoRegions(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<GeoRegionEntity>> {
        return this.getGeoRegions$Response(params).pipe(
            map((r: StrictHttpResponse<Array<GeoRegionEntity>>) => r.body as Array<GeoRegionEntity>)
        );
    }

    /**
     * Path part for operation getBuildingStructures
     */
    static readonly GetBuildingStructuresPath = '/general/building-structures';

    /**
     * Returns all building structure types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getBuildingStructures()` instead.
     *
     * This method doesn't expect any request body.
     */
    getBuildingStructures$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<BuildingStructureEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetBuildingStructuresPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<BuildingStructureEntity>>;
                })
            );
    }

    /**
     * Returns all building structure types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getBuildingStructures$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getBuildingStructures(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<BuildingStructureEntity>> {
        return this.getBuildingStructures$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<BuildingStructureEntity>>) =>
                    r.body as Array<BuildingStructureEntity>
            )
        );
    }

    /**
     * Path part for operation getBuildingStructuresWithSupplier
     */
    static readonly GetBuildingStructuresWithSupplierPath =
        '/general/building-structures-with-suppliers';

    /**
     * Returns all building structure types with active suppliers.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getBuildingStructuresWithSupplier()` instead.
     *
     * This method doesn't expect any request body.
     */
    getBuildingStructuresWithSupplier$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<BuildingStructureWithSupplierEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetBuildingStructuresWithSupplierPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<BuildingStructureWithSupplierEntity>>;
                })
            );
    }

    /**
     * Returns all building structure types with active suppliers.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getBuildingStructuresWithSupplier$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getBuildingStructuresWithSupplier(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<BuildingStructureWithSupplierEntity>> {
        return this.getBuildingStructuresWithSupplier$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<BuildingStructureWithSupplierEntity>>) =>
                    r.body as Array<BuildingStructureWithSupplierEntity>
            )
        );
    }

    /**
     * Path part for operation getBuildingStructureProducts
     */
    static readonly GetBuildingStructureProductsPath = '/general/building-structure-products';

    /**
     * Returns all building structure products for active suppliers.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getBuildingStructureProducts()` instead.
     *
     * This method doesn't expect any request body.
     */
    getBuildingStructureProducts$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<BuildingStructureProductEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetBuildingStructureProductsPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<BuildingStructureProductEntity>>;
                })
            );
    }

    /**
     * Returns all building structure products for active suppliers.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getBuildingStructureProducts$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getBuildingStructureProducts(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<BuildingStructureProductEntity>> {
        return this.getBuildingStructureProducts$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<BuildingStructureProductEntity>>) =>
                    r.body as Array<BuildingStructureProductEntity>
            )
        );
    }

    /**
     * Path part for operation getRoles
     */
    static readonly GetRolesPath = '/general/role';

    /**
     * Get all roles.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getRoles()` instead.
     *
     * This method doesn't expect any request body.
     */
    getRoles$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<RoleResponseDto>>> {
        const rb = new RequestBuilder(this.rootUrl, GeneralApiService.GetRolesPath, 'get');
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<RoleResponseDto>>;
                })
            );
    }

    /**
     * Get all roles.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getRoles$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getRoles(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<RoleResponseDto>> {
        return this.getRoles$Response(params).pipe(
            map((r: StrictHttpResponse<Array<RoleResponseDto>>) => r.body as Array<RoleResponseDto>)
        );
    }

    /**
     * Path part for operation getUtilityTypes
     */
    static readonly GetUtilityTypesPath = '/general/utility-types';

    /**
     * Returns all utility types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getUtilityTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getUtilityTypes$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<UtilityTypeEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, GeneralApiService.GetUtilityTypesPath, 'get');
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<UtilityTypeEntity>>;
                })
            );
    }

    /**
     * Returns all utility types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getUtilityTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getUtilityTypes(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<UtilityTypeEntity>> {
        return this.getUtilityTypes$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<UtilityTypeEntity>>) =>
                    r.body as Array<UtilityTypeEntity>
            )
        );
    }

    /**
     * Path part for operation getLandImprovementTypes
     */
    static readonly GetLandImprovementTypesPath = '/general/land-improvement-types';

    /**
     * Returns all land improvement types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getLandImprovementTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getLandImprovementTypes$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<LandImprovementTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetLandImprovementTypesPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<LandImprovementTypeEntity>>;
                })
            );
    }

    /**
     * Returns all land improvement types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getLandImprovementTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getLandImprovementTypes(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<LandImprovementTypeEntity>> {
        return this.getLandImprovementTypes$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<LandImprovementTypeEntity>>) =>
                    r.body as Array<LandImprovementTypeEntity>
            )
        );
    }

    /**
     * Path part for operation getProjectsPerGeoRegions
     */
    static readonly GetProjectsPerGeoRegionsPath = '/general/projects-geo-regions';

    /**
     * Returns all geo regions and count of closed draft phases for each region with the sum of total costs.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getProjectsPerGeoRegions()` instead.
     *
     * This method doesn't expect any request body.
     */
    getProjectsPerGeoRegions$Response(params: {
        all: boolean;

        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<DraftPhaseGeoRegionEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetProjectsPerGeoRegionsPath,
            'get'
        );
        if (params) {
            rb.query('all', params.all, {});
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<DraftPhaseGeoRegionEntity>>;
                })
            );
    }

    /**
     * Returns all geo regions and count of closed draft phases for each region with the sum of total costs.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getProjectsPerGeoRegions$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getProjectsPerGeoRegions(params: {
        all: boolean;

        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<DraftPhaseGeoRegionEntity>> {
        return this.getProjectsPerGeoRegions$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<DraftPhaseGeoRegionEntity>>) =>
                    r.body as Array<DraftPhaseGeoRegionEntity>
            )
        );
    }

    /**
     * Path part for operation getGeoRegionProjectDetails
     */
    static readonly GetGeoRegionProjectDetailsPath = '/general/projects-geo-regions/{id}';

    /**
     * Retrieve the sum of total costs and total area, including building functions (if applicable), for all closed phases within a given region.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getGeoRegionProjectDetails()` instead.
     *
     * This method doesn't expect any request body.
     */
    getGeoRegionProjectDetails$Response(params: {
        id: string;
        all: boolean;

        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<GeoRegionStatDetailsEntity>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetGeoRegionProjectDetailsPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
            rb.query('all', params.all, {});
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<GeoRegionStatDetailsEntity>;
                })
            );
    }

    /**
     * Retrieve the sum of total costs and total area, including building functions (if applicable), for all closed phases within a given region.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getGeoRegionProjectDetails$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getGeoRegionProjectDetails(params: {
        id: string;
        all: boolean;

        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<GeoRegionStatDetailsEntity> {
        return this.getGeoRegionProjectDetails$Response(params).pipe(
            map(
                (r: StrictHttpResponse<GeoRegionStatDetailsEntity>) =>
                    r.body as GeoRegionStatDetailsEntity
            )
        );
    }

    /**
     * Path part for operation getApplianceTypes
     */
    static readonly GetApplianceTypesPath = '/general/appliance-types';

    /**
     * Returns all general appliance types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getApplianceTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getApplianceTypes$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, GeneralApiService.GetApplianceTypesPath, 'get');
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeEntity>>;
                })
            );
    }

    /**
     * Returns all general appliance types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getApplianceTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getApplianceTypes(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<ApplianceTypeEntity>> {
        return this.getApplianceTypes$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeEntity>>) =>
                    r.body as Array<ApplianceTypeEntity>
            )
        );
    }

    /**
     * Path part for operation getAppliances
     */
    static readonly GetAppliancesPath = '/general/appliances';

    /**
     * Returns all function level general appliances.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getAppliances()` instead.
     *
     * This method doesn't expect any request body.
     */
    getAppliances$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceSimpleEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, GeneralApiService.GetAppliancesPath, 'get');
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceSimpleEntity>>;
                })
            );
    }

    /**
     * Returns all function level general appliances.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getAppliances$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getAppliances(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<ApplianceSimpleEntity>> {
        return this.getAppliances$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceSimpleEntity>>) =>
                    r.body as Array<ApplianceSimpleEntity>
            )
        );
    }

    /**
     * Path part for operation unsubscribeNewsLetter
     */
    static readonly UnsubscribeNewsLetterPath = '/general/unsubscribe-newsletter/{id}';

    /**
     * Inquires a newsletter subscription.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `unsubscribeNewsLetter()` instead.
     *
     * This method doesn't expect any request body.
     */
    unsubscribeNewsLetter$Response(params: {
        id: string;
        context?: HttpContext;
    }): Observable<StrictHttpResponse<void>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.UnsubscribeNewsLetterPath,
            'get'
        );
        if (params) {
            rb.path('id', params.id, {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'text',
                    accept: '*/*',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return (r as HttpResponse<any>).clone({
                        body: undefined
                    }) as StrictHttpResponse<void>;
                })
            );
    }

    /**
     * Inquires a newsletter subscription.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `unsubscribeNewsLetter$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    unsubscribeNewsLetter(params: { id: string; context?: HttpContext }): Observable<void> {
        return this.unsubscribeNewsLetter$Response(params).pipe(
            map((r: StrictHttpResponse<void>) => r.body as void)
        );
    }

    /**
     * Path part for operation getSubscriptionTypes
     */
    static readonly GetSubscriptionTypesPath = '/general/subscription-types';

    /**
     * Returns all subscription types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getSubscriptionTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getSubscriptionTypes$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<SubscriptionTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetSubscriptionTypesPath,
            'get'
        );
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<SubscriptionTypeEntity>>;
                })
            );
    }

    /**
     * Returns all subscription types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getSubscriptionTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getSubscriptionTypes(params?: {
        context?: HttpContext;
    }): Observable<Array<SubscriptionTypeEntity>> {
        return this.getSubscriptionTypes$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<SubscriptionTypeEntity>>) =>
                    r.body as Array<SubscriptionTypeEntity>
            )
        );
    }

    /**
     * Path part for operation getHeatPumpApplianceTypes
     */
    static readonly GetHeatPumpApplianceTypesPath = '/general/heat-pump-appliance-types';

    /**
     * Returns all heat pump appliance types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getHeatPumpApplianceTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getHeatPumpApplianceTypes$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetHeatPumpApplianceTypesPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeEntity>>;
                })
            );
    }

    /**
     * Returns all heat pump appliance types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getHeatPumpApplianceTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getHeatPumpApplianceTypes(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<ApplianceTypeEntity>> {
        return this.getHeatPumpApplianceTypes$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeEntity>>) =>
                    r.body as Array<ApplianceTypeEntity>
            )
        );
    }

    /**
     * Path part for operation getHeatPumpAppliances
     */
    static readonly GetHeatPumpAppliancesPath = '/general/heat-pumps';

    /**
     * Returns all heat pump appliances.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getHeatPumpAppliances()` instead.
     *
     * This method doesn't expect any request body.
     */
    getHeatPumpAppliances$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceSimpleEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetHeatPumpAppliancesPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceSimpleEntity>>;
                })
            );
    }

    /**
     * Returns all heat pump appliances.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getHeatPumpAppliances$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getHeatPumpAppliances(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<ApplianceSimpleEntity>> {
        return this.getHeatPumpAppliances$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceSimpleEntity>>) =>
                    r.body as Array<ApplianceSimpleEntity>
            )
        );
    }

    /**
     * Path part for operation getSolarCollectorApplianceTypes
     */
    static readonly GetSolarCollectorApplianceTypesPath =
        '/general/solar-collector-appliance-types';

    /**
     * Returns all solar collector appliance types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getSolarCollectorApplianceTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getSolarCollectorApplianceTypes$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetSolarCollectorApplianceTypesPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeEntity>>;
                })
            );
    }

    /**
     * Returns all solar collector appliance types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getSolarCollectorApplianceTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getSolarCollectorApplianceTypes(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<ApplianceTypeEntity>> {
        return this.getSolarCollectorApplianceTypes$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeEntity>>) =>
                    r.body as Array<ApplianceTypeEntity>
            )
        );
    }

    /**
     * Path part for operation getSolarCollectorAppliances
     */
    static readonly GetSolarCollectorAppliancesPath = '/general/solar-collectors';

    /**
     * Returns all solar collector appliances.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getSolarCollectorAppliances()` instead.
     *
     * This method doesn't expect any request body.
     */
    getSolarCollectorAppliances$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceSimpleEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetSolarCollectorAppliancesPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceSimpleEntity>>;
                })
            );
    }

    /**
     * Returns all solar collector appliances.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getSolarCollectorAppliances$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getSolarCollectorAppliances(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<ApplianceSimpleEntity>> {
        return this.getSolarCollectorAppliances$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceSimpleEntity>>) =>
                    r.body as Array<ApplianceSimpleEntity>
            )
        );
    }

    /**
     * Path part for operation getWindEnergyApplianceTypes
     */
    static readonly GetWindEnergyApplianceTypesPath = '/general/wind-energy-appliance-types';

    /**
     * Returns all wind energy appliance types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getWindEnergyApplianceTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWindEnergyApplianceTypes$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetWindEnergyApplianceTypesPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeEntity>>;
                })
            );
    }

    /**
     * Returns all wind energy appliance types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getWindEnergyApplianceTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWindEnergyApplianceTypes(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<ApplianceTypeEntity>> {
        return this.getWindEnergyApplianceTypes$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeEntity>>) =>
                    r.body as Array<ApplianceTypeEntity>
            )
        );
    }

    /**
     * Path part for operation getWindEnergyAppliances
     */
    static readonly GetWindEnergyAppliancesPath = '/general/wind-energies';

    /**
     * Returns all wind energy appliances.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getWindEnergyAppliances()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWindEnergyAppliances$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceSimpleEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetWindEnergyAppliancesPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceSimpleEntity>>;
                })
            );
    }

    /**
     * Returns all wind energy appliances.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getWindEnergyAppliances$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getWindEnergyAppliances(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<ApplianceSimpleEntity>> {
        return this.getWindEnergyAppliances$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceSimpleEntity>>) =>
                    r.body as Array<ApplianceSimpleEntity>
            )
        );
    }

    /**
     * Path part for operation getFenceApplianceTypes
     */
    static readonly GetFenceApplianceTypesPath = '/general/fence-appliance-types';

    /**
     * Returns all fence appliance types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getFenceApplianceTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getFenceApplianceTypes$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceTypeEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetFenceApplianceTypesPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceTypeEntity>>;
                })
            );
    }

    /**
     * Returns all fence appliance types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getFenceApplianceTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getFenceApplianceTypes(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<ApplianceTypeEntity>> {
        return this.getFenceApplianceTypes$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceTypeEntity>>) =>
                    r.body as Array<ApplianceTypeEntity>
            )
        );
    }

    /**
     * Path part for operation getFenceAppliances
     */
    static readonly GetFenceAppliancesPath = '/general/fences';

    /**
     * Returns all fence appliances.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getFenceAppliances()` instead.
     *
     * This method doesn't expect any request body.
     */
    getFenceAppliances$Response(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<ApplianceSimpleEntity>>> {
        const rb = new RequestBuilder(
            this.rootUrl,
            GeneralApiService.GetFenceAppliancesPath,
            'get'
        );
        if (params) {
            rb.header('Accept-Language', params['Accept-Language'], {});
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<ApplianceSimpleEntity>>;
                })
            );
    }

    /**
     * Returns all fence appliances.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getFenceAppliances$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getFenceAppliances(params?: {
        /**
         * Language to be used for the response
         */
        'Accept-Language'?: 'hu' | 'en';
        context?: HttpContext;
    }): Observable<Array<ApplianceSimpleEntity>> {
        return this.getFenceAppliances$Response(params).pipe(
            map(
                (r: StrictHttpResponse<Array<ApplianceSimpleEntity>>) =>
                    r.body as Array<ApplianceSimpleEntity>
            )
        );
    }

    /**
     * Path part for operation getBuildingTypes
     */
    static readonly GetBuildingTypesPath = '/general/building-types';

    /**
     * Returns all building types.
     *
     *
     *
     * This method provides access to the full `HttpResponse`, allowing access to response headers.
     * To access only the response body, use `getBuildingTypes()` instead.
     *
     * This method doesn't expect any request body.
     */
    getBuildingTypes$Response(params?: {
        context?: HttpContext;
    }): Observable<StrictHttpResponse<Array<LookupEntity>>> {
        const rb = new RequestBuilder(this.rootUrl, GeneralApiService.GetBuildingTypesPath, 'get');
        if (params) {
        }

        return this.http
            .request(
                rb.build({
                    responseType: 'json',
                    accept: 'application/json',
                    context: params?.context
                })
            )
            .pipe(
                filter((r: any) => r instanceof HttpResponse),
                map((r: HttpResponse<any>) => {
                    return r as StrictHttpResponse<Array<LookupEntity>>;
                })
            );
    }

    /**
     * Returns all building types.
     *
     *
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getBuildingTypes$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getBuildingTypes(params?: { context?: HttpContext }): Observable<Array<LookupEntity>> {
        return this.getBuildingTypes$Response(params).pipe(
            map((r: StrictHttpResponse<Array<LookupEntity>>) => r.body as Array<LookupEntity>)
        );
    }
}
