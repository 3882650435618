import { createAction, props } from '@ngrx/store';
import { ApplianceItemArchiveEntity, ApplianceTypeEntity } from 'app/api';

/* Get Appliance */
export const getWindEnergyApplianceTypes = createAction(
    '[Wind Energy Types Tab] Get wind energy types from backend',
    props<{ date: string | null }>()
);
export const getWindEnergyApplianceTypesSuccess = createAction(
    '[Wind Energy Types Tab] Get wind energy types from backend success',
    props<{ windEnergyApplianceTypes: Array<ApplianceTypeEntity> }>()
);
export const getWindEnergyApplianceTypesFailure = createAction(
    '[Wind Energy Types Tab] Get wind energy types from backend failure',
    props<{ error: string | null }>()
);

/* Get Appliance Archives */
export const getWindEnergyApplianceTypeArchive = createAction(
    '[Wind Energy Types Tab] Get wind energy type archives from backend',
    props<{ id: string }>()
);
export const getWindEnergyApplianceTypeArchiveSuccess = createAction(
    '[Wind Energy Types Tab] Get wind energy type archives from backend success',
    props<{ id: string; windEnergyApplianceTypeArchives: ApplianceItemArchiveEntity[] }>()
);
export const getWindEnergyApplianceTypeArchiveFailure = createAction(
    '[Wind Energy Types Tab] Get wind energy type archives from backend failure',
    props<{ error: string | null }>()
);

/* Edit Appliance item */
export const editWindEnergyApplianceItem = createAction(
    '[Wind Energy Types Tab] Edit wind energy type to backend',
    props<{
        id: string;
        name: string;
        value: number;
        labourCost: number;
        nameTranslation: { hu: string; en: string };
    }>()
);
export const editWindEnergyApplianceItemSuccess = createAction(
    '[Wind Energy Types Tab] Edit wind energy type to backend success',
    props<{ id: string; name: string; value: number }>()
);
export const editWindEnergyApplianceItemFailure = createAction(
    '[Wind Energy Types Tab] Edit wind energy type to backend failure',
    props<{ error: string | null }>()
);

/* Save Appliance item */
export const saveWindEnergyApplianceItem = createAction(
    '[Wind Energy Types Tab] Save Wind energy appliance Item to backend',
    props<{
        id: string;
        name: string;
        value: number;
        labourCost: number;
        nameTranslation: { hu: string; en: string };
    }>()
);
export const saveWindEnergyApplianceItemSuccess = createAction(
    '[Wind Energy Types Tab] Save Wind energy appliance Item to backend success',
    props<{ windEnergyApplianceTypes: Array<ApplianceTypeEntity> }>()
);
export const saveWindEnergyApplianceItemFailure = createAction(
    '[Wind Energy Types Tab] Save Wind energy appliance Item to backend failure',
    props<{ error: string | null }>()
);

/* Delete Appliance item */
export const deleteWindEnergyApplianceItem = createAction(
    '[Wind Energy Types Tab] Delete Wind energy appliance Item from backend',
    props<{ id: string }>()
);
export const deleteWindEnergyapplianceItemSuccess = createAction(
    '[Wind Energy Types Tab] Delete Wind energy appliance Item from backend success',
    props<{ id: string }>()
);
export const deleteWindEnergyApplianceItemFailure = createAction(
    '[Wind Energy Types Tab] Delete Wind energy appliance Item from backend failure',
    props<{ error: string | null }>()
);

/* Clear Appliance items */
export const logout = createAction('[Header] Logout');
export const clearWindEnergyTypesData = createAction(
    '[Wind Energy Types Tab] Clear wind energy types data'
);
