import { createReducer, on } from '@ngrx/store';
import {
    clearHeatPumpTypesData,
    getHeatPumpApplianceTypeArchive,
    getHeatPumpApplianceTypeArchiveFailure,
    getHeatPumpApplianceTypeArchiveSuccess,
    getHeatPumpApplianceTypes,
    getHeatPumpApplianceTypesFailure,
    getHeatPumpApplianceTypesSuccess
} from './heat-pump.actions';
import { HeatPumpState } from './heat-pump.state';

export const initialState: HeatPumpState = {
    heatPumpApplianceTypes: [],
    heatPumpApplianceTypesInProgress: false,
    heatPumpApplianceTypesError: null,
    heatPumpApplianceTypeArchives: [],
    heatPumpApplianceTypeArchivesInProgress: false,
    heatPumpApplianceTypeArchivesError: null
};

export const heatPumpReducer = createReducer(
    initialState,
    /* Get Appliance */
    on(getHeatPumpApplianceTypes, (state) => ({
        ...state,
        heatPumpApplianceTypesInProgress: true,
        heatPumpApplianceTypesError: null
    })),
    on(getHeatPumpApplianceTypesSuccess, (state, { heatPumpApplianceTypes }) => ({
        ...state,
        heatPumpApplianceTypes,
        heatPumpApplianceTypesInProgress: false,
        heatPumpApplianceTypesError: null
    })),
    on(getHeatPumpApplianceTypesFailure, (state, { error }) => ({
        ...state,
        heatPumpApplianceTypesInProgress: false,
        heatPumpApplianceTypesError: error
    })),
    /* Get Appliance Archives */
    on(getHeatPumpApplianceTypeArchive, (state, { id }) => ({
        ...state,
        heatPumpApplianceTypeArchivesInProgress: true,
        heatPumpApplianceTypeArchivesError: null
    })),
    on(getHeatPumpApplianceTypeArchiveSuccess, (state, { heatPumpApplianceTypeArchives }) => ({
        ...state,
        heatPumpApplianceTypeArchives,
        heatPumpApplianceTypeArchivesInProgress: false,
        heatPumpApplianceTypeArchivesError: null
    })),
    on(getHeatPumpApplianceTypeArchiveFailure, (state, { error }) => ({
        ...state,
        heatPumpApplianceTypeArchivesInProgress: false,
        heatPumpApplianceTypeArchivesError: error
    })),
    on(clearHeatPumpTypesData, (state) => ({
        ...initialState
    }))
);
