import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { IWorkEntryEntity } from 'app/api/models/work-entry-entity';
import { IWorkTypeEntity, WorkTypeEntity } from 'app/api/models/work-type-entity';
import { getProjectSubTotal } from 'app/shared/helpers/project.helpers';
import { firstValueFrom } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { SummaryTableComponent, WorkEntry } from './summary-table.component';

@Injectable()
export class SummaryTableService {
    public summaryTableComponent!: SummaryTableComponent | null;

    constructor(
        public readonly dialog: MatDialog,
        private readonly translocoService: TranslocoService
    ) {}

    // Column cost-info, actions
    public getEntriesFor(workType: IWorkTypeEntity, fromList?: WorkEntry[]): WorkEntry[] {
        if (!this.summaryTableComponent) return [];

        return (Array.isArray(fromList) ? fromList : this.summaryTableComponent.entries)
            .filter((entry) => !entry.isCategory)
            .filter((entry) => entry.typeId === workType.id)
            .filter(
                (entry) =>
                    this.summaryTableComponent!.showShadowedRowsControl.value || !entry.shadowed
            )
            .map((entry, i) => ({
                ...entry,
                name: entry.name ?? workType.name,
                versionId: workType.major + '.' + (workType.minor ?? 0 + (i + 1)),
                version: workType.minor
                    ? `${workType.major}.${workType.minor}`
                    : workType.major.toString()
            }));
    }
    // Column cost-info, actions
    public getSubcategoriesFor(workType: IWorkTypeEntity): WorkTypeEntity[] {
        if (!this.summaryTableComponent || workType.minor != null) return [];

        return this.summaryTableComponent.workTypes
            .filter((wt) => wt.minor != null)
            .filter((wt) => {
                return wt.major === workType.major;
            });
    }
    // Column cost-info
    public toggleCategory(row: WorkEntry): void {
        const rowCategory = row.workType;

        if (!this.summaryTableComponent || !rowCategory?.id) return;

        const rowVersion = rowCategory?.minor
            ? `${rowCategory?.major}.${rowCategory?.minor}`
            : rowCategory.major.toString();
        if (!rowCategory.minor) {
            if (this.summaryTableComponent.toggledCategories.has(rowVersion)) {
                this.summaryTableComponent.toggledCategories.delete(rowVersion);
                this.summaryTableComponent.toggledCategories.forEach((ver) => {
                    if (ver && ver.startsWith(rowVersion + '.')) {
                        this.summaryTableComponent!.toggledCategories.delete(ver);
                    }
                });
            } else {
                this.summaryTableComponent.toggledCategories.add(rowVersion);
            }
        } else {
            const isCategoryToggled = this.summaryTableComponent.toggledCategories.has(rowVersion);
            if (isCategoryToggled) {
                this.summaryTableComponent.toggledCategories.delete(rowVersion);
            } else {
                this.summaryTableComponent.toggledCategories.add(rowVersion);
            }
        }
        this.calculateTableData(this.summaryTableComponent.dataSource.data);
    }

    // Column Shadowed
    public findEntryToEdit(entry: WorkEntry): WorkEntry | undefined {
        if (!this.summaryTableComponent) return;
        return this.summaryTableComponent.dataSource.data.find((data) => data.id === entry.id);
    }
    // Column Shadowed
    public updateTableDataWith(entry: { id?: string }): void {
        if (!this.summaryTableComponent) return;
        this.summaryTableComponent.dataSource.data = this.summaryTableComponent.dataSource.data.map(
            (data) => (data.id === entry.id ? (entry as WorkEntry) : data)
        );
    }
    // Column Shadowed
    public onShadowedChange(shadowed: boolean, entry: WorkEntry): void {
        const editedEntry = this.findEntryToEdit(entry);
        if (!editedEntry) return;

        const updatedEntry: WorkEntry = {
            ...editedEntry,
            shadowed
        };

        this.updateTableDataWith(updatedEntry);
    }
    // Column name
    public onEditCell(
        newValue: string,
        property: keyof WorkEntry,
        entry: WorkEntry,
        castToNumber?: boolean
    ): void {
        const editedEntry = this.findEntryToEdit(entry);
        if (!editedEntry) {
            return;
        }

        const updatedEntry: WorkEntry = {
            ...editedEntry,
            [property]: castToNumber ? +newValue : newValue
        };

        this.updateTableDataWith(updatedEntry);
    }

    // has different units
    public hasDifferentUnits(workType: IWorkTypeEntity): boolean {
        if (!this.summaryTableComponent) return false;
        const isMainCategory = this.isMainCategory(workType);

        const subCategories = this.summaryTableComponent.workTypes
            .filter(Boolean)
            .filter((type) => type.minor !== null && type.major === workType?.major);

        const subCategoryIds = subCategories.map((category) => category.id);

        const correspondingEntries = this.summaryTableComponent.dataSource.data
            .filter((entry) =>
                isMainCategory
                    ? entry.workType?.id === workType?.id ||
                      subCategoryIds.includes(entry.workType?.id!)
                    : entry.workType?.id === workType?.id
            )
            .filter((entry) => !entry.isCategory)
            .filter((entry) => !entry.shadowed);
        return correspondingEntries
            .filter((e) => {
                const compareCategory =
                    e.workType?.omniclass == '21-02' ? e.function ?? e.workType : e.workType;

                if (workType.minor) {
                    // this is a subcategory
                    return (
                        compareCategory?.major == workType.major &&
                        compareCategory?.minor == workType.minor
                    );
                } else {
                    // this is a major category
                    return compareCategory?.major == workType.major;
                }
            })
            .some((e) => e.workType?.unit?.symbol !== workType.unit?.symbol);
    }
    // Column amount
    public aggregateValuesFor(workType: IWorkTypeEntity, field: keyof IWorkEntryEntity): number {
        if (!this.summaryTableComponent) return 0;
        const isMainCategory = this.isMainCategory(workType);

        const subCategories = this.summaryTableComponent.workTypes
            .filter(Boolean)
            .filter((type) => type.minor !== null && type.major === workType?.major);

        const subCategoryIds = subCategories.map((category) => category.id);

        const correspondingEntries = this.summaryTableComponent.dataSource.data
            .filter((entry) =>
                isMainCategory
                    ? entry.workType?.id === workType?.id ||
                      subCategoryIds.includes(entry.workType?.id!)
                    : entry.workType?.id === workType?.id
            )
            .filter((entry) => !entry.isCategory)
            .filter((entry) => !entry.shadowed);

        const hasDifferentUnits = correspondingEntries
            .filter((e) => {
                const compareCategory =
                    e.workType?.omniclass == '21-02' ? e.function ?? e.workType : e.workType;

                if (workType.minor) {
                    // this is a subcategory
                    return (
                        compareCategory?.major == workType.major &&
                        compareCategory?.minor == workType.minor
                    );
                } else {
                    // this is a major category
                    return compareCategory?.major == workType.major;
                }
            })
            .some((e) => e.workType?.unit?.symbol !== workType.unit?.symbol);

        return hasDifferentUnits &&
            (field === 'amount' ||
                field === 'eventualUnitValue' ||
                field === 'eventualUnitLabourCost')
            ? 0
            : correspondingEntries.reduce((acc, entry) => acc + +(entry[field] ?? 0), 0);
    }
    // Column actions
    public onAddNewRow(rowData: WorkEntry) {
        if (!this.summaryTableComponent) return;

        const newEntry = this.createItem(rowData);
        this.summaryTableComponent.dataSource.data = [
            ...this.summaryTableComponent.dataSource.data,
            newEntry
        ];

        // This sort seems to work fine, this might just be a temporary solution since currently i can't seem to find why the first time
        //  I add a new row to a category gets added in the correct place but every subsequent row gets added to the end of the table
        //  instead of the correct place. This sort seems to fix the issue but I'm not sure if it's the best solution.
        this.summaryTableComponent.dataSource.data =
            this.summaryTableComponent.dataSource.data.sort((a, b) => {
                const aValue = (a.workType?.major ?? 1) * 100 + (a.workType?.minor ?? 0);
                const bValue = (b.workType?.major ?? 1) * 100 + (b.workType?.minor ?? 0);

                return aValue - bValue;
            });

        const rowVersion = rowData.workType?.minor
            ? `${rowData.workType?.major}.${rowData.workType?.minor}`
            : rowData.workType?.major.toString();

        if (!this.summaryTableComponent.toggledCategories.has(rowVersion)) {
            this.toggleCategory(rowData);
        }

        this.onEditRow(newEntry);
    }
    // Column actions + function onAddNewRow
    public async onEditRow(entry: WorkEntry): Promise<void> {
        if (!this.summaryTableComponent) return;

        if (this.summaryTableComponent.editedRow) {
            const editedBeforeRow = this.summaryTableComponent.dataSource.data.find(
                (row) => row.id === this.summaryTableComponent!.editedRow?.id
            );

            const diff = this.calculateRowDiff(
                editedBeforeRow!,
                this.summaryTableComponent.originalRow as WorkEntry
            );
            const hasChanged = Object.keys(diff).length > 0;

            if (hasChanged && editedBeforeRow) {
                const shouldSave = await firstValueFrom(
                    this.dialog
                        .open(this.summaryTableComponent.editOverrideConfirmDialogTpl, {
                            width: '500px',
                            data: entry
                        })
                        .afterClosed()
                );

                if (shouldSave) {
                    await this.onSaveRow(editedBeforeRow);
                }
            }
        }

        this.onUndoRow();
        this.summaryTableComponent.editedRow = { ...entry };
        this.summaryTableComponent.originalRow = { ...entry };
    }
    // Column actions + function onEditRow
    public async onSaveRow(entry: WorkEntry): Promise<void> {
        if (!this.summaryTableComponent) return;

        const rowValidationErrors = this.validateRow(entry);
        if (rowValidationErrors) {
            this.summaryTableComponent.editErrors = rowValidationErrors;
            return;
        }

        const isRowSavedOnServer = this.isRowSavedOnServer(entry);
        if (!isRowSavedOnServer) {
            this.summaryTableComponent.addEntry.emit({
                projectId: this.summaryTableComponent.project.id!,
                entry
            });

            return;
        }

        let diff = this.calculateRowDiff(entry);
        const hasChanged = Object.keys(diff).length > 0;
        if (!hasChanged) {
            this.onUndoRow();
            return;
        }

        const numericFieldExclusionErrors = this.validateNumericFieldExclusions(diff);
        if (numericFieldExclusionErrors) {
            this.summaryTableComponent.editErrors = {
                ...this.summaryTableComponent.editErrors,
                numericFieldExclusion: this.translocoService.translate(
                    'project-creation.table.only-one-field-can-be-edited'
                )
            };
            return;
        }

        let dialogToUse;
        if (diff['shadowed'] === true) {
            // remove every other field from diff as changing them does not matter.
            diff = {
                shadowed: true
            };
            dialogToUse = this.summaryTableComponent.saveShadowConfirmDialogTpl;
        } else {
            dialogToUse = diff['percent']
                ? this.summaryTableComponent.savePercentRowConfirmDialogTpl
                : this.summaryTableComponent.saveRowConfirmDialogTpl;
        }

        const shouldSave = await firstValueFrom(
            this.dialog
                .open(dialogToUse, {
                    width: '500px',
                    data: entry
                })
                .afterClosed()
        );

        if (!shouldSave) {
            return;
        }

        this.summaryTableComponent.saveEntry.emit({
            projectId: this.summaryTableComponent.project.id!,
            entryId: entry.id!,
            changes: diff
        });
    }
    // function onSaveRow
    public isRowSavedOnServer(rowData: WorkEntry | null | undefined): boolean {
        if (!this.summaryTableComponent) return false;
        return this.summaryTableComponent._originalEntries.some(
            (entry) => entry.id === rowData?.id
        );
    }
    // Column actions
    public onUndoRow(): void {
        //TODO need this.cdr.markForCheck();
        if (!this.summaryTableComponent) return;
        if (!this.isRowSavedOnServer(this.summaryTableComponent.editedRow as WorkEntry)) {
            this.summaryTableComponent.dataSource.data =
                this.summaryTableComponent.dataSource.data.filter(
                    (row) => row.id !== this.summaryTableComponent!.editedRow?.id
                );
        }

        if (this.summaryTableComponent.originalRow) {
            this.updateTableDataWith(this.summaryTableComponent.originalRow);
            this.summaryTableComponent.originalRow = null;
        }

        this.summaryTableComponent.editedRow = null;
        this.summaryTableComponent.editErrors = null;
        this.summaryTableComponent.footerRowNewPercent = null;
    }
    // Column actions
    public onSaveCategoryVatRate(element: any): void {
        if (!this.summaryTableComponent) return;
        const vatRate = element.vatRate;
        if (vatRate === undefined || vatRate >= 100 || vatRate == null || isNaN(vatRate)) {
            this.summaryTableComponent.editErrors = {
                vatRate: this.translocoService.translate(
                    'project-creation.table.incorrect-vat-value'
                )
            };
            return;
        } else if (vatRate < 0) {
            this.summaryTableComponent.editErrors = {
                vatRate: this.translocoService.translate(
                    'project-creation.table.incorrect-vat-value'
                )
            };
            return;
        } else {
            this.summaryTableComponent.editErrors = null;
        }

        this.summaryTableComponent.saveCategoryVatRate.emit({
            projectId: this.summaryTableComponent.project.id!,
            typeId: element.typeId,
            vatRate: element.vatRate
        });
    }
    // Column actions
    public onUndoCategoryVatRateEdit(): void {
        if (!this.summaryTableComponent) return;
        if (this.summaryTableComponent.originalRow) {
            this.updateTableDataWith(this.summaryTableComponent.originalRow);
            this.summaryTableComponent.originalRow = null;
        }

        this.summaryTableComponent.editedRow = null;
        this.summaryTableComponent.editErrors = null;
    }
    // Column actions
    public async onDeleteNewRow(rowData: WorkEntry) {
        if (!this.summaryTableComponent) return;
        const shouldDelete = await firstValueFrom(
            this.dialog
                .open(this.summaryTableComponent.deleteRowConfirmDialogTpl, {
                    width: '500px',
                    data: rowData
                })
                .afterClosed()
        );

        if (!shouldDelete) {
            return;
        }

        const isRowSavedOnServer = this.summaryTableComponent._originalEntries.some(
            (entry) => entry.id === rowData.id
        );
        if (!isRowSavedOnServer) {
            this.summaryTableComponent.dataSource.data =
                this.summaryTableComponent.dataSource.data.filter(
                    (entry) => entry.id !== rowData.id
                );
            this.calculateTableData(this.summaryTableComponent.dataSource.data);
            return;
        }

        this.summaryTableComponent.deleteEntry.emit({
            projectId: this.summaryTableComponent.project.id!,
            entryId: rowData.id!
        });
    }

    /* --------Not used in HTML-------- */
    // function onDeleteNewRow, toggleCategory, onInit...
    public calculateTableData(fromList?: WorkEntry[]): void {
        if (!this.summaryTableComponent) return;
        const showEmptyCategories = this.summaryTableComponent.showEmptyCategoriesControl.value;

        const groups = this.summaryTableComponent.workTypes.reduce((acc, workType) => {
            const typeEntryList = this.calculateCategoryGroup(workType, fromList);
            if (
                !showEmptyCategories &&
                typeEntryList.length === 1 &&
                !this.isMainCategory(workType)
            ) {
                return acc;
            }

            acc[workType.id] = typeEntryList;

            return acc;
        }, {} as Record<string, WorkEntry[]>);

        const tableData = Object.keys(groups)
            .map((key) => groups[key])
            .flat();

        const entryPercent = tableData.map((entry) => ({
            ...entry,
            percent: entry.isCategory
                ? this.aggregateValuesFor(entry.workType!, 'value') /
                  getProjectSubTotal(fromList ?? this.summaryTableComponent!.entries)
                : (entry.value ?? 0) /
                  getProjectSubTotal(fromList ?? this.summaryTableComponent!.entries)
        }));

        this.summaryTableComponent.dataSource.data = entryPercent;
    }
    // function onAddNewRow
    private createItem(data: WorkEntry): WorkEntry {
        const newDataType = data.workType;

        const newObject = {
            id: uuidv4(),
            name: '',
            unit: newDataType?.unit,
            amount: 1,
            value: 0,
            percent: 0,
            vatRate: 0, // get from category if exists, otherwise use default value: 27
            labourCost: 0,
            isCategory: false,
            version: data?.version,
            projectId: this.summaryTableComponent!.project.id,
            typeId: data.typeId,
            workType: newDataType,
            functionId: data.functionId
        } as WorkEntry;

        return newObject;
    }
    // function onEditRow, onSaveRow
    private calculateRowDiff(entry: WorkEntry, compareTo?: WorkEntry): Record<string, unknown> {
        if (!this.summaryTableComponent) return {};

        return Object.entries(entry).reduce((diff, [key, value]) => {
            const compareToPropVal = compareTo
                ? (compareTo as unknown as Record<string, unknown>)[key]
                : (this.summaryTableComponent!.editedRow as unknown as Record<string, unknown>)[
                      key
                  ];

            const isPropUpdated = value !== compareToPropVal;
            if (isPropUpdated) {
                diff[key] = value;
            }

            return diff;
        }, {} as Record<string, unknown>);
    }
    // onSaveRow function
    private validateRow(entry: WorkEntry): ValidationErrors | null {
        const { amount, value, name, percent, temporaryVatRate } = entry;

        if (!amount || +amount <= 0) {
            return { amount: true };
        }

        if (!value || +value <= 0) {
            return { value: true };
        }

        if (!name || name.length === 0) {
            return { name: true };
        }

        if (amount === undefined && value === undefined) {
            if (!percent || +percent <= 0 || +percent > 99) {
                return { percent: true };
            }
        }

        if (amount >= 1e12 || value >= 1e12) {
            return { amount: true, value: true };
        }

        return null;
    }
    // onSaveRow function
    private validateNumericFieldExclusions(diff: Record<string, unknown>): boolean {
        const { amount, value, percent } = diff;

        if (amount !== undefined && percent !== undefined) {
            return true;
        }

        if (value !== undefined && percent !== undefined) {
            return true;
        }

        return false;
    }
    // function calculateTableData
    private calculateCategoryGroup(workType: WorkTypeEntity, fromList?: WorkEntry[]): WorkEntry[] {
        const typeEntryList = this.getEntriesFor(workType, fromList);
        const list = [];
        const categoryAdded = typeEntryList.some((entry) => entry.isCategory);
        if (!categoryAdded) {
            list.push(this.workTypeToWorkEntry(workType));
        }
        return list.concat(typeEntryList);
    }
    // function calculateCategoryGroup
    private workTypeToWorkEntry(workType: IWorkTypeEntity): WorkEntry {
        const categoryRow = {
            ...workType,
            amount: 0,
            isCategory: true,
            version: workType.minor
                ? `${workType.major}.${workType.minor}`
                : workType.major.toString(),
            id: workType.id,
            name: workType.name,
            projectId: this.summaryTableComponent!.project.id,
            workType,
            percent: undefined,
            typeId: workType.id,
            labourCost: 0,
            value: 0,
            vatRate: 0
        };
        categoryRow.vatRate = this.calculateCategoryVatRate(categoryRow);
        return categoryRow as WorkEntry;
    }
    // function workTypeToWorkEntry
    public calculateCategoryVatRate(workType: IWorkTypeEntity): number {
        if (!this.summaryTableComponent) return 0;
        const isMainCategory = this.isMainCategory(workType);

        const subCategories = this.summaryTableComponent.workTypes.filter(
            (type) => type.minor !== null && type.major === workType?.major
        );

        const subCategoryIds = subCategories.map((category) => category.id);

        const correspondingEntries = this.summaryTableComponent.dataSource.data
            .filter((entry) =>
                isMainCategory
                    ? entry.workType?.id === workType?.id ||
                      subCategoryIds.includes(entry.workType?.id!)
                    : entry.workType?.id === workType?.id
            )
            .filter((entry) => !entry.isCategory)
            .filter((entry) => !entry.shadowed);

        if (correspondingEntries.length === 0) {
            return -1;
        }

        const vatRates = correspondingEntries.map((entry) => entry.vatRate!);

        if (vatRates.every((rate) => rate !== undefined && rate === vatRates[0])) {
            return vatRates[0];
        }

        return -1;
    }
    // function calculateCategoryVatRate, aggregateValuesFor
    public isMainCategory(category: WorkTypeEntity): boolean {
        return category?.major !== undefined && !category?.minor;
    }
}
