import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectActiveLanguageCode } from 'app/store/language/language.selectors';
import { environment } from 'environments/environment';
import { Observable, combineLatest } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { selectAccessToken } from '../store/user/user.selectors';

@Injectable({
    providedIn: 'root'
})
export class AuthTokenInterceptorService implements HttpInterceptor {
    constructor(private readonly store: Store) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.includes(environment.apiUrl)) {
            return next.handle(req);
        }

        if (
            [
                '/general',
                '/user',
                '/project',
                '/draft-phase',
                '/architect-profile',
                '/barion',
                '/asset',
                '/admin',
                '/supplier'
            ].some((pathPart) => req.url.includes(pathPart))
        ) {
            return this.handleUserRequests(req, next);
        }

        return next.handle(req);
    }

    private handleUserRequests(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return combineLatest(
            this.store.select(selectAccessToken),
            this.store.select(selectActiveLanguageCode).pipe(filter(Boolean))
        ).pipe(
            take(1),
            switchMap(([accessToken, languageCode]) => {
                return accessToken
                    ? next.handle(
                          req.clone({
                              setHeaders: {
                                  Authorization: `Bearer ${accessToken}`,
                                  'Accept-Language': languageCode
                              }
                          })
                      )
                    : next.handle(req);
            })
        );
    }
}
